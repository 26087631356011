// @ts-nocheck
import React, { Fragment } from "react";
import zxcvbn from "zxcvbn";

import { withLocalized } from "../HOC";
import CustomLinearProgress from "../CustomLinearProgress/CustomLinearProgress";
import Translate from "../I18n/Translate";

//0 # too guessable: risky password. (guesses < 10^3)
//1 # very guessable: protection from throttled online attacks. (guesses < 10^6)
//2 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
//3 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
//4 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)

const PasswordStrengthMeter = withLocalized(
  ({ localizedString, password, userInputs }) => {
    const testedResult = zxcvbn(password, userInputs);
    const score = testedResult.score;

    const getColorForScore = () => {
      switch (score) {
        case 4:
          return "primary";
        case 3:
          return "success";
        case 2:
          return "warning";
        case 1:
          return "danger";

        default:
          return "gray";
      }
    };

    const getLabelForScore = () => {
      switch (score) {
        case 4:
          return localizedString({
            id: "PasswordStrengthMeter@strong",
            defaultString: "Excelente",
          });
        case 3:
          return localizedString({
            id: "PasswordStrengthMeter@good",
            defaultString: "Ótimo",
          });
        case 2:
          return localizedString({
            id: "PasswordStrengthMeter@fair",
            defaultString: "Bom",
          });

        default:
          return localizedString({
            id: "PasswordStrengthMeter@weak",
            defaultString: "Fraca",
          });
      }
    };

    return (
      <Fragment>
        <CustomLinearProgress
          variant="determinate"
          color={getColorForScore()}
          value={score === 0 ? 10 : score * 25}
        />
        <p style={{ marginTop: -16, fontSize: "0.9em" }}>
          <Translate
            id="PasswordStrengthMeter@label"
            variables={{ passwordStrength: getLabelForScore() }}
          >
            Força da senha: <strong>$passwordStrength</strong>
          </Translate>
        </p>
      </Fragment>
    );
  }
);

PasswordStrengthMeter.defaultParams = {
  userInputs: [],
};

export default PasswordStrengthMeter;
