// @ts-nocheck
import React, { Fragment } from "react";

import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import { SimpleCard } from "../../components/Card";
import SearchBar from "../../components/SearchBar/SearchBar";
import Table from "../../components/Table/Table";

const LinesTableContainer = ({
  dataList,
  searchString,
  setSearchString,
  data,
  didWantToAddLine,
  didWantToDeleteRow,
  didWantToEditRow,
}) => (
  <Fragment>
    <GridContainer style={{ marginBottom: 20 }}>
      {dataList.length > 5 && (
        <GridItem xs sm md lg>
          <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
            <SearchBar value={searchString} onChange={setSearchString} />
          </SimpleCard>
        </GridItem>
      )}
    </GridContainer>
    <SimpleCard>
      <GridContainer>
        <GridItem xs={12}>
          <Button color="primary" onClick={didWantToAddLine} small>
            Adicionar linha <i className="fas fa-plus" />
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Table
            columns={[
              {
                Header: "Nome",
                accessor: "name",
              },
              {
                Header: "Paradas",
                accessor: "stops",
              },
              {
                Header: "Randômico",
                accessor: "random",
              },
            ]}
            data={data}
            actions={[
              {
                type: "delete",
                onClick: didWantToDeleteRow,
              },
              {
                type: "edit",
                onClick: didWantToEditRow,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </SimpleCard>
  </Fragment>
);

export default LinesTableContainer;
