// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { SimpleCard } from "../../components/Card";
import Map from "../../components/Map/Map";
import LoadingView from "../../components/LoadingView/LoadingView";

import { KEYCODE } from "../../store/KeyboardEventsStore/KeyboardEventsStore";

// Styles
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0,0,0,0.4)",
  },
  selectedVehicleOnMapCard: {
    position: "absolute",
    width: 220,
    height: 90,
    margin: 0,
    top: -75,
    zIndex: 1000,
    left: 22,
    textAlign: "center",
  },
};

@inject("stores")
@observer
class GlobalLiveMapContainer extends Component {
  state = {
    isFetching: true,
    vehiclesList: [],
    selectedVehicleOnMap: null,
  };

  componentDidMount() {
    this.props.stores.keyboardEventsStore.setListener(
      "GlobalLiveMapContainer",
      this.didNotifyKeyboardKeyPress
    );

    this.requestVehicles();
    this.updateVehiclesListTimer = setInterval(this.requestVehicles, 15000);
  }

  componentWillUnmount() {
    this.props.stores.keyboardEventsStore.removeListener(
      "GlobalLiveMapContainer"
    );

    if (this.updateVehiclesListTimer) {
      clearInterval(this.updateVehiclesListTimer);
    }
  }

  didNotifyKeyboardKeyPress = (event) => {
    const { keyCode } = event;

    if (keyCode === KEYCODE.ESC) {
      this.removeSelectedVehicleOnMap();
    }
  };

  requestVehicles = () => {
    this.props.stores.vehicleStore
      .requestAllServersVehicles()
      .then((vehiclesList) => {
        this.setState({ vehiclesList, isFetching: false });
      });
  };

  didSelectVehicleOnMap = ({ value }) =>
    this.setState({
      selectedVehicleOnMap: value,
    });

  removeSelectedVehicleOnMap = () =>
    this.setState({
      selectedVehicleOnMap: null,
    });

  vehicleIsSelectedOnMap = (token) => this.state.selectedVehicleOnMap === token;

  getMarkersFromVehiclesList = (vehiclesList) =>
    vehiclesList.map(({ token, name, terminal }) => {
      const { gps, color, lastStatus } = terminal;
      const vehicleIsSelectedOnMap = this.vehicleIsSelectedOnMap(token);
      const marker = (markerColor, zIndex, icon) => (
        <span className="fa-stack" style={{ zIndex }}>
          <i
            className="fas fa-map-marker fa-stack-2x"
            style={{ color: markerColor }}
          />
          <i
            className={`fas fa-${icon} fa-stack-1x`}
            style={{ color: "#fff", top: -5, fontSize: "0.9em" }}
          />
        </span>
      );

      return {
        key: token,
        latitude: gps.latitude,
        longitude: gps.longitude,
        customIcon: vehicleIsSelectedOnMap ? (
          <div>
            <SimpleCard
              classNames={{
                card: this.props.classes.selectedVehicleOnMapCard,
              }}
            >
              <p style={{ fontSize: "1.2em", color }}>
                <i className="fas fa-bus-alt fa-fw" /> <strong>{name}</strong>
              </p>
              <p style={{ margin: 0 }}>
                <i className="fal fa-tachometer-alt fa-fw" /> {gps.velocity}
              </p>
              <p>
                <i className="fal fa-clock fa-fw" /> {lastStatus}
              </p>
            </SimpleCard>
            {marker(primaryColor, 1100, "info")}
          </div>
        ) : (
          marker(color, 0, "bus-alt")
        ),
        value: token,
        onClick: this.didSelectVehicleOnMap,
      };
    });

  render() {
    const { fullScreen, classes } = this.props;
    const { vehiclesList } = this.state;

    return (
      <Map
        latitude={-15.799889}
        longitude={-47.887267}
        zoom={11}
        containerStyle={{
          height: fullScreen ? "100vh" : "calc(100vh - 114px)",
        }}
        markers={this.getMarkersFromVehiclesList(vehiclesList)}
        onClick={this.removeSelectedVehicleOnMap}
      >
        {this.state.isFetching && (
          <div className={classes.loadingContainer}>
            <LoadingView height={100}></LoadingView>
          </div>
        )}
      </Map>
    );
  }
}

export default withStyles(styles)(GlobalLiveMapContainer);
