// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import PieChart from "../../components/Charts/PieChart";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

// Translate
import Translate from "../../components/I18n/Translate";

// Icons
import WifiIcon from "@material-ui/icons/Wifi";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";

const WifiDifferentDaysChartWidget = observer(
  ({ classes, localizedString, uniqueConnections, reconnections }) => (
    <Card className={classes.chartCard}>
      <CardHeader color="black" icon>
        <CardIcon color="black">
          <WifiIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <Translate id="Connectivity@peopleStaticsVisitorsTitle">
            Visitantes
          </Translate>
        </h4>
      </CardHeader>
      <CardBody>
        <PieChart
          height={400}
          legend={{
            position: "bottom",
            horizontalAlign: "center",
            fontSize: 16,
          }}
          data={[
            {
              name: localizedString({
                id: "Connectivity@peopleStatisticsVisitorsNew",
                defaultString: "Novos",
              }),
              y: uniqueConnections,
            },
            {
              name: localizedString({
                id: "Connectivity@peopleStatisticsVisitorsRecurrent",
                defaultString: "Recorrentes",
              }),
              y: reconnections,
            },
          ]}
        />
      </CardBody>
    </Card>
  )
);
export default withLocalized(
  withStyles(chartsStyle)(WifiDifferentDaysChartWidget)
);
