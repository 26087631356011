// @ts-nocheck
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";

import BooleanIcon from "../components/BooleanIcon/BooleanIcon";
import AlertModal from "../components/AlertModal/AlertModal";
import { SimpleCard } from "../components/Card";
import LineConfigurationForm from "../components/AppForm/LineConfigurationForm";

import LinesTableContainer from "./linesPage/LinesTableContainer";

// Translate
import {
  ConfirmActionString,
  DeleteString,
  CancelString,
  DeleteHintString,
} from "../components/I18n/CommonStrings";

@inject("stores")
@observer
class LinesPage extends Component {
  state = {
    selectedLine: null,
    didWantToDelete: false,
  };

  componentWillMount() {
    this.props.stores.linesStore.getDataList();
  }

  getTableData = (linesList) =>
    linesList.map(({ token, name, stopsList, isRandom }) => ({
      name: name,
      stops: stopsList.length,
      random: <BooleanIcon isTrue={isRandom} />,
      actions: token,
    }));

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.stores.linesStore
      .delete(this.state.selectedLine.token)
      .then(() => {
        this.setState({
          didWantToDelete: false,
          selectedLine: null,
        });
      });
  };

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
    });
  };

  didWantToDeleteRow = (tokenToDelete) => () => {
    const { stores } = this.props;
    const selectedLine = stores.linesStore.getWithToken(tokenToDelete);
    this.setState({
      didWantToDelete: true,
      selectedLine,
    });
  };

  didWantToEditRow = (token) => () => {
    this.props.history.push(`${this.props.match.path}/editar/${token}`);
  };

  didWantToAddLine = () => {
    this.props.history.push(`${this.props.match.path}/adicionar`);
  };

  renderForm = (props) => {
    return (
      <SimpleCard cardStyle={{ marginBottom: 240 }}>
        <LineConfigurationForm {...props} />
      </SimpleCard>
    );
  };

  render() {
    const { linesStore } = this.props.stores;
    const linesList = linesStore.filteredDataList;
    const { didWantToDelete } = this.state;

    const pathUrl = this.props.match.path;

    return (
      <div style={{ padding: "0 16px" }}>
        <PageTitle>Linhas</PageTitle>

        {didWantToDelete && (
          <AlertModal
            warning
            title={<ConfirmActionString />}
            onConfirm={this.didDeleteConfiguration}
            onCancel={this.didCancelDeleteAction}
            confirmButtonLabel={<DeleteString />}
            cancelButtonLabel={<CancelString />}
            showCancel
          >
            <i className="fas fa-exclamation-triangle" /> <DeleteHintString />
          </AlertModal>
        )}
        <Switch>
          <Route
            path={`${pathUrl}/adicionar`}
            render={(props) => this.renderForm(props)}
          />
          <Route
            path={`${pathUrl}/editar/:lineToken`}
            render={(props) => this.renderForm(props)}
          />
          <Route
            path={pathUrl}
            render={(props) => (
              <LinesTableContainer
                {...props}
                dataList={linesStore.dataList}
                searchString={linesStore.searchString}
                setSearchString={linesStore.setSearchString}
                data={this.getTableData(linesList)}
                didWantToAddLine={this.didWantToAddLine}
                didWantToDeleteRow={this.didWantToDeleteRow}
                didWantToEditRow={this.didWantToEditRow}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default LinesPage;
