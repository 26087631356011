import { observable, computed, action } from "mobx";

import TerminalModel from "../../Model/TerminalModel";
import MobR2APIService from "../../services/MobR2APIService";
import NotificationsStore from "../NotificationsStore/NotificationsStore";

// Utils Stores
import { DID_LOGOUT } from "../InternalEventsStore/InternalEventsStore";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class TerminalsStore {
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;

  @observable terminalsList: TerminalModel[] = [];
  @observable isFetching = false;
  @observable searchString: string = "";
  @observable filterByStatus: "all" | "only_available" | "only_associated" =
    "all";

  constructor(internalEventsStore, notificationsStore, mobR2APIService) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.terminalsList = [];
    this.searchString = "";
    this.filterByStatus = "all";
  };

  @action
  setSearchString = (newValue: string) => {
    this.searchString = newValue;
  };
  @action
  setFilterByStatus = (
    newValue: "all" | "only_available" | "only_associated"
  ) => {
    this.filterByStatus = newValue;
  };
  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setTerminalsList = (newList: TerminalModel[]) => {
    this.terminalsList = newList;
  };

  @computed
  get filteredTerminalsList() {
    const list =
      this.filterByStatus !== "all"
        ? this.terminalsList.filter(({ usedIn }) => {
            if (this.filterByStatus === "only_associated") {
              return usedIn.token.length > 0;
            }
            return usedIn.token.length < 1;
          })
        : this.terminalsList;

    if (this.searchString.length > 0 && list.length > 0) {
      return didSearchList(
        this.searchString,
        list,
        (terminal, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(terminal.name);
          const matchBusName = textIncludesSearchString(terminal.usedIn.name);
          const matchSerialNumber = textIncludesSearchString(
            terminal.serialNumber
          );
          const matchVersion = textIncludesSearchString(terminal.version);
          const matchComments = textIncludesSearchString(terminal.comments);

          return (
            matchName ||
            matchBusName ||
            matchSerialNumber ||
            matchVersion ||
            matchComments
          );
        }
      );
    }

    return list;
  }

  fetchSingleTerminal = async (token: string) => {
    if (!token) return Promise.resolve(new TerminalModel(null));
    try {
      const response: any = await this.mobR2APIService.requestTerminal(token);
      if (response.status === 200 && response.data && response.data.terminals) {
        const terminal = new TerminalModel(response.data.terminals[0]);
        return Promise.resolve(terminal);
      }
      return Promise.resolve(new TerminalModel(null));
    } catch (error) {
      console.error("error in requestTerminal", error);
      return Promise.reject(error);
    }
  };

  requestTerminals = () => {
    return this.mobR2APIService.requestTerminals();
  };

  @action
  getTerminalsList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newTerminalsList = [];

    this.requestTerminals()
      .then((response: any) => {
        if (response.status === 200) {
          response.data.terminals.forEach((terminal) => {
            newTerminalsList.push(new TerminalModel(terminal));
          });
        }
        this.setTerminalsList(
          newTerminalsList.sort(dynamicSortByProperty("name"))
        );

        this.setIsFetching(false);
      })
      .catch((error) => {
        console.error("error in requestTerminals", error);
        if (error && error.statusCode === 429) {
          setTimeout(() => {
            this.getTerminalsList(withFetchingAnimation);
          }, 1000);
        }
      });
  };

  update = async (data: TerminalModel) => {
    try {
      const response: any = await this.mobR2APIService.updateTerminal(data);
      this.getTerminalsListWithoutFetchingAnimation();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });

      return Promise.resolve();
    } catch (error) {
      console.error("error in updateTerminal", error);
      return Promise.reject(error);
    }
  };

  getTerminalsListWithoutFetchingAnimation = () => {
    this.getTerminalsList(false);
  };

  delete = async (token: string) => {
    try {
      const response: any = await this.mobR2APIService.deleteTerminal(token);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.success,
        color: "success",
      });

      this.getTerminalsList();

      return Promise.resolve();
    } catch (error) {
      console.error("error in deleteTerminal", error);
      return Promise.reject(error);
    }
  };

  getWithToken = (token: string) => {
    if (this.terminalsList.length > 0) {
      return this.terminalsList.find((terminal) => terminal.token === token);
    }
    return null;
  };
}

export default TerminalsStore;
