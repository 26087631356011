// @ts-nocheck
import React, { Fragment } from "react";
import PageTitle from "../components/PageTitle/PageTitle";

import VehiclesListPageContainer from "./vehiclesListPage/VehiclesListPageContainer";

const VehiclesListPage = ({ location }) => (
  <Fragment>
    <PageTitle id="Menu@devices">Veículos</PageTitle>
    <VehiclesListPageContainer location={location} />
  </Fragment>
);

export default VehiclesListPage;
