// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import _cloneDeep from "lodash/cloneDeep";

import Button from "../../components/Button/Button";
import { InputText, InputSelect } from "../../components/Form";
import Validate, { ValidationType } from "../../components/Form/Validate";

import PasswordStrengthMeter from "../../components/Form/PasswordStrengthMeter";

import {
  CONTRACT_DEFAULT_RULES,
  titleForRules,
} from "../../Model/ContractModel";
import GarageModel from "../../Model/GarageModel";

// Style
const styles = {
  subtitle: {
    fontSize: "1.4em",
  },
};

const getRulesListOptions = (rules) => {
  const ruleList = [
    { label: "Nenhum", value: "" },
    ...Object.keys(CONTRACT_DEFAULT_RULES[rules]).map((rule) => ({
      label: titleForRules(rule),
      value: rule,
    })),
  ];

  return ruleList;
};

@inject("stores")
@observer
class UserForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState(props.user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.updateForm();
    }
  }

  defaultState = (user) => {
    const name = user ? user.name : "";
    const email = user ? user.email : "";
    const rules = user ? user.rules : _cloneDeep(CONTRACT_DEFAULT_RULES);
    const sysSelectedRule = this.getRuleKeyFromRules(rules.sys);
    const dvrSelectedRule = this.getRuleKeyFromRules(rules.dvr);
    const garages = user?.garages || [];

    return {
      formWasSubmit: false,
      isWaitingForm: false,
      name,
      email,
      password: "",
      confirmPassword: "",
      sysSelectedRule,
      dvrSelectedRule,
      garages,
      isValid: {
        name: Validate(name, ValidationType.REQUIRED),
        email:
          Validate(email, ValidationType.REQUIRED) &&
          Validate(email, ValidationType.EMAIL),
        password: false,
        confirmPassword: false,
      },
    };
  };

  componentDidMount() {
    this.props.stores.garageStore.getGaragesList();
  }

  getRuleKeyFromRules = (rules: any) =>
    Object.keys(rules).find((key) => rules[key]) || null;

  updateForm = () => {
    this.setState(this.defaultState(this.props.user));
  };

  getNotSelectedGarages = (): GarageModel[] => {
    if (this.state.garages) {
      return this.props.stores.garageStore.garagesList.filter(
        ({ token }) => !this.state.garages.includes(token)
      );
    }
    return this.props.stores.garageStore.garagesList;
  };

  getSelectedGarages = (): GarageModel[] => {
    if (this.state.garages) {
      return this.state.garages
        .map((token) => this.props.stores.garageStore.getWithToken(token))
        .filter((garage) => garage);
    }
    return [];
  };

  onChangeName = ({ target }) => {
    const { value } = target;

    this.setState((prevState) => ({
      name: value,
      isValid: {
        ...prevState.isValid,
        name: Validate(value, ValidationType.REQUIRED),
      },
    }));
  };

  onChangeEmail = ({ target }) => {
    const { value } = target;

    this.setState((prevState) => ({
      email: value,
      isValid: {
        ...prevState.isValid,
        email:
          Validate(value, ValidationType.REQUIRED) &&
          Validate(value, ValidationType.EMAIL),
      },
    }));
  };

  onChangePassword = ({ target }) => {
    const { value, name } = target;

    let isValid = Validate(value, ValidationType.REQUIRED);

    if (this.state.formWasSubmit && name === "confirmPassword") {
      isValid = value === this.state.password;
    }

    this.setState((prevState) => ({
      [name]: value,
      isValid: {
        ...prevState.isValid,
        [name]: isValid,
      },
    }));
  };

  onChangeSwitch = (name) => {
    const [category, rule] = name.split(".");
    this.setState((prevState) => {
      let updatedCategory = {};

      Object.keys(prevState.rules[category]).forEach((key) => {
        if (key === rule) {
          updatedCategory[key] = !prevState.rules[category][rule];
        } else {
          updatedCategory[key] = false;
        }
      });

      return {
        rules: {
          ...prevState.rules,
          [category]: updatedCategory,
        },
      };
    });
  };

  canSubmitForm = () => {
    const { name, email, password, confirmPassword } = this.state.isValid;

    let matchPassword = password && confirmPassword;
    if (this.props.user && this.state.password.length < 1) {
      matchPassword = true;
    }
    return name && email && matchPassword;
  };

  didSubmit = () => {
    this.setState(
      {
        isWaitingForm: true,
        formWasSubmit: true,
      },
      async () => {
        const { stores, user } = this.props;
        const { usersStore } = stores;
        const {
          name,
          email,
          password,
          sysSelectedRule,
          dvrSelectedRule,
          garages,
        } = this.state;
        const rules = _cloneDeep(CONTRACT_DEFAULT_RULES);
        if (sysSelectedRule?.length > 0) {
          rules.sys[sysSelectedRule] = true;
        }
        if (dvrSelectedRule?.length > 0) {
          rules.dvr[dvrSelectedRule] = true;
        }
        const data = { name, email, password, rules, garages };

        try {
          if (user) {
            await usersStore.update(
              { token: user.token, ...data },
              this.props.isEditingTheirOwnAccount
            );
          } else {
            await usersStore.submit(data);
          }

          this.props.didCancel();
          this.updateForm();
        } catch (_) {
          this.setState({
            isWaitingForm: false,
          });
        }
      }
    );
  };

  onChangeSelectInput = (name, selectedValue) => {
    this.setState({
      [name]: selectedValue.value,
    });
  };

  onChangeGarages = (name, selectedValue) => {
    this.setState({
      garages: selectedValue.map(({ token }) => token),
    });
  };

  render() {
    const {
      name,
      email,
      password,
      confirmPassword,
      isValid,
      formWasSubmit,
      sysSelectedRule,
      dvrSelectedRule,
    } = this.state;
    const isEditing = this.props.user;
    const isEditingTheirOwnAccount = this.props.isEditingTheirOwnAccount;

    const { hasAccessTo } = this.props.stores.authStore;

    return this.props.children({
      isEditing,
      body: (
        <Grid container>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6}>
              <InputText
                label="Nome"
                name="name"
                hasError={formWasSubmit && !isValid.name}
                hasSuccess={formWasSubmit && isValid.name}
                value={name}
                onChange={this.onChangeName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText
                label="E-mail"
                name="email"
                hasError={formWasSubmit && !isValid.email}
                hasSuccess={formWasSubmit && isValid.email}
                value={email}
                onChange={this.onChangeEmail}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText
                label="Senha"
                name="password"
                value={password}
                inputProps={{ type: "password", autoComplete: "off" }}
                hasError={
                  formWasSubmit && password.length > 0 && !isValid.password
                }
                hasSuccess={formWasSubmit && isValid.password}
                onChange={this.onChangePassword}
                helpText={
                  isEditing
                    ? "Preencha apenas se deseja alterar a senha do usuário."
                    : null
                }
              />
              {password.length > 0 && (
                <PasswordStrengthMeter
                  password={password}
                  userInputs={[name, email]}
                />
              )}
            </Grid>
            {password.length > 0 && (
              <Grid item xs={12} md={6}>
                <InputText
                  label="Confirme a senha"
                  name="confirmPassword"
                  value={confirmPassword}
                  inputProps={{ type: "password", autoComplete: "off" }}
                  hasError={formWasSubmit && !isValid.confirmPassword}
                  hasSuccess={formWasSubmit && isValid.confirmPassword}
                  onChange={this.onChangePassword}
                />
              </Grid>
            )}
          </Grid>

          {!isEditingTheirOwnAccount && (
            <Grid container spacing={3} item xs={12}>
              {hasAccessTo("sys", "admin") && (
                <Grid item xs={12} md={6}>
                  <InputSelect
                    label="Sistema"
                    name="sysSelectedRule"
                    value={getRulesListOptions("sys").find(
                      ({ value }) => value === sysSelectedRule
                    )}
                    options={getRulesListOptions("sys")}
                    onChange={this.onChangeSelectInput}
                    withPortal
                  />
                </Grid>
              )}
              {hasAccessTo("dvr", "admin") && (
                <Grid item xs={12} md={6}>
                  <InputSelect
                    label="CFTV"
                    name="dvrSelectedRule"
                    value={getRulesListOptions("dvr").find(
                      ({ value }) => value === dvrSelectedRule
                    )}
                    options={getRulesListOptions("dvr")}
                    onChange={this.onChangeSelectInput}
                    withPortal
                  />
                </Grid>
              )}
              {(hasAccessTo("sys", "admin") || hasAccessTo("dvr", "admin")) && (
                <Grid item xs={12}>
                  <InputSelect
                    isLoading={this.props.stores.garageStore.isFetching}
                    multiple
                    labelKey="name"
                    valueKey="token"
                    label="Garagens"
                    name="garages"
                    value={this.getSelectedGarages()}
                    options={this.getNotSelectedGarages()}
                    onChange={this.onChangeGarages}
                    withPortal
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      ),
      action: (
        <Button
          color="primary"
          block
          isLoading={this.state.isWaitingForm}
          disabled={!this.canSubmitForm()}
          onClick={this.didSubmit}
        >
          {isEditing ? "Atualizar" : "Salvar"} <i className="fas fa-save" />
        </Button>
      ),
    });
  }
}

UserForm.defaultProps = {
  isEditingTheirOwnAccount: false,
};

export default withStyles(styles)(UserForm);
