// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import TopItemsList from "../../components/List/TopItemsList";
import PhoneWithSMSLink from "../../components/Phone/PhoneWithSMSLink";

import withStyles from "@material-ui/core/styles/withStyles";

// Translate
import Translate from "../../components/I18n/Translate";
import Plural from "../../components/I18n/Plural";

// Icons
import WifiIcon from "@material-ui/icons/Wifi";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
const styles = {
  ...chartsStyle,
  phoneNumber: {
    fontSize: "1.1em",
  },
};

const WifiTop10UsersChartWidget = observer(({ classes, chartData }) => (
  <Card className={classes.chartCard}>
    <CardHeader color="black" icon>
      <CardIcon color="black">
        <WifiIcon />
      </CardIcon>
      <h4 className={classes.cardIconTitle}>
        <Translate id="Dashboard@wifiTop10UsersChartTitle">
          Numeros que mais acessaram sua Wi-Fi nos ultimos 30 dias
        </Translate>
      </h4>
    </CardHeader>
    <CardBody>
      <TopItemsList
        itemsList={chartData}
        renderTitle={(item) => (
          <span className={classes.phoneNumber}>
            <PhoneWithSMSLink>{item.phoneNumber}</PhoneWithSMSLink>
          </span>
        )}
        renderDescription={(item) => (
          <Plural
            id="Dashboard@wifiTop10UsersVisitCounter"
            value={item.quantity}
            one={"Uma visita no período"}
            other={"# visitas no período"}
          />
        )}
      />
    </CardBody>
  </Card>
));
export default withStyles(styles)(WifiTop10UsersChartWidget);
