// @ts-nocheck
export const DID_LOGOUT = "didLogout";

class InternalEventsStore {
  subscribers = {};

  subscribeTo = ({ eventKey, observer, callback }) => {
    if (!this.subscribers[eventKey]) {
      this.subscribers[eventKey] = [];
    }

    this.subscribers[eventKey].push({
      observer,
      callback,
    });
  };

  unsubscribe = (observerToRemove, eventKey) => {
    if (this.subscribers[eventKey]) {
      this.subscribers[eventKey] = this.subscribers[eventKey].filter(
        (subscriber) => subscriber.observer !== observerToRemove
      );
    }
  };

  notify = (eventKey: string, params?: Record<string, any>) => {
    if (this.subscribers[eventKey]) {
      this.subscribers[eventKey].forEach((subscription) => {
        subscription.callback(params);
      });
    }
  };
}

export default InternalEventsStore;
