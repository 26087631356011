// @ts-nocheck
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PigeonMap from "pigeon-maps";

import MapMarker from "./MapMarker";

import { Button } from "../Button";

// styles
const styles = {
  mapContainer: {
    position: "relative",
  },
  zoomButtonsContainer: {
    position: "absolute",
    bottom: 12,
    right: 4,
  },
  zoomButton: {
    backgroundColor: "#fff",
    color: "#666666",
    display: "block",
    margin: 0,
  },
};

class Maps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: this.props.zoom,
      bounds: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.zoom !== this.props.zoom) {
      this.setState({
        zoom: this.props.zoom,
      });
    }
  }

  plusZoom = () => {
    this.setState({
      zoom: this.state.zoom + 1,
    });
  };
  minusZoom = () => {
    this.setState({
      zoom: this.state.zoom - 1,
    });
  };

  renderZoomButtons = () => {
    if (this.props.hideZoomButtons) {
      return null;
    }
    const { classes } = this.props;
    return (
      <div className={classes.zoomButtonsContainer}>
        <Button
          className={classes.zoomButton}
          justIcon
          small
          style={{ marginBottom: 4 }}
          onClick={this.plusZoom}
        >
          <i className="fas fa-plus" />
        </Button>
        <Button
          className={classes.zoomButton}
          justIcon
          small
          onClick={this.minusZoom}
        >
          <i className="fas fa-minus" />
        </Button>
      </div>
    );
  };

  onBoundsChanged = ({ center, zoom, bounds, initial }) => {
    this.setState({
      bounds,
    });
  };

  filterMarkersByBounds = (markers) => {
    const { bounds } = this.state;
    if (!bounds) return markers;

    const { ne, sw } = bounds;

    return markers.filter(({ latitude, longitude }) => {
      const matchLatitude =
        Math.abs(latitude) > Math.abs(ne[0]) &&
        Math.abs(latitude) < Math.abs(sw[0]);
      const matchLongitude =
        Math.abs(longitude) > Math.abs(ne[1]) &&
        Math.abs(longitude) < Math.abs(sw[1]);

      return matchLatitude && matchLongitude;
    });
  };

  renderMarkers = () => {
    const { markers } = this.props;
    if (!markers) return null;

    return this.filterMarkersByBounds(markers).map(
      ({ key, latitude, longitude, ...props }) => (
        <MapMarker
          key={key}
          anchor={[latitude, longitude]}
          value={key}
          {...props}
        />
      )
    );
  };

  render() {
    const {
      classes,
      latitude,
      longitude,
      disabled,
      height,
      width,
      onClick,
      containerStyle,
      children,
      metaWheelZoom,
    } = this.props;

    const { zoom } = this.state;
    return (
      <div className={classes.mapContainer} style={containerStyle}>
        <PigeonMap
          provider={(x, y, z) => {
            const s = String.fromCharCode(97 + ((x + y + z) % 3));
            return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
          }}
          center={[latitude, longitude]}
          zoom={zoom}
          height={height}
          width={width}
          attribution={false}
          mouseEvents={!disabled}
          touchEvents={!disabled}
          metaWheelZoom={metaWheelZoom}
          metaWheelZoomWarning="Aperte e segure META para utilizar o zoom!"
          onClick={onClick}
          onBoundsChanged={this.onBoundsChanged}
        >
          {this.renderMarkers()}
        </PigeonMap>
        {children}
        {this.renderZoomButtons()}
      </div>
    );
  }
}

Maps.defaultProps = {
  zoom: 12,
  disabled: false,
  metaWheelZoom: false,
};

export default withStyles(styles)(Maps);
