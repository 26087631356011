// @ts-nocheck
import React from "react";
import ReactAudioPlayer from "react-audio-player";

const AudioPlayer = ({ src, autoPlay, styles, showControls }) => {
  //   return (
  //     <audio src={src} preload="none" controls="">
  //       <p>Seu navegador não suporta o elemento audio </p>
  //     </audio>
  //   );
  return (
    <ReactAudioPlayer
      style={styles}
      src={src}
      autoPlay={autoPlay}
      controls={showControls}
    />
  );
};

AudioPlayer.defaultProps = {
  autoPlay: false,
  styles: { width: "100%" },
  showControls: true,
};

export default AudioPlayer;
