import { ContractRules, CONTRACT_DEFAULT_RULES } from "./ContractModel";
class UserModel {
  token: string;
  name: string;
  email: string;
  rules: ContractRules;
  garages: string[];

  constructor(user: any) {
    this.token = user.user_token;
    this.name = user.name;
    this.email = user.email;
    this.rules = user.rules || CONTRACT_DEFAULT_RULES;
    this.garages = user.garages || [];
  }

  get isSimplyAsEmployee(): boolean {
    return this.email.includes("@simplyas.com");
  }
}

export default UserModel;
