// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import Modal from "../../components/Modal/Modal";
import UserForm from "./UserForm";

@inject("stores")
@observer
class UserFormModal extends Component {
  render() {
    const { isOpen, didCancel, ...rest } = this.props;
    return (
      <UserForm didCancel={didCancel} {...rest}>
        {({ isEditing, body, action }) => (
          <Modal
            modalTitle={isEditing ? "Atualizar usuário" : "Cadastrar usuário"}
            isOpen={isOpen}
            didCloseModal={didCancel}
            actions={action}
          >
            {body}
          </Modal>
        )}
      </UserForm>
    );
  }
}
export default UserFormModal;
