import React, { CSSProperties, FC } from "react";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

// Styles
const styles = {
  normal: {
    fontSize: "1.1em",
  },
  large: {
    fontSize: "1.4em",
  },
};

interface IInfoLabelProps {
  classes?: any;
  className?: string;
  large?: boolean;
  showIcon?: boolean;
  style?: CSSProperties;
}

const InfoLabel: FC<IInfoLabelProps> = ({
  classes,
  className,
  children,
  large,
  showIcon = true,
  style,
}) => (
  <p
    className={classnames({
      [classes.normal]: !large,
      [classes.large]: large,
      [className]: true,
    })}
    style={style}
  >
    {showIcon && <i className="fa fa-info-circle" style={{ marginRight: 6 }} />}{" "}
    {children}
  </p>
);

export default withStyles(styles)(InfoLabel);
