// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { when } from "mobx";
import Select from "react-select";
import queryString from "query-string";

import withStyles from "@material-ui/core/styles/withStyles";

import { LinkButton } from "../../components/Button";
import Tooltip from "../../components/Tooltip/Tooltip";
import { SimpleCard } from "../../components/Card";

import Map from "../../components/Map/Map";

import { APP_ROUTES } from "../../routes/app-routes";
import { KEYCODE } from "../../store/KeyboardEventsStore/KeyboardEventsStore";

// Styles
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  mapBarContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "0 6px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectVehicleContainer: {
    width: 300,
    display: "inline-block",
    marginRight: 6,
  },
  selectedVehicleOnMapCard: {
    position: "absolute",
    width: 220,
    height: 90,
    margin: 0,
    top: -75,
    zIndex: 1000,
    left: 22,
    textAlign: "center",
  },
};

@inject("stores")
@observer
class LiveMapContainer extends Component {
  state = {
    selectedVehicleOnMap: null,
  };

  componentWillMount() {
    const { vehicleStore } = this.props.stores;
    vehicleStore.getVehiclesList({
      withFetchingAnimation: true,
      filterByGPS: true,
    });
  }

  componentDidMount() {
    this.props.stores.keyboardEventsStore.setListener(
      "LiveMapContainer",
      this.didNotifyKeyboardKeyPress
    );

    this.updateVehiclesListTimer = setInterval(() => {
      const { vehicleStore } = this.props.stores;
      if (!vehicleStore.selectedVehicle) {
        vehicleStore.getVehiclesList({
          withFetchingAnimation: false,
          filterByGPS: true,
        });
      }
    }, 15000);

    const urlQuery = queryString.parse(this.props.location.search);

    if (urlQuery.veiculo) {
      const { vehicleStore } = this.props.stores;

      when(
        () => vehicleStore.vehiclesList.length > 0,
        () => {
          this.didSelectVehicle({ token: urlQuery.veiculo });
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.stores.keyboardEventsStore.removeListener("LiveMapContainer");

    this.props.stores.vehicleStore.removeSelectedVehicle();
    this.clearUpdateVehiclesInterval();
  }

  didNotifyKeyboardKeyPress = (event) => {
    const { keyCode } = event;

    if (keyCode === KEYCODE.ESC) {
      this.removeSelectedVehicleOnMap();
    }
  };

  clearUpdateVehiclesInterval = () => {
    if (this.updateVehiclesListTimer) {
      clearInterval(this.updateVehiclesListTimer);
    }
  };

  didSelectVehicle = (selectedVehicle) => {
    if (selectedVehicle.token === "all") {
      this.props.stores.vehicleStore.removeSelectedVehicle();
    } else {
      this.props.stores.vehicleStore.setSelectedVehicle(selectedVehicle.token);
      this.didSelectVehicleOnMap(selectedVehicle);
    }
    this.vehicleSelectElement.blur();
  };

  renderMapBar = (vehiclesList, selectedVehicle) => {
    const { classes, fullScreen } = this.props;
    return (
      <div className={classes.mapBarContainer}>
        <div>
          <div className={classes.selectVehicleContainer}>
            <Select
              ref={(vehicleSelectElement) =>
                (this.vehicleSelectElement = vehicleSelectElement)
              }
              value={
                selectedVehicle
                  ? selectedVehicle
                  : { label: "Todos os veículos", value: "all" }
              }
              options={[
                { label: "Todos os veículos", value: "all" },
                ...vehiclesList,
              ]}
              noOptionsMessage={() => "Nenhum veículo encontrado"}
              onChange={this.didSelectVehicle}
              getOptionValue={(option) => option.token}
            />
          </div>
          <Tooltip
            title={fullScreen ? "Fechar tela inteira" : "Ativar tela inteira"}
          >
            <span>
              <LinkButton
                justIcon
                small
                to={fullScreen ? APP_ROUTES.GPS : "/mapa-tela-inteira"}
              >
                <i
                  className={`fas fa-${
                    fullScreen ? "compress" : "expand"
                  }-wide`}
                />
              </LinkButton>
            </span>
          </Tooltip>
        </div>
        <div>
          <LinkButton color="info" small to={`${this.props.pathUrl}/replay`}>
            <i className="fas fa-fast-backward" /> Replay
          </LinkButton>
        </div>
      </div>
    );
  };

  didSelectVehicleOnMap = ({ value }) =>
    this.setState({
      selectedVehicleOnMap: value,
    });

  removeSelectedVehicleOnMap = () =>
    this.setState({
      selectedVehicleOnMap: null,
    });

  vehicleIsSelectedOnMap = (token) => this.state.selectedVehicleOnMap === token;

  getMarkersFromVehiclesList = (vehiclesList) =>
    vehiclesList.map(({ name, token, terminal }) => {
      const { gps, color, lastStatus } = terminal;
      const vehicleIsSelectedOnMap = this.vehicleIsSelectedOnMap(token);
      const marker = (markerColor, zIndex, icon) => (
        <span className="fa-stack" style={{ zIndex }}>
          <i
            className="fas fa-map-marker fa-stack-2x"
            style={{ color: markerColor }}
          />
          <i
            className={`fas fa-${icon} fa-stack-1x`}
            style={{ color: "#fff", top: -5, fontSize: "0.9em" }}
          />
        </span>
      );

      return {
        key: token,
        latitude: parseFloat(gps.latitude),
        longitude: parseFloat(gps.longitude),
        customIcon: vehicleIsSelectedOnMap ? (
          <div>
            <SimpleCard
              classNames={{
                card: this.props.classes.selectedVehicleOnMapCard,
              }}
            >
              <p style={{ fontSize: "1.2em", color }}>
                <i className="fas fa-bus-alt fa-fw" /> <strong>{name}</strong>
              </p>
              <p style={{ margin: 0 }}>
                <i className="fal fa-tachometer-alt fa-fw" /> {gps.velocity}
              </p>
              <p>
                <i className="fal fa-clock fa-fw" /> {lastStatus}
              </p>
            </SimpleCard>
            {marker(primaryColor, 1100, "info")}
          </div>
        ) : (
          marker(color, 0, "bus-alt")
        ),
        value: token,
        onClick: this.didSelectVehicleOnMap,
      };
    });

  render() {
    const { fullScreen, stores } = this.props;
    const { vehiclesWithGpsLocation: vehiclesList, selectedVehicle } =
      stores.vehicleStore;

    return (
      <Map
        latitude={
          selectedVehicle ? selectedVehicle.terminal.gps.latitude : -15.799889
        }
        longitude={
          selectedVehicle ? selectedVehicle.terminal.gps.longitude : -47.887267
        }
        zoom={selectedVehicle ? 17 : 12}
        containerStyle={{
          height: fullScreen ? "100vh" : "calc(100vh - 114px)",
        }}
        markers={this.getMarkersFromVehiclesList(
          selectedVehicle ? [selectedVehicle] : vehiclesList
        )}
        onClick={this.removeSelectedVehicleOnMap}
      >
        {this.renderMapBar(vehiclesList, selectedVehicle)}
      </Map>
    );
  }
}

export default withStyles(styles)(LiveMapContainer);
