// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";

import Button from "../../components/Button/Button";
import { InputText } from "../../components/Form";
import Validate, { ValidationType } from "../../components/Form/Validate";

@inject("stores")
@observer
class GarageForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState(props.garage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.garage !== this.props.garage) {
      this.updateForm();
    }
  }

  defaultState = (garage) => {
    const name = garage ? garage.name : "";
    return {
      formWasSubmit: false,
      isWaitingForm: false,
      name,
      isValid: {
        name: Validate(name, ValidationType.REQUIRED),
      },
    };
  };

  updateForm = () => {
    this.setState(this.defaultState(this.props.garage));
  };

  onChangeName = ({ target }) => {
    const { value } = target;

    this.setState((prevState) => ({
      name: value,
      isValid: {
        ...prevState.isValid,
        name: Validate(value, ValidationType.REQUIRED),
      },
    }));
  };

  canSubmitForm = () => {
    return this.state.isValid.name;
  };

  didSubmit = () => {
    this.setState(
      {
        isWaitingForm: true,
        formWasSubmit: true,
      },
      async () => {
        const { stores, garage } = this.props;
        const { garageStore } = stores;
        const { name } = this.state;

        const data = { name };
        try {
          if (garage) {
            await garageStore.update({ token: garage.token, ...data });
          } else {
            await garageStore.submit(data);
          }

          this.props.didCancel();
        } finally {
          this.setState({
            isWaitingForm: false,
          });
        }
      }
    );
  };

  render() {
    const { name, isValid, formWasSubmit } = this.state;
    const isEditing = this.props.garage;

    return this.props.children({
      isEditing,
      body: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputText
              label="Nome"
              name="name"
              hasError={formWasSubmit && !isValid.name}
              hasSuccess={formWasSubmit && isValid.name}
              value={name}
              onChange={this.onChangeName}
            />
          </Grid>
        </Grid>
      ),
      action: (
        <Button
          color="primary"
          block
          isLoading={this.state.isWaitingForm}
          disabled={!this.canSubmitForm()}
          onClick={this.didSubmit}
        >
          {isEditing ? "Atualizar" : "Salvar"} <i className="fas fa-save" />
        </Button>
      ),
    });
  }
}

export default GarageForm;
