// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import SimpleCard from "../components/Card/SimpleCard";
import PageTitle from "../components/PageTitle/PageTitle";
import Table from "../components/Table/Table";
import Button from "../components/Button/Button";
import LoadingView from "../components/LoadingView/LoadingView";

import GarageFormModal from "./garagesPage/GarageFormModal";

// Style
const styles = {};

@inject("stores")
@observer
class GaragesPage extends Component {
  state = {
    isFormOpen: false,
    isEditingToken: null,
  };

  componentDidMount() {
    this.props.stores.garageStore.getGaragesList();
  }

  didWantToAdd = () => {
    this.setState({
      isFormOpen: true,
    });
  };

  didWantToUpdate = (isEditingToken) => () => {
    this.setState({
      isEditingToken,
      isFormOpen: true,
    });
  };

  didCancelForm = () => {
    this.setState({
      isEditingToken: null,
      isFormOpen: false,
    });
  };

  render() {
    const { isFetching, garagesList } = this.props.stores.garageStore;

    const selectedGarage = this.state.isEditingToken
      ? garagesList.find(({ token }) => token === this.state.isEditingToken)
      : null;

    return (
      <Fragment>
        <PageTitle>Cadastro de garagens</PageTitle>
        <GarageFormModal
          isOpen={this.state.isFormOpen}
          garage={selectedGarage}
          didCancel={this.didCancelForm}
        />
        <Grid container style={{ padding: "0 16px" }} spacing={2}>
          <SimpleCard>
            <Grid item xs={12}>
              <Button color="primary" small onClick={this.didWantToAdd}>
                Nova garagem <i className="fas fa-plus" />
              </Button>
            </Grid>
            <Grid item xs={12}>
              {isFetching ? (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <LoadingView />
                </Grid>
              ) : (
                <Table
                  data={garagesList.map(({ token, name }) => {
                    return {
                      name,
                      actions: token,
                    };
                  })}
                  columns={[
                    {
                      Header: "Nome",
                      accessor: "name",
                    },
                  ]}
                  actions={[
                    {
                      type: "edit",
                      onClick: this.didWantToUpdate,
                    },
                  ]}
                />
              )}
            </Grid>
          </SimpleCard>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(GaragesPage);
