// @ts-nocheck
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

import { isProductionServer } from "../../utils/EnvUtils";

const developmentConfig = {
  apiKey: "AIzaSyB2UeYHNC2seHC3Wr4TIXTtPaowE_KfkmY",
  authDomain: "mobr2dev.firebaseapp.com",
  databaseURL: "https://mobr2dev.firebaseio.com",
  projectId: "mobr2dev",
  storageBucket: "mobr2dev.appspot.com",
  messagingSenderId: "770055204242",
};

const productionConfig = {
  apiKey: "AIzaSyCedHUZB3_p81w6aFZsVI0nWNuIac4OHs4",
  authDomain: "mobr2prod.firebaseapp.com",
  databaseURL: "https://mobr2prod.firebaseio.com",
  projectId: "mobr2prod",
  storageBucket: "",
  messagingSenderId: "163814317571",
};

if (!firebase.apps.length) {
  firebase.initializeApp(
    isProductionServer ? productionConfig : developmentConfig
  );
}

export const auth = firebase.auth(); //the Firebase auth namespace
const database = firebase.database(); //the real-time database

export const rootRef = database.ref();
export const connectedRef = database.ref(".info/connected");
export const analyticsRef = database.ref("analytics");
export const versionControlRef = database.ref("versionControl");
export const settingsRef = database.ref("settings/webApp");
export const i18nRef = database.ref("i18n/webApp");
export const authUsersRef = database.ref("authUsers");
export const usersRef = database.ref("users");
export const contractsRef = database.ref("contracts");
export const notificationsRef = database.ref("notifications");
export const serverTimestamp = firebase.database.ServerValue.TIMESTAMP;

export const saveComponentErrorLog = (log) => {
  database.ref("logs/reactComponentsWebApp").push({
    ...log,
    dateTimestamp: serverTimestamp,
  });
};

export const saveLog = (name, params = null) => {
  database.ref("logs/webApp").push({
    name,
    dateTimestamp: serverTimestamp,
    params,
  });
};

const FirebaseStore = {
  database,
  serverTimestamp,
};

export default FirebaseStore;
