// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import Checkbox from "@material-ui/core/Checkbox";

import Table, { createIconColumn } from "../../components/Table/Table";
import TableActionButton from "../../components/Table/TableActionButton";
import BooleanIcon from "../../components/BooleanIcon/BooleanIcon";
import Tooltip from "../../components/Tooltip/Tooltip";
import Button from "../../components/Button/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import { dangerColor } from "../../assets/jss/app-js-styles";
import moment from "moment";

@inject("stores")
@observer
class VehiclesTableContainer extends Component {
  getTableData = (vehiclesList) => {
    const { hasCFTV } = this.props.stores.contractStore.selectedContract;
    return vehiclesList.map(
      ({
        token,
        name,
        isSelected,
        garage,
        terminal,
        playlists,
        internet,
        line,
        statsCameras,
        isTerminalOnline,
        hasCamerasAlert,
        hasCamera,
        hasSendCamCommand,
        phoneNumber,
        operator,
        comments,
        lastCameraCollectDate,
        hasCameraCollectAlert,
      }) => {
        const { color, lastStatus, version, wifi } = terminal;

        return {
          isSelected,
          checkbox: (
            <Checkbox
              checked={isSelected}
              onChange={this.didSelectVehicle(token)}
            />
          ),
          name: <span style={{ color }}>{name}</span>,
          garage:
            garage?.token.length > 0 ? (
              garage.name
            ) : (
              <BooleanIcon isTrue={false} />
            ),
          line:
            line?.token.length > 0 ? (
              <Tooltip title={line.name}>
                <span>
                  <BooleanIcon isTrue={true} />
                </span>
              </Tooltip>
            ) : (
              <BooleanIcon isTrue={false} />
            ),
          status: lastStatus,
          lastCameraCollectDate: lastCameraCollectDate,
          version: version,
          playlists:
            playlists.length > 0 ? (
              <Tooltip
                title={
                  <div style={{ textAlign: "left" }}>
                    {playlists.map(({ name }) => (
                      <p
                        key={name}
                        style={{ textAlign: "left", margin: "4px 0" }}
                      >
                        <i
                          className="fas fa-play"
                          style={{ marginRight: 12 }}
                        />{" "}
                        {name}
                      </p>
                    ))}
                  </div>
                }
              >
                <span>
                  <BooleanIcon isTrue={true} />
                </span>
              </Tooltip>
            ) : (
              <BooleanIcon isTrue={false} />
            ),
          internet:
            internet?.token.length > 0 ? (
              <Tooltip title={`Clientes conectados: ${wifi.clients}`}>
                <span>
                  <BooleanIcon isTrue={true} />
                </span>
              </Tooltip>
            ) : (
              <BooleanIcon isTrue={false} />
            ),
          camera: (
            <Tooltip
              lightBackground
              title={
                statsCameras.length < 1 ? (
                  <p style={{ margin: 0 }}>
                    <i className="fas fa-exclamation-triangle" /> Câmeras
                    offline
                  </p>
                ) : (
                  <span>
                    {statsCameras.map((cameraIsWorking, index) => (
                      <p key={index} style={{ margin: 0, marginBottom: 4 }}>
                        Câmera {index + 1}:{" "}
                        <BooleanIcon isTrue={cameraIsWorking} />
                      </p>
                    ))}
                  </span>
                )
              }
            >
              <span>
                {hasCamerasAlert ? (
                  <i
                    className="fas fa-exclamation-triangle"
                    style={{ color: dangerColor }}
                  />
                ) : (
                  <BooleanIcon
                    isTrue={statsCameras.length > 1}
                    falseIcon="question-circle"
                  />
                )}
              </span>
            </Tooltip>
          ),
          phoneNumber: `${phoneNumber} ${
            operator?.length > 0 ? `(${operator})` : ""
          }`,
          comments,
          commands: hasCFTV &&
            isTerminalOnline &&
            hasCamera &&
            !hasSendCamCommand && (
              <TableActionButton
                label="Coletar Cam"
                icon="play"
                color="success"
                onClick={this.props.didWantToCollectCam(token)}
              />
            ),
          actions: token,
        };
      }
    );
  };

  didCheckAll = (isAllSelected) => () => this.props.didCheckAll(!isAllSelected);

  didSelectVehicle = (selectedVehicleToken) => () =>
    this.props.didSelectVehicle(selectedVehicleToken);

  chooseConfig = (type) => () => this.props.didWantToChooseConfig(type);

  renderConfigButton = (type, icon, label, isActive) => (
    <Button
      color="default"
      outline={!isActive}
      disabled={!isActive}
      small
      onClick={this.chooseConfig(type)}
      style={{ margin: 4 }}
    >
      <i className={`fas fa-fw fa-${icon}`} /> {label}
    </Button>
  );

  getTableColumns = (selectedVehiclesCount) => {
    const { vehiclesList, hasSysOperatorRule, hasSysMaintenanceRule } =
      this.props;

    let columns = [];

    const commandColumn = {
      Header: "Comandos",
      accessor: "commands",
      sortable: false,
      getHeaderProps: () => ({ style: { textAlign: "right" } }),
      getProps: () => ({ style: { textAlign: "right" } }),
    };

    if (hasSysOperatorRule) {
      const isAllSelected = selectedVehiclesCount === vehiclesList.length;
      columns.push({
        Header: (
          <Checkbox
            checked={isAllSelected}
            onChange={this.didCheckAll(isAllSelected)}
          />
        ),
        accessor: "checkbox",
        sortable: false,
      });
    }
    columns.push({
      Header: "Nome",
      accessor: "name",
    });
    columns.push({
      Header: "Garagem",
      accessor: "garage",
    });
    columns.push({
      Header: "Status",
      accessor: "status",
      sortMethod: (a, b) => {
        a = a === null || !a || a === '—' ? 0  : moment(a,'DD/MM/YY HH:mm').unix();
        b = b === null || !b || b  === '—' ? 0 : moment(b,'DD/MM/YY HH:mm').unix();
      if(a<b) return 1;
      else if(a>b) return -1;
      else return 0;
      },
    });
    if (hasSysOperatorRule) {
      columns.push({
        Header: "Versão",
        accessor: "version",
      });
    }
    if (hasSysOperatorRule) {
      columns.push(createIconColumn({ icon: "play", accessor: "playlists" }));
      columns.push(createIconColumn({ icon: "wifi", accessor: "internet" }));
      columns.push(createIconColumn({ icon: "arrows", accessor: "line" }));
    }
    columns.push(createIconColumn({ icon: "video", accessor: "camera" }));
    columns.push({
      Header: "CFTV",
      accessor: "lastCameraCollectDate",
      sortMethod: (a, b) => {
        a = a === null || !a || a === '—' ? 0  : moment(a,'DD/MM/YY HH:mm').unix();
        b = b === null || !b || b  === '—' ? 0 : moment(b,'DD/MM/YY HH:mm').unix();
      if(a<b) return 1;
      else if(a>b) return -1;
      else return 0;
      },
      getHeaderProps: () => ({
        style: {
          textAlign: "left",
        },
      }),
      getProps: () => ({
        style: {
          textAlign: "left",
        },
      }),
    });
    if (hasSysOperatorRule) {
      columns.push({
        Header: "Chip",
        accessor: "phoneNumber",
        sortable: false,
      });
      columns.push({
        Header: "Observações",
        accessor: "comments",
        sortable: false,
      });
      columns.push(commandColumn);
    }
    if (hasSysMaintenanceRule && !hasSysOperatorRule) {
      columns.push(commandColumn);
    }

    return columns;
  };

  getTableActions = () => {
    if (!this.props.hasSysOperatorRule) return null;
    return [
      {
        label: "Visualizar",
        icon: "eye",
        color: "info",
        onClick: this.props.didWantToOpenVehicleModal,
      },
      {
        type: "edit",
        onClick: this.props.didWantToEditVehicle,
      },
    ];
  };

  render() {
    const { vehiclesList, hasSysOperatorRule } = this.props;
    const data = this.getTableData(vehiclesList);
    const selectedVehiclesCount = data.filter(
      ({ isSelected }) => isSelected
    ).length;
    const hasAnyVehicleSelected = selectedVehiclesCount > 0;

    return (
      <GridContainer>
        {hasSysOperatorRule && (
          <GridItem xs={12}>
            <Button
              color="primary"
              small
              onClick={this.props.didWantToAddVehicle}
              style={{ margin: 4, marginRight: 16 }}
            >
              Novo veículo <i className="fas fa-plus" />
            </Button>
            {this.renderConfigButton(
              "playlists",
              "play",
              "Playlists",
              hasAnyVehicleSelected
            )}
            {this.renderConfigButton(
              "internet",
              "wifi",
              "Internet",
              hasAnyVehicleSelected
            )}
            {this.renderConfigButton(
              "line",
              "arrows",
              "Linha",
              hasAnyVehicleSelected
            )}
            {this.renderConfigButton(
              "command",
              "cog",
              "Sistema",
              hasAnyVehicleSelected
            )}
          </GridItem>
        )}
        <GridItem xs={12}>
          <Table
            data={data}
            columns={this.getTableColumns(selectedVehiclesCount)}
            actions={this.getTableActions()}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default VehiclesTableContainer;
