// @ts-nocheck
import React from "react";
import { inject, observer } from "mobx-react";

const withLocalized = (WrappedComponent) => {
  return inject("stores")(
    observer((props) => {
      const { i18nStore } = props.stores;
      const { localizedString, localizedPlural } = i18nStore;
      return (
        <WrappedComponent
          localizedString={localizedString}
          localizedPlural={localizedPlural}
          i18nStore={i18nStore}
          {...props}
        />
      );
    })
  );
};

export default withLocalized;
