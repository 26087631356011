// @ts-nocheck
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import { stringDateFromUnixDate } from "../../utils/DateUtils";

const styles = {
  date: {
    margin: 0,
  },
};
const Period = ({ classes, fromDate, toDate }) => (
  <p className={classes.date}>
    <strong>{stringDateFromUnixDate(fromDate, "DD/MM")}</strong>
    {" -> "}
    <strong>{stringDateFromUnixDate(fromDate, "HH:mm")}</strong>
    {" às "}
    <strong>{stringDateFromUnixDate(toDate, "HH:mm")}</strong>
  </p>
);

export default withStyles(styles)(Period);
