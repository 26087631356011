// @ts-nocheck
import React from "react";
import { inject, observer } from "mobx-react";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import BarChart from "../../components/Charts/BarChart";

import withStyles from "@material-ui/core/styles/withStyles";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";

const InstalledDevicesChartWidget = inject("stores")(
  observer(({ stores, classes, onClick }) => {
    const { online, offline } = stores.vehicleStore.vehiclesStatusCount;
    return (
      <Card className={classes.chartCard}>
        <CardHeader color="black" icon>
          <CardIcon color="black">
            <i className="fas fa-bus-alt" />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Veículos</h4>
        </CardHeader>
        <CardBody>
          <BarChart
            height={265}
            xAxisCategories={["Online", "Offline"]}
            data={[
              {
                name: "",
                data: [online, offline],
              },
            ]}
            customChartOptions={{
              events: {
                dataPointSelection: (_, __, config) => {
                  onClick(config.dataPointIndex === 0 ? "online" : "offline");
                },
              },
            }}
          />
        </CardBody>
      </Card>
    );
  })
);
export default withStyles(chartsStyle)(InstalledDevicesChartWidget);
