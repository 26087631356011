import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useState, FC } from "react";
import {
  endOfTheDayMoment,
  startOfTheDayMoment,
  getLastWeekMoment,
} from "../../utils/DateUtils";
import { DatePicker } from "../Form";

interface IDateFromAndDateToPickersProps {
  updatedDates: (fromDate: moment.Moment, toDate: moment.Moment) => void;
  isCollectDateByWeek?: boolean;
}

const DateFromAndDateToPickers: FC<IDateFromAndDateToPickersProps> = ({
  updatedDates,
  isCollectDateByWeek,
}) => {
  const [fromDate, setFromDate] = useState(
    isCollectDateByWeek ? getLastWeekMoment() : startOfTheDayMoment()
  );
  const [toDate, setToDate] = useState(endOfTheDayMoment());

  return (
    <>
      <Grid item xs={12} sm>
        {/* @ts-ignore */}
        <DatePicker
          label="Data início"
          name="fromDate"
          value={fromDate}
          onChange={onChangeFromDate}
          fullWidth
          removeMargin
        />
      </Grid>
      <Grid item xs={12} sm>
        {/* @ts-ignore */}
        <DatePicker
          label="Data fim"
          name="toDate"
          value={toDate}
          onChange={onChangeToDate}
          fullWidth
          removeMargin
          isValidDate={($0) => $0.isSameOrBefore(endOfTheDayMoment())}
        />
      </Grid>
    </>
  );

  function onChangeFromDate(_, newValue) {
    const newToDate = toDate.isBefore(newValue) ? newValue : toDate;
    setNewDataFilter(newValue, newToDate);
  }

  function onChangeToDate(_, newValue) {
    const newFromDate = fromDate.isAfter(newValue) ? newValue : fromDate;
    setNewDataFilter(newFromDate, newValue);
  }

  function setNewDataFilter(newFromDate, newToDate) {
    setFromDate(newFromDate);
    setToDate(newToDate);

    updatedDates(newFromDate, newToDate);
  }
};

export default DateFromAndDateToPickers;
