class OccurrenceCategoryModel {
  token: string;
  name: string;
  code?: string;

  constructor(occurrence) {
    this.token = occurrence.token;
    this.name = occurrence.name;
    this.code = occurrence.code;
  }

  get label(): string {
    return `${this.code}- ${this.name}`;
  }
}

export default OccurrenceCategoryModel;
