// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { withLocalized } from "../../components/HOC";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer, GridItem } from "../../components/Grid";

import ChartWizard from "../../components/Charts/ChartWizard";

import PageTitle from "../../components/PageTitle/PageTitle";
import HourlyTrafficChartContainer from "./connectivityMetricsReportPage/HourlyTrafficChartContainer";
import VisitorsChartContainer from "./connectivityMetricsReportPage/VisitorsChartContainer";

import { WifiChartType } from "../../store/ReportsStore/WifiMetricsReportStore";

// Style
const styles = {
  button: {
    padding: "6px 12px",
    marginRight: 4,
    marginLeft: 4,
  },
  buttonLabelBase: {
    display: "block",
    padding: "10px 8px",
    width: 120,
  },
};

@withLocalized
@inject("stores")
@observer
class ConnectivityMetricsReportPage extends Component {
  componentDidMount() {
    this.props.stores.wifiMetricsReportStore.observeChart(this.props.chartType);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chartType !== this.props.chartType) {
      this.props.stores.wifiMetricsReportStore.observeChart(
        this.props.chartType
      );
    }
  }

  componentWillUnmount() {
    this.props.stores.wifiMetricsReportStore.clearStore();
  }

  onChangeChartTitle = ({ target }) => {
    this.props.stores.wifiMetricsReportStore.onChangeSelectedChartName(
      target.value
    );
  };

  renderReportForType = (type, props, viewId = null) => {
    switch (type) {
      case WifiChartType.hourlyTraffic:
        return <HourlyTrafficChartContainer {...props} />;
      case WifiChartType.visitors:
      default:
        return <VisitorsChartContainer {...props} />;
    }
  };

  renderChart = (chartType) => {
    const { wifiMetricsReportStore } = this.props.stores;
    const viewId = wifiMetricsReportStore.getChartViewForType(chartType);
    const chartView = wifiMetricsReportStore[viewId] || null;

    if (!chartView) return null;

    const {
      isFetching,
      dateRange,
      fromDate,
      toDate,
      wizardCurrentStep,
      chartData,
      error,
    } = chartView;

    return (
      <ChartWizard
        color={"info"}
        allowToContinue={wifiMetricsReportStore.allowToContinue(viewId)}
        allowToExportData={wifiMetricsReportStore.allowToExportData(viewId)}
        currentStep={wizardCurrentStep}
        disabledSteps={wifiMetricsReportStore.disabledStepsForView(viewId)}
        onChangeCurrentStep={wifiMetricsReportStore.didChangeChartWizardCurrentStep(
          viewId
        )}
        reportTypes={[
          {
            id: WifiChartType.hourlyTraffic,
            title: wifiMetricsReportStore.titleForChartType(
              WifiChartType.hourlyTraffic
            ),
          },
          {
            id: WifiChartType.visitors,
            title: wifiMetricsReportStore.titleForChartType(
              WifiChartType.visitors
            ),
          },
        ]}
        chartType={chartType}
        dateRange={dateRange}
        fromDate={fromDate}
        toDate={toDate}
        onChangeDateRange={wifiMetricsReportStore.onChangeDateRangeForView(
          viewId
        )}
        onChangeFromDate={wifiMetricsReportStore.onChangeFromDateForView(
          viewId
        )}
        onChangeToDate={wifiMetricsReportStore.onChangeToDateForView(viewId)}
        renderReport={this.renderReportForType(
          chartType,
          {
            isFetching,
            error,
            chartData,
            datePeriod: { dateRange, fromDate, toDate },
          },
          viewId
        )}
        didWantToResetChart={() =>
          wifiMetricsReportStore.resetChartView(viewId)
        }
        didWantToExportCsv={() =>
          wifiMetricsReportStore.exportChartViewToCsv(viewId)
        }
      />
    );
  };

  render() {
    const { chartType } = this.props;

    return (
      <Fragment>
        <PageTitle id="Menu@wifiMetricsReports">Relatórios Avançados</PageTitle>

        <GridContainer justify="center">
          <GridItem xs={12}>{this.renderChart(chartType)}</GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ConnectivityMetricsReportPage);
