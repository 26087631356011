// @ts-nocheck
import { observable, action, computed } from "mobx";

import UserModel from "../../Model/UserModel";

// Utils Stores
import { DID_LOGOUT } from "../InternalEventsStore/InternalEventsStore";

// Utils
import { didSearchList } from "../../utils/Utils";

class UsersStore {
  @observable usersList = [];
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    authStore,
    internalEventsStore,
    notificationsStore,
    mobR2APIService
  ) {
    this.authStore = authStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.searchString = "";
    this.usersList = [];
  };

  @computed
  get filteredUsersList() {
    if (this.searchString.length > 0 && this.usersList.length > 0) {
      return didSearchList(
        this.searchString,
        this.usersList,
        (user, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(user.name);
          const matchEmail = textIncludesSearchString(user.email);

          return matchName || matchEmail;
        }
      );
    }

    return this.usersList;
  }

  @computed
  get dvrAnalystAndOperators() {
    const filteredDvrList = this.usersList.filter(({ rules: { dvr } }) => {
      const isNotDVRAdmin = !dvr.admin;
      const isDVRAnalystOrOperator = dvr.analyst || dvr.operator;
      return isNotDVRAdmin && isDVRAnalystOrOperator;
    });

    if (this.searchString.length > 0 && filteredDvrList.length > 0) {
      return didSearchList(
        this.searchString,
        filteredDvrList,
        (user, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(user.name);
          const matchEmail = textIncludesSearchString(user.email);

          return matchName || matchEmail;
        }
      );
    }

    return filteredDvrList;
  }

  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setUsersList = (newList) => {
    this.usersList = newList;
  };

  getUsersList = async () => {
    this.setIsFetching(true);
    const newUsersList = [];

    try {
      const response = await this.mobR2APIService.requestUsers();
      if (response.status === 200) {
        response.data.forEach((userData) => {
          newUsersList.push(new UserModel(userData));
        });
      }
    } catch (error) {
      console.error("error in requestUsers", error);
      if (error && error.statusCode === 429) {
        setTimeout(() => this.getUsersList(), 1000);
      }
    } finally {
      this.setUsersList(newUsersList);
      this.setIsFetching(false);
    }
  };

  getWithToken = (token?: string) => {
    if (token && this.usersList.length > 0) {
      return this.usersList.find((user) => user.token === token);
    }
    return null;
  };

  userIsDvrAnalyst = (token: string) => {
    const user = this.getWithToken(token);
    return !!user?.rules?.dvr?.analyst;
  };

  userIsDvrOperator = (token: string) => {
    const user = this.getWithToken(token);
    return !!user?.rules?.dvr?.operator;
  };

  submit = async (data) => {
    try {
      const response = await this.mobR2APIService.submitUser(data);
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.getUsersList();
      return Promise.resolve();
    } catch (error) {
      console.error("error in submitUser", error);
      return Promise.reject(error);
    }
  };
  update = async (data, isEditingTheirOwnAccount) => {
    try {
      const response = await this.mobR2APIService.updateUser(data);
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });

      if (!isEditingTheirOwnAccount) {
        this.getUsersList();
      } else if (data.password.length > 0) {
        this.authStore.logout();
      }

      return Promise.resolve();
    } catch (error) {
      console.error("error in updateUser", error);
      return Promise.reject(error);
    }
  };
  delete = async (token) => {
    try {
      const response = await this.mobR2APIService.deleteUser(token);
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "warning",
      });
      this.getUsersList();
      return Promise.resolve();
    } catch (error) {
      console.error("error in deleteUser", error);
      return Promise.reject(error);
    }
  };
}

export default UsersStore;
