// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../HOC";

import { Button } from "../Button";
import Wizard from "../Wizard/Wizard";
import { GridContainer, GridItem } from "../Grid";

import DateRangeFilter from "./DateRangeFilter";

// Translate
import Translate from "../I18n/Translate";

// Styles
const styles = {
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  messageTextArea: {
    width: "100%",
    resize: "none",
    borderRadius: 6,
    padding: 6,
    borderColor: "#D2D1D2",
  },
  messageHelpText: {
    fontSize: "0.9em",
  },
};

const ChartWizard = ({
  classes,
  localizedString,
  color,
  dateRange,
  fromDate,
  toDate,
  didWantToResetChart,
  onChangeDateRange,
  onChangeFromDate,
  onChangeToDate,
  currentStep,
  disabledSteps,
  onChangeCurrentStep,
  renderReport,
  allowToContinue,
  allowToExportData,
  didWantToExportCsv,
}) => {
  return (
    <Wizard
      color={color}
      allowToContinue={allowToContinue}
      resetOnFinish
      removeNavMargin
      currentStep={currentStep}
      onChangeCurrentStep={onChangeCurrentStep}
      disabledSteps={disabledSteps}
      previousButtonLabel={
        <span>
          <i className="fas fa-chevron-left" />{" "}
          <Translate id="Common@back">Voltar</Translate>
        </span>
      }
      nextButtonLabel={
        <span>
          <Translate id="Common@goForward">Avançar</Translate>{" "}
          {allowToContinue ? (
            <i className="fas fa-chevron-right" />
          ) : (
            <i className="fas fa-ban" />
          )}
        </span>
      }
      actionButtons={
        currentStep === 3 && (
          <GridContainer>
            {allowToExportData && (
              <GridItem xs={12} sm>
                <Button color="default" block onClick={didWantToExportCsv}>
                  <Translate id="Common@export">Exportar</Translate>{" "}
                  <i className="fas fa-file-export" />
                </Button>
              </GridItem>
            )}
            <GridItem xs={12} sm>
              <Button color="warning" block onClick={didWantToResetChart}>
                <Translate id="Metrics@resetChartViewButtonLabel">
                  Resetar relatório atual
                </Translate>{" "}
                <i className="fas fa-undo-alt" />
              </Button>
            </GridItem>
          </GridContainer>
        )
      }
      steps={[
        {
          stepName: localizedString({
            id: "Metrics@dateRangeStepTitle",
            defaultString: "Período",
          }),
          stepComponent: (
            <GridContainer>
              <GridItem xs={12}>
                <h2 className={classes.sessionTitle}>
                  <Translate id="Metrics@dateRangeTitle">
                    Escolha o período
                  </Translate>
                </h2>
                <DateRangeFilter
                  dateRange={dateRange}
                  fromDate={fromDate}
                  toDate={toDate}
                  onChangeDateRange={onChangeDateRange}
                  onChangeFromDate={onChangeFromDate}
                  onChangeToDate={onChangeToDate}
                />
              </GridItem>
            </GridContainer>
          ),
        },
        {
          stepName: localizedString({
            id: "Metrics@showReportStepTitle",
            defaultString: "Visualizar",
          }),
          stepComponent: renderReport,
        },
      ]}
    />
  );
};

export default withStyles(styles)(withLocalized(observer(ChartWizard)));
