class PlaylistModel {
  name: string;
  token: string;

  constructor(playlist: any) {
    this.name = playlist.name;
    this.token = playlist.token;
  }

  get label(): string {
    return this.name;
  }
}

export default PlaylistModel;
