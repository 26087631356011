// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";
import SimpleCard from "../components/Card/SimpleCard";
import Table from "../components/Table/Table";
import BooleanIcon from "../components/BooleanIcon/BooleanIcon";
import Modal from "../components/Modal/Modal";
import AlertModal from "../components/AlertModal/AlertModal";
import { GridContainer, GridItem } from "../components/Grid";
import { Button } from "../components/Button";

import NetworkConfigurationForm from "../components/AppForm/NetworkConfigurationForm";

// Translate
import {
  ConfirmActionString,
  DeleteString,
  CancelString,
  DeleteHintString,
} from "../components/I18n/CommonStrings";

@inject("stores")
@observer
class ConnectivityPage extends Component {
  state = {
    isOpenModal: false,
    selectedNetwork: null,
    isAddingNetwork: false,
    didWantToDelete: false,
  };

  componentWillMount() {
    this.props.stores.networksStore.getDataList();
  }

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
      isOpenModal: false,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.stores.networksStore
      .delete({
        token: this.state.selectedNetwork.token,
      })
      .then(() => {
        this.setState({
          didWantToDelete: false,
          selectedNetwork: null,
        });
      });
  };

  renderWifiFilter = (filter) => {
    if (filter.length > 0) {
      switch (filter) {
        case "high":
          return "Alto";
        case "medium":
          return "Médio";
        case "low":
        default:
          return "Baixo";
      }
    }
    return <BooleanIcon isTrue={false} />;
  };

  getTableData = () => {
    const { stores } = this.props;
    const internetList = stores.networksStore.dataList;

    return internetList.map(
      ({
        token,
        wifiName,
        smartAuth,
        wifiBannerUrl,
        wifiFilter,
        wifiTime,
      }) => ({
        name: wifiName,
        auth: <BooleanIcon isTrue={smartAuth} />,
        banner: <BooleanIcon isTrue={wifiBannerUrl.length > 0} />,
        filter: this.renderWifiFilter(wifiFilter),
        wifiTime: smartAuth ? `${wifiTime}min` : <BooleanIcon isTrue={false} />,
        actions: token,
      })
    );
  };

  didWantToDeleteRow = (token) => () => {
    const selectedNetwork = this.props.stores.networksStore.getWithToken(token);
    this.setState({
      didWantToDelete: true,
      selectedNetwork,
    });
  };

  didWantToAddNetwork = () => {
    this.setState({
      isOpenModal: true,
      isAddingNetwork: true,
    });
  };

  didWantToEditRow = (token) => () => {
    const selectedNetwork = this.props.stores.networksStore.getWithToken(token);
    this.setState({
      isOpenModal: true,
      selectedNetwork,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedNetwork: null,
            isAddingNetwork: false,
          });
        }, 200);
      }
    );
  };

  render() {
    const { isOpenModal, selectedNetwork, isAddingNetwork, didWantToDelete } =
      this.state;
    return (
      <div style={{ padding: "0 16px" }}>
        {didWantToDelete && (
          <AlertModal
            warning
            title={<ConfirmActionString />}
            onConfirm={this.didDeleteConfiguration}
            onCancel={this.didCancelDeleteAction}
            confirmButtonLabel={<DeleteString />}
            cancelButtonLabel={<CancelString />}
            showCancel
          >
            <i className="fas fa-exclamation-triangle" /> <DeleteHintString />
          </AlertModal>
        )}
        {(selectedNetwork || isAddingNetwork) && (
          <Modal
            isOpen={isOpenModal}
            maxWidth={"md"}
            didCloseModal={this.didCloseModal}
          >
            <NetworkConfigurationForm
              network={isAddingNetwork ? null : selectedNetwork}
              didCancel={this.didCloseModal}
              didWantToDelete={this.didWantToDelete}
            />
          </Modal>
        )}
        <PageTitle>Compartilhar Internet</PageTitle>
        <SimpleCard>
          <GridContainer>
            <GridItem xs={12}>
              <Button
                color="primary"
                small
                onClick={this.didWantToAddNetwork}
                style={{ margin: 4 }}
              >
                Adicionar Internet <i className="fas fa-plus" />
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Table
                columns={[
                  {
                    Header: "Nome",
                    accessor: "name",
                  },
                  {
                    Header: "Autenticação",
                    accessor: "auth",
                    sortable: false,
                  },
                  {
                    Header: "Banner",
                    accessor: "banner",
                    sortable: false,
                  },
                  {
                    Header: "Filtros",
                    accessor: "filter",
                    sortable: false,
                  },
                  {
                    Header: "Limite",
                    accessor: "wifiTime",
                    sortable: false,
                  },
                ]}
                actions={[
                  {
                    type: "delete",
                    onClick: this.didWantToDeleteRow,
                  },
                  {
                    type: "edit",
                    onClick: this.didWantToEditRow,
                  },
                ]}
                data={this.getTableData()}
              />
            </GridItem>
          </GridContainer>
        </SimpleCard>
      </div>
    );
  }
}

export default ConnectivityPage;
