// @ts-nocheck
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
// Style
import {
  dangerColor,
  successColor,
  warningColor,
} from "../../assets/jss/app-js-styles";
//Utils
import { APP_ROUTES } from "../../routes/app-routes";
import { secondsToMinutesAndHours } from "../../utils/DateUtils";
import { showInternetSpeedFromBytes } from "../../utils/StringUtils";
import { GridContainer, GridItem } from "../Grid";
import { withLocalized } from "../HOC";
import TerminalStateMessage from "../TerminalStateMessage/TerminalStateMessage";
import Tooltip from "../Tooltip/Tooltip";
import Modal from "./Modal";

const styles = (theme) => ({
  card: {
    width: "100%",
    marginBottom: 16,
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    color: "#fff",
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
  },
  cardHeaderTitleStatusOnline: {
    color: successColor,
  },
  cardHeaderTitleStatusIdle: {
    color: warningColor,
  },
  cardHeaderTitleStatusOffline: {
    color: dangerColor,
  },
  cardHeaderSubtitle: {
    fontSize: "0.9em",
    marginTop: -2,
  },
  media: {
    backgroundColor: "#000",
    backgroundSize: "contain",
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginBottom: 20,
  },
  grayscale: {
    filter: "grayscale(100%)",
  },
  actions: {
    display: "flex",
    padding: 4,
  },
  iconButton: {
    width: 30,
    height: 30,
    fontSize: "1.2em",
  },
  floatRightIcon: {
    marginLeft: "auto",
  },
  tooltip: {
    fontSize: "1.2em",
  },
  listItemIcon: {
    width: 26,
    marginRight: 0,
    textAlign: "center",
  },
});

@withLocalized
@inject("stores")
@observer
class ModalVehicleDetails extends Component {
  state = {
    isOpenModal: false,
    selectedDeviceToStreaming: null,
    alreadyPlayingStreaming: false,
    isLoadingStreaming: false,
    streamingError: false,
    screenshotViewHeight: 0,
  };

  didWantToLocate = () => {
    this.props.history.push({
      pathname: APP_ROUTES.GPS,
      search: `?veiculo=${this.props.vehicle.token}`,
    });
  };

  didCloseInternalModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedDeviceToStreaming: null,
            alreadyPlayingStreaming: false,
          });
        }, 200);
      }
    );
  };

  sendCommand = (command) => () => {
    this.props.stores.vehicleStore.sendCommandToVehicle({
      command,
      token: this.props.vehicle.token,
    });
    this.didCloseModal();
  };

  sendCommandRestart = () => {
    this.sendCommand("reboot")();
  };

  sendCommandUpdateSystem = () => {
    this.sendCommand("update")();
  };

  didCloseModal = () => {
    this.props.didCloseModal();
  };

  renderEnergyMessage = () => {
    const { hasCriticalEnergyAlert, hasEnergyAlert } =
      this.props.vehicle.terminal;

    const state = hasCriticalEnergyAlert
      ? "critical"
      : hasEnergyAlert
      ? "warning"
      : "normal";
    return <TerminalStateMessage state={state} />;
  };

  renderTemperatureMessage = () => {
    const { hasCriticalTemperatureAlert, hasTemperatureAlert } =
      this.props.vehicle.terminal;

    const state = hasCriticalTemperatureAlert
      ? "critical"
      : hasTemperatureAlert
      ? "warning"
      : "normal";
    return <TerminalStateMessage state={state} />;
  };

  renderDiskSpeedMessage = () => {
    const { hasCriticalDiskSpeedAlert, hasDiskSpeedAlert } =
      this.props.vehicle.terminal;

    const state = hasCriticalDiskSpeedAlert
      ? "critical"
      : hasDiskSpeedAlert
      ? "warning"
      : "normal";
    return <TerminalStateMessage state={state} />;
  };

  renderIcon = (iconName, type = "fas") => (
    <i className={`${type} fa-fw fa-${iconName}`} />
  );

  renderListItem = ({
    button,
    divider,
    disabled,
    onClick,
    icon,
    iconType,
    primary,
    secondary,
  }) => (
    <ListItem
      button={button}
      divider={divider}
      disabled={disabled}
      dense
      onClick={onClick}
    >
      <ListItemIcon classes={{ root: this.props.classes.listItemIcon }}>
        {this.renderIcon(icon, iconType)}
      </ListItemIcon>
      <ListItemText primary={primary ? primary : "—"} secondary={secondary} />
    </ListItem>
  );

  render() {
    const { contractStore } = this.props.stores;
    const { isOpen, vehicle } = this.props;
    const {
      name,
      comments,
      plate,
      garage,
      playlists,
      internet,
      line,
      terminal,
      lastCameraCollectDate,
    } = vehicle;
    const {
      color,
      diskFreeSpace,
      screenSize,
      version,
      uptime,
      internetInterface,
      localIPAddress,
      internetIPAddress,
      internetMacAddress,
      bluetooth,
      serialNumber,
      internetDownloadSpeed,
      rpi,
      gps,
      wifiAntenna,
      status,
      lastStatus,
      hasTemperatureAlert,
      hasCriticalAlert,
      hasDiskSpaceAlert,
      ramMemoryFree,
    } = terminal;

    const convertedUptime = secondsToMinutesAndHours(uptime);

    return (
      <Fragment>
        <Modal isOpen={isOpen} didCloseModal={this.didCloseModal} maxWidth="lg">
          <GridContainer alignItems="center">
            <GridItem>
              <h4
                style={{
                  fontWeight: "bold",
                  color: color,
                  marginBottom: comments.length > 0 && 0,
                }}
              >
                <i className="fas fa-bus-alt" /> {name}
              </h4>
              {comments.length > 0 && (
                <p style={{ fontStyle: "italic" }}>{comments}</p>
              )}
            </GridItem>
            {hasCriticalAlert && (
              <GridItem xs style={{ textAlign: "center" }}>
                <p
                  style={{
                    color: dangerColor,
                    fontSize: "1.4em",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  <i className="fas fa-exclamation-triangle" /> Equipamento em
                  estado crítico!
                </p>
              </GridItem>
            )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <h4 style={{ textAlign: "center" }}>Comandos</h4>
              <List style={{ border: "2px solid lightgrey", borderRadius: 8 }}>
                {this.renderListItem({
                  button: true,
                  divider: true,
                  disabled: !gps.latitude,
                  onClick: this.didWantToLocate,
                  icon: "map-marker",
                  primary: "Localizar veículo",
                })}
                {this.renderListItem({
                  button: true,
                  divider: true,
                  onClick: this.props.didWantToEditVehicle,
                  icon: "edit",
                  primary: "Editar veículo",
                })}
                {this.renderListItem({
                  button: true,
                  divider: true,
                  disabled: status !== "online",
                  onClick: this.sendCommandRestart,
                  icon: "sync",
                  primary: "Reiniciar equipamento",
                })}
                {contractStore.selectedContract.hasCFTV
                  ? this.renderListItem({
                      button: true,
                      divider: true,
                      disabled: status !== "online",
                      onClick: this.sendCommand("sendcam"),
                      icon: "video",
                      primary: "Coletar câmeras",
                    })
                  : null}
                {this.renderListItem({
                  button: true,
                  divider: true,
                  disabled: status !== "online",
                  onClick: this.sendCommand("sendlog"),
                  icon: "info-circle",
                  primary: "Coletar logs",
                })}
                {this.renderListItem({
                  button: true,
                  divider: true,
                  disabled: status !== "online",
                  onClick: this.sendCommand("remote"),
                  icon: "toolbox",
                  primary: "Suporte técnico",
                })}
                {this.renderListItem({
                  button: true,
                  divider: false,
                  disabled: status !== "online",
                  onClick: this.sendCommandUpdateSystem,
                  icon: "download",
                  primary: "Atualizar sistema",
                })}
              </List>
            </GridItem>
            <GridItem xs={12} sm>
              <h4 style={{ textAlign: "center" }}>Informações</h4>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <List>
                    {this.renderListItem({
                      divider: true,
                      icon: "ruler-horizontal",
                      primary: plate,
                      secondary: "Placa",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "warehouse",
                      primary: garage ? garage.name : "",
                      secondary: "Garagem",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "play",
                      primary: playlists.map(({ name }) => name).join(", "),
                      secondary: "Playlists",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "wifi",
                      primary: internet ? internet.name : "",
                      secondary: "Internet",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "arrows",
                      primary: line ? line.name : "",
                      secondary: "Linha",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "video",
                      primary: lastCameraCollectDate,
                      secondary: "CFTV",
                    })}
                    {this.renderListItem({
                      divider: false,
                      icon: "clock",
                      primary: lastStatus,
                      secondary: "Último status",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "hdd",
                      primary: terminal.token || "—",
                      secondary: "Token",
                    })}
                    {this.renderListItem({
                      icon: "window-maximize",
                      primary: serialNumber,
                      secondary: "Número de série",
                    })}
                  </List>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <List>
                    {version &&
                      this.renderListItem({
                        divider: true,
                        icon: "window-maximize",
                        primary: version,
                        secondary: "Versão do sistema",
                      })}
                    {status === "online" &&
                      this.renderListItem({
                        divider: true,
                        icon: "plug",
                        primary: convertedUptime,
                        secondary: "Tempo ligado",
                      })}
                    {rpi.model &&
                      this.renderListItem({
                        divider: true,
                        icon: "hdd",
                        primary: (
                          <Fragment>
                            {this.renderIcon("thermometer-empty")}{" "}
                            <span
                              style={{
                                color: hasTemperatureAlert
                                  ? dangerColor
                                  : "inherit",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${rpi.model} ${rpi.temperature}`,
                              }}
                            />
                          </Fragment>
                        ),
                        secondary: "Hardware",
                      })}
                    {this.renderListItem({
                      divider: true,
                      icon: "bolt",
                      primary: this.renderEnergyMessage(),
                      secondary: "Energia",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: hasTemperatureAlert ? "fire" : "thermometer-empty",
                      primary: this.renderTemperatureMessage(),
                      secondary: "Temperatura",
                    })}
                    {rpi.model &&
                      this.renderListItem({
                        divider: true,
                        icon: "folder-open",
                        primary: (
                          <span
                            style={{
                              color: hasDiskSpaceAlert
                                ? dangerColor
                                : "inherit",
                            }}
                          >
                            {diskFreeSpace} MB (livre)
                          </span>
                        ),
                        secondary: "Espaço em disco",
                      })}
                    {this.renderListItem({
                      divider: true,
                      icon: "hdd",
                      primary: this.renderDiskSpeedMessage(),
                      secondary: "Velocidade do disco",
                    })}
                    {ramMemoryFree &&
                      this.renderListItem({
                        divider: true,
                        icon: "memory",
                        primary: <span>{ramMemoryFree} MB </span>,
                        secondary: "Memória RAM",
                      })}
                    {screenSize.length > 0 &&
                      this.renderListItem({
                        divider: true,
                        icon: "tv",
                        primary: screenSize,
                        secondary: "Tamanho da tela",
                      })}
                  </List>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <List>
                    {this.renderListItem({
                      divider: true,
                      icon: "broadcast-tower",
                      primary: wifiAntenna ? "Conectada" : "Não encontrada",
                      secondary: "Antena externa",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "map-pin",
                      primary: gps.externalDevice
                        ? "Conectado"
                        : "Não encontrado",
                      secondary: "Módulo GPS",
                    })}
                    {this.renderListItem({
                      divider: true,
                      icon: "bluetooth-b",
                      iconType: "fab",
                      primary: bluetooth,
                      secondary: "Bluetooth",
                    })}
                    <Tooltip
                      title={`${internetInterface} > ${internetMacAddress}`}
                    >
                      {this.renderListItem({
                        divider: true,
                        icon: "globe",
                        primary: localIPAddress,
                        secondary: "Endereço de rede",
                      })}
                    </Tooltip>
                    {this.renderListItem({
                      divider: internetDownloadSpeed !== "—",
                      icon: "globe",
                      primary: internetIPAddress,
                      secondary: "Endereço de internet",
                    })}
                    {internetDownloadSpeed !== "—" &&
                      this.renderListItem({
                        icon: "cloud-download-alt",
                        primary: showInternetSpeedFromBytes(
                          internetDownloadSpeed
                        ),
                        secondary: "Velocidade de internet",
                      })}
                  </List>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(ModalVehicleDetails));
