import { observable, computed, action } from "mobx";

// Model
import StopModel from "../../Model/StopModel";

// Utils Stores
import InternalEventsStore, {
  DID_LOGOUT,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import MobR2APIService from "../../services/MobR2APIService";
import FileService from "../../services/FileService";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class StopsStore {
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  @observable dataList: StopModel[] = [];
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService,
    fileService: FileService
  ) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;
    this.fileService = fileService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setDataList = (newList: StopModel[]) => {
    this.dataList = newList;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (stop, textIncludesSearchString) => textIncludesSearchString(stop.name)
      );
    }

    return this.dataList;
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newStopsList = [];

    this.mobR2APIService
      .requestStops()
      .then((response: any) => {
        if (response.status === 200) {
          response.data.stops.forEach((stop) => {
            const newStop = new StopModel(stop, this.fileService);

            newStopsList.push(newStop);
          });
        }
        this.setDataList(newStopsList.sort(dynamicSortByProperty("name")));

        this.setIsFetching(false);
      })
      .catch((error) => {
        console.error("error in requestStops", error);
        if (error && error.statusCode === 429) {
          setTimeout(() => {
            this.getDataList();
          }, 2000);
        }
      });
  };

  submit = (stop: StopModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .submitStop(stop)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (stop: StopModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .updateStop(stop)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (token: string) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .deleteStop(token)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "warning",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getWithToken = (token: string) => {
    if (this.dataList.length > 0) {
      const list = this.dataList.filter(($0) => $0.token === token);
      if (list.length > 0) {
        return list[0];
      }
    }
    return null;
  };
}

export default StopsStore;
