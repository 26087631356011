// @ts-nocheck
import React, { Component } from "react";
import { inject } from "mobx-react";
import classnames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { Button } from "../Button";
import Tooltip from "../Tooltip/Tooltip";

// Style
const defaultButtonStyle = {
  padding: "6px 8px",
  opacity: 1,
  margin: 0,
  width: "fit-content",

  "&:hover": {
    zIndex: "2",
  },
};
const styles = (theme) => ({
  label: {
    display: "block",
    color: "#AAAAAA !important",
    fontWeight: "500",
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: "1.42857",
  },
  whiteBackgroundColor: {
    backgroundColor: "#fff!important",
  },
  buttonGroup: {
    position: "relative",
    margin: "10px 1px",
    verticalAlign: "middle",
  },
  firstButton: {
    ...defaultButtonStyle,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: "0!important",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 0,
      borderRightWidth: "1px!important",
      borderBottomWidth: "1px!important",
      width: "100%",
    },
  },
  middleButton: {
    ...defaultButtonStyle,
    borderRadius: 0,
    borderRightWidth: "0!important",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      borderRightWidth: "1px!important",
      borderTopWidth: "0!important",
      borderBottomWidth: "1px!important",
      width: "100%",
    },
  },
  lastButton: {
    ...defaultButtonStyle,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    [theme.breakpoints.down("md")]: {
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 8,
      borderTopWidth: "0!important",
      width: "100%",
    },
  },
  disabledContainer: {
    filter: "grayscale(100%)",
  },
});

@inject("stores")
class ButtonGroup extends Component {
  onChangeValue = (selectedValue) => {
    const { name, onChange } = this.props;

    onChange(name, selectedValue);
  };

  renderOption = (
    index,
    { label, value, description, isDisabled },
    lastIndex
  ) => {
    const {
      classes,
      value: groupSelectedValue,
      color,
      disabled,
      small,
      large,
      whiteBackgroundColor,
    } = this.props;
    const isActive = value === groupSelectedValue;
    return (
      <Button
        key={value}
        color={color}
        outline={!isActive}
        disabled={isDisabled || disabled}
        small={small}
        large={large}
        className={classnames({
          [classes.firstButton]: index === 0,
          [classes.middleButton]: index > 0 && index < lastIndex,
          [classes.lastButton]: index === lastIndex,
          [classes.whiteBackgroundColor]: !isActive && whiteBackgroundColor,
          [classes.disabledContainer]: isDisabled === true,
        })}
        onClick={() => this.onChangeValue(value)}
      >
        {label}{" "}
        {description && (
          <Tooltip title={description}>
            <i className="fa fa-question-circle" />
          </Tooltip>
        )}
      </Button>
    );
  };

  render() {
    const { classes, label, options, disabled } = this.props;
    return (
      <div
        className={classnames({
          [classes.buttonGroup]: true,
          [classes.disabledContainer]: disabled,
        })}
      >
        {label && <label className={classes.label}>{label}</label>}
        <div className="btn-group" role="group">
          {options.map((option, index) =>
            this.renderOption(index, option, options.length - 1)
          )}
        </div>
      </div>
    );
  }
}

ButtonGroup.defaultProps = {
  color: "primary",
};

ButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  whiteBackgroundColor: PropTypes.bool,
};

export default withStyles(styles)(ButtonGroup);
