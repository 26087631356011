// @ts-nocheck
class GarageModel {
  name;
  token;

  constructor(garage) {
    this.name = garage.name;
    this.token = garage.token;
  }
}

export default GarageModel;
