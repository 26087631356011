// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTooltip from "@material-ui/core/Tooltip";

const styles = {
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    color: "#FFFFFF",
    lineHeight: "1.7em",
    background: "rgba(85,85,85,0.9)",
    border: "none",
    borderRadius: "3px",
    opacity: "1!important",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto",
  },
  lightBackground: {
    background: "#fff",
    color: "#333",
  },
  noMaxWidth: {
    maxWidth: "none",
  },
};

const Tooltip = React.forwardRef(
  (
    {
      classes,
      title,
      children,
      placement,
      disableFocusListener,
      disableHoverListener,
      disableTouchListener,
      disabled,
      noMaxWidth,
      lightBackground,
      ...rest
    },
    ref
  ) => (
    <MaterialTooltip
      {...rest}
      innerRef={ref}
      classes={{
        tooltip: classnames({
          [classes.tooltip]: true,
          [classes.noMaxWidth]: noMaxWidth,
          [classes.lightBackground]: lightBackground,
        }),
      }}
      title={title}
      placement={placement}
      disableFocusListener={disabled || disableFocusListener}
      disableHoverListener={disabled || disableHoverListener}
      disableTouchListener={disabled || disableTouchListener}
      TransitionProps={{
        timeout: 20,
      }}
    >
      {children}
    </MaterialTooltip>
  )
);

Tooltip.defaultProps = {
  placement: "bottom",
  disableFocusListener: true,
};

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top",
  ]),
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  disabled: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
  lightBackground: PropTypes.bool,
};

export default withStyles(styles)(Tooltip);
