// @ts-nocheck
import React, { Fragment } from "react";

export const generateRandomLabel = (label = "Label") => {
  const randomInt = Math.floor(Math.random() * 100 + 1);
  return `${label}-${randomInt}`;
};

export const showInternetSpeedFromBytes = (speedInBytes) => {
  const suffixes = ["Bit/s", "Kbit/s", "Mbit/s", "Gbit/s", "Tbit/s"];
  let size = parseFloat(speedInBytes);
  let suffixIndex = 0;
  while (size > 1024 && suffixIndex < 4) {
    suffixIndex++;
    size = size / 1024.0;
  }

  return `${size.toFixed(2)} ${suffixes[suffixIndex]}`;
};

export const toBase64 = (str) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
};

export const textNewLineToBr = (text) => {
  if (text && text.length < 1) return "";

  return text.split("").map((item, index) => (
    <Fragment key={index}>
      {item}
      <br />
    </Fragment>
  ));
};
