// @ts-nocheck
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";

import SimpleCard from "../components/Card/SimpleCard";
import LoadingView from "../components/LoadingView/LoadingView";
import PageTitle from "../components/PageTitle/PageTitle";
import SearchBar from "../components/SearchBar/SearchBar";
import Table from "../components/Table/Table";

import UserReportsFormModal from "./userReportsPage/UserReportsFormModal";

import { titleForRules } from "../Model/ContractModel";

// Style
const styles = {};

@inject("stores")
@observer
class UserReportsPage extends Component {
  componentDidMount() {
    const { cftvReportsStore, usersStore } = this.props.stores;

    usersStore.getUsersList();
    cftvReportsStore.setViewMode("individual");
  }

  componentWillUnmount() {
    this.props.stores.cftvReportsStore.clearStore();
  }

  didWantToOpenUserReportsModal = (selectedUserToken: string) => () => {
    this.props.stores.cftvReportsStore.setSelectedUserToken(selectedUserToken);
  };

  didCancelForm = () => {
    this.props.stores.cftvReportsStore.setSelectedUserToken(null);
  };

  getColumns = () => {
    const hasDvrAdminRule = this.props.stores.authStore.hasAccessTo(
      "dvr",
      "admin"
    );
    let list = [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
    ];

    if (hasDvrAdminRule) {
      list.push({
        Header: "CFTV",
        accessor: "dvr",
      });
    }

    return list;
  };

  getRuleLabel = (rules) => {
    const selectedRule = Object.entries(rules).find(([key, value]) => value);
    if (selectedRule) {
      return titleForRules(selectedRule[0]);
    }
    return "-";
  };

  render() {
    const { cftvReportsStore, usersStore } = this.props.stores;
    const { selectedUserToken } = cftvReportsStore;
    const {
      dvrAnalystAndOperators,
      isFetching,
      searchString,
      setSearchString,
    } = usersStore;

    const selectedUser = usersStore.getWithToken(selectedUserToken);

    return (
      <Fragment>
        <PageTitle>Relatório individual</PageTitle>
        <UserReportsFormModal
          userFullName={selectedUser?.name}
          didCancel={this.didCancelForm}
          selectedUserToken={selectedUserToken}
        />
        <Grid container style={{ padding: "0 16px" }} spacing={2}>
          <Grid item xs={12}>
            <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
              <SearchBar value={searchString} onChange={setSearchString} />
            </SimpleCard>
          </Grid>
          <Grid item xs={12}>
            <SimpleCard>
              <Grid container>
                <Grid item xs={12}>
                  {isFetching ? (
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <LoadingView />
                    </Grid>
                  ) : (
                    <Table
                      data={dvrAnalystAndOperators.map(
                        ({ token, name, email, rules }) => {
                          return {
                            name,
                            email,
                            dvr: this.getRuleLabel(rules.dvr),
                            actions: token,
                          };
                        }
                      )}
                      columns={this.getColumns()}
                      actions={[
                        {
                          label: "Visualizar",
                          icon: "eye",
                          color: "info",
                          onClick: this.didWantToOpenUserReportsModal,
                        },
                      ]}
                    />
                  )}
                </Grid>
              </Grid>
            </SimpleCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(UserReportsPage);
