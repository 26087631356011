// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

import MaterialList from "@material-ui/core/List";

const List = ({ children }) => <MaterialList>{children}</MaterialList>;

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default List;
