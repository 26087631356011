// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";
import Table from "../components/Table/Table";
import BooleanIcon from "../components/BooleanIcon/BooleanIcon";
import AudioPlayer from "../components/Audio/AudioPlayer";
import Modal from "../components/Modal/Modal";
import AlertModal from "../components/AlertModal/AlertModal";
import StopConfigurationForm from "../components/AppForm/StopConfigurationForm";
import { Button } from "../components/Button";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import SearchBar from "../components/SearchBar/SearchBar";

// Translate
import {
  ConfirmActionString,
  DeleteString,
  CancelString,
  DeleteHintString,
} from "../components/I18n/CommonStrings";

@inject("stores")
@observer
class StopsPage extends Component {
  state = {
    isOpenModal: false,
    selectedStop: null,
    isAddingStop: false,
    didWantToDelete: false,
  };

  componentWillMount() {
    this.props.stores.stopsStore.getDataList();
  }

  renderAudio = (audioUrl) => (
    <div
      style={{
        minWidth: 80,
        minHeight: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {audioUrl ? (
        <AudioPlayer src={audioUrl} />
      ) : (
        <BooleanIcon isTrue={false} />
      )}
    </div>
  );

  getTableData = () => {
    const { stores } = this.props;
    const stopsList = stores.stopsStore.filteredDataList;

    return stopsList.map(({ name, audioUrl, token }) => ({
      name: name,
      audio: this.renderAudio(audioUrl),
      actions: token,
    }));
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.stores.stopsStore
      .delete(this.state.selectedStop.token)
      .then(() => {
        this.setState({
          didWantToDelete: false,
          selectedStop: null,
        });
      });
  };

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
    });
  };

  didWantToDeleteRow = (token) => () => {
    const selectedStop = this.props.stores.stopsStore.getWithToken(token);
    this.setState({
      didWantToDelete: true,
      isOpenModal: true,
      selectedStop,
    });
  };

  didWantToEditRow = (token) => () => {
    const selectedStop = this.props.stores.stopsStore.getWithToken(token);
    this.setState({
      isOpenModal: true,
      selectedStop,
    });
  };

  didWantToAddStop = () => {
    this.setState({
      isOpenModal: true,
      isAddingStop: true,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedStop: null,
            isAddingStop: false,
          });
        }, 200);
      }
    );
  };

  render() {
    const { stopsStore } = this.props.stores;
    const { isOpenModal, selectedStop, isAddingStop, didWantToDelete } =
      this.state;

    return (
      <div style={{ padding: "0 16px" }}>
        <PageTitle>Paradas</PageTitle>

        {didWantToDelete && (
          <AlertModal
            warning
            title={<ConfirmActionString />}
            onConfirm={this.didDeleteConfiguration}
            onCancel={this.didCancelDeleteAction}
            confirmButtonLabel={<DeleteString />}
            cancelButtonLabel={<CancelString />}
            showCancel
          >
            <i className="fas fa-exclamation-triangle" /> <DeleteHintString />
          </AlertModal>
        )}
        {(selectedStop || isAddingStop) && (
          <Modal
            isOpen={isOpenModal}
            maxWidth={"sm"}
            didCloseModal={this.didCloseModal}
          >
            <StopConfigurationForm
              stop={isAddingStop ? null : selectedStop}
              didCancel={this.didCloseModal}
              didWantToDelete={this.didWantToDelete}
            />
          </Modal>
        )}
        <GridContainer style={{ marginBottom: 20 }}>
          {stopsStore.dataList.length > 5 && (
            <GridItem xs sm md lg>
              <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
                <SearchBar
                  value={stopsStore.searchString}
                  onChange={stopsStore.setSearchString}
                />
              </SimpleCard>
            </GridItem>
          )}
        </GridContainer>
        <SimpleCard>
          <GridContainer>
            <GridItem xs={12}>
              <Button color="primary" onClick={this.didWantToAddStop} small>
                Adicionar parada <i className="fas fa-plus" />
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Table
                columns={[
                  {
                    Header: "Nome",
                    accessor: "name",
                  },
                  {
                    accessor: "audio",
                    Header: (
                      <div
                        style={{
                          minWidth: 80,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Áudio
                      </div>
                    ),
                    sortable: false,
                  },
                ]}
                data={this.getTableData()}
                actions={[
                  {
                    type: "delete",
                    onClick: this.didWantToDeleteRow,
                  },
                  {
                    type: "edit",
                    onClick: this.didWantToEditRow,
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </SimpleCard>
      </div>
    );
  }
}

export default StopsPage;
