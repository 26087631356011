// @ts-nocheck
import React from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@material-ui/core";

import Tooltip from "../../components/Tooltip/Tooltip";
import { GridContainer, GridItem } from "../../components/Grid";

// Style
import { primaryColor, dangerColor } from "../../assets/jss/app-js-styles";

const styles = (theme) => ({
  card: {
    width: "100%",
    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    color: "#fff",
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
    padding: 6,
  },
  media: {
    backgroundColor: "#000",
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  grayscale: {
    filter: "grayscale(100%)",
  },
  floatingActions: {
    position: "absolute",
    bottom: 38,
    padding: 0,
    width: "100%",
    backgroundColor: "#fff",
    "& > button": {
      backgroundColor: "#ffffffb3",
    },
  },
  actions: {
    display: "flex",
    padding: "0 8px",
    backgroundColor: "#e9e9e94f",
  },
  iconButton: {
    width: 30,
    height: 30,
    fontSize: "1.0em",
    padding: 0,
    color: "#efefef",
  },
  activeIconButton: {
    color: primaryColor,
  },
});

const BusInfoValue = ({ label, value }) => (
  <GridContainer>
    <GridItem xs={4}>{label}:</GridItem>
    <GridItem xs>
      {value.length < 1 ? (
        "—"
      ) : value.length > 20 ? (
        <Tooltip title={value}>
          <strong>{`${value.substring(0, 17)}...`}</strong>
        </Tooltip>
      ) : (
        <strong>{value}</strong>
      )}
    </GridItem>
  </GridContainer>
);

const VehicleItemContainer = ({
  classes,
  vehicleItem,
  didWantToOpenVehicleModal,
}) => {
  const {
    name,
    comments,
    garage,
    line,
    playlist,
    internet,
    hasCamera,
    phoneNumber,
    operator,
    terminal,
  } = vehicleItem;

  const {
    color,
    hasTemperatureAlert,
    hasEnergyAlert,
    hasCriticalAlert,
    hasDiskSpaceAlert,
    hasDiskSpeedAlert,
    lastStatus,
    wifi,
  } = terminal;

  return (
    <Card
      className={classes.card}
      onClick={didWantToOpenVehicleModal}
      style={{
        border: hasCriticalAlert ? `2px solid ${dangerColor}` : "inherit",
      }}
    >
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
        }}
        className={classes.cardHeader}
        title={
          <span style={{ color }}>
            <i className="fas fa-bus-alt" /> {name}
          </span>
        }
      />
      <CardContent>
        <BusInfoValue label="Garagem" value={garage?.name || "—"} />
        <BusInfoValue label="Terminal" value={terminal?.name || "—"} />
        <BusInfoValue label="Linha" value={line?.name || "—"} />
        <BusInfoValue
          label="Chip"
          value={phoneNumber.length > 0 ? `${phoneNumber} (${operator})` : "—"}
        />
        <BusInfoValue
          label="Usuários"
          value={internet?.token?.length > 0 && wifi ? wifi.clients : "—"}
        />
        <BusInfoValue label="Observações" value={comments} />
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        <GridContainer justify="space-between" alignItems="center">
          <GridItem>{lastStatus}</GridItem>
          <GridItem xs style={{ textAlign: "right" }}>
            {hasCriticalAlert && (
              <IconButton
                className={classes.iconButton}
                style={{ backgroundColor: dangerColor }}
              >
                <i className="fas fa-exclamation-triangle" />
              </IconButton>
            )}
            {hasTemperatureAlert && (
              <IconButton
                className={classes.iconButton}
                style={{ color: dangerColor }}
              >
                <i className="fas fa-fire" />
              </IconButton>
            )}
            {hasEnergyAlert && (
              <IconButton
                className={classes.iconButton}
                style={{ color: "#feca57" }}
              >
                <i className="fas fa-bolt" />
              </IconButton>
            )}
            {hasDiskSpeedAlert && (
              <IconButton
                className={classes.iconButton}
                style={{ color: "#feca57" }}
              >
                <i className="fas fa-hdd" />
              </IconButton>
            )}
            {hasDiskSpaceAlert && (
              <IconButton
                className={classes.iconButton}
                style={{ color: dangerColor }}
              >
                <i className="fas fa-folder-open" />
              </IconButton>
            )}
            <Tooltip title={playlist?.name || ""}>
              <IconButton
                className={classnames({
                  [classes.iconButton]: true,
                  [classes.activeIconButton]: playlist?.token?.length > 0,
                })}
              >
                <i className="fas fa-play" />
              </IconButton>
            </Tooltip>
            <Tooltip title={internet?.name || ""}>
              <IconButton
                className={classnames({
                  [classes.iconButton]: true,
                  [classes.activeIconButton]: internet?.token?.length > 0,
                })}
              >
                <i className="fas fa-wifi" />
              </IconButton>
            </Tooltip>
            <IconButton
              className={classnames({
                [classes.iconButton]: true,
                [classes.activeIconButton]: hasCamera,
              })}
            >
              <i className="fas fa-video" />
            </IconButton>
          </GridItem>
        </GridContainer>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(VehicleItemContainer);
