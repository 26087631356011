import { reaction } from "mobx";

import SettingsStore from "../SettingsStore/SettingsStore";
import I18nStore from "../I18nStore/I18nStore";
import AuthStore from "../AuthStore/AuthStore";
import FirebaseAuthStore from "../FirebaseAuthStore/FirebaseAuthStore";
import NetworksStore from "../NetworksStore/NetworksStore";
import PlaylistsStore from "../PlaylistsStore/PlaylistsStore";
import LinesStore from "../LinesStore/LinesStore";
import StopsStore from "../StopsStore/StopsStore";
import InternalEventsStore from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import ReportsStore from "../ReportsStore/ReportsStore";
import CFTVReportsStore from "../ReportsStore/CFTVReportsStore";
import WifiMetricsReportStore from "../ReportsStore/WifiMetricsReportStore";
import TerminalsStore from "../TerminalsStore/TerminalsStore";
import KeyboardEventsStore from "../KeyboardEventsStore/KeyboardEventsStore";
import UsersStore from "../UsersStore/UsersStore";
import GarageStore from "../GarageStore/GarageStore";
import VehicleStore from "../VehicleStore/VehicleStore";
import JobScheduleStore from "../JobScheduleStore/JobScheduleStore";
import VideoStore from "../VideoStore/VideoStore";
import OccurrencesStore from "../OccurrencesStore/OccurrencesStore";
import ContractStore from "../ContractStore/ContractStore";
import MainServices from "../../services/MainServices";

class MainStore {
  authStore: AuthStore;
  cftvReportsStore: CFTVReportsStore;
  contractStore: ContractStore;
  internalEventsStore: InternalEventsStore;
  firebaseAuthStore: FirebaseAuthStore;
  garageStore: GarageStore;
  i18nStore: I18nStore;
  jobScheduleStore: JobScheduleStore;
  keyboardEventsStore: KeyboardEventsStore;
  linesStore: LinesStore;
  networksStore: NetworksStore;
  notificationsStore: NotificationsStore;
  occurrencesStore: OccurrencesStore;
  playlistsStore: PlaylistsStore;
  reportsStore: ReportsStore;
  settingsStore: SettingsStore;
  stopsStore: StopsStore;
  terminalsStore: TerminalsStore;
  usersStore: UsersStore;
  vehicleStore: VehicleStore;
  videoStore: VideoStore;
  wifiMetricsReportStore: WifiMetricsReportStore;

  constructor({ fileService, mobR2APIService }: MainServices) {
    this.settingsStore = new SettingsStore();
    this.internalEventsStore = new InternalEventsStore();
    this.notificationsStore = new NotificationsStore();
    this.keyboardEventsStore = new KeyboardEventsStore();

    this.authStore = new AuthStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.usersStore = new UsersStore(
      this.authStore,
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.i18nStore = new I18nStore(this.authStore);
    this.firebaseAuthStore = new FirebaseAuthStore(
      this.authStore,
      this.internalEventsStore
    );
    this.contractStore = new ContractStore(mobR2APIService);

    this.networksStore = new NetworksStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService,
      fileService
    );

    this.playlistsStore = new PlaylistsStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService,
      fileService
    );

    this.linesStore = new LinesStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService,
      fileService
    );

    this.stopsStore = new StopsStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService,
      fileService
    );

    this.reportsStore = new ReportsStore(
      this.internalEventsStore,
      mobR2APIService
    );

    this.cftvReportsStore = new CFTVReportsStore(
      this.internalEventsStore,
      this.usersStore,
      mobR2APIService
    );

    this.wifiMetricsReportStore = new WifiMetricsReportStore(
      this.authStore,
      this.i18nStore,
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.terminalsStore = new TerminalsStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.garageStore = new GarageStore(
      this.authStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.vehicleStore = new VehicleStore(
      this.internalEventsStore,
      this.terminalsStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.jobScheduleStore = new JobScheduleStore(
      this.authStore,
      this.contractStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.videoStore = new VideoStore(
      this.internalEventsStore,
      this.notificationsStore,
      mobR2APIService
    );

    this.occurrencesStore = new OccurrencesStore(
      this.authStore,
      this.videoStore,
      this.jobScheduleStore,
      this.notificationsStore,
      mobR2APIService
    );

    // Set stores on services
    mobR2APIService.authStore = this.authStore;
    mobR2APIService.contractStore = this.contractStore;
    mobR2APIService.notificationsStore = this.notificationsStore;
    fileService.settingsStore = this.settingsStore;

    // Set i18nStore to stores who need it
    this.authStore.i18nStore = this.i18nStore;
    this.networksStore.i18nStore = this.i18nStore;
    this.playlistsStore.i18nStore = this.i18nStore;

    this.authStore.contractStore = this.contractStore;

    this.setReactions();
  }

  private setReactions = () => {
    reaction(
      () => this.authStore.isAuthenticated,
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.contractStore.getContractsList();
        } else {
          this.contractStore.clearStore();
        }
      },
      { delay: 100 }
    );

    reaction(
      () => !!this.contractStore.selectedContract,
      (isSelectedContract) => {
        if (!isSelectedContract) {
          Object.keys(this)
            .filter((key) => {
              const isIncludedOnRestrictedList = [
                "authStore",
                "contractStore",
                "firebaseAuthStore",
                "internalEventsStore",
                "keyboardEventsStore",
              ].includes(key);
              return key.match(/Store$/) && !isIncludedOnRestrictedList;
            })
            .forEach((key) => {
              this[key].clearStore?.();
            });
        }
      },
      { delay: 100 }
    );
  };
}

export default MainStore;
