// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

// Components
import { InputText, SubmitButton, InputSelect } from "../Form";
import { withLocalized } from "../HOC";

import { GridContainer, GridItem } from "../Grid";
import Validate, { ValidationType } from "../Form/Validate";

// Icons
import { SaveIcon } from "../Icon";

const OPERATOR_OPTIONS = [
  { label: "Nenhum", value: "" },
  { label: "Claro", value: "CLARO" },
  { label: "Vivo", value: "VIVO" },
  { label: "Tim", value: "TIM" },
];

const noneOption = {
  name: "Nenhum",
  label: "Nenhum",
  value: "null",
  token: "null",
};
const createOptionsWithList = (list) => [noneOption, ...list];

@withLocalized
@inject("stores")
@observer
class VehicleForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState(props.vehicle);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle !== this.props.vehicle) {
      this.updateForm();
    }
  }

  updateForm = () => {
    this.setState(this.defaultState(this.props.vehicle));
  };

  defaultState = (vehicle) => {
    const hasTerminal = vehicle && vehicle.terminal?.token.length > 0;

    const vehicleName = vehicle ? vehicle.name : "";
    const vehiclePlate = vehicle ? vehicle.plate : "";
    return {
      phoneNumber: vehicle ? vehicle.phoneNumber : "",
      operator: vehicle
        ? OPERATOR_OPTIONS.find(({ value }) => value === vehicle.operator)
        : null,
      name: vehicleName,
      plate: vehiclePlate,
      comments: vehicle ? vehicle.comments : "",
      garage:
        vehicle && vehicle.garage?.token.length > 0
          ? vehicle.garage
          : noneOption,
      terminal: hasTerminal ? vehicle.terminal : noneOption,
      playlists: vehicle ? vehicle.playlists : [],
      internet:
        vehicle && vehicle.internet?.token.length > 0
          ? vehicle.internet
          : noneOption,
      line:
        vehicle && vehicle.line?.token.length > 0 ? vehicle.line : noneOption,
      isWaitingForm: false,
      formWasSubmit: false,
      isEditing: vehicle !== null,
      isValid: {
        name: Validate(
          { value: vehicleName, length: 3 },
          ValidationType.LENGTH
        ),
        plate: Validate(vehiclePlate, ValidationType.VEHICLE_PLATE),
      },
    };
  };

  componentWillMount() {
    this.props.stores.terminalsStore.getTerminalsList();
    this.props.stores.playlistsStore.getDataList();
    this.props.stores.networksStore.getDataList();
    this.props.stores.linesStore.getDataList();
  }

  onChangeComments = ({ target }) => {
    const { value } = target;

    this.setState({
      comments: value,
    });
  };

  onChangeName = ({ target }) => {
    const { value } = target;
    this.setState((prevState) => ({
      name: value,
      isValid: {
        ...prevState.isValid,
        name: Validate({ value, length: 3 }, ValidationType.LENGTH),
      },
    }));
  };
  onChangePlate = ({ target }) => {
    const { value } = target;
    this.setState((prevState) => ({
      plate: value.length === 3 ? `${value}-` : value,
      isValid: {
        ...prevState.isValid,
        plate: Validate(value, ValidationType.VEHICLE_PLATE),
      },
    }));
  };
  onChangeInputText = ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value,
      isValid: {
        ...this.state.isValid,
        [key]: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeSelectInput = (name, selectedValue) => {
    this.setState({
      [name]: selectedValue,
    });
  };

  canSubmitForm = () => {
    const { isValid } = this.state;

    return isValid.name;
  };

  getFormData = ({
    name,
    plate,
    comments,
    garage,
    terminal,
    playlists,
    internet,
    line,
    operator,
    phoneNumber,
  }) => ({
    name,
    plate,
    comments,
    garageToken: garage?.token,
    terminalToken: terminal?.token,
    playlists: playlists,
    internetToken: internet?.token,
    lineToken: line?.token,
    operator: operator ? operator.value : "",
    phoneNumber,
  });

  submitForm = async () => {
    const data = this.getFormData(this.state);
    try {
      if (this.state.isEditing) {
        await this.props.stores.vehicleStore.update({
          ...data,
          token: this.props.vehicle.token,
        });
        this.submitHasSuccess();
      } else {
        await this.props.stores.vehicleStore.submit(data);
        this.submitHasSuccess();
      }
    } catch (error) {
      this.submitHasError();
    }
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();
    if (this.canSubmitForm()) {
      this.setState(
        { isWaitingForm: true, formWasSubmit: true },
        this.submitForm
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.stores.notificationsStore.addSnackbarNotification({
            message: "Existem erros no formulário. Por favor verifique.",
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => {
    this.props.didSave();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const {
      name,
      plate,
      comments,
      garage,
      terminal,
      playlists,
      internet,
      line,
      phoneNumber,
      operator,
      formWasSubmit,
      isValid,
      isWaitingForm,
      isEditing,
    } = this.state;
    const {
      playlistsStore,
      networksStore,
      linesStore,
      garageStore,
      terminalsStore,
    } = this.props.stores;

    const playlistsList = playlistsStore.dataList;
    const internetList = networksStore.dataList;
    const lineList = linesStore.dataList;

    const { garagesList } = garageStore;
    const { terminalsList } = terminalsStore;

    return this.props.children({
      body: (
        <Fragment>
          <GridContainer>
            <GridItem xs={12} md={7}>
              <InputText
                label="Nome"
                name="name"
                value={name}
                disabled={isEditing}
                hasSuccess={formWasSubmit && isValid.name}
                hasError={formWasSubmit && !isValid.name}
                onChange={this.onChangeName}
                isRequired
              />
            </GridItem>
            <GridItem xs={12} md={5}>
              <InputText
                label="Placa"
                name="plate"
                value={plate}
                hasSuccess={formWasSubmit && isValid.plate}
                hasError={formWasSubmit && !isValid.plate}
                onChange={this.onChangePlate}
                maxLength={8}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputText
                label="Número do celular"
                name="phoneNumber"
                maxLength={120}
                value={phoneNumber}
                onChange={this.onChangeInputText}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputSelect
                label="Operadora"
                name="operator"
                value={operator}
                options={OPERATOR_OPTIONS}
                onChange={this.onChangeSelectInput}
                withPortal
              />
            </GridItem>
            <GridItem xs={12}>
              <InputText
                label="Observações"
                name="comments"
                multiline
                maxLength={140}
                value={comments}
                onChange={this.onChangeComments}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <InputSelect
                label="Garagem"
                name="garage"
                value={garage}
                options={createOptionsWithList(garagesList)}
                onChange={this.onChangeSelectInput}
                withPortal
                labelKey="name"
                valueKey="token"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <InputSelect
                label="Terminal"
                name="terminal"
                value={terminal}
                options={createOptionsWithList(terminalsList)}
                onChange={this.onChangeSelectInput}
                withPortal
                labelKey="name"
                valueKey="token"
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <InputSelect
                label="Playlists"
                name="playlists"
                value={playlists}
                multiple
                options={playlistsList}
                onChange={this.onChangeSelectInput}
                withPortal
                labelKey="name"
                valueKey="token"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <InputSelect
                label="Internet"
                name="internet"
                value={internet}
                options={createOptionsWithList(internetList)}
                onChange={this.onChangeSelectInput}
                withPortal
                labelKey="name"
                valueKey="token"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <InputSelect
                label="Linha"
                name="line"
                value={line}
                options={createOptionsWithList(lineList)}
                onChange={this.onChangeSelectInput}
                withPortal
                labelKey="name"
                valueKey="token"
              />
            </GridItem>
          </GridContainer>
        </Fragment>
      ),
      action: (
        <SubmitButton
          block
          large={false}
          isLoading={isWaitingForm}
          onClick={this.onSubmit}
        >
          {isEditing ? "Atualizar" : "Salvar"} <SaveIcon />
        </SubmitButton>
      ),
    });
  }
}

VehicleForm.defaultProps = {
  vehicle: null,
};

export default VehicleForm;
