import "mobx-react-lite/batchingForReactDom"; // MobX fix for React described here: https://github.com/mobxjs/mobx-react-lite/#observer-batching
import React from "react";
import ReactDOM from "react-dom";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import packageJson from "../package.json";

// Main CSS
import "./assets/scss/app-style.css";

import MainStore from "./store/MainStore/MainStore";
import MainServices from "./services/MainServices";

import App from "./App";

import { isProductionServer, isLocalhost } from "./utils/EnvUtils";

declare global {
  interface Window {
    isDebugActive: boolean;
    debugLog: (message?: any, ...optionalParams: any[]) => void;
    debugError: (message?: any, ...optionalParams: any[]) => void;
  }
}

window.isDebugActive =
  window.location.href.includes("debug=true") || !isProductionServer;

window.debugLog =
  window.debugLog ||
  function (message?: any, ...optionalParams: any[]) {
    if (window.isDebugActive) {
      console.log(message, ...optionalParams);
    }
  };
window.debugError =
  window.debugError ||
  function (message?: any, ...optionalParams: any[]) {
    if (window.isDebugActive) {
      console.error(message, ...optionalParams);
    }
  };

configure({ enforceActions: isLocalhost ? "observed" : "never" });

const services = new MainServices();
const mainStore = new MainStore(services);
const APP_VERSION = packageJson.version;

ReactDOM.render(
  <Provider stores={mainStore} services={services} appVersion={APP_VERSION}>
    <App />
  </Provider>,
  document.getElementById("app-root")
);

// if ((module as any).hot) {
//   (module as any).hot.accept();
// }
