// @ts-nocheck
import axios from "axios";

// Utils Stores
import { saveLog } from "../store/FirebaseStore/FirebaseStore";

import { JOB_SCHEDULE_RULES } from "../Model/JobScheduleModel";

import { isProductionServer, SERVER_URL, isLocalhost } from "../utils/EnvUtils";
import ContractStore from "../store/ContractStore/ContractStore";
import AuthStore from "../store/AuthStore/AuthStore";
import NotificationsStore from "../store/NotificationsStore/NotificationsStore";

const CUSTOM_USER_TOKEN = "a8a3ff90-8983-45e1-8da1-4661cd2526d4";

const API_METHOD = Object.freeze({
  submitLogin: "submitLogin",
  submitUser: "submitUser",
  updateUser: "submitUser",
  deleteUser: "deleteUser",
  requestUser: "requestUser",
  requestUsers: "requestUsers",
  requestContracts: "requestContracts",
  requestBus: "requestBus",
  submitBus: "submitBus",
  updateBus: "submitBus",
  requestGarages: "requestGarages",
  submitGarage: "submitGarage",
  updateGarage: "submitGarage",
  deleteGarage: "deleteGarage",
  submitTerminal: "submitTerminal",
  deleteTerminal: "deleteTerminal",
  requestTerminals: "requestTerminals",
  requestGPS: "requestGPS",
  requestPlaylists: "requestPlaylists",
  requestDataWifi: "requestDataWifi",
  requestMetricsWifi: "requestMetricsWifi",
  requestMetricsDashboard: "requestMetricsDashboard",
  requestNetworks: "requestWifis",
  submitNetwork: "submitWifi",
  updateNetwork: "submitWifi",
  deleteNetwork: "deleteWifi",
  requestStops: "requestStops",
  submitStop: "submitStop",
  deleteStop: "deleteStop",
  requestLines: "requestLines",
  submitLine: "submitLine",
  deleteLine: "deleteLine",
  submitConfig: "submitConfig",
  deleteConfig: "deleteConfig",
  submitCategory: "dvr/submitCategory",
  requestCategories: "dvr/requestCategories",
  requestVideos: "dvr/requestVideos",
  submitVideoOrder: "dvr/submitVideoOrder",
  deleteVideoOrder: "dvr/deleteVideoOrder",
  requestSchedules: "dvr/requestSchedules",
  submitSchedule: "dvr/submitSchedule",
  deleteSchedule: "dvr/deleteSchedule",
  watchSchedule: "dvr/watchSchedule",
  viewedSchedule: "dvr/viewedSchedule",
  finishedSchedule: "dvr/finishedSchedule",
  requestEvents: "dvr/requestEvents",
  submitEvent: "dvr/submitEvent",
  updateEvent: "dvr/updateEvent",
  deleteEvent: "dvr/deleteEvent",
  requestMetricsCFTV: "requestMetricsCFTV",
});

class MobR2APIService {
  authStore: AuthStore = null;
  contractStore: ContractStore = null;
  notificationsStore: NotificationsStore = null;

  getBaseUrl = (customServer?: string) => {
    const protocol = isLocalhost ? "https" : "https";
    return `${protocol}://${
      customServer ? `${customServer}.mobr2.com` : SERVER_URL
    }/api`;
  };

  postPublicMethod = (method, data = {}) => {
    const publicApi = axios.create({
      baseURL: this.getBaseUrl(),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .post(method, data)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(this.errorHandlingCenter(method, error));
        });
    });
  };

  postPrivateMethod = (method, data, customServer) => {
    if (this.authStore.isAuthenticated) {
      const privateApi = axios.create({
        baseURL: this.getBaseUrl(customServer),
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        maxRedirects: 0,
      });

      return new Promise((resolve, reject) => {
        privateApi
          .post(method, data)
          .then((response) => resolve(response))
          .catch((error) => {
            reject(this.errorHandlingCenter(method, error));
          });
      });
    }

    return Promise.reject(null);
  };

  saveErrorLog = (logData) => {
    try {
      saveLog("modbox API Error", logData);
    } catch (_) {
      console.log("firebase log collector error");
    }
  };

  errorHandlingCenter = (method, error) => {
    const logData = {
      method,
      originError: JSON.stringify(error),
    };

    if (error.response && error.response.status) {
      const errorStatusCode = error.response.status;
      const data = error.response.data || {};

      const responseError = {
        statusCode: errorStatusCode,
        ...data,
        message:
          (error.response.data && error.response.data.error) ||
          "Erro desconhecido",
      };

      if (method === API_METHOD.requestMetricsWifi && errorStatusCode === 404) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "404 error",
        });
        return responseError;
      }

      if (errorStatusCode === 502) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "502 error caused logout",
        });
        this.notificationsStore.addServerErrorNotification();
        if (isProductionServer) this.authStore.logout();
        return null;
      }

      if (errorStatusCode === 403) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "403 error caused logout",
        });
        // FORBIDDEN / ACCESS DENIED
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "danger",
        });
        if (method === API_METHOD.requestContracts) {
          return responseError;
        }
        if (isProductionServer) this.authStore.logout();
        return null;
      } else if (errorStatusCode === 409) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "409 error",
        });
        // CONFLICT
        return responseError;
      } else if (errorStatusCode === 429) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "429 error",
        });
        // API is busy
        return responseError;
      } else {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "Generic error",
        });
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "danger",
        });
        return responseError;
      }
    } else {
      this.saveErrorLog({
        ...logData,
        comment: "Fatal error did logout",
      });
      this.notificationsStore.addServerErrorNotification();
      if (isProductionServer) this.authStore.logout();
      return null;
    }
  };

  getDefaultParams = (customServer?: string) => {
    if (!this.authStore || !this.authStore.isAuthenticated) {
      return {};
    }
    const user_token = customServer
      ? CUSTOM_USER_TOKEN
      : this.authStore.currentUserToken;

    const contract_hash =
      this.contractStore?.selectedContract?.hash || undefined;

    return {
      user_token,
      contract_hash,
    };
  };

  getExtraParams = () => {
    return {};
  };

  sanitizeFileType = (type) => {
    return type
      .replace("image/", "")
      .replace("audio/", "")
      .replace("video/", "")
      .replace("jpeg", "jpg");
  };

  sanitizeColor = (color) => {
    return color.replace("#", "");
  };

  // Auth
  submitLogin = ({ email, password }) => {
    return this.postPublicMethod(API_METHOD.submitLogin, {
      email,
      password,
    });
  };

  requestUser = async (userToken) => {
    return this.postPublicMethod(API_METHOD.requestUser, {
      user_token: userToken,
    });
  };

  requestUsers = () => {
    return this.postPrivateMethod(
      API_METHOD.requestUsers,
      this.getDefaultParams()
    );
  };

  sanitizeUserData = ({ name, email, password, rules, garages }) => ({
    ...this.getDefaultParams(),
    name,
    email,
    password: password.length > 1 ? password : undefined,
    rules,
    garages,
  });

  submitUser = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitUser,
      this.sanitizeUserData(data)
    );
  };
  updateUser = ({ token, ...rest }) => {
    return this.postPrivateMethod(API_METHOD.updateUser, {
      ...this.sanitizeUserData(rest),
      token_user: token,
    });
  };
  deleteUser = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteUser, {
      ...this.getDefaultParams(),
      token_user: token,
    });
  };

  // Contracts
  requestContracts = () => {
    return this.postPublicMethod(API_METHOD.requestContracts, {
      user_token: this.authStore.currentUser.token,
    });
  };

  // Garage
  requestGarages = () => {
    return this.postPrivateMethod(
      API_METHOD.requestGarages,
      this.getDefaultParams()
    );
  };

  sanitizeGarageData = ({ name }) => ({
    ...this.getDefaultParams(),
    name,
  });

  submitGarage = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitGarage,
      this.sanitizeGarageData(data)
    );
  };
  updateGarage = ({ token, ...data }) => {
    return this.postPrivateMethod(API_METHOD.updateGarage, {
      ...this.sanitizeGarageData(data),
      garage_token: token,
    });
  };

  recoverPassword = ({ email }) => {
    return this.postPublicMethod(API_METHOD.submitLogin, {
      email,
      recover: true,
    });
  };

  // Devices
  requestTerminals = (customServer?: string) => {
    return this.postPrivateMethod(
      API_METHOD.requestTerminals,
      {
        ...this.getDefaultParams(customServer),
      },
      customServer
    );
  };

  requestTerminal = (terminalToken) => {
    return this.postPrivateMethod(API_METHOD.requestTerminals, {
      ...this.getDefaultParams(),
      terminal_token: terminalToken,
    });
  };

  requestGPS = ({ vehicleToken, fromDate, toDate }) => {
    return this.postPrivateMethod(API_METHOD.requestGPS, {
      ...this.getDefaultParams(),
      bus_token: vehicleToken,
      mode: "replay",
      date_in: fromDate,
      date_out: toDate,
    });
  };

  updateTerminal = ({ token, comments, screenInterface }) => {
    return this.postPrivateMethod(API_METHOD.submitTerminal, {
      ...this.getDefaultParams(),
      terminal_token: token,
      screen_interface: screenInterface,
      comments,
    });
  };

  deleteTerminal = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteTerminal, {
      ...this.getDefaultParams(),
      terminal_token: token,
    });
  };

  // Playlists
  // Doc: https://gist.github.com/mmprestes/e339a0cc7de14e6032fc4d0d454f5a08
  requestPlaylists = () => {
    return this.postPrivateMethod(
      API_METHOD.requestPlaylists,
      this.getDefaultParams()
    );
  };

  // Mod Wifi
  // Doc: https://gist.github.com/mmprestes/1ed6ac7767022c99f47a37fca34e4966
  requestNetworks = () => {
    return this.postPrivateMethod(
      API_METHOD.requestNetworks,
      this.getDefaultParams()
    );
  };

  sanitizeWifiData = ({
    wifiName,
    wifiPassword,
    smartAuth,
    wifiTime,
    wifiRedirect,
    bandwidth,
    wifiFilter,
    banner,
    boost,
  }) => {
    return {
      ...this.getDefaultParams(),
      name: wifiName.trim(),
      password: wifiPassword.trim(),
      auth: smartAuth ? "True" : "False",
      max_time: wifiTime,
      redirect: wifiRedirect,
      bandwidth,
      boost: boost ? "True" : "False",
      filter: wifiFilter !== "none" ? wifiFilter : "",
      media_type: banner ? this.sanitizeFileType(banner.type) : undefined,
      media_data: banner ? banner.base64 : undefined,
    };
  };

  submitNetwork = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitNetwork,
      this.sanitizeWifiData(data)
    );
  };

  updateNetwork = (data) => {
    return this.postPrivateMethod(API_METHOD.updateNetwork, {
      ...this.sanitizeWifiData(data),
      wifi_token: data.token,
    });
  };

  deleteNetwork = ({ token }) => {
    return this.postPrivateMethod(API_METHOD.deleteNetwork, {
      ...this.getDefaultParams(),
      wifi_token: token,
    });
  };

  // ADVERTISING
  sanitizeAdvertisingData = ({
    fromDate,
    toDate,
    occurrences,
    occurrencesUntil,
    phoneNumber,
    connectedInTheLastValue,
    notConnectedInTheLastValue,
    isOnlineNow,
    terminalToken,
    groupToken,
  }) => ({
    ...this.getDefaultParams(),
    from_date: fromDate,
    to_date: toDate,
    occurrences,
    until: occurrencesUntil,
    phone_number: phoneNumber,
    online: connectedInTheLastValue,
    not_online: notConnectedInTheLastValue,
    only_online: isOnlineNow,
    terminal_token: terminalToken,
    group_token: groupToken,
  });

  requestAdvertisingMessages = (data) => {
    return this.postPrivateMethod(
      API_METHOD.requestAdvertisingMessages,
      this.sanitizeAdvertisingData(data)
    );
  };

  submitAdvertisingMessages = (data) => {
    return this.postPrivateMethod(API_METHOD.submitAdvertisingMessages, {
      ...this.sanitizeAdvertisingData(data),
      message: data.message,
      limit_people: data.limitPeople,
    });
  };

  submitSMS = ({ phonesList, message }) => {
    return this.postPrivateMethod(API_METHOD.submitSMS, {
      ...this.getDefaultParams(),
      phones: phonesList,
      message,
    });
  };

  // Reports | Metrics
  requestDataWifi = ({ fromDate, toDate, vehicleToken }) => {
    return this.postPrivateMethod(API_METHOD.requestDataWifi, {
      ...this.getDefaultParams(),
      date_ini: fromDate,
      date_end: toDate,
      bus_token: vehicleToken,
    });
  };

  requestMetricsDashboard = () => {
    return this.postPrivateMethod(API_METHOD.requestMetricsDashboard, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
    });
  };

  requestMetricsCFTV = ({
    token,
    rule,
    fromDate,
    toDate,
    garage,
  }: {
    fromDate: string;
    rule: string;
    toDate: string;
    token?: string;
    garage?: string;
  }) => {
    return this.postPrivateMethod(API_METHOD.requestMetricsCFTV, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      date_ini: fromDate,
      date_end: toDate,
      rule,
      token,
      garage_token: garage,
    });
  };

  requestMetricsWifi = ({
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    connectionsPerType,
    hourlyTraffic,
    dailyTraffic,
    monthlyTraffic,
    yearlyTraffic,
    peopleStatistics,
  }) => {
    let dataTypeList = [];
    if (connectionsPerType) dataTypeList.push("connections_by_type");
    if (hourlyTraffic) dataTypeList.push("hourly_traffic");
    if (dailyTraffic) dataTypeList.push("daily_traffic");
    if (monthlyTraffic) dataTypeList.push("monthly_traffic");
    if (yearlyTraffic) dataTypeList.push("yearly_traffic");
    if (peopleStatistics) dataTypeList.push("users_statistics");
    return this.postPrivateMethod(API_METHOD.requestMetricsWifi, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      date_ini: fromDate,
      date_end: toDate,
      hour_ini: fromHour,
      hour_end: toHour,
      week_days: weekDays,
      list_data_type: dataTypeList,
    });
  };

  submitConfig = ({ vehicles }) => {
    return this.postPrivateMethod(API_METHOD.submitConfig, {
      ...this.getDefaultParams(),
      buses: vehicles,
    });
  };
  deleteConfig = ({ internet, playlist, line }) => {
    return this.postPrivateMethod(API_METHOD.deleteConfig, {
      ...this.getDefaultParams(),
      wifi: internet,
      playlist,
      line,
    });
  };

  requestLines = () => {
    return this.postPrivateMethod(
      API_METHOD.requestLines,
      this.getDefaultParams()
    );
  };

  sanitizeLineData = ({ name, isRandom, stopsList }) => ({
    ...this.getDefaultParams(),
    name,
    random: isRandom ? "True" : "False",
    stops: stopsList.map(($0) => $0.token),
  });

  submitLine = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitLine,
      this.sanitizeLineData(data)
    );
  };

  updateLine = (data) => {
    return this.postPrivateMethod(API_METHOD.submitLine, {
      ...this.sanitizeLineData(data),
      line_token: data.token,
    });
  };

  deleteLine = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteLine, {
      ...this.getDefaultParams(),
      line_token: token,
    });
  };

  requestStops = () => {
    return this.postPrivateMethod(
      API_METHOD.requestStops,
      this.getDefaultParams()
    );
  };

  sanitizeStopData = ({
    name,
    latitude,
    longitude,
    bearing,
    meters,
    screenArea,
    backgroundColor,
    fontColor,
    isTransparent,
    duration,
    audio,
  }) => ({
    ...this.getDefaultParams(),
    name,
    latitude,
    longitude,
    bearing,
    meters,
    screen_area: screenArea,
    background_color: this.sanitizeColor(backgroundColor),
    font_color: this.sanitizeColor(fontColor),
    transparent: isTransparent ? "True" : "False",
    duration,
    media_type: audio ? "mp3" : undefined,
    media_data: audio ? audio.base64 : undefined,
  });

  submitStop = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitStop,
      this.sanitizeStopData(data)
    );
  };

  updateStop = (data) => {
    return this.postPrivateMethod(API_METHOD.submitStop, {
      ...this.sanitizeStopData(data),
      stop_token: data.token,
    });
  };

  deleteStop = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteStop, {
      ...this.getDefaultParams(),
      stop_token: token,
    });
  };

  requestBus = ({ filterByCamera, filterByGPS }, customServer) => {
    return this.postPrivateMethod(
      API_METHOD.requestBus,
      {
        ...this.getDefaultParams(customServer),
        camera: filterByCamera,
        gps: filterByGPS,
      },
      customServer
    );
  };

  sanitizeBusData = ({
    name,
    plate,
    comments,
    garageToken,
    playlists,
    internetToken,
    lineToken,
    terminalToken,
    operator,
    phoneNumber,
  }) => ({
    ...this.getDefaultParams(),
    name,
    plate,
    comments,
    garage_token: garageToken,
    playlists: playlists?.map(({ token }) => token) ?? [],
    wifi_token: internetToken,
    line_token: lineToken,
    terminal_token: terminalToken,
    operator,
    phone_number: phoneNumber,
  });

  submitBus = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitBus,
      this.sanitizeBusData(data)
    );
  };
  updateBus = ({ token, ...rest }) => {
    return this.postPrivateMethod(API_METHOD.updateBus, {
      ...this.sanitizeBusData(rest),
      bus_token: token,
    });
  };

  requestTerminals = () => {
    return this.postPrivateMethod(
      API_METHOD.requestTerminals,
      this.getDefaultParams()
    );
  };

  requestOccurrencesCategories = () => {
    return this.postPrivateMethod(
      API_METHOD.requestCategories,
      this.getDefaultParams()
    );
  };

  // Schedules
  requestSchedules = ({
    token,
    status,
    rules,
    page,
    limit,
    searchString,
    fromDate,
    toDate,
  }) => {
    return this.postPrivateMethod(API_METHOD.requestSchedules, {
      ...this.getDefaultParams(),
      schedule_token: token ? token : undefined,
      status: status ? status : undefined,
      rules: rules ? rules : undefined,
      page,
      limit,
      search_string: searchString,
      date_ini: fromDate,
      date_end: toDate,
    });
  };
  submitSchedule = ({ vehiclesList, fromDate, toDate, rule }) => {
    return this.postPrivateMethod(API_METHOD.submitSchedule, {
      ...this.getDefaultParams(),
      bus_tokens: vehiclesList,
      date_ini: fromDate,
      date_end: toDate,
      rule:
        rule && Object.values(JOB_SCHEDULE_RULES).includes(rule)
          ? rule
          : "operator",
    });
  };
  deleteSchedule = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteSchedule, {
      ...this.getDefaultParams(),
      schedule_token: token,
    });
  };
  watchSchedule = (token) => {
    return this.postPrivateMethod(API_METHOD.watchSchedule, {
      ...this.getDefaultParams(),
      schedule_token: token,
    });
  };
  viewedSchedule = (token) => {
    return this.postPrivateMethod(API_METHOD.viewedSchedule, {
      ...this.getDefaultParams(),
      schedule_token: token,
    });
  };
  finishedSchedule = ({ token, analystMessage }) => {
    return this.postPrivateMethod(API_METHOD.finishedSchedule, {
      ...this.getDefaultParams(),
      schedule_token: token,
      obs_analyst: analystMessage,
    });
  };

  // Events
  requestEvents = (token) => {
    return this.postPrivateMethod(API_METHOD.requestEvents, {
      ...this.getDefaultParams(),
      schedule_token: token,
    });
  };

  sanitizeEvent = ({ scheduleToken, category, description }) => ({
    ...this.getDefaultParams(),
    schedule_token: scheduleToken,
    category_token: category.token,
    description: description,
  });

  submitEvent = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitEvent,
      this.sanitizeEvent(data)
    );
  };
  updateEvent = ({ token, ...rest }) => {
    return this.postPrivateMethod(API_METHOD.submitEvent, {
      ...this.sanitizeEvent(rest),
      event_token: token,
    });
  };
  deleteEvent = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteEvent, {
      ...this.getDefaultParams(),
      event_token: token,
    });
  };
}

export default MobR2APIService;
