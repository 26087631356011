// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import SnackbarNotificationContainer from "./components/Notifications/SnackbarNotificationContainer";
import ErrorCatcher from "./components/ErrorCatcher";
import AppRouter from "./routes/AppRouter";

@inject("stores")
@observer
class App extends Component {
  componentDidMount() {
    document.addEventListener(
      "keydown",
      this.props.stores.keyboardEventsStore.notify,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.props.stores.keyboardEventsStore.notify,
      false
    );
  }
  render() {
    return (
      <ErrorCatcher showErrorOnTheScreen={false}>
        <SnackbarNotificationContainer />
        <AppRouter />
      </ErrorCatcher>
    );
  }
}

export default App;
