// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PageTitle from "../components/PageTitle/PageTitle";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import { InputText } from "../components/Form";
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import ConfirmModal from "../components/Modal/ConfirmModal";
import OccurrencesListDrawer from "../components/Occurrences/OccurrencesListDrawer";

// Utils
import { APP_ROUTES } from "../routes/app-routes";

// Style
const styles = {};

@inject("stores")
@observer
class WatchingVideoPage extends Component {
  state = {
    isOpenAnalystModal: false,
    isOpenSemobOrGarageManagerModal: false,
    isOpenOperatorModal: false,
  };

  navigateTo = (route) => {
    this.props.history.push(route);
  };

  didWantToClose = () => {
    const { authStore } = this.props.stores;
    if (authStore.isDVRAnalyst) {
      this.setState({
        isOpenAnalystModal: true,
      });
    } else if (authStore.isDVRGarageManager || authStore.isDVRSemob) {
      this.setState({
        isOpenSemobOrGarageManagerModal: true,
      });
    } else if (authStore.isDVROperator) {
      this.setState({
        isOpenOperatorModal: true,
      });
    } else {
      this.didCloseAndNavigateTo(APP_ROUTES.REQUEST_VIDEO);
    }
  };

  didCloseAndNavigateTo = (destination) => {
    this.navigateTo(destination);
    this.props.stores.videoStore.setUrlListToWatch(null);
  };

  didCloseModal = () => {
    this.setState({
      isOpenAnalystModal: false,
      isOpenSemobOrGarageManagerModal: false,
      isOpenOperatorModal: false,
    });
  };

  didConfirmOperatorModal = async () => {
    let didSuccess = false;
    try {
      didSuccess =
        await this.props.stores.jobScheduleStore.operatorDidFinishedToWatch();
    } finally {
      if (didSuccess) {
        this.didCloseAndNavigateTo(APP_ROUTES.OPERATOR);
      } else {
        this.didCloseModal();
      }
    }
  };

  didConfirmAnalystModal = async () => {
    try {
      await this.props.stores.jobScheduleStore.analystDidFinishedSchedule();
    } finally {
      this.didCloseAndNavigateTo(APP_ROUTES.ANALYST);
    }
  };

  didConfirmSemobOrGarageManagerModal = async () => {
    try {
      await this.props.stores.jobScheduleStore.analystDidFinishedSchedule();
    } finally {
      this.didCloseAndNavigateTo(APP_ROUTES.CFTV_LIST);
    }
  };

  renderConfirmationModal = () => {
    const { authStore, jobScheduleStore } = this.props.stores;
    if (authStore.isDVRGarageManager || authStore.isDVRSemob) {
      return (
        <Modal
          isOpen={this.state.isOpenSemobOrGarageManagerModal}
          modalTitle="Deseja finalizar e arquivar?"
          maxWidth="sm"
          didCloseModal={this.didCloseModal}
          actions={
            <Grid container spacing={3}>
              <Grid item xs={12} md>
                <Button
                  block
                  color="default"
                  outline
                  onClick={() =>
                    this.didCloseAndNavigateTo(APP_ROUTES.CFTV_LIST)
                  }
                >
                  Sair sem finalizar <i className="fas fa-times" />
                </Button>
              </Grid>
              <Grid item xs={12} md>
                <Button
                  color="primary"
                  block
                  onClick={this.didConfirmSemobOrGarageManagerModal}
                >
                  Finalizar <i className="fas fa-save" />
                </Button>
              </Grid>
            </Grid>
          }
        />
      );
    }
    if (authStore.isDVRAnalyst) {
      return (
        <Modal
          isOpen={this.state.isOpenAnalystModal}
          modalTitle="Finalize sua análise"
          didCloseModal={this.didCloseModal}
          actions={
            <Grid container spacing={3}>
              <Grid item xs={12} md>
                <Button block onClick={this.didCloseModal}>
                  <i className="fas fa-chevron-left" /> Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} md>
                <Button
                  block
                  color="warning"
                  outline
                  onClick={() => this.didCloseAndNavigateTo(APP_ROUTES.ANALYST)}
                >
                  Sair sem finalizar <i className="fas fa-times" />
                </Button>
              </Grid>
              <Grid item xs={12} md>
                <Button
                  color="primary"
                  block
                  onClick={this.didConfirmAnalystModal}
                >
                  Finalizar <i className="fas fa-save" />
                </Button>
              </Grid>
            </Grid>
          }
        >
          <InputText
            label="Observações"
            name="analystMessage"
            value={jobScheduleStore.analystMessage}
            multiline
            onChange={({ target }) =>
              jobScheduleStore.setAnalystMessage(target.value)
            }
          />
        </Modal>
      );
    }
    if (authStore.isDVROperator) {
      return (
        <ConfirmModal
          isOpen={this.state.isOpenOperatorModal}
          title="Confirme esta ação"
          didConfirm={this.didConfirmOperatorModal}
          didCancel={this.didCloseModal}
          confirmLabel="Concluir"
          cancelLabel="Cancelar"
        >
          Você realmente concluiu a análise do trabalho atual?
        </ConfirmModal>
      );
    }
    return null;
  };

  render() {
    if (!this.props.stores.videoStore.urlListToWatch) {
      return <Redirect to="/"></Redirect>;
    }
    return (
      <Grid container>
        <PageTitle>Assistir vídeo</PageTitle>

        {this.renderConfirmationModal()}
        <OccurrencesListDrawer />

        <Grid item xs={12}>
          <VideoPlayer
            disabledKeyboardListener={this.state.isOpenAnalystModal}
            urlList={this.props.stores.videoStore.urlListToWatch}
            didWantToExit={this.didWantToClose}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(WatchingVideoPage);
