// @ts-nocheck
import { APP_ROUTES } from "./app-routes";

// Pages
import DashboardPage from "../pages/DashboardPage";
import UserProfilePage from "../pages/UserProfilePage";
import VehiclesListPage from "../pages/VehiclesListPage";
import ReportsPage from "../pages/ReportsPage";
import ConnectivityPage from "../pages/ConnectivityPage";
import GaragesPage from "../pages/GaragesPage";
import TerminalsPage from "../pages/TerminalsPage";
import LinesPage from "../pages/LinesPage";
import StopsPage from "../pages/StopsPage";
import GPSMonitoringPage from "../pages/GPSMonitoringPage";
import UsersPage from "../pages/UsersPage";
import OperatorPage from "../pages/OperatorPage";
import AnalystPage from "../pages/AnalystPage";
import CFTVListPage from "../pages/CFTVListPage";
import SupervisorPage from "../pages/SupervisorPage";
import UserReportsPage from "../pages/UserReportsPage";
import GroupReportsPage from "../pages/GroupReportsPage";

export const getPageComponentForRoutePath = (routePath) => {
  switch (routePath) {
    case APP_ROUTES.DASHBOARD:
      return DashboardPage;

    case APP_ROUTES.PROFILE:
      return UserProfilePage;

    case APP_ROUTES.VEHICLES:
      return VehiclesListPage;

    case APP_ROUTES.GARAGE:
      return GaragesPage;

    case APP_ROUTES.TERMINALS:
      return TerminalsPage;

    case APP_ROUTES.WIFI:
      return ConnectivityPage;

    case APP_ROUTES.LINES:
      return LinesPage;

    case APP_ROUTES.STOPS:
      return StopsPage;

    case APP_ROUTES.AUTHENTICATION_REPORTS:
      return ReportsPage;

    case APP_ROUTES.GPS:
      return GPSMonitoringPage;

    case APP_ROUTES.USERS:
      return UsersPage;

    case APP_ROUTES.SUPERVISOR:
      return SupervisorPage;

    case APP_ROUTES.OPERATOR:
      return OperatorPage;

    case APP_ROUTES.ANALYST:
      return AnalystPage;

    case APP_ROUTES.CFTV_LIST:
      return CFTVListPage;

    case APP_ROUTES.USER_REPORTS:
      return UserReportsPage;

    case APP_ROUTES.GROUP_REPORTS:
      return GroupReportsPage;

    default:
      return null;
  }
};
