class NetworkModel {
  name: string;
  token: string;
  wifiName: string;
  wifiTime: string;
  bandwidth?: string;
  smartAuth: boolean;
  boost: boolean;
  wifiRedirect?: string;
  wifiPassword?: string;
  wifiFilter?: string;
  wifiBannerUrl?: string;

  constructor(network: any, fileService: any) {
    this.name = network.name;
    this.token = network.token;
    this.wifiName = network.name;
    this.wifiTime = network.max_time;
    this.bandwidth = network.bandwidth;
    this.smartAuth = network.auth === "True";
    this.boost = network.boost === "True";
    this.wifiRedirect = network.redirect;
    this.wifiPassword = network.password;
    this.wifiFilter = network.filter.length > 0 ? network.filter : "none";
    this.wifiBannerUrl = fileService.mountFileUrl({
      hash: network.banner,
    });
  }

  get label(): string {
    return this.name;
  }
}

export default NetworkModel;
