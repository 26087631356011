// @ts-nocheck
export const APP_ROUTES = Object.freeze({
  DASHBOARD: "/painel",
  PROFILE: "/meu-perfil",
  GARAGE: "/cadastro/garagem",
  TERMINALS: "/cadastro/terminais",
  VEHICLES: "/cadastro/veiculos",
  USERS: "/cadastro/usuarios",
  ANALYST: "/cftv/analista",
  OPERATOR: "/cftv/operador",
  SUPERVISOR: "/cftv/supervisor",
  CFTV_LIST: "/cftv/lista",
  GPS: "/monitoramento",
  STOPS: "/proxima-parada/pontos-de-parada",
  LINES: "/proxima-parada/linhas",
  WIFI: "/internet",
  REPORTS: "/relatorio",
  AUTHENTICATION_REPORTS: "/relatorio-de-autenticacao",
  USER_REPORTS: "/relatorio-individual",
  GROUP_REPORTS: "/relatorio-geral",
  LOGIN: "/login",
  REQUEST_VIDEO: "/solicitar-video",
  WATCHING: "/assistindo",
});

const createSystemRule = (rules) => ({
  sys: rules,
});
const createDVRRule = (rules) => ({
  dvr: rules,
});

const DASHBOARD_RULES = {
  ...createSystemRule({ admin: true, operator: true }),
  ...createDVRRule({ admin: true }),
};

const profileRoute = {
  showOnMenu: false,
  path: APP_ROUTES.PROFILE,
  name: "Meu perfil",
  icon: "user",
  rules: "all",
};
const dvrAnalystRoute = {
  showOnMenu: true,
  path: APP_ROUTES.ANALYST,
  name: "Analista",
  icon: "user-shield",
  rules: createDVRRule({
    admin: true,
    analyst: true,
  }),
};
const dvrOperatorRoute = {
  showOnMenu: true,
  path: APP_ROUTES.OPERATOR,
  name: "Operador",
  icon: "film",
  rules: createDVRRule({
    operator: true,
  }),
};

const dvrSupervisorRoute = (name = "Supervisor") => ({
  showOnMenu: true,
  path: APP_ROUTES.SUPERVISOR,
  name,
  icon: "user-secret",
  rules: createDVRRule({
    admin: true,
    supervisor: true,
    garage_manager: true,
  }),
});

const dvrCFTVListRoute = {
  showOnMenu: true,
  path: APP_ROUTES.CFTV_LIST,
  name: "Player",
  icon: "video",
  rules: createDVRRule({
    admin: true,
    supervisor: true,
    semob: true,
    garage_manager: true,
  }),
};

const usersRoute = {
  showOnMenu: true,
  path: APP_ROUTES.USERS,
  name: "Usuários",
  icon: "users",
  rules: {
    ...createSystemRule({ admin: true }),
    ...createDVRRule({ admin: true }),
  },
};
const vehiclesRoute = {
  showOnMenu: true,
  path: APP_ROUTES.VEHICLES,
  name: "Veículos",
  icon: "bus-alt",
  rules: {
    ...createSystemRule({ operator: true, admin: true, maintenance: true }),
    ...createDVRRule({ admin: true, garage_manager: true, supervisor: true }),
  },
};
const terminalsRoute = {
  showOnMenu: true,
  path: APP_ROUTES.TERMINALS,
  name: "Terminais",
  icon: "hdd",
  rules: createSystemRule({ operator: true, admin: true, maintenance: true }),
};

const REDIRECT_APP_ROUTES = [
  {
    redirect: true,
    from: "/perfil",
    to: APP_ROUTES.PROFILE,
    rules: "all",
  },
  {
    redirect: true,
    from: "/",
    to: APP_ROUTES.DASHBOARD,
    rules: DASHBOARD_RULES,
  },
  {
    redirect: true,
    from: "/",
    to: APP_ROUTES.SUPERVISOR,
    rules: createDVRRule({ supervisor: true }),
  },
  {
    redirect: true,
    from: "/",
    to: APP_ROUTES.OPERATOR,
    rules: createDVRRule({ operator: true }),
  },
  {
    redirect: true,
    from: "/",
    to: APP_ROUTES.ANALYST,
    rules: createDVRRule({ analyst: true }),
  },
];

const APP_ROUTES_LIST = [
  profileRoute,
  {
    showOnMenu: true,
    path: APP_ROUTES.DASHBOARD,
    name: "Painel",
    icon: "chart-bar",
    rules: DASHBOARD_RULES,
  },
  {
    showOnMenu: true,
    collapse: true,
    path: "/cadastro",
    name: "Cadastros",
    icon: "folders",
    rules: {
      ...createSystemRule({ operator: true, admin: true, maintenance: true }),
      ...createDVRRule({ admin: true }),
    },
    submenus: [
      {
        showOnMenu: true,
        path: APP_ROUTES.GARAGE,
        name: "Garagem",
        icon: "warehouse",
        rules: createSystemRule({ operator: true, admin: true }),
      },
      terminalsRoute,
      vehiclesRoute,
      usersRoute,
    ],
  },
  {
    showOnMenu: true,
    collapse: true,
    path: "/cftv",
    name: "CFTV",
    icon: "video",
    rules: createDVRRule({
      admin: true,
      supervisor: true,
      operator: true,
      analyst: true,
    }),
    submenus: [dvrAnalystRoute, dvrOperatorRoute, dvrSupervisorRoute()],
  },
  {
    showOnMenu: true,
    path: APP_ROUTES.GPS,
    name: "Monitoramento",
    icon: "map-marker-alt",
    rules: createSystemRule({ operator: true, admin: true }),
  },
  {
    showOnMenu: true,
    collapse: true,
    path: "/proxima-parada",
    name: "Próxima parada",
    icon: "hand-paper",
    rules: createSystemRule({ operator: true, admin: true }),
    submenus: [
      {
        showOnMenu: true,
        path: APP_ROUTES.STOPS,
        name: "Paradas",
        icon: "hand-paper",
        rules: createSystemRule({ operator: true, admin: true }),
      },
      {
        showOnMenu: true,
        path: APP_ROUTES.LINES,
        name: "Linhas",
        icon: "arrows",
        rules: createSystemRule({ operator: true, admin: true }),
      },
    ],
  },
  {
    showOnMenu: true,
    path: APP_ROUTES.WIFI,
    name: "Internet",
    icon: "wifi",
    rules: createSystemRule({ operator: true, admin: true }),
  },
  {
    showOnMenu: true,
    collapse: true,
    path: APP_ROUTES.REPORTS,
    name: "Relatórios",
    icon: "chart-bar",
    rules: createSystemRule({ operator: true, admin: true }),
    submenus: [
      {
        showOnMenu: true,
        path: APP_ROUTES.GROUP_REPORTS,
        name: "Geral",
        icon: "layer-group",
        rules: createSystemRule({ operator: true, admin: true }),
      },
      {
        showOnMenu: true,
        path: APP_ROUTES.USER_REPORTS,
        name: "Individual",
        icon: "walking",
        rules: createSystemRule({ operator: true, admin: true }),
      },
      {
        showOnMenu: true,
        path: APP_ROUTES.AUTHENTICATION_REPORTS,
        name: "Autenticação",
        icon: "stamp",
        rules: createSystemRule({ operator: true, admin: true }),
      },
    ],
  },
  {
    showOnMenu: false,
    path: APP_ROUTES.WATCHING,
    name: "Assistindo",
    icon: "video",
    rules: "all",
  },
  ...REDIRECT_APP_ROUTES,
];

export const appRoutesForCurrentUser = (userRules) => {
  if (userRules.sys.admin || userRules.sys.operator) {
    return APP_ROUTES_LIST.filter(({ rules }) => {
      if (rules === "all") return true;

      let matchRule = false;
      let index = 0;

      const list = Object.keys(rules);
      const listLength = list.length;

      // loop rules categories
      while (!matchRule && index < listLength) {
        const categoryKey = list[index];

        const list2 = Object.keys(rules[categoryKey]);
        const listLength2 = list2.length;

        let index2 = 0;

        // loop every rule in category
        while (!matchRule && index2 < listLength2) {
          const ruleKey = list2[index2];
          matchRule = userRules[categoryKey][ruleKey];
          index2++;
        }

        index++;
      }

      return matchRule;
    });
  }
  if (userRules.sys.maintenance) {
    return [vehiclesRoute, terminalsRoute];
  }

  const { admin, supervisor, analyst, operator, semob, garage_manager } =
    userRules.dvr;
  const routes = [];
  let redirect = null;

  if (analyst) {
    routes.push(dvrAnalystRoute);

    redirect = {
      redirect: true,
      from: "/",
      to: APP_ROUTES.ANALYST,
      rules: createDVRRule({
        admin: true,
        analyst: true,
      }),
    };
  } else if (operator) {
    routes.push(dvrOperatorRoute);
    redirect = {
      redirect: true,
      from: "/",
      to: APP_ROUTES.OPERATOR,
      rules: createDVRRule({
        admin: true,
        analyst: true,
      }),
    };
  } else if (semob) {
    routes.push(dvrCFTVListRoute);
    redirect = {
      redirect: true,
      from: "/",
      to: APP_ROUTES.CFTV_LIST,
      rules: createDVRRule({
        admin: true,
        analyst: true,
        semob: true,
        garage_manager: true,
      }),
    };
  } else if (garage_manager) {
    routes.push(vehiclesRoute);
    routes.push({
      showOnMenu: true,
      collapse: true,
      path: "/cftv",
      name: "CFTV",
      icon: "video",
      rules: createDVRRule({
        admin: true,
        supervisor: true,
        operator: true,
        analyst: true,
      }),
      submenus: [dvrSupervisorRoute("Solicitar"), dvrCFTVListRoute],
    });

    redirect = {
      redirect: true,
      from: "/",
      to: APP_ROUTES.CFTV_LIST,
      rules: createDVRRule({
        admin: true,
        analyst: true,
        semob: true,
        garage_manager: true,
      }),
    };
  } else if (admin || supervisor) {
    routes.unshift(dvrSupervisorRoute());
    redirect = {
      redirect: true,
      from: "/",
      to: APP_ROUTES.SUPERVISOR,
      rules: createDVRRule({
        admin: true,
        analyst: true,
      }),
    };
  }
  routes.push(profileRoute);
  if (admin) {
    routes.unshift(vehiclesRoute);
    routes.unshift(usersRoute);
    redirect = {
      redirect: true,
      from: "/",
      to: APP_ROUTES.USERS,
      rules: createDVRRule({
        admin: true,
      }),
    };
  }
  if (redirect) {
    routes.push(redirect);
  }
  return routes;
};
