// @ts-nocheck
import React from "react";

import { primaryColor, dangerColor } from "../../assets/jss/app-js-styles";

const BooleanIcon = ({ isTrue, trueIcon, falseIcon }) => (
  <i
    className={`fas fa-${isTrue ? trueIcon : falseIcon}`}
    style={{ color: isTrue ? primaryColor : dangerColor }}
  />
);

BooleanIcon.defaultProps = {
  isTrue: false,
  trueIcon: "check",
  falseIcon: "ban",
};

export default BooleanIcon;
