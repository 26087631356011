// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { List, ListItem, ListItemText } from "@material-ui/core";

import SimpleCard from "../components/Card/SimpleCard";
import PageTitle from "../components/PageTitle/PageTitle";
import LoadingView from "../components/LoadingView/LoadingView";
import InfoLabel from "../components/InfoLabel/InfoLabel";
import ControlledPaginate from "../components/Paginate/ControlledPaginate";
import Period from "../components/Period/Period";
import UpdateListProgressCounter from "../components/UpdateListProgressCounter/UpdateListProgressCounter";

import { JOB_SCHEDULE_STATUS } from "../Model/JobScheduleModel";

// Style
const styles = {
  title: {
    fontSize: "1.4em",
    fontWeight: 500,
  },
};

@inject("stores")
@observer
class CFTVListPage extends Component {
  componentDidMount() {
    this.refreshJobs(true)();
  }

  refreshJobs = (withFetching) => () => {
    const { jobScheduleStore } = this.props.stores;
    jobScheduleStore.setIsFetching(withFetching);
    jobScheduleStore.getJobScheduleList();
  };

  didWantToPlay = (token, files) => () => {
    const { jobScheduleStore, videoStore } = this.props.stores;
    jobScheduleStore.setSelectedScheduleToken(token);
    videoStore.setUrlListToWatch(files);
  };

  render() {
    const { classes } = this.props;
    const {
      isFetching,
      isFetchingPaginate,
      jobScheduleList,
      limit,
      page,
      totalItems,
      onChangePaginate,
    } = this.props.stores.jobScheduleStore;

    const list = jobScheduleList.filter(
      ({ status }) => status === JOB_SCHEDULE_STATUS.READY
    );

    return (
      <Fragment>
        <PageTitle>CFTV</PageTitle>
        <Grid
          container
          style={{
            marginTop: -2,
            marginBottom: 20,
            position: "sticky",
            top: 104,
            zIndex: 10,
          }}
        >
          {!(isFetching || isFetchingPaginate) && (
            <Grid item xs={12}>
              <UpdateListProgressCounter
                callback={this.refreshJobs(false)}
                isHidden={isFetching}
              />
            </Grid>
          )}
        </Grid>
        <Grid container style={{ padding: "0 16px" }} spacing={3}>
          {isFetching ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingView />
            </Grid>
          ) : list.length < 1 ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <InfoLabel>
                Nenhum vídeo disponível para análise no momento.
              </InfoLabel>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12}>
                <p style={{ textAlign: "center" }}>
                  Selecione um trabalho para analisar:
                </p>
              </Grid>
              <ControlledPaginate
                isFetching={isFetchingPaginate}
                itemsPerPage={limit}
                currentPage={page}
                itemsCount={totalItems}
                onChange={onChangePaginate}
              >
                {list.map(
                  ({ token, cams, vehicle, supervisor, fromDate, toDate }) => (
                    <Grid key={token} item xs={12} sm={6} md={4} lg={3}>
                      <SimpleCard
                        onClick={this.didWantToPlay(token, cams)}
                        cardStyle={{ margin: 0, cursor: "pointer" }}
                        cardBodyStyle={{ padding: 0 }}
                      >
                        <List>
                          <ListItem divider dense>
                            <ListItemText
                              primary={
                                <p className={classes.title}>{vehicle}</p>
                              }
                              secondary="Veículo"
                            />
                          </ListItem>
                          <ListItem divider dense>
                            <ListItemText
                              primary={supervisor.name}
                              secondary="Supervisor"
                            />
                          </ListItem>
                          <ListItem dense>
                            <ListItemText
                              primary={
                                <Period fromDate={fromDate} toDate={toDate} />
                              }
                              secondary="Período"
                            />
                          </ListItem>
                        </List>
                      </SimpleCard>
                    </Grid>
                  )
                )}
              </ControlledPaginate>
            </Fragment>
          )}
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(CFTVListPage);
