import React, { FC } from "react";
import { dangerColor, warningColor } from "../../assets/jss/app-js-styles";

interface ITerminalStateMessageProps {
  state: "critical" | "normal" | "warning";
}

const TerminalStateMessage: FC<ITerminalStateMessageProps> = ({ state }) => {
  const isCritical = state === "critical";
  const isWarning = state === "warning";

  return <span style={{ color: getColor() }}>{getMessage()}</span>;

  function getColor() {
    if (isCritical) return dangerColor;
    if (isWarning) return warningColor;
    return "inherit";
  }

  function getMessage() {
    if (isCritical) return "Estado crítico";
    if (isWarning) return "Problema identificado";
    return "Condição normal";
  }
};

export default TerminalStateMessage;
