// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { List, ListItem, ListItemText } from "@material-ui/core";

import SimpleCard from "../components/Card/SimpleCard";
import PageTitle from "../components/PageTitle/PageTitle";
import SearchBar from "../components/SearchBar/SearchBar";
import LoadingView from "../components/LoadingView/LoadingView";
import InfoLabel from "../components/InfoLabel/InfoLabel";
import ControlledPaginate from "../components/Paginate/ControlledPaginate";
import Period from "../components/Period/Period";
import UpdateListProgressCounter from "../components/UpdateListProgressCounter/UpdateListProgressCounter";

import { JOB_SCHEDULE_STATUS } from "../Model/JobScheduleModel";

// Utils
import { stringDateFromUnixDate } from "../utils/DateUtils";

// Style
const styles = {
  title: {
    fontSize: "1.2em",
    fontWeight: 500,
  },
};

@inject("stores")
@observer
class AnalystPage extends Component {
  componentDidMount() {
    this.refreshJobs(true)();
  }

  refreshJobs = (withFetching) => () => {
    const { jobScheduleStore } = this.props.stores;
    jobScheduleStore.setIsFetching(withFetching);
    jobScheduleStore.getJobScheduleList();
  };

  didWantToPlay = (token, files) => () => {
    const { jobScheduleStore, videoStore } = this.props.stores;
    jobScheduleStore.setSelectedScheduleToken(token);
    videoStore.setUrlListToWatch(files);
  };

  render() {
    const { classes } = this.props;
    const {
      isFetching,
      isFetchingPaginate,
      jobScheduleList,
      searchString,
      setSearchString,
      limit,
      page,
      totalItems,
      onChangePaginate,
    } = this.props.stores.jobScheduleStore;

    const list = jobScheduleList.filter(
      ({ status, totalEvents }) =>
        totalEvents > 0 && status === JOB_SCHEDULE_STATUS.VIEWED
    );

    return (
      <Fragment>
        <PageTitle>Painel do Analista</PageTitle>
        <Grid container style={{ padding: "0 16px" }}>
          <Grid item xs={12}>
            {!(isFetching || isFetchingPaginate) && (
              <UpdateListProgressCounter
                callback={this.refreshJobs(false)}
                isHidden={isFetching}
              />
            )}
            <SimpleCard>
              <SearchBar value={searchString} onChange={setSearchString} />
            </SimpleCard>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "0 16px" }} spacing={4}>
          {isFetching ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingView />
            </Grid>
          ) : list.length < 1 ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <InfoLabel>
                Nenhum vídeo disponível para análise no momento.
              </InfoLabel>
            </Grid>
          ) : (
            <ControlledPaginate
              isFetching={isFetchingPaginate}
              itemsPerPage={limit}
              currentPage={page}
              itemsCount={totalItems}
              onChange={onChangePaginate}
            >
              {list.map(
                ({
                  token,
                  vehicle,
                  supervisor,
                  operator,
                  dateViewed,
                  fromDate,
                  toDate,
                  cams,
                  totalEvents,
                }) => (
                  <Grid key={token} item xs={12} sm={6} md={4} lg={3}>
                    <SimpleCard
                      onClick={this.didWantToPlay(token, cams)}
                      cardStyle={{ margin: 0, cursor: "pointer" }}
                      cardBodyStyle={{ padding: 0 }}
                    >
                      <List>
                        <ListItem divider dense>
                          <ListItemText
                            primary={<p className={classes.title}>{vehicle}</p>}
                            secondary="Veículo"
                          />
                        </ListItem>
                        <ListItem divider dense>
                          <ListItemText
                            primary={supervisor.name}
                            secondary="Supervisor"
                          />
                        </ListItem>
                        <ListItem divider dense>
                          <ListItemText
                            primary={`${
                              operator.name
                            } > ${stringDateFromUnixDate(
                              dateViewed,
                              "DD/MM/YY HH:mm"
                            )}`}
                            secondary="Operador"
                          />
                        </ListItem>
                        <ListItem divider dense>
                          <ListItemText
                            primary={`${totalEvents} evento(s) registrado(s)`}
                            secondary="Total"
                          />
                        </ListItem>
                        <ListItem dense>
                          <ListItemText
                            primary={
                              <Period fromDate={fromDate} toDate={toDate} />
                            }
                            secondary="Período"
                          />
                        </ListItem>
                      </List>
                    </SimpleCard>
                  </Grid>
                )
              )}
            </ControlledPaginate>
          )}
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(AnalystPage);
