// @ts-nocheck
import React from "react";
import Chart from "react-apexcharts";

const HeatMapChart = ({
  title,
  categories,
  data,
  height,
  width,
  showDataLabels,
  showToolbar,
  hideTooltip,
  customChartOption,
}) => {
  const chartData = {
    options: {
      colors: ["#f64747"],
      dataLabels: {
        enabled: showDataLabels,
      },
      xaxis: categories
        ? {
            type: "category",
            categories: categories,
          }
        : {},
      title: {
        text: title,
      },
      tooltip: {
        enabled: !hideTooltip,
      },
      chart: {
        toolbar: {
          show: showToolbar,
        },
        ...customChartOption,
      },
    },
  };
  return (
    <Chart
      options={chartData.options}
      series={data}
      type="heatmap"
      height={height}
      width={width}
    />
  );
};

HeatMapChart.defaultProps = {
  showToolbar: false,
  hideTooltip: false,
  showDataLabels: false,
  customChartOption: {},
};

export default HeatMapChart;
