// @ts-nocheck
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { GridItem } from "../Grid";
import InfoLabel from "../InfoLabel/InfoLabel";
import LoadingView from "../LoadingView/LoadingView";

// Style
const styles = {
  message: {
    // fontSize: "1.2em",
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
  },
};

const MetricChartBody = ({ classes, isFetching, hasError, children }) => (
  <GridItem xs={12}>
    {isFetching ? (
      <LoadingView height={60} />
    ) : hasError ? (
      <InfoLabel large className={classes.message}>
        {hasError}
      </InfoLabel>
    ) : (
      children
    )}
  </GridItem>
);

export default withStyles(styles)(MetricChartBody);
