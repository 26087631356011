// @ts-nocheck
import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

class UpdateListProgressCounter extends Component {
  state = {
    refreshProgress: 0,
  };

  componentDidMount() {
    this.progressIndicatorTimer = setInterval(() => {
      if (this.props.isDisabled) return false;
      this.setState((prevState) => {
        const { refreshProgress } = prevState;
        if (refreshProgress >= 100) {
          this.props.callback();
          return {
            refreshProgress: 0,
          };
        }

        return {
          refreshProgress: refreshProgress + 0.1667,
        };
      });
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.progressIndicatorTimer);
  }

  render() {
    return (
      <LinearProgress
        variant="determinate"
        value={this.state.refreshProgress}
        style={{
          width: "100%",
          margin: "8px 0",
          display: this.props.isHidden ? "none" : "inherit",
          WebkitTransition: "opacity 0.3s ease-in-out",
          MozTransition: "opacity 0.3s ease-in-out",
          transition: "opacity 0.3s ease-in-out",
          opacity: this.props.isHidden || this.props.isDisabled ? 0 : 1,
        }}
      />
    );
  }
}

export default UpdateListProgressCounter;
