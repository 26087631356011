// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import SimpleCard from "../components/Card/SimpleCard";
import SearchBar from "../components/SearchBar/SearchBar";
import InputSelect from "../components/Form/InputSelect";
import PageTitle from "../components/PageTitle/PageTitle";
import Table, { createIconColumn } from "../components/Table/Table";
import ConfirmDeleteModal from "../components/Modal/ConfirmDeleteModal";
import LoadingView from "../components/LoadingView/LoadingView";
import Button from "../components/Button/Button";
import BooleanIcon from "../components/BooleanIcon/BooleanIcon";
import Tooltip from "../components/Tooltip/Tooltip";

import TerminalFormModal from "./terminalsPage/TerminalFormModal";

import { APP_ROUTES } from "../routes/app-routes";

const CustomBooleanIcon = ({ isTrue }) => (
  <BooleanIcon
    isTrue={isTrue}
    trueIcon="thumbs-up"
    falseIcon="exclamation-triangle"
  />
);

const statusListOptions = [
  { label: "Exibir todos", value: "all" },
  { label: "Exibir apenas disponíveis", value: "only_available" },
  { label: "Exibir apenas associados à veículo", value: "only_associated" },
];

// Style
const styles = {};

@inject("stores")
@observer
class TerminalsPage extends Component {
  state = {
    isFormOpen: false,
    isEditingToken: null,
    didWantToDelete: false,
    tokenToDelete: null,
  };

  componentDidMount() {
    this.props.stores.terminalsStore.getTerminalsList();
  }

  didWantToAdd = () => {
    this.setState({
      isFormOpen: true,
    });
  };

  didWantToUpdate =
    ({ token }) =>
    () => {
      this.setState({
        isEditingToken: token,
        isFormOpen: true,
      });
    };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
      tokenToDelete: null,
    });
  };

  didDelete = async () => {
    await this.props.stores.terminalsStore.delete(this.state.tokenToDelete);

    this.didCancelDeleteAction();
  };

  didWantToDelete =
    ({ token }) =>
    () => {
      this.setState({
        didWantToDelete: true,
        tokenToDelete: token,
      });
    };

  didCancelForm = () => {
    this.setState({
      isEditingToken: null,
      isFormOpen: false,
    });
  };

  goToVehiclePageWithSerialNumberFilter = (serialNumber) => () => {
    this.props.history.push({
      pathname: APP_ROUTES.VEHICLES,
      search: `?terminal=${serialNumber}`,
    });
  };

  render() {
    const { terminalsStore } = this.props.stores;
    const { isFetching, filteredTerminalsList: terminalsList } = terminalsStore;

    const selectedTerminal = this.state.isEditingToken
      ? terminalsList.find(({ token }) => token === this.state.isEditingToken)
      : null;

    return (
      <Fragment>
        <PageTitle>Cadastro de terminais</PageTitle>
        {this.state.didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDelete}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <TerminalFormModal
          isOpen={this.state.isFormOpen}
          terminal={selectedTerminal}
          didCancel={this.didCancelForm}
        />
        <Grid container style={{ padding: "0 16px" }} spacing={2}>
          <Grid item xs={12} sm>
            <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm>
                  <SearchBar
                    value={terminalsStore.searchString}
                    onChange={terminalsStore.setSearchString}
                  />
                </Grid>
                <Grid item xs={12} sm>
                  <InputSelect
                    name="status"
                    value={statusListOptions.find(
                      ({ value }) => value === terminalsStore.filterByStatus
                    )}
                    options={statusListOptions}
                    onChange={(_, selectedStatus) => {
                      terminalsStore.setFilterByStatus(selectedStatus.value);
                    }}
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </Grid>
              </Grid>
            </SimpleCard>
          </Grid>
          <Grid item xs={12}>
            <SimpleCard>
              {isFetching ? (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <LoadingView />
                </Grid>
              ) : (
                <Table
                  data={terminalsList.map(
                    ({
                      token,
                      name,
                      color,
                      lastStatus,
                      version,
                      serialNumber,
                      comments,
                      hasTemperatureAlert,
                      hasEnergyAlert,
                      hasDiskSpeedAlert,
                      hasCriticalAlert,
                      hasDiskSpaceAlert,
                      usedIn,
                    }) => {
                      return {
                        name: <span style={{ color }}>{name}</span>,
                        status: lastStatus,
                        version,
                        serialNumber,
                        critical: (
                          <CustomBooleanIcon isTrue={!hasCriticalAlert} />
                        ),
                        temperature: (
                          <CustomBooleanIcon isTrue={!hasTemperatureAlert} />
                        ),
                        energy: <CustomBooleanIcon isTrue={!hasEnergyAlert} />,
                        disk: <CustomBooleanIcon isTrue={!hasDiskSpaceAlert} />,
                        diskSpeed: (
                          <CustomBooleanIcon isTrue={!hasDiskSpeedAlert} />
                        ),
                        usedIn:
                          usedIn.token.length > 0 ? (
                            <Tooltip title={usedIn.name}>
                              <Button
                                color="transparent"
                                justIcon
                                small
                                onClick={this.goToVehiclePageWithSerialNumberFilter(
                                  serialNumber
                                )}
                              >
                                <BooleanIcon isTrue={true} />
                              </Button>
                            </Tooltip>
                          ) : (
                            <BooleanIcon isTrue={false} />
                          ),
                        comments,
                        actions: {
                          token,
                          canDelete: !usedIn.token.length > 0,
                        },
                      };
                    }
                  )}
                  columns={[
                    createIconColumn({ icon: "bus-alt", accessor: "usedIn" }),
                    {
                      Header: "Nome",
                      accessor: "name",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Versão",
                      accessor: "version",
                    },
                    {
                      Header: "Serial",
                      accessor: "serialNumber",
                    },
                    createIconColumn({
                      icon: "exclamation-triangle",
                      accessor: "temperature",
                    }),
                    createIconColumn({ icon: "fire", accessor: "temperature" }),
                    createIconColumn({ icon: "bolt", accessor: "energy" }),
                    createIconColumn({ icon: "folder-open", accessor: "disk" }),
                    createIconColumn({ icon: "hdd", accessor: "diskSpeed" }),
                    {
                      Header: "Observações",
                      accessor: "comments",
                      sortable: false,
                    },
                  ]}
                  actions={[
                    {
                      type: "delete",
                      onClick: this.didWantToDelete,
                    },
                    {
                      type: "edit",
                      onClick: this.didWantToUpdate,
                    },
                  ]}
                />
              )}
            </SimpleCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(TerminalsPage);
