// @ts-nocheck
import React from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Collapse,
  Divider,
} from "@material-ui/core";

// Style
import sidebarStyle from "../../assets/jss/js-styles/components/sidebarStyle";

class SidebarWrapper extends React.Component {
  render() {
    const { className, user, links } = this.props;
    return (
      <div className={className}>
        {user}
        {links}
      </div>
    );
  }
}

@inject("stores")
@observer
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDevicesMenu: this.isRouteActive("/devices"),
      openLocationsMenu: this.isRouteActive("/locations"),
      openInformativeMenu: this.isRouteActive("/informative"),
      openConnectivityMenu: this.isRouteActive("/wifi"),
      openAdvertisingMenu: this.isRouteActive("/advertising"),
      openCameraMenu: this.isRouteActive("/camera"),
      openAdministrativeMenu: this.isRouteActive("/administrativo"),
    };
  }
  // verifies if routeName is the one active (in browser input)
  isRouteActive = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1;
  };

  toggleCollapse = (collapseKey) => (event) => {
    event.preventDefault();

    this.setState({
      [collapseKey]: !this.state[collapseKey],
    });
  };

  renderLinks = (routes) => {
    const { classes } = this.props;
    return (
      <List className={classes.list} style={{ marginBottom: 60 }}>
        {routes.map((prop, key) => {
          if (prop.collapse) {
            const navLinkClasses = classnames({
              [classes.itemLink]: true,
              [classes.collapseActive]: this.isRouteActive(prop.path),
            });
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={this.toggleCollapse(prop.state)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <i className={`fas fa-${prop.icon}`} />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={
                      <b
                        className={
                          classes.caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={classes.itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.submenus.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      const navLinkClasses = classnames({
                        [classes.collapseItemLink]: true,
                        [classes.blue]: this.isRouteActive(prop.path),
                      });
                      const collapseItemMini = classes.collapseItemMini;
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              <i className={`fas fa-${prop.icon}`} />
                            </span>
                            <ListItemText
                              primary={prop.name}
                              disableTypography={true}
                              className={classes.collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
                <Divider className={classes.listDivider} />
              </ListItem>
            );
          }
          const navLinkClasses = classnames({
            [classes.itemLink]: true,
            [classes.blue]: this.isRouteActive(prop.path),
          });
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={classes.itemIcon}>
                  <i className={`fas fa-${prop.icon}`} />
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={classes.itemText}
                />
              </NavLink>
              {prop.nameId !== "Menu@logout" && (
                <Divider className={classes.listDivider} />
              )}
            </ListItem>
          );
        })}
      </List>
    );
  };

  render() {
    const { classes, routes, isMobileDrawerOpen, handleMobileDrawerToggle } =
      this.props;

    return (
      <div ref="mainPanel">
        <Hidden mdUp>
          {
            <Drawer
              variant={"temporary"}
              anchor={"left"}
              open={isMobileDrawerOpen}
              classes={{
                paper: classes.drawerPaper + " " + classes.blackBackground,
              }}
              onClose={handleMobileDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <SidebarWrapper
                className={classes.sidebarWrapper}
                links={this.renderLinks(routes)}
              />
            </Drawer>
          }
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(sidebarStyle)(Sidebar);
