// @ts-nocheck
import { observable, action } from "mobx";

import { isProductionServer } from "../../utils/EnvUtils";

class SettingsStore {
  @observable mediaFilesPath = "";
  @observable imagesPath = "";
  @observable logoImage = "";
  @observable loginPageBgImage = "";

  @observable sidebarBgImage = "";

  @observable wifiRedirectToDefault =
    window.localStorage.getItem("wifiRedirectToDefault") ||
    "https://google.com";
  @observable wifiTimeDefault =
    window.localStorage.getItem("wifiTimeDefault") || "60";
  @observable exibitionMode =
    window.localStorage.getItem("exibitionMode") || "list";

  constructor() {
    this.setDefaults();
  }

  @action
  setDefaults = () => {
    const hostname = isProductionServer
      ? window.location.hostname
      : "lab.mobr2.com";

    const hostnameList = hostname.split(".");
    this.whiteLabelCompanyDomain = hostnameList[1];

    const hostUrl = isProductionServer ? window.location.host : "lab.mobr2.com";

    this.mediaFilesPath = `https://${hostUrl}/media`;
    this.imagesPath = `https://${hostUrl}/static/img`;

    this.logoImage = `${this.imagesPath}/logo.png`;
    this.loginPageBgImage = `${this.imagesPath}/bg-login-page.jpg`;
  };

  @action
  actionChangeWifiRedirectToDefault = (newValue) => {
    this.wifiRedirectToDefault = newValue;
    window.localStorage.setItem("wifiRedirectToDefault", newValue);
  };

  @action
  actionChangeWifiTimeDefault = (newValue) => {
    this.wifiTimeDefault = newValue;
    window.localStorage.setItem("wifiTimeDefault", newValue);
  };

  @action
  actionChangeExibitionModeType = (newType) => {
    this.exibitionMode = newType;
    window.localStorage.setItem("exibitionMode", newType);
  };
}

export default SettingsStore;
