// @ts-nocheck
import { observable, computed, action } from "mobx";

// Utils Stores
import { DID_LOGOUT } from "../InternalEventsStore/InternalEventsStore";

// Utils
import { didSearchList } from "../../utils/Utils";

class ReportsStore {
  @observable
  isFetching = false;
  @observable
  dashboardChartData = null;
  @observable
  connectivityAuthenticationLogs = [];
  @observable
  searchString = "";

  constructor(internalEventsStore, mobR2APIService) {
    this.mobR2APIService = mobR2APIService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dashboardChartData = null;
    this.connectivityAuthenticationLogs = [];
    this.searchString = "";
  };

  @action
  setSearchString = (newValue: string) => {
    this.searchString = newValue;
  };
  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setDashboardChartData = (newList: any[]) => {
    this.dashboardChartData = newList;
  };
  @action
  setConnectivityAuthenticationLogs = (newList: any[]) => {
    this.connectivityAuthenticationLogs = newList;
  };

  @computed
  get filteredConnectivityAuthenticationLogs() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.connectivityAuthenticationLogs,
        (log, textIncludesSearchString, searchStringItem) => {
          const matchDate = textIncludesSearchString(log.date);
          const matchName = textIncludesSearchString(log.name);
          const matchVendor = textIncludesSearchString(log.vendor);
          const matchMacAddress = textIncludesSearchString(log.mac_address);
          const matchPhone = textIncludesSearchString(log.phone);
          const matchOperator = textIncludesSearchString(log.operator);
          const matchWhatsApp =
            searchStringItem === "whatsapp" && log.whatsapp === "True";
          const matchIsOnline = searchStringItem === log.status;

          return (
            matchDate ||
            matchName ||
            matchVendor ||
            matchMacAddress ||
            matchPhone ||
            matchOperator ||
            matchWhatsApp ||
            matchIsOnline
          );
        }
      );
    }
    return this.connectivityAuthenticationLogs;
  }

  getDashboardChartData = async () => {
    this.setIsFetching(true);

    try {
      const response = await this.mobR2APIService.requestMetricsDashboard();
      if (response.status === 200 && response.data) {
        this.setDashboardChartData(response.data);
      }
    } catch (error) {
      window.debugError("error in requestMetricsDashboard", error);
    } finally {
      this.setIsFetching(false);
    }
  };

  getConnectivityAuthenticationsLogs = async (
    fromDate,
    toDate,
    vehicleToken = null
  ) => {
    this.setIsFetching(true);

    try {
      const response = await this.mobR2APIService.requestDataWifi({
        fromDate,
        toDate,
        vehicleToken,
      });
      if (response.status === 200) {
        this.setConnectivityAuthenticationLogs(response.data?.logs || []);
      }
    } catch (error) {
      window.debugError("error in requestDataWifi", error);
    } finally {
      this.setIsFetching(false);
    }
  };
}

export default ReportsStore;
