// @ts-nocheck
class StopModel {
  name;
  token;
  audioUrl;
  latitude;
  longitude;
  bearing;
  meters;
  backgroundColor;
  fontColor;
  isTransparent;
  duration;

  constructor(stop, fileService) {
    this.name = stop.name || stop.stop_name;
    this.token = stop.token || stop.stop_token;
    this.audioUrl = stop.audio
      ? fileService.mountFileUrl({
          hash: stop.audio,
        })
      : null;
    this.latitude = stop.latitude || null;
    this.longitude = stop.longitude || null;
    this.bearing = stop.bearing || null;
    this.meters = stop.meters || null;
    this.backgroundColor = stop.background_color
      ? `#${stop.background_color}`
      : null;
    this.fontColor = stop.font_color ? `#${stop.font_color}` : null;
    this.isTransparent = stop.transparent ? stop.transparent === "True" : null;
    this.duration = stop.duration || null;
  }

  get label(): string {
    return this.name;
  }
}

export default StopModel;
