// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import LineChart from "../../components/Charts/LineChart";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

// Translate
import Translate from "../../components/I18n/Translate";

// Icons
import WifiIcon from "@material-ui/icons/Wifi";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";

const WifiHoursChartWidget = observer(
  ({ classes, localizedString, chartData }) => (
    <Card className={classes.chartCard}>
      <CardHeader color="black" icon>
        <CardIcon color="black">
          <WifiIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <Translate id="ConnectivityBI@wifiHoursChartTitle">
            Volume de registros de acesso por hora
          </Translate>
        </h4>
      </CardHeader>
      <CardBody>
        <LineChart
          xAxisTitle={localizedString({
            id: "ConnectivityBI@scheduleChartInfo",
            defaultString: "Horário",
          })}
          xAxisCategories={[
            localizedString({ id: "Time@zero", defaultString: "00" }),
            localizedString({ id: "Time@one", defaultString: "01" }),
            localizedString({ id: "Time@two", defaultString: "02" }),
            localizedString({ id: "Time@three", defaultString: "03" }),
            localizedString({ id: "Time@four", defaultString: "04" }),
            localizedString({ id: "Time@five", defaultString: "05" }),
            localizedString({ id: "Time@six", defaultString: "06" }),
            localizedString({ id: "Time@seven", defaultString: "07" }),
            localizedString({ id: "Time@eight", defaultString: "08" }),
            localizedString({ id: "Time@nine", defaultString: "09" }),
            localizedString({ id: "Time@ten", defaultString: "10" }),
            localizedString({ id: "Time@eleven", defaultString: "11" }),
            localizedString({ id: "Time@twelve", defaultString: "12" }),
            localizedString({ id: "Time@thirteen", defaultString: "13" }),
            localizedString({ id: "Time@fourteen", defaultString: "14" }),
            localizedString({ id: "Time@fifteen", defaultString: "15" }),
            localizedString({ id: "Time@sixteen", defaultString: "16" }),
            localizedString({ id: "Time@seventeen", defaultString: "17" }),
            localizedString({ id: "Time@eighteen", defaultString: "18" }),
            localizedString({ id: "Time@nineteen", defaultString: "19" }),
            localizedString({ id: "Time@twenty", defaultString: "20" }),
            localizedString({ id: "Time@twentyOne", defaultString: "21" }),
            localizedString({ id: "Time@twentyTwo", defaultString: "22" }),
            localizedString({ id: "Time@twentyThree", defaultString: "23" }),
          ]}
          yAxisTitle={localizedString({
            id: "ConnectivityBI@connectedDevicesChartInfo",
            defaultString: "Dispositivos conectados",
          })}
          data={[
            {
              name: "#",
              data: chartData,
            },
          ]}
          height={400}
        />
      </CardBody>
    </Card>
  )
);
export default withLocalized(withStyles(chartsStyle)(WifiHoursChartWidget));
