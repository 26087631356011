// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ConnectivityAuthenticationReportPage from "./reportsPage/ConnectivityAuthenticationReportPage";
import ConnectivityMetricsReportPage from "./reportsPage/ConnectivityMetricsReportPage";

import { WifiChartType } from "../store/ReportsStore/WifiMetricsReportStore";

const availableReportsList = [
  {
    label: "Acessos",
    component: ConnectivityAuthenticationReportPage,
  },
  {
    label: "Horário de Pico",
    component: ConnectivityMetricsReportPage,
    props: {
      chartType: WifiChartType.hourlyTraffic,
    },
  },
  {
    label: "Visitantes",
    component: ConnectivityMetricsReportPage,
    props: {
      chartType: WifiChartType.visitors,
    },
  },
];

@inject("stores")
@observer
class ReportsPage extends Component {
  state = {
    selectedReportIndex: 0,
  };

  handleChange = (_, value) => {
    this.setState({ selectedReportIndex: value });
  };

  render() {
    const { selectedReportIndex } = this.state;
    const selectedReport = availableReportsList[selectedReportIndex];
    const Component = selectedReport.component;
    const componentProps = selectedReport.props || {};

    return (
      <Fragment>
        <PageTitle id="Menu@reports">Relatórios</PageTitle>
        <AppBar position="static" color="default">
          {availableReportsList.length > 1 && (
            <Tabs
              value={selectedReportIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
              variant="fullWidth"
            >
              {availableReportsList.map(($0, index) => (
                <Tab key={index} label={$0.label} />
              ))}
            </Tabs>
          )}
        </AppBar>
        <div style={{ padding: 8 * 3 }}>
          <Component {...componentProps} />
        </div>
      </Fragment>
    );
  }
}

export default ReportsPage;
