// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";

import Grid from "@material-ui/core/Grid";
import RandomAnimation from "../components/Animations/RandomAnimation";
import SimpleCard from "../components/Card/SimpleCard";
import DateFromAndDateToPickers from "../components/Date/DateFromAndDateToPickers";
import InputSelect from "../components/Form/InputSelect";
import InfoLabel from "../components/InfoLabel/InfoLabel";
import LoadingView from "../components/LoadingView/LoadingView";
import PageTitle from "../components/PageTitle/PageTitle";

import OccurrencesCategoriesChart from "./groupReportsPage/OccurrencesCategoriesChart";
import TotalVideosWatchedChart from "./groupReportsPage/TotalVideosWatchedChart";

import { titleForRules } from "../Model/ContractModel";

// Style
const styles = {};

const allRuleOptions = ["operator", "analyst"].map((value) => ({
  label: titleForRules(value),
  value,
}));
const garagesOptionDefault = {
  name: "Todos",
  token: "",
};

@inject("stores")
@observer
class GroupReportsPage extends Component {
  componentDidMount() {
    const { cftvReportsStore, garageStore } = this.props.stores;

    garageStore.getGaragesList();

    cftvReportsStore.setIsFetching(true);
    cftvReportsStore.setViewMode("global");
  }

  componentWillUnmount() {
    this.props.stores.cftvReportsStore.clearStore();
  }

  setNewDataFilter = (fromDate, toDate) => {
    const { cftvReportsStore } = this.props.stores;
    cftvReportsStore.setFromDate(fromDate);
    cftvReportsStore.setToDate(toDate);
  };

  onChangeSelectedRule = (_, selectedRule) => {
    const { cftvReportsStore } = this.props.stores;
    cftvReportsStore.setRule(selectedRule.value);
  };

  onChangeSelectedGarage = (_, selectedGarage) => {
    const { cftvReportsStore } = this.props.stores;
    cftvReportsStore.setGarage(selectedGarage.token);
  };

  onChangeSelectedFilter = (_, selectedFilter) => {
    const { cftvReportsStore } = this.props.stores;
    cftvReportsStore.setSelectedFilter(selectedFilter.value);
  };

  renderNotFoundMessage = () => {
    return (
      <Grid container justify="center" style={{ marginTop: 40 }}>
        <Grid item xs={12}>
          <InfoLabel large style={{ textAlign: "center", marginBottom: 30 }}>
            Não encontramos informações para os filtros selecionados. Tente
            altera-los para realizar uma nova consulta.
          </InfoLabel>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <RandomAnimation />
        </Grid>
      </Grid>
    );
  };

  renderChart = () => {
    const { cftvReportsStore } = this.props.stores;
    const { isFetching, getGlobalReportData } = cftvReportsStore;

    if (isFetching) {
      return (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <LoadingView />
        </Grid>
      );
    }

    if (getGlobalReportData) {
      return (
        <Fragment>
          <Grid container>
            <Grid item xs={12}>
              <TotalVideosWatchedChart />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 40 }}>
              <OccurrencesCategoriesChart />
            </Grid>
          </Grid>
        </Fragment>
      );
    }

    return (
      <Grid item xs={12}>
        {this.renderNotFoundMessage()}
      </Grid>
    );
  };

  render() {
    const { cftvReportsStore, garageStore } = this.props.stores;
    const { garagesList } = garageStore;

    return (
      <Fragment>
        <PageTitle>Relatório geral</PageTitle>
        <Grid container style={{ padding: "0 16px" }}>
          <SimpleCard cardStyle={{ marginTop: 0 }}>
            <Grid container spacing={4}>
              <DateFromAndDateToPickers
                updatedDates={this.setNewDataFilter}
                isCollectDateByWeek
              />
              <Grid item xs={12} sm>
                <InputSelect
                  label="Garagem"
                  name="selectedGarage"
                  placeholder={garagesOptionDefault.name}
                  options={[garagesOptionDefault, ...garagesList]}
                  onChange={this.onChangeSelectedGarage}
                  withPortal
                  labelKey="name"
                  valueKey="token"
                />
              </Grid>
              <Grid item xs={12} sm>
                <InputSelect
                  label="Função"
                  name="selectedRule"
                  value={allRuleOptions.find(
                    ({ value }) => value === cftvReportsStore.rule
                  )}
                  options={allRuleOptions}
                  onChange={this.onChangeSelectedRule}
                  withPortal
                  valueKey="label"
                />
              </Grid>
            </Grid>
          </SimpleCard>
          {this.renderChart()}
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(GroupReportsPage);
