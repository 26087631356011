// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import queryString from "query-string";
import _isArray from "lodash/isArray";

import { withLocalized } from "../../components/HOC";

import { GridContainer, GridItem } from "../../components/Grid";
import { SimpleCard } from "../../components/Card";
import SearchBar from "../../components/SearchBar/SearchBar";
import LoadingView from "../../components/LoadingView/LoadingView";
import Paginate from "../../components/Paginate/Paginate";
import Modal from "../../components/Modal/Modal";
import { Button } from "../../components/Button";
import InputSelect from "../../components/Form/InputSelect";
import UpdateListProgressCounter from "../../components/UpdateListProgressCounter/UpdateListProgressCounter";
import VehicleForm from "../../components/AppForm/VehicleForm";

import VehicleItemContainer from "./VehicleItemContainer";
import VehiclesTableContainer from "./VehiclesTableContainer";

import ModalVehicleDetails from "../../components/Modal/ModalVehicleDetails";

import InfoLabel from "../../components/InfoLabel/InfoLabel";

import Plural from "../../components/I18n/Plural";

const ALL_GARAGES_OPTION = { name: "Todas", token: "all" };
const NONE_OPTION = { name: "Nenhum", token: "undefined" };

@withLocalized
@inject("stores")
@observer
class VehiclesListPageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      isOpenVehicleForm: false,
      selectedVehicle: null,
      selectedVehicleToEdit: null,
      selectedConfigType: null,
      selectedConfig: NONE_OPTION,
      isSubmittingConfig: false,
    };
  }

  componentDidMount() {
    this.props.stores.garageStore.getGaragesList();

    const urlQuery = queryString.parse(this.props.location.search);
    if (urlQuery.status) {
      this.props.stores.vehicleStore.setFilterByStatus(urlQuery.status);
    }
    if (urlQuery.terminal) {
      this.props.stores.vehicleStore.setSearchString(urlQuery.terminal);
    }

    this.refreshVehiclesList(true);
  }

  hasSysOperatorRule = () =>
    this.props.stores.authStore.hasAccessTo("sys", "operator");
  hasSysMaintenanceRule = () =>
    this.props.stores.authStore.hasAccessTo("sys", "maintenance");

  refreshVehiclesList = (withFetchingAnimation = false) => {
    this.props.stores.vehicleStore.getVehiclesList({
      withFetchingAnimation,
      filterByCamera: !this.hasSysOperatorRule(),
      filterByGPS: false,
    });
  };

  didWantToCollectCam = (vehicleToken) => () => {
    const { vehicleStore, notificationsStore } = this.props.stores;

    const vehicleToCollectCam = vehicleStore.getWithToken(vehicleToken);

    if (vehicleToCollectCam?.hasSendCamCommand) {
      notificationsStore.addSnackbarNotification({
        message:
          "O comando Coletar Câmeras já está sendo processado, por favor aguarde.",
        color: "warning",
      });
    } else {
      vehicleStore.sendCommandToVehicle({
        token: vehicleToken,
        command: "sendcam",
      });
    }
  };

  didWantToAddVehicle = () => {
    this.setState({
      selectedVehicleToEdit: null,
      isOpenVehicleForm: true,
    });
  };

  didWantToEditVehicle = (vehicleToken) => () => {
    const selectedVehicleToEdit =
      this.props.stores.vehicleStore.getWithToken(vehicleToken);
    this.setState({
      selectedVehicle: null,
      selectedVehicleToEdit,
      isOpenVehicleForm: true,
    });
  };

  didWantToOpenVehicleModal = (vehicleToken) => () => {
    const vehicle = this.props.stores.vehicleStore.getWithToken(vehicleToken);
    this.setState({
      selectedVehicle: vehicle,
      isOpenModal: true,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        isOpenModal: false,
        isOpenVehicleForm: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedVehicle: null,
            selectedVehicleToEdit: null,
            selectedConfigType: null,
            selectedConfig: NONE_OPTION,
            isSubmittingConfig: false,
          });
        }, 200);
      }
    );
  };

  didCheckAllVehicles = (isChecked) =>
    this.props.stores.vehicleStore.didCheckAllVehicles(isChecked);

  didSelectVehicle = (token) =>
    this.props.stores.vehicleStore.didSelectVehicle(token);

  didWantToChooseConfig = (type) => {
    if (type === "internet") {
      this.props.stores.networksStore.getDataList();
    } else if (type === "playlists") {
      this.props.stores.playlistsStore.getDataList();
    } else if (type === "line") {
      this.props.stores.linesStore.getDataList();
    }

    this.setState({
      isOpenModal: true,
      selectedConfigType: type,
    });
  };

  onChangeConfigSelectInput = (_, selectedConfig) => {
    this.setState({
      selectedConfig,
    });
  };

  sendSelectedConfigToVehicle = () => {
    const { selectedConfig, selectedConfigType } = this.state;
    const config = {
      [selectedConfigType]: _isArray(selectedConfig)
        ? selectedConfig.map(({ token }) => token)
        : selectedConfig?.token,
    };
    this.setState(
      {
        isSubmittingConfig: true,
      },
      () => {
        this.props.stores.vehicleStore
          .submitConfigToSelectedVehicles(config)
          .then(this.didCloseModal);
      }
    );
  };

  renderConfigSelectorForType = (type) => {
    const { selectedConfig } = this.state;
    const { contractStore } = this.props.stores;

    let label = "";
    let dataList = null;
    let isMultiple = false;

    if (type === "line") {
      label = "Linha";
      dataList = this.props.stores.linesStore.dataList;
    } else if (type === "internet") {
      label = "Internet";
      dataList = this.props.stores.networksStore.dataList;
    } else if (type === "playlists") {
      label = "Playlists";
      dataList = this.props.stores.playlistsStore.dataList;
      isMultiple = true;
    } else if (type === "command") {
      label = "Enviar comando";
      dataList = [
        { name: "Reiniciar equipamento(s)", token: "reboot" },
        { name: "Coletar logs", token: "sendLog" },
        { name: "Suporte técnico", token: "remote" },
        { name: "Atualizar sistema", token: "update" },
      ];
      if (contractStore.selectedContract.hasCFTV) {
        dataList.push({ name: "Coletar câmeras", token: "sendcam" });
      }
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <InputSelect
            label={label}
            name={type}
            value={selectedConfig || (isMultiple ? [] : NONE_OPTION)}
            options={isMultiple ? dataList : [NONE_OPTION, ...dataList]}
            labelKey="name"
            valueKey="token"
            multiple={isMultiple}
            withPortal
            onChange={this.onChangeConfigSelectInput}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button block small onClick={this.didCloseModal}>
            <i className="fas fa-chevron-left" /> Cancelar
          </Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            color="primary"
            block
            small
            onClick={this.sendSelectedConfigToVehicle}
          >
            Enviar <i className="fas fa-check" />
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  getFilterByStatusLabel = (value) => {
    switch (value) {
      case "online":
        return "Online";
      case "offline":
        return "Offline";

      default:
        return "Todos";
    }
  };

  render() {
    const { settingsStore, garageStore, vehicleStore } = this.props.stores;
    const {
      selectedVehicle,
      selectedVehicleToEdit,
      selectedConfigType,
      isOpenModal,
    } = this.state;

    const { garagesList } = garageStore;
    const { selectedGarage, filteredVehiclesList, vehiclesList, isFetching } =
      vehicleStore;

    const exibitionMode = settingsStore.exibitionMode;

    const hasSysOperatorRule = this.hasSysOperatorRule();
    const hasSysMaintenanceRule = this.hasSysMaintenanceRule();

    return (
      <Fragment>
        <VehicleForm
          vehicle={selectedVehicleToEdit}
          didSave={this.didCloseModal}
          didCancel={this.didCloseModal}
        >
          {({ body, action }) => (
            <Modal
              isOpen={this.state.isOpenVehicleForm}
              didCloseModal={this.didCloseModal}
              maxWidth="sm"
              modalTitle={
                selectedVehicleToEdit ? "Editar veículo" : "Cadastrar veículo"
              }
              actions={action}
            >
              {body}
            </Modal>
          )}
        </VehicleForm>
        {selectedVehicle && (
          <ModalVehicleDetails
            isOpen={isOpenModal}
            didCloseModal={this.didCloseModal}
            vehicle={selectedVehicle}
            didWantToEditVehicle={this.didWantToEditVehicle(
              selectedVehicle.token
            )}
          />
        )}
        {selectedConfigType && (
          <Modal
            isOpen={isOpenModal}
            modalTitle="Aplicar configuração"
            didCloseModal={this.didCloseModal}
            maxWidth="xs"
          >
            {selectedConfigType &&
              this.renderConfigSelectorForType(selectedConfigType)}
          </Modal>
        )}
        <UpdateListProgressCounter callback={this.refreshVehiclesList} />
        <GridContainer
          justify={vehiclesList.length > 5 ? "space-between" : "flex-end"}
          style={{ padding: "0 15px", marginBottom: 20 }}
        >
          <GridItem xs sm md lg>
            <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
              <GridContainer>
                <GridItem xs={12} sm>
                  <SearchBar
                    value={vehicleStore.searchString}
                    onChange={vehicleStore.setSearchString}
                  />
                </GridItem>
                <GridItem xs={12} sm>
                  <InputSelect
                    placeholder="Filtre por garagem"
                    name="selectedGarage"
                    value={selectedGarage || ALL_GARAGES_OPTION}
                    options={[ALL_GARAGES_OPTION, ...garagesList]}
                    labelKey="name"
                    valueKey="token"
                    onChange={(_, selectedGarage) => {
                      vehicleStore.setSelectedGarage(selectedGarage);
                    }}
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </GridItem>
                <GridItem xs={12} sm>
                  <InputSelect
                    placeholder="Filtre por status"
                    name="status"
                    value={{
                      label: this.getFilterByStatusLabel(
                        vehicleStore.filterByStatus
                      ),
                      value: vehicleStore.filterByStatus,
                    }}
                    options={[
                      { label: "Todos", value: "all" },
                      { label: "Online", value: "online" },
                      { label: "Offline", value: "offline" },
                    ]}
                    onChange={(_, status) =>
                      vehicleStore.setFilterByStatus(status.value)
                    }
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </GridItem>
                <GridItem xs={12} sm>
                  <InputSelect
                    placeholder="Filtre por alertas"
                    name="alerts"
                    value={vehicleStore.filterByAlerts}
                    options={[
                      { label: "Crítico", value: "critical" },
                      { label: "Energia", value: "energy" },
                      { label: "Espaço em disco", value: "diskSpace" },
                      { label: "Velocidade do disco", value: "diskSpeed" },
                      { label: "Temperatura", value: "temperature" },
                      {
                        label: "Câmera(s) com erro de comunicação",
                        value: "camera",
                      },
                    ]}
                    multiple
                    onChange={(_, alerts) =>
                      vehicleStore.setFilterByAlerts(alerts)
                    }
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </GridItem>
              </GridContainer>
            </SimpleCard>
          </GridItem>
          {hasSysOperatorRule && (
            <GridItem xs={12} sm={2} lg={1} style={{ textAlign: "right" }}>
              <Button
                justIcon
                small
                color={exibitionMode === "grid" ? "info" : "transparent"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  settingsStore.actionChangeExibitionModeType("grid")
                }
              >
                <i className="far fa-grip-horizontal" />
              </Button>
              <Button
                justIcon
                small
                color={exibitionMode === "list" ? "info" : "transparent"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  settingsStore.actionChangeExibitionModeType("list")
                }
              >
                <i className="far fa-list-ul" />
              </Button>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer style={{ padding: "0 15px" }}>
          <GridItem xs={12}>
            {isFetching ? (
              <LoadingView />
            ) : (
              <GridContainer spacing={3}>
                {exibitionMode === "grid" ? (
                  <Paginate itemsList={filteredVehiclesList} itemsPerPage={18}>
                    {({ slicedList }) =>
                      slicedList.map(({ token, ...rest }) => (
                        <GridItem
                          key={token}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                        >
                          <VehicleItemContainer
                            vehicleItem={rest}
                            didWantToOpenVehicleModal={this.didWantToOpenVehicleModal(
                              token
                            )}
                          />
                        </GridItem>
                      ))
                    }
                  </Paginate>
                ) : (
                  <GridItem xs={12}>
                    <SimpleCard>
                      <VehiclesTableContainer
                        vehiclesList={filteredVehiclesList}
                        hasSysOperatorRule={hasSysOperatorRule}
                        hasSysMaintenanceRule={hasSysMaintenanceRule}
                        didSelectVehicle={this.didSelectVehicle}
                        didCheckAll={this.didCheckAllVehicles}
                        didWantToAddVehicle={this.didWantToAddVehicle}
                        didWantToOpenVehicleModal={
                          this.didWantToOpenVehicleModal
                        }
                        didWantToEditVehicle={this.didWantToEditVehicle}
                        didWantToChooseConfig={this.didWantToChooseConfig}
                        didWantToCollectCam={this.didWantToCollectCam}
                      />
                    </SimpleCard>
                  </GridItem>
                )}
              </GridContainer>
            )}
          </GridItem>
          {!isFetching &&
            filteredVehiclesList.length > 0 &&
            vehiclesList.length > 5 && (
              <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
                <InfoLabel showIcon={false}>
                  <Plural
                    id="VehiclesPage@vehiclesQtyLabel"
                    value={filteredVehiclesList.length}
                    zero="Nenhum veículo encontrado"
                    one="# veículo"
                    other="# veículos"
                  />
                </InfoLabel>
              </GridItem>
            )}
        </GridContainer>
      </Fragment>
    );
  }
}

export default VehiclesListPageContainer;
