// @ts-nocheck
import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import { withLocalized } from "../../../components/HOC";
import { GridContainer } from "../../../components/Grid";

import MetricChartSectionTitle from "../../../components/Charts/MetricChartSectionTitle";
import MetricChartBody from "../../../components/Charts/MetricChartBody";

import VisitorsChartWidget from "./VisitorsChartWidget";
import VisitorsPublicityChartWidget from "./VisitorsPublicityChartWidget";

// Translate
import Translate from "../../../components/I18n/Translate";

@withLocalized
@observer
class VisitorsChartContainer extends Component {
  prepareChartData = (result) => {
    if (!result) return null;

    const connectionsPerType = result.connections_by_type || null;
    const publicity = connectionsPerType
      ? {
          connectionsPerType: {
            identified: connectionsPerType.identified || 0,
            authenticated: connectionsPerType.authenticated || 0,
            authenticating: connectionsPerType.authenticating || 0,
            connected: connectionsPerType.connected || 0,
          },
        }
      : null;

    const statistics = result.users_statistics || null;
    const peopleStatistics = statistics
      ? {
          nearbyTimeAverage: statistics.all_time_mean,
          authenticatedTimeAverage: statistics.authenticated_time_mean,
          recurrent: statistics.recurrent,
          new: statistics.new,
        }
      : null;

    return {
      peopleStatistics,
      publicity,
    };
  };

  render() {
    const {
      isFetching,
      error,
      chartData: result,
      locationsList,
      datePeriod,
    } = this.props;
    const chartData = this.prepareChartData(result);

    return (
      <GridContainer justify="center">
        <MetricChartSectionTitle
          locationsList={locationsList}
          datePeriod={datePeriod}
        >
          <Translate id="Connectivity@visitorsChartTitle">
            Perfil dos visitantes
          </Translate>
        </MetricChartSectionTitle>
        <MetricChartBody isFetching={isFetching} hasError={error}>
          <VisitorsChartWidget chartData={chartData} />
        </MetricChartBody>
        {chartData && chartData.publicity && (
          <Fragment>
            <MetricChartSectionTitle
              locationsList={locationsList}
              datePeriod={datePeriod}
              showDivider
            >
              <Translate id="Connectivity@visitorsPublicityChartTitle">
                Publicidade
              </Translate>
            </MetricChartSectionTitle>
            <MetricChartBody isFetching={isFetching} hasError={error}>
              <VisitorsPublicityChartWidget chartData={chartData.publicity} />
            </MetricChartBody>
          </Fragment>
        )}
      </GridContainer>
    );
  }
}

export default VisitorsChartContainer;
