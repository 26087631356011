import { observable, computed, action } from "mobx";

// Model
import LineModel from "../../Model/LineModel";

// Utils Stores
import InternalEventsStore, {
  DID_LOGOUT,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import MobR2APIService from "../../services/MobR2APIService";
import FileService from "../../services/FileService";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class LinesStore {
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  @observable dataList: LineModel[] = [];
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService,
    fileService: FileService
  ) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;
    this.fileService = fileService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString: string) => {
    this.searchString = searchString;
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };

  @action
  setDataList = (newList: LineModel[]) => {
    this.dataList = newList;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (line, textIncludesSearchString) => textIncludesSearchString(line.name)
      );
    }

    return this.dataList;
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newLinesList = [];

    this.mobR2APIService
      .requestLines()
      .then((response: any) => {
        if (response.status === 200) {
          response.data.lines.forEach((line) => {
            const newLine = new LineModel(line, this.fileService);

            newLinesList.push(newLine);
          });
        }
        this.setDataList(newLinesList.sort(dynamicSortByProperty("name")));

        this.setIsFetching(false);
      })
      .catch((error) => {
        console.error("error in requestLines", error);
        if (error && error.statusCode === 429) {
          setTimeout(() => {
            this.getDataList();
          }, 2000);
        }
      });
  };

  submit = (line: LineModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .submitLine(line)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (line: LineModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .updateLine(line)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (token: string) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .deleteLine(token)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "warning",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getWithToken = (token: string) => {
    if (this.dataList.length > 0) {
      return this.dataList.find(($0) => $0.token === token);
    }
    return null;
  };
}

export default LinesStore;
