// @ts-nocheck
import React from "react";

import Modal from "../../components/Modal/Modal";
import TerminalForm from "./TerminalForm";

const TerminalFormModal = ({ isOpen, didCancel, ...rest }) => (
  <TerminalForm didCancel={didCancel} {...rest}>
    {({ isEditing, body, action }) => (
      <Modal
        modalTitle={isEditing ? "Atualizar terminal" : "Cadastrar terminal"}
        isOpen={isOpen}
        didCloseModal={didCancel}
        actions={action}
        maxWidth="xs"
      >
        {body}
      </Modal>
    )}
  </TerminalForm>
);
export default TerminalFormModal;
