// @ts-nocheck
class VideoRequestOrderModel {
  token;
  vehicle;
  dateOrder;
  fromDate;
  toDate;
  cam1;
  cam2;
  cam3;
  cam4;

  constructor({ token_order, date_ini, date_end, date_order, terminal_name }) {
    this.token = token_order;
    this.vehicle = terminal_name;
    this.dateOrder = date_order;
    this.fromDate = date_ini;
    this.toDate = date_end;
  }
}

export default VideoRequestOrderModel;
