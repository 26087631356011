// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

// Components
import { InputText, ButtonGroup, SubmitButton } from "../../components/Form";

// Icons
import { SaveIcon } from "../../components/Icon";

@inject("stores")
@observer
class TerminalForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState(props.terminal);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.terminal !== this.props.terminal) {
      this.updateForm();
    }
  }

  updateForm = () => {
    this.setState(this.defaultState(this.props.terminal));
  };

  defaultState = (terminal) => ({
    comments: terminal ? terminal.comments : "",
    screenInterface: terminal ? terminal.screenInterface : "hdmi",
    isWaitingForm: false,
    isEditing: terminal !== null,
  });

  onChangeInputText = ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value,
    });
  };

  onChangeScreenInterface = (_, selectedValue) => {
    this.setState({
      screenInterface: selectedValue,
    });
  };

  canSubmitForm = () => {
    return true;
  };

  submitForm = async () => {
    try {
      await this.props.stores.terminalsStore.update({
        comments: this.state.comments,
        screenInterface: this.state.screenInterface,
        token: this.props.terminal.token,
      });
      this.submitHasSuccess();
    } catch (error) {
      this.submitHasError();
    }
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();
    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true }, this.submitForm);
    } else {
      this.props.stores.notificationsStore.addSnackbarNotification({
        message: "Existem erros no formulário. Por favor verifique.",
        color: "danger",
      });
    }
  };

  submitHasSuccess = () => {
    this.props.didCancel();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const { comments, screenInterface, isWaitingForm, isEditing } = this.state;

    return this.props.children({
      isEditing,
      body: (
        <Fragment>
          <InputText
            label="Observações"
            name="comments"
            multiline
            maxLength={140}
            value={comments}
            onChange={this.onChangeInputText}
          />
          <ButtonGroup
            label="Conexão da tela"
            name="screenInterface"
            value={screenInterface}
            onChange={this.onChangeScreenInterface}
            options={[
              {
                value: "hdmi",
                label: "HDMI",
              },
              {
                value: "vga",
                label: "VGA",
              },
            ]}
          />
        </Fragment>
      ),
      action: (
        <SubmitButton
          block
          large={false}
          isLoading={isWaitingForm}
          onClick={this.onSubmit}
        >
          {isEditing ? "Atualizar" : "Salvar"} <SaveIcon />
        </SubmitButton>
      ),
    });
  }
}

export default TerminalForm;
