// @ts-nocheck
import React, { Fragment } from "react";
import { observer } from "mobx-react";

import PieChart from "../../../components/Charts/PieChart";
import { GridContainer, GridItem } from "../../../components/Grid";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../../components/HOC";
import BigCounter from "../../../components/BigCounter/BigCounter";

// Translate
import Translate from "../../../components/I18n/Translate";

// Style
const styles = {
  chartTitle: {
    fontSize: "1.2em",
    textAlign: "center",
  },
};

const VisitorsChartWidget = observer(
  ({ classes, localizedString, chartData }) => {
    if (!chartData) return null;

    const bigCounterStyle = {
      fontSize: "4em",
      marginTop: 20,
    };

    return (
      <Fragment>
        <GridContainer alignItems="center">
          {chartData.peopleStatistics && (
            <Fragment>
              <GridItem xs={12} md={6}>
                <PieChart
                  legend={{
                    position: "bottom",
                    horizontalAlign: "center",
                    fontSize: 16,
                  }}
                  height={260}
                  data={[
                    {
                      name: localizedString({
                        id: "Connectivity@peopleStatisticsVisitorsNew",
                        defaultString: "Novos",
                      }),
                      y: chartData.peopleStatistics.new,
                    },
                    {
                      name: localizedString({
                        id: "Connectivity@peopleStatisticsVisitorsRecurrent",
                        defaultString: "Recorrentes",
                      }),
                      y: chartData.peopleStatistics.recurrent,
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <h2 className={classes.chartTitle}>
                  <Translate id="Connectivity@nearbyTimeAverageTitle">
                    Tempo médio de permanência dos visitantes no local
                  </Translate>
                </h2>
                <BigCounter
                  style={bigCounterStyle}
                  value={
                    chartData.peopleStatistics.nearbyTimeAverage > 0
                      ? parseInt(
                          chartData.peopleStatistics.nearbyTimeAverage / 60,
                          10
                        )
                      : 0
                  }
                  label={
                    <Translate id="Common@minutesString">minutos</Translate>
                  }
                />
                <h2 className={classes.chartTitle}>
                  <Translate id="Connectivity@authenticatedTimeAverageTitle">
                    Tempo de uso da Internet
                  </Translate>
                </h2>
                <BigCounter
                  style={bigCounterStyle}
                  value={
                    chartData.peopleStatistics.authenticatedTimeAverage > 0
                      ? parseInt(
                          chartData.peopleStatistics.authenticatedTimeAverage /
                            60,
                          10
                        )
                      : 0
                  }
                  label={
                    <Translate id="Common@minutesString">minutos</Translate>
                  }
                />
              </GridItem>
            </Fragment>
          )}
        </GridContainer>
      </Fragment>
    );
  }
);

export default withLocalized(withStyles(styles)(VisitorsChartWidget));
