// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import debounce from "lodash/debounce";

import withStyles from "@material-ui/core/styles/withStyles";

import { LinkButton } from "../../components/Button";
import { SimpleCard } from "../../components/Card";
import DatePicker from "../../components/Form/DatePicker";
import LoadingView from "../../components/LoadingView/LoadingView";
import InfoLabel from "../../components/InfoLabel/InfoLabel";
import Map from "../../components/Map/Map";
import { GridContainer, GridItem } from "../../components/Grid";

import Plural from "../../components/I18n/Plural";

import { KEYCODE } from "../../store/KeyboardEventsStore/KeyboardEventsStore";

// Utils
import {
  startOfTheDayMoment,
  endOfTheDayMoment,
  stringDateFromUnixDate,
} from "../../utils/DateUtils";

// Styles
import { successColor, dangerColor } from "../../assets/jss/app-js-styles";
const styles = {
  mapBarContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "0 6px",
    background: "#fff",
  },
  selectVehicleContainer: {
    width: 300,
    display: "inline-block",
    marginRight: 6,
  },
  selectedVehicleOnMapCard: {
    position: "absolute",
    width: 220,
    height: 90,
    margin: 0,
    top: -75,
    zIndex: 1000,
    left: 22,
    textAlign: "center",
  },
};

@inject("stores")
@observer
class ReplayMapContainer extends Component {
  state = {
    fromDate: startOfTheDayMoment(),
    toDate: endOfTheDayMoment(),
    selectedLocationOnMap: null,
    selectedVehicle: null,
    gpsLocationsList: [],
    isFetchingLocations: false,
  };

  componentDidMount() {
    this.props.stores.keyboardEventsStore.setListener(
      "ReplayMapContainer",
      this.didNotifyKeyboardKeyPress
    );

    const { vehicleStore } = this.props.stores;
    vehicleStore.getVehiclesList({
      withFetchingAnimation: true,
      filterByGPS: true,
    });
  }

  componentWillUnmount() {
    this.props.stores.keyboardEventsStore.removeListener("ReplayMapContainer");

    this.props.stores.vehicleStore.removeSelectedVehicle();
  }

  didNotifyKeyboardKeyPress = (event) => {
    const { keyCode } = event;

    if (keyCode === KEYCODE.ESC) {
      this.removeSelectedLocationOnMap();
    }
  };

  requestVehicleGPSLocations = () => {
    if (this.state.selectedVehicle) {
      this.setState(
        {
          isFetchingLocations: true,
        },
        this.requestGPS
      );
    }
  };

  requestGPS = debounce(() => {
    const { fromDate, toDate, selectedVehicle } = this.state;
    this.props.stores.vehicleStore
      .requestGPS({
        token: selectedVehicle.token,
        fromDate: fromDate.format("X"),
        toDate: toDate.format("X"),
      })
      .then((result) => {
        this.setState({
          gpsLocationsList: result,
          isFetchingLocations: false,
        });
      });
  }, 2000);

  setNewDataFilter = (fromDate, toDate) => {
    this.setState(
      {
        fromDate,
        toDate,
      },
      this.requestVehicleGPSLocations
    );
  };

  onChangeFromDate = (_, newValue) => {
    const toDate = this.state.toDate.isBefore(newValue)
      ? newValue
      : this.state.toDate;
    this.setNewDataFilter(newValue, toDate);
  };

  onChangeToDate = (_, newValue) => {
    const fromDate = this.state.fromDate.isAfter(newValue)
      ? newValue
      : this.state.fromDate;
    this.setNewDataFilter(fromDate, newValue);
  };

  didSelectVehicle = (selectedVehicle) => {
    this.setState(
      {
        selectedVehicle,
        gpsLocationsList: [],
        selectedLocationOnMap: null,
      },
      this.requestVehicleGPSLocations
    );
    this.vehicleSelectElement.blur();
  };

  renderMapBar = (vehiclesList) => {
    const { classes } = this.props;
    const { isFetchingLocations, selectedVehicle, gpsLocationsList } =
      this.state;

    // TODO: Utilizar o componente DateFromAndDateToPickers aqui;

    return (
      <GridContainer className={classes.mapBarContainer} alignItems="center">
        <GridItem xs={12}>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={12} md style={{ marginBottom: 10 }}>
              <Select
                ref={(vehicleSelectElement) =>
                  (this.vehicleSelectElement = vehicleSelectElement)
                }
                value={selectedVehicle}
                options={vehiclesList}
                noOptionsMessage={() => "Nenhum veículo encontrado"}
                onChange={this.didSelectVehicle}
                getOptionValue={(option) => option.token}
                placeholder="Selecione um veículo"
              />
            </GridItem>
            <GridItem xs={12} sm md>
              <DatePicker
                label="Data Início"
                name="fromDate"
                value={this.state.fromDate}
                onChange={this.onChangeFromDate}
                fullWidth
                isValidDate={($0) =>
                  $0.isSameOrBefore(endOfTheDayMoment()) &&
                  $0.isSameOrAfter(startOfTheDayMoment().subtract(7, "days"))
                }
              />
            </GridItem>
            <GridItem xs={12} sm md>
              <DatePicker
                label="Data fim"
                name="toDate"
                value={this.state.toDate}
                onChange={this.onChangeToDate}
                fullWidth
                isValidDate={($0) => $0.isSameOrBefore(endOfTheDayMoment())}
              />
            </GridItem>
            <GridItem>
              <LinkButton color="info" small to={this.props.pathUrl}>
                Ao vivo <i className="fas fa-video" />
              </LinkButton>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} style={{ margin: "6px 0" }}>
          {!selectedVehicle && (
            <InfoLabel showIcon large style={{ textAlign: "center" }}>
              Selecione um veículo
            </InfoLabel>
          )}
          {isFetchingLocations ? (
            <LoadingView height={50}></LoadingView>
          ) : (
            selectedVehicle && (
              <InfoLabel
                showIcon={gpsLocationsList.length < 1}
                style={{ textAlign: "center" }}
              >
                <Plural
                  id="DevicesPage@replayGpsLocations"
                  value={gpsLocationsList.length}
                  zero="Não encontrei para os filtros atuais"
                  one="Um registro"
                  other="# registros"
                />
              </InfoLabel>
            )
          )}
        </GridItem>
      </GridContainer>
    );
  };

  didSelectVehicleOnMap = ({ value }) =>
    this.setState({
      selectedLocationOnMap: value,
    });

  removeSelectedLocationOnMap = () =>
    this.setState({
      selectedLocationOnMap: null,
    });

  locationIsSelectedOnMap = (index) =>
    this.state.selectedLocationOnMap === index;

  getMarkersFromGPSLocationsList = (gpsLocationsList) =>
    gpsLocationsList.map(
      ({ date, latitude, longitude, status, velocity }, index) => {
        const color = status === "online" ? successColor : dangerColor;
        const locationIsSelectedOnMap = this.locationIsSelectedOnMap(index);
        const marker = (
          <span
            className="fa-stack"
            style={{ zIndex: locationIsSelectedOnMap ? 1100 : 0 }}
          >
            <i
              className="fas fa-map-marker fa-stack-2x"
              style={{
                color,
              }}
            />
            <span
              className={`fas fa-stack-1x`}
              style={{ color: "#fff", top: -5, fontSize: "0.9em" }}
            >
              {index}
            </span>
          </span>
        );

        return {
          key: index,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          customIcon: locationIsSelectedOnMap ? (
            <div>
              <SimpleCard
                classNames={{
                  card: this.props.classes.selectedVehicleOnMapCard,
                }}
              >
                <p style={{ fontSize: "1.2em", color }}>
                  <i className="fas fa-bus-alt fa-fw" />{" "}
                  <strong>{this.state.selectedVehicle.name}</strong>
                </p>
                <p style={{ margin: 0 }}>
                  <i className="fal fa-tachometer-alt fa-fw" /> {velocity}
                </p>
                <p>
                  <i className="fal fa-clock fa-fw" />{" "}
                  {stringDateFromUnixDate(date, "DD/MM/YY HH:mm")}
                </p>
              </SimpleCard>
              {marker}
            </div>
          ) : (
            marker
          ),
          value: index,
          onClick: this.didSelectVehicleOnMap,
        };
      }
    );

  render() {
    const { gpsLocationsList } = this.state;
    const { vehiclesWithGpsLocation: vehiclesList } =
      this.props.stores.vehicleStore;

    return (
      <Map
        latitude={-15.799889}
        longitude={-47.887267}
        zoom={12}
        containerStyle={{
          height: this.props.fullScreen ? "100vh" : "calc(100vh - 114px)",
        }}
        markers={this.getMarkersFromGPSLocationsList(gpsLocationsList)}
        onClick={this.removeSelectedLocationOnMap}
      >
        {this.renderMapBar(vehiclesList)}
      </Map>
    );
  }
}

export default withStyles(styles)(ReplayMapContainer);
