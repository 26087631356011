// @ts-nocheck
class FileService {
  settingsStore = null;

  getServerUrl = () => {
    if (this.settingsStore) {
      return this.settingsStore.mediaFilesPath;
    }
    return null;
  };

  mountFileUrl = ({ hash }) => {
    if (hash.length < 1) {
      return "";
    }
    const serverUrl = this.getServerUrl();
    if (serverUrl) {
      return `${serverUrl}/${hash}`;
    }
    return "";
  };
}

export default FileService;
