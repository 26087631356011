// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";

import PageTitle from "../components/PageTitle/PageTitle";
import SimpleCard from "../components/Card/SimpleCard";

import UserForm from "./usersPage/UserForm";

@inject("stores")
@observer
class UserProfilePage extends Component {
  render() {
    return (
      <Fragment>
        <PageTitle id="Menu@profile">Meu perfil</PageTitle>
        <Grid container spacing={3} style={{ margin: 16 }}>
          <Grid item xs={11} sm={8} md={4}>
            <SimpleCard>
              <UserForm
                didCancel={() => {}}
                isEditingTheirOwnAccount
                user={this.props.stores.authStore.currentUser}
              >
                {({ isEditing, body, action }) => (
                  <Fragment>
                    {body}
                    <Grid container>
                      <Grid item xs={12}>
                        {action}
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </UserForm>
            </SimpleCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default UserProfilePage;
