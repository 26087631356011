import { observable, action, autorun } from "mobx";

import GarageModel from "../../Model/GarageModel";
import MobR2APIService from "../../services/MobR2APIService";
import AuthStore from "../AuthStore/AuthStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";

class GarageStore {
  authStore: AuthStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;

  @observable isFetching = false;
  @observable searchString = "";
  @observable garagesList: GarageModel[] = [];

  constructor(
    authStore: AuthStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService
  ) {
    this.authStore = authStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    autorun(() => {
      if (!authStore.isAuthenticated) {
        this.clearStore();
      }
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.searchString = "";
    this.garagesList = [];
  };

  @action
  setSearchString = (newValue: string) => {
    this.searchString = newValue;
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };

  @action
  setGaragesList = (newList: GarageModel[]) => {
    this.garagesList = newList;
  };

  getGaragesList = async () => {
    this.setIsFetching(true);
    let newGaragesList = [];

    try {
      const response: any = await this.mobR2APIService.requestGarages();
      if (response.status === 200) {
        response.data.forEach((garage) => {
          newGaragesList.push(new GarageModel(garage));
        });
      }
      this.setGaragesList(newGaragesList);
      this.setIsFetching(false);
    } catch (error) {
      console.error("error in requestGarages", error);
      if (error && error.statusCode === 429) {
        setTimeout(() => {
          this.getGaragesList();
        }, 1000);
      }
    }
  };

  getWithToken = (tokenToSearch: string): GarageModel | null =>
    this.garagesList.find(({ token }) => token === tokenToSearch) || null;

  submit = async (data: GarageModel) => {
    try {
      const response: any = await this.mobR2APIService.submitGarage(data);
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.getGaragesList();
      return Promise.resolve();
    } catch (error) {
      console.error("error in submitGarage", error);
      return Promise.reject(error);
    }
  };
  update = async (data: GarageModel) => {
    try {
      const response: any = await this.mobR2APIService.updateGarage(data);
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });

      this.getGaragesList();

      return Promise.resolve();
    } catch (error) {
      console.error("error in updateGarage", error);
      return Promise.reject(error);
    }
  };
}

export default GarageStore;
