// @ts-nocheck
import React, { Component } from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

// Style
import { primaryColor, successColor } from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    position: "absolute",
    cursor: "pointer",
    fontSize: 18,
  },
  icon: { color: primaryColor },
  selectedIcon: { color: successColor },
};

const PIN_CENTER = {
  x: 15,
  y: 31,
};

// freely adapted from https://github.com/mariusandra/pigeon-marker/blob/master/src/index.js
class MapMarker extends Component {
  onClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick({ event, value: this.props.value });
    }
  };

  render() {
    const { classes, left, top, isSelected, customIcon } = this.props;
    return (
      <div
        className={classes.container}
        style={{
          left: left - PIN_CENTER.x,
          top: top - PIN_CENTER.y,
        }}
        onClick={this.onClick}
      >
        {customIcon ? (
          customIcon
        ) : (
          <i
            className={classnames({
              fas: true,
              "fa-map-marker-check": isSelected,
              "fa-map-marker": !isSelected,
              [classes.icon]: !isSelected,
              [classes.selectedIcon]: isSelected,
            })}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MapMarker);
