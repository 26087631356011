// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import SimpleCard from "../../components/Card/SimpleCard";
import Button from "../../components/Button/Button";
import SearchBar from "../../components/SearchBar/SearchBar";
import InfoLabel from "../../components/InfoLabel/InfoLabel";
import { DatePicker, InputSelect, ButtonGroup } from "../../components/Form";

// Utils
import { getNowMoment, endOfTheDayMoment } from "../../utils/DateUtils";

import {
  JOB_SCHEDULE_RULES,
  titleForRules,
} from "../../Model/JobScheduleModel";

import { successColor } from "../../assets/jss/app-js-styles";

const getRuleOptions = () => {
  return Object.values(JOB_SCHEDULE_RULES).map((rule) => ({
    label: titleForRules(rule),
    value: rule,
  }));
};

const ruleOptions = getRuleOptions();

// Style
const styles = {};

const LAST_WEEK_DAY = getNowMoment().subtract(7, "days");

const DEFAULT_STATE = {
  isCustomDate: false,
  customFromDate: LAST_WEEK_DAY.clone().set({ hour: 9, minute: 0 }),
  customToDate: LAST_WEEK_DAY.clone().set({ hour: 10, minute: 0 }),
  selectedVehiclesList: [],
  isWaitingForm: false,
  day: LAST_WEEK_DAY,
  fromHour: LAST_WEEK_DAY.clone().set({ hour: 9, minute: 0 }),
  toHour: LAST_WEEK_DAY.clone().set({ hour: 10, minute: 0 }),
  rule: "operator",
};

const getHoursIntervals = () => {
  let list = [];
  for (let index = 0; index < 24; index++) {
    const indexPlusOne = index + 1;
    const fromHour = index < 10 ? `0${index}` : `${index}`;
    const toHour =
      indexPlusOne === 24
        ? "00"
        : indexPlusOne < 10
        ? `0${indexPlusOne}`
        : `${indexPlusOne}`;
    list.push({
      label: `${fromHour}:00 às ${toHour}:00h`,
      value: `${fromHour}_${toHour}`,
    });
  }

  return list;
};

@inject("stores")
@observer
class RequestScheduleForm extends Component {
  state = DEFAULT_STATE;

  componentDidMount() {
    this.props.stores.garageStore.getGaragesList();
  }

  onChangeSelectedGarage = (_, selectedGarage) => {
    this.props.stores.vehicleStore.setSelectedGarage(selectedGarage);
  };

  onChangeSelectedRule = (_, selectedRule) => {
    this.setState({
      rule: selectedRule.value,
    });
  };

  onChangeIsCustomDate = (_, isCustomDate) => {
    this.setState({ isCustomDate });
  };

  onChangeCustomDate = (name, newValue) => {
    this.setState((prevState) => {
      let customFromDate;
      let customToDate;
      if (name === "customFromDate") {
        customFromDate = newValue;
        customToDate = prevState.customToDate.isSameOrAfter(newValue)
          ? prevState.customToDate
          : newValue;
      } else {
        customFromDate = prevState.customFromDate.isSameOrBefore(newValue)
          ? prevState.customFromDate
          : newValue;
        customToDate = newValue;
      }
      return {
        customFromDate,
        customToDate,
      };
    });
  };

  onChangeFromHour = (_, newValue) => {
    this.setState((prevState) => {
      const fromHourPlusSix = newValue.clone().add(6, "hours");

      let toHour = prevState.toHour;
      if (prevState.toHour.isSameOrBefore(newValue)) {
        toHour = newValue.clone().add(1, "hours");
      } else if (toHour.isAfter(fromHourPlusSix)) {
        toHour = fromHourPlusSix;
      }
      return { fromHour: newValue, toHour };
    });
  };
  onChangeToHour = (_, newValue) => {
    this.setState((prevState) => {
      const toHourMinusSix = newValue.clone().add(-6, "hours");

      let fromHour = prevState.fromHour;
      if (prevState.fromHour.isSameOrAfter(newValue)) {
        fromHour = newValue.clone().add(-1, "hours");
      } else if (fromHour.isBefore(toHourMinusSix)) {
        fromHour = toHourMinusSix;
      }
      return { toHour: newValue, fromHour };
    });
  };

  onChangeHoursInterval = (_, hoursInterval) => {
    this.setState((prevState) => {
      const [fromHourValue, toHourValue] = hoursInterval.value.split("_");
      const fromHour = prevState.day
        .clone()
        .set({ hour: parseInt(fromHourValue, 10), minute: 0 });
      const toHour = prevState.day
        .clone()
        .set({ hour: parseInt(toHourValue, 10), minute: 0 });
      return {
        fromHour,
        toHour,
      };
    });
  };

  onChangeDay = (_, newValue) => {
    this.setState({
      day: newValue,
    });
  };

  toggleVehicleSelectState = (token) => () => {
    if (this.state.selectedVehiclesList.find(($0) => $0 === token)) {
      this.didRemoveVehicle(token);
    } else {
      this.didSelectVehicle(token);
    }
  };

  didSelectVehicle = (token) => {
    this.setState((prevState) => ({
      selectedVehiclesList: [...prevState.selectedVehiclesList, token],
    }));
  };

  didRemoveVehicle = (token) => {
    this.setState((prevState) => ({
      selectedVehiclesList: prevState.selectedVehiclesList.filter(
        ($0) => $0 !== token
      ),
    }));
  };

  didSubmit = async (event) => {
    if (event) event.preventDefault();
    const {
      isCustomDate,
      customFromDate,
      customToDate,
      selectedVehiclesList,
      day,
      fromHour,
      toHour,
      rule,
    } = this.state;
    const fromDate = isCustomDate
      ? customFromDate
      : day
          .clone()
          .set({ hour: fromHour.format("HH"), minute: fromHour.format("mm") });
    const toDate = isCustomDate
      ? customToDate
      : day
          .clone()
          .set({ hour: toHour.format("HH"), minute: toHour.format("mm") });

    this.setState(
      {
        isWaitingForm: true,
      },
      async () => {
        try {
          await this.props.stores.jobScheduleStore.submit({
            vehiclesList: selectedVehiclesList,
            fromDate: fromDate.format("X"),
            toDate: toDate.format("X"),
            rule: rule,
          });

          this.props.didCancel();
          this.setState(DEFAULT_STATE);
        } catch (_) {
          this.setState({ isWaitingForm: false });
        }
      }
    );
  };

  renderSelectVehicles = () => {
    const {
      filteredVehiclesList,
      searchString,
      setSearchString,
      selectedGarage,
    } = this.props.stores.vehicleStore;
    const { garagesList } = this.props.stores.garageStore;
    const { selectedVehiclesList } = this.state;

    const allGaragesOption = { name: "Todas", token: "all" };

    const selectedCount = selectedVehiclesList.length;

    return (
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={6}>
            <InputSelect
              label="Filtre por Garagem"
              name="selectedGarage"
              value={selectedGarage || allGaragesOption}
              options={[allGaragesOption, ...garagesList]}
              labelKey="name"
              valueKey="token"
              onChange={this.onChangeSelectedGarage}
              withPortal
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchBar
              marginBottom
              value={searchString}
              onChange={setSearchString}
            />
          </Grid>
          {filteredVehiclesList.length > 1 && (
            <Grid item xs={12} style={{ marginTop: -20 }}>
              <Button
                color="primary"
                outline
                small
                onClick={() =>
                  filteredVehiclesList.forEach(({ token }) =>
                    this.didSelectVehicle(token)
                  )
                }
                style={{ marginRight: 8 }}
              >
                Selecionar todos <i className="fas fa-check" />
              </Button>
              <Button
                color="warning"
                outline
                small
                onClick={() =>
                  filteredVehiclesList.forEach(({ token }) =>
                    this.didRemoveVehicle(token)
                  )
                }
              >
                Remover todos <i className="fas fa-times" />
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {filteredVehiclesList.length < 1 && (
            <p style={{ fontSize: "0.9em", marginLeft: 8 }}>
              Nenhum veículo encontrado para os filtros atuais
            </p>
          )}
          {filteredVehiclesList.map(
            ({ token, name, lastCameraCollectDate }) => {
              const isSelected = selectedVehiclesList.includes(token);
              return (
                <Grid item key={token}>
                  <SimpleCard
                    cardStyle={{ margin: 0 }}
                    cardBodyStyle={{
                      padding: 8,
                      textAlign: "center",
                      color: isSelected ? successColor : "inherit",
                    }}
                    onClick={this.toggleVehicleSelectState(token)}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      <i className="fas fa-bus-alt" /> {name}
                    </p>
                    <p style={{ margin: 0, fontSize: "0.9em" }}>
                      {lastCameraCollectDate}
                    </p>
                  </SimpleCard>
                </Grid>
              );
            }
          )}
        </Grid>
        {selectedCount > 0 && (
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <InfoLabel hideIcon style={{ textAlign: "center" }}>
              {selectedCount > 1
                ? `${selectedCount} veículos selecionados`
                : "1 veículo selecionado"}
            </InfoLabel>
          </Grid>
        )}
      </Grid>
    );
  };

  render() {
    const {
      isCustomDate,
      selectedVehiclesList,
      fromHour,
      toHour,
      customFromDate,
      customToDate,
      rule,
    } = this.state;

    const hoursIntervalsOptions = getHoursIntervals();

    const hoursInterval = isCustomDate
      ? null
      : hoursIntervalsOptions.find(
          ({ value }) =>
            value === `${fromHour.format("HH")}_${toHour.format("HH")}`
        );

    // TODO: Utilizar o componente DateFromAndDateToPickers aqui;

    return this.props.children({
      body: (
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs md={4}>
              <InputSelect
                label="Regra de visualização"
                name="rule"
                value={ruleOptions.find(({ value }) => value === rule)}
                options={ruleOptions}
                onChange={this.onChangeSelectedRule}
                withPortal
              />
            </Grid>
            <Grid item>
              <ButtonGroup
                name="isCustomDate"
                value={isCustomDate}
                label="Data"
                whiteBackgroundColor
                small
                onChange={this.onChangeIsCustomDate}
                options={[
                  {
                    value: false,
                    label: "Período",
                  },
                  {
                    value: true,
                    label: "Customizada",
                  },
                ]}
              />
            </Grid>
            {isCustomDate ? (
              <Grid item>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      withPortal
                      label="Data início"
                      name="customFromDate"
                      value={customFromDate}
                      onChange={this.onChangeCustomDate}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      withPortal
                      label="Data fim"
                      name="customToDate"
                      value={customToDate}
                      onChange={this.onChangeCustomDate}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid item>
                  <DatePicker
                    withPortal
                    hideTime
                    label="Selecione o dia"
                    name="day"
                    value={this.state.day}
                    onChange={this.onChangeDay}
                    isValidDate={(currentDate) =>
                      currentDate.isSameOrBefore(endOfTheDayMoment())
                    }
                  />
                </Grid>
                <Grid item xs md={4}>
                  {rule === JOB_SCHEDULE_RULES.OPERATOR ? (
                    <InputSelect
                      label="Selecione o período"
                      name="hoursInterval"
                      value={hoursInterval}
                      options={hoursIntervalsOptions}
                      onChange={this.onChangeHoursInterval}
                      withPortal
                    />
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          withPortal
                          label="Hora início"
                          name="fromHour"
                          value={fromHour}
                          onChange={this.onChangeFromHour}
                          fullWidth
                          hideDate
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          withPortal
                          label="Hora fim"
                          name="toHour"
                          value={toHour}
                          onChange={this.onChangeToHour}
                          fullWidth
                          hideDate
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider light />
            {this.renderSelectVehicles()}
          </Grid>
        </Grid>
      ),
      action: (
        <Button
          color="primary"
          block
          isLoading={this.state.isWaitingForm}
          disabled={selectedVehiclesList.length < 1}
          onClick={this.didSubmit}
        >
          Registrar <i className="fas fa-save" />
        </Button>
      ),
    });
  }
}
export default withStyles(styles)(RequestScheduleForm);
