// @ts-nocheck
import OccurrenceCategoryModel from "./OccurrenceCategoryModel";

class OccurrenceModel {
  token;
  description;
  category;

  constructor(occurrence) {
    this.token = occurrence.token;
    this.description = occurrence.description;
    this.category = new OccurrenceCategoryModel(occurrence.category);
  }
}

export default OccurrenceModel;
