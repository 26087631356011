// @ts-nocheck
import _debounce from "lodash/debounce";
import { action, computed, observable, reaction } from "mobx";
import { v4 as uuid } from "uuid";
import JobScheduleModel, {
  JOB_SCHEDULE_RULES,
  JOB_SCHEDULE_STATUS,
} from "../../Model/JobScheduleModel";
import { endOfTheDayMoment, getOneMonthAgoMoment } from "../../utils/DateUtils";
// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

class JobScheduleStore {
  @observable isFetching = false;
  @observable jobScheduleList = [];
  @observable searchString = "";
  @observable analystMessage = "";

  @observable filterByStatus = [JOB_SCHEDULE_STATUS.READY];
  @observable filterByRule = JOB_SCHEDULE_RULES.OPERATOR;

  @observable selectedScheduleToken = null;

  @observable didFirstRequest = false;
  @observable hasSchedulesOnFirstRequest = false;
  @observable page = 1;
  @observable limit = 10;
  @observable totalItems = 0;
  @observable isFetchingPaginate = false;
  @observable fromDate = getOneMonthAgoMoment();
  @observable toDate = endOfTheDayMoment();

  ongoingRequests = {};

  constructor(authStore, contractStore, notificationsStore, mobR2APIService) {
    this.authStore = authStore;
    this.contractStore = contractStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    reaction(
      () => authStore.isAuthenticated && contractStore?.selectedContract,
      (isAuthenticatedAndHasSelectedContract) => {
        if (isAuthenticatedAndHasSelectedContract) {
          this.resetFilters();
        } else {
          this.clearStore();
        }
      }
    );

    reaction(
      () => {
        if (contractStore.selectedContract) {
          return [
            this.searchString,
            this.filterByRule,
            this.filterByStatus,
            this.fromDate,
            this.toDate,
          ];
        }
        return false;
      },
      () => {
        this.setIsFetching(true);
        this.getJobScheduleList(true);
      }
    );
  }

  @action
  clearStore = () => {
    this.requestSchedules.cancel();

    this.isFetching = false;
    this.analystMessage = "";
    this.jobScheduleList = [];
    this.selectedScheduleToken = null;
    this.totalItems = 0;
    this.hasSchedulesOnFirstRequest = false;

    this.resetFilters();
  };

  @action
  resetFilters = () => {
    this.page = 1;
    this.limit = 10;
    this.fromDate = getOneMonthAgoMoment();
    this.toDate = endOfTheDayMoment();
    this.searchString = "";

    if (this.authStore.isDVRGarageManager) {
      this.setFiltersGarageManager();
    } else if (this.authStore.isDVRSemob) {
      this.setFiltersToSemob();
    } else if (this.authStore.isDVRAnalyst) {
      this.setFiltersToAnalyst();
    } else if (this.authStore.isDVROperator) {
      this.setFiltersToOperator();
    } else {
      this.filterByStatus = [JOB_SCHEDULE_STATUS.READY];
      this.filterByRule = JOB_SCHEDULE_RULES.OPERATOR;
      this.limit = 10;
    }
  };

  @action
  setDidFirstRequest = (hasSchedules) => {
    this.didFirstRequest = true;
    this.hasSchedulesOnFirstRequest = hasSchedules;
  };

  @action
  setSearchString = (newValue) => {
    this.page = 1;
    this.searchString = newValue;
  };
  @action
  setFromDateFilter = (newValue) => {
    this.page = 1;
    this.fromDate = newValue;
  };
  @action
  setToDateFilter = (newValue) => {
    this.page = 1;
    this.toDate = newValue;
  };
  @action
  setAnalystMessage = (newValue) => {
    this.analystMessage = newValue;
  };
  @action
  setFilterByStatus = (newList) => {
    this.page = 1;
    this.filterByStatus = newList;
  };
  @action
  setFilterByRule = (newRule) => {
    this.page = 1;
    this.filterByRule = newRule;
  };
  @action
  setCurrentPage = (newValue) => {
    this.page = newValue;
  };
  @action
  setLimit = (newValue) => {
    this.limit = newValue;
    this.page = 1;
  };
  @action
  setTotalItems = (newValue) => (this.totalItems = newValue);
  @action
  setIsFetchingPaginate = (newValue) => {
    this.isFetchingPaginate = newValue;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setSelectedScheduleToken = (newValue) => {
    this.selectedScheduleToken = newValue;
  };

  @action
  setJobScheduleList = (newList) => {
    this.jobScheduleList = newList;
  };

  @computed
  get hasSchedules() {
    return this.totalItems > 0;
  }

  @computed
  get selectedJobSchedule() {
    return (
      this.jobScheduleList.find(({ token, status, operator }) => {
        if (this.authStore.isDVROperator) {
          return (
            status === JOB_SCHEDULE_STATUS.WATCHING &&
            operator.token === this.authStore.currentUserToken
          );
        }

        return token === this.selectedScheduleToken;
      }) || null
    );
  }

  @action
  onChangePaginate = (page, limit) => {
    this.setIsFetchingPaginate(true);
    this.page = page;
    this.limit = limit;

    this.getJobScheduleList();
  };

  setNewDataFilter = (fromDate, toDate) => {
    this.setFromDateFilter(fromDate);
    this.setToDateFilter(toDate);
  };

  setFiltersToAnalyst = () => {
    this.setFilterByStatus([JOB_SCHEDULE_STATUS.VIEWED]);
    this.setFilterByRule(JOB_SCHEDULE_RULES.OPERATOR);
    this.setLimit(12);
  };

  setFiltersToOperator = () => {
    this.setFilterByStatus([
      JOB_SCHEDULE_STATUS.READY,
      JOB_SCHEDULE_STATUS.WATCHING,
    ]);
    this.setFilterByRule(JOB_SCHEDULE_RULES.OPERATOR);
    this.setLimit(12);
  };

  setFiltersGarageManager = () => {
    this.setFilterByStatus([JOB_SCHEDULE_STATUS.READY]);
    this.setFilterByRule(JOB_SCHEDULE_RULES.GARAGE_MANAGER);
    this.setLimit(12);
  };

  setFiltersToSemob = () => {
    this.setFilterByStatus([JOB_SCHEDULE_STATUS.READY]);
    this.setFilterByRule(JOB_SCHEDULE_RULES.SEMOB);
    this.setLimit(12);
  };

  getJobScheduleList = () => {
    this.requestSchedules(
      {
        status: this.filterByStatus,
        rules: [this.filterByRule],
      },
      ({ totals, list }) => {
        this.setTotalItems(totals);
        this.setJobScheduleList(list);

        this.setIsFetching(false);
        this.setIsFetchingPaginate(false);
      }
    );
  };

  requestSchedules = _debounce(async ({ status, rules }, callback) => {
    let newJobScheduleList = [];
    let totals = 0;

    // Everytime we call this funtion we sign it with a token to prevent previous calls from completing callback
    const token = uuid();

    try {
      const data = {
        status,
        rules,
        page: this.page,
        limit: this.limit,
        searchString: this.searchString,
        fromDate: this.fromDate.format("X"),
        toDate: this.toDate.format("X"),
      };

      // set current ongoing request with token
      this.ongoingRequests["requestSchedules"] = token;

      const response = await this.mobR2APIService.requestSchedules(data);
      if (response.status === 200 && response.data) {
        totals = response.data.totals;
        response.data.schedules.forEach((job) => {
          newJobScheduleList.push(
            new JobScheduleModel(
              job,
              this.contractStore.selectedContract.cftvUrl
            )
          );
        });
      }
    } catch (error) {
      console.error("error in requestSchedules", error);
    } finally {
      const list = newJobScheduleList
        .sort(dynamicSortByProperty("dateCreate"))
        .sort(dynamicSortByProperty("vehicle"));

      // only call callback if this is the last signed token
      if (this.ongoingRequests.requestSchedules === token) {
        callback({
          totals,
          list,
        });

        // clear ongoingRequests = {}
        this.ongoingRequests.requestSchedules = null;
      }
    }
  }, 2000);

  submit = async ({ vehiclesList, fromDate, toDate, rule }) => {
    try {
      const response = await this.mobR2APIService.submitSchedule({
        vehiclesList,
        fromDate,
        toDate,
        rule,
      });

      this.notificationsStore.addSnackbarNotification({
        message: response.data.success,
        color: "success",
      });

      this.setIsFetching(true);
      this.getJobScheduleList();

      return Promise.resolve();
    } catch (error) {
      console.error("error in submitSchedule", error);
      return Promise.reject(error);
    }
  };

  delete = async (token) => {
    try {
      const response = await this.mobR2APIService.deleteSchedule(token);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.success,
        color: "success",
      });

      this.setIsFetching(true);
      this.getJobScheduleList();

      return Promise.resolve();
    } catch (error) {
      console.error("error in deleteSchedule", error);
      return Promise.reject(error);
    }
  };

  operatorDidWantToPlay = async (token) => {
    this.setIsFetching(true);
    try {
      await this.mobR2APIService.watchSchedule(token);
      return Promise.resolve();
    } catch (error) {
      console.error("error in watchSchedule", error);
      this.notificationsStore.addSnackbarNotification({
        message: error.message,
        color: "warning",
      });
      return Promise.reject(error);
    } finally {
      this.setIsFetching(true);
      this.getJobScheduleList();
    }
  };

  operatorDidFinishedToWatch = async () => {
    let didSuccess = true;
    try {
      const response = await this.mobR2APIService.viewedSchedule(
        this.selectedJobSchedule.token
      );

      this.notificationsStore.addSnackbarNotification({
        message: response.data.success,
        color: "success",
      });

      this.resetFilters();
      this.setJobScheduleList([]);
    } catch (error) {
      console.error("error in viewedSchedule", error);
      didSuccess = false;
    } finally {
      return Promise.resolve(didSuccess);
    }
  };

  analystDidFinishedSchedule = async () => {
    try {
      await this.mobR2APIService.finishedSchedule({
        token: this.selectedJobSchedule.token,
        analystMessage: this.analystMessage,
      });
    } catch (error) {
      console.error("error in finishedSchedule", error);
    } finally {
      this.resetFilters();
      this.setJobScheduleList([]);
      return Promise.resolve();
    }
  };
}

export default JobScheduleStore;
