import StopModel from "./StopModel";

class LineModel {
  name: string;
  token: string;
  isRandom?: boolean;
  stopsList: StopModel[];

  constructor(line: any, fileService: any) {
    this.name = line.name;
    this.token = line.token;
    this.isRandom = line.random === "True";
    this.stopsList = line.stops
      ? line.stops.map(($0: any) => new StopModel($0, fileService))
      : [];
  }

  get label(): string {
    return this.name;
  }
}

export default LineModel;
