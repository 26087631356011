// @ts-nocheck
import PropTypes from "prop-types";
import React from "react";
import Card from "./Card";
import CardBody from "./CardBody";

const SimpleCard = ({
  classNames,
  cardStyle,
  cardBodyStyle,
  children,
  onClick,
}) => (
  <Card className={classNames.card} style={cardStyle} onClick={onClick}>
    <CardBody className={classNames.cardBody} style={cardBodyStyle}>
      {children}
    </CardBody>
  </Card>
);

SimpleCard.defaultProps = {
  classNames: {
    card: "",
    cardBody: "",
  },
};

SimpleCard.propTypes = {
  cardStyle: PropTypes.object,
  cardBodyStyle: PropTypes.object,
  classNames: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SimpleCard;
