// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classnames from "classnames";

import useMediaQuery from "../../hooks/useMediaQuery";

import { Button } from "../Button";

const useStyles = makeStyles({
  dialog60vh: {
    height: "60vh",
  },
  dialog80vh: {
    height: "80vh",
  },
  dialog100vh: {
    height: "100vh",
  },
  modalCloseButton: {
    position: "absolute",
    margin: 0,
    right: 0,
    top: 0,
    zIndex: 1000000,
  },
});

const Modal = ({
  isOpen,
  didCloseModal,
  modalTitle,
  hideCloseButton,
  maxWidth,
  height,
  fullScreen,
  actions,
  children,
}) => {
  const classes = useStyles();
  const ariaLabelledby = modalTitle ? encodeURI(modalTitle) : "modal-title";

  const isMobileMediaQuery = useMediaQuery({ down: "sm" });

  return ReactDOM.createPortal(
    <Dialog
      fullScreen={fullScreen || isMobileMediaQuery}
      open={isOpen}
      onClose={didCloseModal}
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby={ariaLabelledby}
      disableBackdropClick={!didCloseModal}
      disableEscapeKeyDown={!didCloseModal}
      classes={{
        paper: classnames({
          [classes.dialog60vh]: height === 60,
          [classes.dialog80vh]: height === 80,
          [classes.dialog100vh]: height === 100,
        }),
      }}
    >
      {!hideCloseButton && didCloseModal && (
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={didCloseModal}
        >
          <i className="fas fa-times" />
        </Button>
      )}
      {modalTitle && (
        <DialogTitle id={ariaLabelledby}>{modalTitle}</DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>,
    document.getElementById("modal-root")
  );
};

Modal.defaultProps = {
  maxWidth: "md",
  fullScreen: false,
};

Modal.propTypes = {
  // children: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node
  // ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  modalTitleStyle: PropTypes.object,
  didCloseModal: PropTypes.func,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  height: PropTypes.oneOf([60, 80, 100]),
  fullScreen: PropTypes.bool,
};

export default Modal;
