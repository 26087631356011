// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import InputSelect from "../Form/InputSelect";

// @material-ui/core components
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

import { Button } from "../Button";
import LoadingView from "../LoadingView/LoadingView";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  drawerPaper: {
    backgroundColor: "#EEEEEE",
    width: 600,
  },
  drawerHeader: {
    backgroundColor: primaryColor,
    padding: "0 8px",
  },
  drawerTitle: {
    color: "#fff",
    margin: 0,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  list: {
    width: "100%",
    maxWidth: "100%",
    padding: 6,
  },
};

const Occurrence = ({
  description,
  category,
  didWantToEdit,
  didWantToDelete,
}) => (
  <ListItem dense divider style={{ minHeight: 80 }}>
    <ListItemText primary={description} secondary={category.label} />
    <ListItemSecondaryAction
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Button
        small
        justIcon
        color="danger"
        outline
        onClick={didWantToDelete}
        style={{ margin: 2 }}
      >
        <i className="fas fa-trash-alt" />
      </Button>
      <Button
        small
        justIcon
        color="primary"
        onClick={didWantToEdit}
        style={{ margin: 2 }}
      >
        <i className="fas fa-pen" />
      </Button>
    </ListItemSecondaryAction>
  </ListItem>
);

@inject("stores")
@observer
class OccurrencesListDrawer extends Component {
  state = { didWantToDelete: false, tokenToDelete: null };

  componentDidMount() {
    this.props.stores.occurrencesStore.getOccurrencesCategoriesList();
  }

  didOpenOccurrenceModal = () => {
    this.props.stores.occurrencesStore.didOpenOccurrenceModal();
  };

  handleDrawerToggle = () => {
    this.props.stores.occurrencesStore.toggleOccurrenceDrawer();
  };

  didWantToDelete = (token) => () =>
    this.setState({
      didWantToDelete: true,
      tokenToDelete: token,
    });

  didWantToEdit = (occurrence) => () =>
    this.props.stores.occurrencesStore.didSelectOccurrenceToEdit(occurrence);

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
      tokenToDelete: null,
    });
  };

  didDelete = async () => {
    await this.props.stores.occurrencesStore.deleteOccurrence(
      this.state.tokenToDelete
    );
    this.didCancelDeleteAction();
  };

  render() {
    const { classes } = this.props;
    const {
      isFetching,
      isFetchingCategories,
      filteredOccurrencesList,
      occurrencesList,
      isOpenOccurrenceDrawer,
      selectedOccurrenceCategory,
      occurrencesCategoriesList,
      setSelectedOccurrenceCategory,
    } = this.props.stores.occurrencesStore;

    return (
      <Fragment>
        {this.state.didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDelete}
            message="Tem certeza que deseja remover este registro? Esta ação não pode ser desfeita."
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <Drawer
          variant="temporary"
          anchor="right"
          open={isOpenOccurrenceDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={this.handleDrawerToggle}
        >
          <Grid container style={{ margin: 0 }}>
            <Grid item xs={12}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                classes={{ container: classes.drawerHeader }}
              >
                <Grid item xs={8}>
                  <p className={classes.drawerTitle}>Ocorrências</p>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Button
                    small
                    justIcon
                    style={{ color: "#fff" }}
                    color="transparent"
                    onClick={this.handleDrawerToggle}
                  >
                    <i className="fas fa-times" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {isFetching ? (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <LoadingView />
              </Grid>
            ) : (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    outline
                    small
                    block
                    onClick={this.didOpenOccurrenceModal}
                    style={{ margin: 8 }}
                  >
                    Registrar ocorrência <i className="fas fa-plus" />
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ padding: 8 }}>
                  <InputSelect
                    placeholder="Filtre por tipo"
                    name="selectedOccurrenceCategory"
                    value={selectedOccurrenceCategory}
                    options={occurrencesCategoriesList}
                    isLoading={isFetchingCategories}
                    onChange={(_, selectedValue) => {
                      setSelectedOccurrenceCategory(selectedValue);
                    }}
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                    valueKey="token"
                    isClearable
                  />
                </Grid>
                <Grid item xs={12}>
                  {occurrencesList.length > 0 ? (
                    filteredOccurrencesList.length > 0 ? (
                      <List className={classes.list}>
                        {filteredOccurrencesList.map((occurrence) => (
                          <Occurrence
                            key={occurrence.token}
                            category={occurrence.category}
                            description={occurrence.description}
                            didWantToEdit={this.didWantToEdit(occurrence)}
                            didWantToDelete={this.didWantToDelete(
                              occurrence.token
                            )}
                          />
                        ))}
                      </List>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "1.2em",
                          marginTop: 20,
                        }}
                      >
                        Nenhuma occorrência encontrada para o filtro atual.
                      </p>
                    )
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "1.2em",
                        marginTop: 40,
                      }}
                    >
                      Nenhuma occorrência registrada.
                    </p>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Drawer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OccurrencesListDrawer);
