// @ts-nocheck
import { observable, computed, action } from "mobx";

import { isProductionServer } from "../../utils/EnvUtils";

import i18nData from "./i18nData.json";

class I18nStore {
  @observable
  i18nList = [];
  @observable
  isFetching = true;
  @observable
  searchString = "";
  @observable
  filterOnlyPending = true;

  constructor(authStore) {
    this.authStore = authStore;

    this.readJsonData();
  }

  supportedLanguages = ["pt", "en"];

  @action
  readJsonData = () => {
    let newList = [];
    Object.keys(i18nData).forEach((key) => {
      newList.push({
        id: key,
        ...i18nData[key],
      });
    });
    this.i18nList = newList;
    this.isFetching = false;
  };

  @computed
  get currentLanguage() {
    const browserLanguage =
      window.navigator.userLanguage || window.navigator.language;

    if (this.authStore && this.authStore.currentUser) {
      return this.authStore.currentUser.language;
    }
    return browserLanguage.includes("en") ? "en" : "pt";
  }

  @computed
  get dateFormatWithFourDigitsYear() {
    switch (this.currentLanguage) {
      case "pt":
        return `DD/MM/YYYY`;

      case "en":
      default:
        return `MM/DD/YYYY`;
    }
  }

  @computed
  get dateFormatWithTwoDigitsYear() {
    switch (this.currentLanguage) {
      case "pt":
        return `DD/MM/YY`;

      case "en":
      default:
        return `MM/DD/YY`;
    }
  }

  @computed
  get timeFormat() {
    switch (this.currentLanguage) {
      case "pt":
        return "HH:mm";

      case "en":
      default:
        return "h:mm A";
    }
  }

  @computed
  get dateFormatToExportFileName() {
    switch (this.currentLanguage) {
      case "pt":
        return `DD-MM-YY_HH-mm`;

      case "en":
      default:
        return `MM-DD-YY_h-mm A`;
    }
  }

  @computed
  get apexChartDateFormatShort() {
    switch (this.currentLanguage) {
      case "pt":
        return `dd/MM`;

      case "en":
      default:
        return `MM/dd`;
    }
  }

  @computed
  get apexChartDateFormatFull() {
    switch (this.currentLanguage) {
      case "pt":
        return `dd/MM/yyyy`;

      case "en":
      default:
        return `MM/dd/yyyy`;
    }
  }

  getPluralKeyToSearch = (value) =>
    value === 0 ? "zero" : value === 1 ? "one" : "other";

  getTranslatedStringFromDatabase = (id, pluralKeyToSearch) => {
    // console.log("getTranslatedStringFromDatabase", id);
    try {
      const value = this.textsList[id][this.currentLanguage];
      return pluralKeyToSearch ? value[pluralKeyToSearch] : value;
    } catch (error) {
      // console.error("error in getTranslatedStringFromDatabase", id, error);
      return null;
    }
  };

  saveSingularStringIfNew = (key, pt) => {
    this.saveIfNotExists({
      isPlural: false,
      key,
      pt,
      en: "",
    });
  };

  savePluralStringIfNew = (key, zero, one, other) => {
    this.saveIfNotExists({
      key,
      pt: {
        zero,
        one,
        other,
      },
      en: {
        zero: "",
        one: "",
        other: "",
      },
      isPlural: true,
    });
  };

  transformVariablesAndPlural = (localizedText, variables, pluralValue) => {
    let resultText = localizedText;

    if (variables) {
      Object.keys(variables).forEach((key) => {
        resultText = resultText.replace(`$${key}`, variables[key]);
      });
    }
    if (pluralValue && resultText && resultText.length > 0) {
      resultText = resultText.replace(
        "#",
        pluralValue > 9 ? pluralValue : `0${pluralValue}`
      );
    }
    return resultText;
  };

  localizedString = ({ id, defaultString }, variables) => {
    const result = this.getTranslatedStringFromDatabase(id);
    if (!result) {
      //  Doesn't exists, create new entry on database
      this.saveSingularStringIfNew(id, defaultString);
    }

    return this.transformVariablesAndPlural(
      result ? result : defaultString,
      variables
    );
  };

  localizedPlural = ({ id, value, zero, one, other }, variables) => {
    this.savePluralStringIfNew(id, zero, one, other);

    const defaultString = value === 0 ? zero : value === 1 ? one : other;
    const result = this.getTranslatedStringFromDatabase(
      id,
      this.getPluralKeyToSearch(value)
    );
    return this.transformVariablesAndPlural(
      result ? result : defaultString,
      variables,
      value
    );
  };

  @computed
  get textsList() {
    let result = {};
    this.i18nList.forEach((stringSnapshot) => {
      result[stringSnapshot.id] = stringSnapshot;
    });

    return result;
  }

  @computed
  get textsCount() {
    return this.filteredI18nList.length;
  }

  logicForMatchLanguageFilter = (language, textObject, searchString) => {
    if (textObject.isPlural) {
      return textObject[language]
        ? Object.values(textObject[language]).filter((textString) =>
            textString.toLowerCase().includes(searchString)
          ).length > 0
        : false;
    }
    return textObject[language]
      ? textObject[language].toLowerCase().includes(searchString)
      : false;
  };

  logicForMatchPendingFilter = (textObject) => {
    if (!textObject.pt || !textObject.en) {
      return true;
    }
    if (textObject.isPlural) {
      const pt = textObject.pt;
      const en = textObject.en;

      return (
        !pt.one || !pt.other || !pt.zero || !en.one || !en.other || !en.zero
      );
    }
    return false;
  };

  @computed
  get filteredI18nList() {
    if (this.searchString.length > 0 || this.filterOnlyPending) {
      const lowerCasedSearchString = this.searchString.toLowerCase();
      return this.i18nList.filter((textObject) => {
        const matchId = textObject.id
          .toLowerCase()
          .includes(lowerCasedSearchString);

        const matchPortuguese = this.logicForMatchLanguageFilter(
          "pt",
          textObject,
          lowerCasedSearchString
        );
        const matchEnglish = this.logicForMatchLanguageFilter(
          "en",
          textObject,
          lowerCasedSearchString
        );

        const matchPending = this.filterOnlyPending
          ? this.logicForMatchPendingFilter(textObject)
          : true;

        return matchPending && (matchId || matchPortuguese || matchEnglish);
      });
    }
    return this.i18nList;
  }

  save = (stringTextData) => {
    if (isProductionServer) return Promise.reject();

    return Promise.resolve();
  };

  saveIfNotExists = (textObject) => {
    return false;
  };
}

export default I18nStore;
