// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../components/HOC";
import PageTitle from "../components/PageTitle/PageTitle";

import { GridContainer, GridItem } from "../components/Grid";
import LoadingView from "../components/LoadingView/LoadingView";
import { LinkButton } from "../components/Button";
import InfoLabel from "../components/InfoLabel/InfoLabel";
import RandomAnimation from "../components/Animations/RandomAnimation";

import InstalledDevicesChartWidget from "./dashboardPage/InstalledDevicesChartWidget";
import WifiHoursChartWidget from "./dashboardPage/WifiHoursChartWidget";
import WifiDevicesOnlineChartWidget from "./dashboardPage/WifiDevicesOnlineChartWidget";
import WifiConnectionsByPeriodChartWidget from "./dashboardPage/WifiConnectionsByPeriodChartWidget";
import WifiDifferentDaysChartWidget from "./dashboardPage/WifiDifferentDaysChartWidget";
import WifiPhonesTodayChartWidget from "./dashboardPage/WifiPhonesTodayChartWidget";
import WifiTop10UsersChartWidget from "./dashboardPage/WifiTop10UsersChartWidget";

//Utils
import { isProductionServer } from "../utils/EnvUtils";
import { APP_ROUTES } from "../routes/app-routes";

// Style
import chartsStyle from "../assets/jss/js-styles/views/chartsStyle";

@withLocalized
@inject("stores")
@observer
class DashboardPage extends Component {
  componentWillMount() {
    const { vehicleStore, reportsStore } = this.props.stores;
    vehicleStore.getVehiclesList();
    reportsStore.getDashboardChartData();
  }

  didSelectVehicleChart = (status) => {
    this.props.history.push({
      pathname: APP_ROUTES.VEHICLES,
      search: `?status=${status}`,
    });
  };

  getWifiConnectionsByPeriodChartData = (data) =>
    data
      .filter(($0) => [$0.yesterday, $0.week, $0.month].find(($1) => $1 > 0))
      .map(($2) => ({
        name: this.props.localizedString({
          id: `Connectivity@wifiConnectionsByPeriod_${$2.type}`,
          defaultString: $2.type,
        }),
        data: [$2.yesterday, $2.week, $2.month],
      }));

  getWifiHourChartData = (data) => {
    let hoursCount = 0;
    const result = data.map((hourValue) => {
      hoursCount += hourValue;
      return hourValue;
    });

    return hoursCount > 0 ? result : null;
  };

  render() {
    const { classes } = this.props;
    const { authStore, reportsStore, vehicleStore } = this.props.stores;

    if (!authStore.hasAccessTo("sys", "admin")) {
      return <p style={{ textAlign: "center", margin: 200 }}>Em manutenção</p>;
    }

    const chartData = reportsStore.dashboardChartData;

    if (vehicleStore.isFetching) {
      return <LoadingView />;
    }

    if (!chartData) {
      return (
        <GridContainer justify="center" style={{ marginTop: 40 }}>
          <GridItem xs={12}>
            <InfoLabel large style={{ textAlign: "center", marginBottom: 30 }}>
              Não encontramos informações relevantes para exibir neste momento.
            </InfoLabel>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <RandomAnimation />
          </GridItem>
        </GridContainer>
      );
    }

    const wifiData = chartData ? chartData.wifi : null;

    const wifiHourChartData =
      wifiData && wifiData.wifi_hour
        ? this.getWifiHourChartData(Object.values(wifiData.wifi_hour))
        : null;

    const wifiPhonesTodayList =
      wifiData && wifiData.wifi_phonestoday ? wifiData.wifi_phonestoday : [];

    const wifiUniqueConnections =
      wifiData && wifiData.wifi_differentdays
        ? parseInt(wifiData.wifi_differentdays.unique_connections, 10)
        : 0;
    const wifiReconnections =
      wifiData && wifiData.wifi_differentdays
        ? parseInt(wifiData.wifi_differentdays.reconnections, 10)
        : 0;

    const connectionByPeriodChartData =
      wifiData && wifiData.wifi_connectionsbyperiod
        ? this.getWifiConnectionsByPeriodChartData(
            wifiData.wifi_connectionsbyperiod
          )
        : null;

    const wifiTop10Users =
      wifiData && wifiData.top10_users
        ? Object.keys(wifiData.top10_users).map((phoneNumber) => ({
            phoneNumber,
            quantity: wifiData.top10_users[phoneNumber],
          }))
        : null;

    return (
      <div style={{ marginTop: 44, padding: "0 15px" }}>
        <PageTitle id="Menu@dashboard">Painel</PageTitle>
        <GridContainer className={classes.chartContainer}>
          {!isProductionServer && (
            <GridItem xs={12} style={{ marginTop: -10, marginBottom: 40 }}>
              <LinkButton
                color="primary"
                outline
                block
                to="/monitoramento-geral"
              >
                Mapa geral com todos os veículos{" "}
                <i className="fas fa-map-marker-alt" />
              </LinkButton>
            </GridItem>
          )}
          {wifiData && wifiHourChartData && (
            <GridItem className={classes.chartGrid} xs={12}>
              <WifiHoursChartWidget chartData={wifiHourChartData} />
            </GridItem>
          )}
          <GridItem className={classes.chartGrid} xs={12} sm={6} lg={3}>
            <InstalledDevicesChartWidget onClick={this.didSelectVehicleChart} />
          </GridItem>
          {wifiData && wifiData.devices_online && (
            <GridItem className={classes.chartGrid} xs={12} sm={6} lg={3}>
              <WifiDevicesOnlineChartWidget
                chartData={wifiData.devices_online}
              />
            </GridItem>
          )}
          {wifiData && connectionByPeriodChartData && (
            <GridItem className={classes.chartGrid} xs={12} sm={6} lg={3}>
              <WifiConnectionsByPeriodChartWidget
                chartData={connectionByPeriodChartData}
              />
            </GridItem>
          )}
          {(wifiUniqueConnections > 0 || wifiReconnections > 0) && (
            <GridItem className={classes.chartGrid} xs={12} sm={6} lg={3}>
              <WifiDifferentDaysChartWidget
                uniqueConnections={wifiUniqueConnections}
                reconnections={wifiReconnections}
              />
            </GridItem>
          )}
          {wifiData && wifiPhonesTodayList.length > 0 && (
            <GridItem className={classes.chartGrid} xs={12} sm={6}>
              <WifiPhonesTodayChartWidget phonesList={wifiPhonesTodayList} />
            </GridItem>
          )}
          {wifiData && wifiTop10Users && wifiTop10Users.length > 0 && (
            <GridItem className={classes.chartGrid} xs={12} sm={6}>
              <WifiTop10UsersChartWidget chartData={wifiTop10Users} />
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(chartsStyle)(DashboardPage);
