// @ts-nocheck
// Utils
import {
  startOfTheDayMoment,
  getStartOfWeekForMoment,
  getEndOfWeekForMoment,
  startOfCurrentWeekMoment,
  endOfCurrentWeekMoment,
  getStartOfMonthForMoment,
  getEndOfMonthForMoment,
  startOfCurrentMonthMoment,
  endOfCurrentMonthMoment,
} from "../../utils/DateUtils";

const DateRange = Object.freeze({
  custom: "custom",
  currentWeek: "currentWeek",
  lastWeek: "lastWeek",
  currentMonth: "currentMonth",
  lastMonth: "lastMonth",
  lastAndCurrentMonth: "lastAndCurrentMonth",
  lastThirtyDays: "lastThirtyDays",
  lastSixtyDays: "lastSixtyDays",
  lastNinetyDays: "lastNinetyDays",
});

export const labelForDataRange = (dateRange, localizedString) => {
  switch (dateRange) {
    case DateRange.custom:
      return localizedString({
        id: "DateRange@custom",
        defaultString: "Customizado",
      });
    case DateRange.currentWeek:
      return localizedString({
        id: "DateRange@currentWeek",
        defaultString: "Esta semana",
      });
    case DateRange.lastWeek:
      return localizedString({
        id: "DateRange@lastWeek",
        defaultString: "Semana passada",
      });
    case DateRange.currentMonth:
      return localizedString({
        id: "DateRange@currentMonth",
        defaultString: "Este mês",
      });
    case DateRange.lastMonth:
      return localizedString({
        id: "DateRange@lastMonth",
        defaultString: "Mês passado",
      });
    case DateRange.lastThirtyDays:
      return localizedString({
        id: "DateRange@lastThirtyDays",
        defaultString: "< 30 dias",
      });
    case DateRange.lastSixtyDays:
      return localizedString({
        id: "DateRange@lastSixtyDays",
        defaultString: "< 60 dias",
      });
    case DateRange.lastNinetyDays:
    default:
      return localizedString({
        id: "DateRange@lastNinetyDays",
        defaultString: "< 90 dias",
      });
  }
};

export const didChangeDateRange = (dateRange, callback) => {
  let fromDate = null;
  let toDate = null;
  switch (dateRange) {
    case DateRange.currentWeek:
      fromDate = startOfCurrentWeekMoment();
      toDate = endOfCurrentWeekMoment();
      break;
    case DateRange.lastWeek:
      fromDate = getStartOfWeekForMoment(
        startOfTheDayMoment().subtract(1, "week")
      );
      toDate = getEndOfWeekForMoment(startOfTheDayMoment().subtract(1, "week"));
      break;
    case DateRange.currentMonth:
      fromDate = startOfCurrentMonthMoment();
      toDate = endOfCurrentMonthMoment();
      break;
    case DateRange.lastMonth:
      fromDate = getStartOfMonthForMoment(
        startOfTheDayMoment().subtract(1, "month")
      );
      toDate = getEndOfMonthForMoment(
        startOfTheDayMoment().subtract(1, "month")
      );
      break;
    case DateRange.lastAndCurrentMonth:
      fromDate = getStartOfMonthForMoment(
        startOfTheDayMoment().subtract(1, "month")
      );
      toDate = getEndOfMonthForMoment(startOfTheDayMoment());
      break;
    case DateRange.lastThirtyDays:
      fromDate = getStartOfMonthForMoment(
        startOfTheDayMoment().subtract(30, "day")
      );
      toDate = getEndOfMonthForMoment(startOfTheDayMoment());
      break;
    case DateRange.lastSixtyDays:
      fromDate = getStartOfMonthForMoment(
        startOfTheDayMoment().subtract(60, "day")
      );
      toDate = getEndOfMonthForMoment(startOfTheDayMoment());
      break;
    case DateRange.lastNinetyDays:
      fromDate = getStartOfMonthForMoment(
        startOfTheDayMoment().subtract(90, "day")
      );
      toDate = getEndOfMonthForMoment(startOfTheDayMoment());
      break;

    case DateRange.custom:
    default:
      fromDate = startOfCurrentWeekMoment();
      toDate = endOfCurrentWeekMoment();
      break;
  }

  callback(dateRange, fromDate, toDate);
};

export default DateRange;
