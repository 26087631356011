// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

// Components
import { InputText, InputSwitch } from "../Form";
import InputFile from "../Form/InputFile";

import MaterialSelectInput from "../Form/MaterialSelectInput";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../HOC";

// Translate
import {
  DeleteString,
  CancelString,
  FormErrorString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Style
const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
};

@withLocalized
@inject("stores")
@observer
class NetworkConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.network) {
      this.state = this.getStateFromExistingNetwork(props.network);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    const { wifiRedirectToDefault, wifiTimeDefault } =
      this.props.stores.settingsStore;
    return {
      wifiName: "",
      smartAuth: true,
      wifiPassword: "",
      wifiTime: wifiTimeDefault,
      wifiRedirect: wifiRedirectToDefault,
      bandwidth: 60,
      wifiFilter: "none",
      wifiBannerUrl: "",
      isWaitingForm: false,
      formWasSubmit: false,
      modalIsOpen: false,
      wasEdited: false,
      bannerFile: null,
      isValid: {
        wifiName: false,
        wifiPassword: false,
        wifiTime: true,
        wifiRedirect: wifiRedirectToDefault.length > 0,
      },
    };
  };

  getStateFromExistingNetwork = (network) => {
    return {
      ...this.getDefaultState(),
      wifiName: network.wifiName,
      smartAuth: network.smartAuth,
      boost: network.boost,
      wifiPassword: network.wifiPassword,
      wifiTime: network.wifiTime || "60",
      wifiRedirect: network.wifiRedirect,
      bandwidth: parseInt(network.bandwidth, 10),
      wifiFilter: network.wifiFilter,
      wifiBannerUrl: network.wifiBannerUrl,
      isValid: {
        wifiName: network.wifiName.length >= 4 && network.wifiName.length <= 32,
        wifiTime: network.wifiTime.length > 0,
        wifiRedirect: network.wifiRedirect.length > 0,
      },
    };
  };

  onChangeInputText = (event) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  onChangeWifiTime = ({ target }) => {
    let time = parseInt(target.value, 10);

    if (target.value === "") {
      time = 0;
    } else if (time > 1440) {
      time = 1440;
    }

    this.props.stores.settingsStore.actionChangeWifiTimeDefault(time);

    this.setState((state) => ({
      wifiTime: `${time}`,
      isValid: {
        ...state.isValid,
        wifiTime: time > 0 && time <= 1440,
      },
      wasEdited: true,
    }));
  };

  onChangeSmartAuth = () => {
    this.setState((prevState) => ({
      smartAuth: !prevState.smartAuth,
      wasEdited: true,
    }));
  };
  onChangeBoost = () => {
    this.setState((prevState) => ({
      boost: !prevState.boost,
      wasEdited: true,
    }));
  };

  onChangeSelectInput = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
      wasEdited: true,
    });
  };

  canSubmitForm = () => {
    const { isValid, smartAuth } = this.state;
    if (smartAuth) {
      const { wifiName, wifiTime, wifiRedirect } = isValid;
      if (wifiName && wifiTime && wifiRedirect) {
        return true;
      }
    } else {
      if (isValid.wifiName) {
        return true;
      }
    }

    return false;
  };

  onChangeFileUpload = (inputName, { name, size, type, base64 }) => {
    this.setState({
      bannerFile: {
        name,
        size,
        type,
        base64,
      },
      wasEdited: true,
    });
  };

  getFormDataToSubmit = ({
    wifiName,
    smartAuth,
    boost,
    wifiPassword,
    wifiTime,
    wifiRedirect,
    bandwidth,
    wifiFilter,
    bannerFile,
  }) => {
    return {
      wifiName,
      wifiPassword: smartAuth ? "" : wifiPassword,
      wifiFilter,
      bandwidth,
      wifiTime,
      wifiRedirect,
      smartAuth,
      boost,
      banner: bannerFile,
    };
  };

  submitForm = () => {
    const { network, stores } = this.props;
    const formData = {
      ...this.getFormDataToSubmit(this.state),
    };

    stores.settingsStore.actionChangeWifiRedirectToDefault(
      formData.wifiRedirect
    );

    if (network) {
      stores.networksStore
        .update({
          ...network,
          ...formData,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      stores.networksStore
        .submit(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.stores.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = (_) => {
    this.props.didCancel();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  getWifiFilterLabel = (wifiFilter) => {
    const { localizedString } = this.props;
    switch (wifiFilter) {
      case "high":
        return localizedString({
          id: "Connectivity@wifiFilterHighLabel",
          defaultString: "Pornografia, Entretenimento e Redes Sociais",
        });
      case "medium":
        return localizedString({
          id: "Connectivity@wifiFilterMediumLabel",
          defaultString: "Pornografia e Entretenimento",
        });
      case "low":
        return localizedString({
          id: "Connectivity@wifiFilterLowLabel",
          defaultString: "Pornografia",
        });
      case "none":
      default:
        return localizedString({
          id: "Connectivity@wifiFilterNoneLabel",
          defaultString: "Sem filtro",
        });
    }
  };

  renderUploadBannerImage = () => {
    if (this.state.smartAuth) {
      const { bannerFile, wifiBannerUrl } = this.state;
      return (
        <Fragment>
          <InputFile
            name="wifiBannerUrl"
            label="Banner"
            onChangeFile={this.onChangeFileUpload}
            accept="image/*"
          >
            {(wifiBannerUrl || bannerFile) && (
              <img
                src={bannerFile ? bannerFile.base64 : wifiBannerUrl}
                alt={"Banner da wifi"}
                style={{
                  maxWidth: 400,
                }}
              />
            )}
          </InputFile>
          <p style={{ textAlign: "center", fontStyle: "italic" }}>
            Para melhor enquadramento a imagem deve ser quadrada (ex: 700px de
            largura e 700px de altura).
          </p>
        </Fragment>
      );
    }

    return null;
  };

  render() {
    const { classes, network } = this.props;
    const isEditing = network !== null;

    const {
      wifiName,
      smartAuth,
      boost,
      wifiPassword,
      wifiTime,
      wifiRedirect,
      bandwidth,
      wifiFilter,
      formWasSubmit,
      isValid,
      isWaitingForm,
    } = this.state;

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <h3 className={classes.title}>Configuração Wifi</h3>
            <InputText
              label="Nome"
              name="wifiName"
              isRequired
              hasError={formWasSubmit && !isValid.wifiName}
              hasSuccess={formWasSubmit && isValid.wifiName}
              value={wifiName}
              onChange={this.onChangeInputText}
              minLength={4}
              maxLength={32}
              helpText={
                <Translate id="Connectivity@wifiNameHint">
                  Mínimo 4 e máximo 32 caracteres. Não utilize acentos.
                </Translate>
              }
            />

            <GridContainer>
              <GridItem xs={12} sm={6}>
                <InputSwitch
                  label="Autenticação"
                  name="smartAuth"
                  isActive={smartAuth}
                  onChange={this.onChangeSmartAuth}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputSwitch
                  label="Impulsionamento"
                  name="boost"
                  isActive={boost}
                  onChange={this.onChangeBoost}
                />
              </GridItem>
            </GridContainer>
            {!smartAuth && (
              <InputText
                label="Senha"
                name="wifiPassword"
                inputProps={{
                  autoComplete: "off",
                }}
                value={wifiPassword}
                onChange={this.onChangeInputText}
                minLength={8}
                maxLength={64}
                helpText={
                  <Translate id="Connectivity@wifiPasswordHint">
                    Mínimo de 8 e máximo 64 caracteres. Não utilize acentos.
                  </Translate>
                }
              />
            )}
          </GridItem>
          {smartAuth && (
            <Fragment>
              <GridItem xs={12} sm={6}>
                <InputText
                  label="Tempo Limite"
                  name="wifiTime"
                  isRequired
                  hasError={formWasSubmit && !isValid.wifiTime}
                  hasSuccess={formWasSubmit && isValid.wifiTime}
                  value={`${wifiTime}`}
                  onChange={this.onChangeWifiTime}
                  helpText="Em minutos"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputText
                  label="Redirecionamento"
                  name="wifiRedirect"
                  isRequired
                  hasError={formWasSubmit && !isValid.wifiRedirect}
                  hasSuccess={formWasSubmit && isValid.wifiRedirect}
                  value={wifiRedirect}
                  onChange={this.onChangeInputText}
                />
              </GridItem>
            </Fragment>
          )}
          <GridItem xs={12} sm={6}>
            <MaterialSelectInput
              label="Filtro"
              name="wifiFilter"
              value={wifiFilter}
              options={[
                {
                  label: this.getWifiFilterLabel("high"),
                  value: "high",
                },
                {
                  label: this.getWifiFilterLabel("medium"),
                  value: "medium",
                },
                {
                  label: this.getWifiFilterLabel("low"),
                  value: "low",
                },
                {
                  label: this.getWifiFilterLabel("none"),
                  value: "none",
                },
              ]}
              onChange={this.onChangeSelectInput}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <MaterialSelectInput
              label="Limite de banda"
              name="bandwidth"
              value={bandwidth}
              options={[
                { label: "100%", value: 100 },
                { label: "80%", value: 80 },
                { label: "60%", value: 60 },
                { label: "40%", value: 40 },
                { label: "20%", value: 20 },
              ]}
              onChange={this.onChangeSelectInput}
            />
          </GridItem>
          <GridItem xs={12}>{this.renderUploadBannerImage()}</GridItem>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={4}>
            <Button block onClick={this.didCancel}>
              <i className="fas fa-chevron-left" /> <CancelString />
            </Button>
          </GridItem>
          {isEditing && (
            <GridItem xs={12} sm={4}>
              <Button color="danger" block onClick={this.props.didWantToDelete}>
                <DeleteString /> <i className="fas fa-trash-alt" />
              </Button>
            </GridItem>
          )}
          <GridItem xs={12} sm={4}>
            <Button
              color="primary"
              block
              disabled={!this.state.wasEdited}
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? "Atualizar" : "Salvar"} <i className="fas fa-save" />
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NetworkConfigurationForm);
