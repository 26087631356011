// @ts-nocheck
import { observable, action, computed, reaction } from "mobx";

import OccurrenceCategoryModel from "../../Model/OccurrenceCategoryModel";
import OccurrenceModel from "../../Model/OccurrenceModel";

// Utils
import { secondsToMinutesAndHours } from "../../utils/DateUtils";

class OccurrencesStore {
  @observable occurrencesCategoriesList = [];
  @observable isFetching = false;
  @observable isFetchingCategories = false;
  @observable isOpenOccurrenceModal = false;
  @observable isOpenOccurrenceDrawer = false;
  @observable searchString = "";

  @observable occurrencesList = [];
  @observable selectedOccurrence = {
    category: null,
    description: "",
  };

  @observable selectedOccurrenceCategory = null;

  constructor(
    authStore,
    videoStore,
    jobScheduleStore,
    notificationsStore,
    mobR2APIService
  ) {
    this.authStore = authStore;
    this.videoStore = videoStore;
    this.jobScheduleStore = jobScheduleStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    reaction(
      () => authStore.isAuthenticated,
      (isAuthenticated) => {
        if (!isAuthenticated) {
          this.clearStore();
        }
      }
    );

    reaction(
      () => {
        if (this.authStore.isDVRGarageManager || this.authStore.isDVRSemob)
          return false;
        return this.jobScheduleStore.selectedJobSchedule;
      },
      (jobExists) => {
        if (jobExists) {
          this.getOcurrencesList();
        }
      },
      { delay: 500 }
    );
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.isFetchingCategories = false;
    this.isOpenOccurrenceModal = false;
    this.isOpenOccurrenceDrawer = false;
    this.searchString = "";
    this.occurrencesCategoriesList = [];
    this.occurrencesList = [];
    this.selectedOccurrence = {
      category: null,
      description: "",
    };
    this.selectedOccurrenceCategory = null;
  };

  @action
  setModal = (isOpen) => {
    this.isOpenOccurrenceModal = isOpen;

    if (!isOpen) {
      this.selectedOccurrence = {
        category: null,
        description: "",
      };
    }
  };
  @action
  setIsOpenOccurrenceDrawer = (newValue) => {
    this.isOpenOccurrenceDrawer = newValue;
  };
  @action
  setOccurrencesList = (newList) => {
    this.occurrencesList = newList;
  };
  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setIsFetchingCategories = (newValue) => {
    this.isFetchingCategories = newValue;
  };

  @action
  setOccurrencesCategoriesList = (newList) => {
    this.occurrencesCategoriesList = newList;
  };
  @action
  setSelectedOccurrenceCategory = (newValue) => {
    this.selectedOccurrenceCategory = newValue;
  };

  @computed
  get filteredOccurrencesList() {
    if (!this.selectedOccurrenceCategory) return this.occurrencesList;

    return this.occurrencesList.filter(
      ({ category }) => category.token === this.selectedOccurrenceCategory.token
    );
  }

  toggleOccurrenceDrawer = () => {
    this.setIsOpenOccurrenceDrawer(!this.isOpenOccurrenceDrawer);
  };

  getOccurrencesCategoriesList = async () => {
    this.setIsFetchingCategories(true);
    let newOccurrencesCategoriesList = [];

    try {
      const response =
        await this.mobR2APIService.requestOccurrencesCategories();
      if (response.status === 200) {
        response.data.forEach((category) => {
          newOccurrencesCategoriesList.push(
            new OccurrenceCategoryModel(category)
          );
        });
      }
      this.setOccurrencesCategoriesList(newOccurrencesCategoriesList);
    } catch (error) {
      console.error("error in getOcurrencesCategoriesList", error);
    } finally {
      this.setIsFetchingCategories(false);
    }
  };

  getOcurrencesList = async () => {
    this.setIsFetching(true);
    let newOccurrencesList = [];

    try {
      const response = await this.mobR2APIService.requestEvents(
        this.jobScheduleStore.selectedJobSchedule.token
      );
      if (response.status === 200) {
        response.data.forEach((occurrence) => {
          newOccurrencesList.push(new OccurrenceModel(occurrence));
        });
      }
      this.setOccurrencesList(newOccurrencesList);
    } catch (error) {
      console.error("error in getOcurrencesList", error);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action
  onChangeCategory = (category) => {
    this.selectedOccurrence["category"] = category;
  };
  @action
  onChangeDescription = (description) => {
    this.selectedOccurrence["description"] = description;
  };

  @action
  didSelectOccurrenceToEdit = (occurrence) => {
    this.selectedOccurrence = { ...occurrence };
    this.setModal(true);
  };

  deleteOccurrence = async (tokenToDelete) => {
    try {
      const response = await this.mobR2APIService.deleteEvent(tokenToDelete);
      this.notificationsStore.addSnackbarNotification({
        message: response.data.success,
        color: "warning",
      });
      this.getOcurrencesList();
      return Promise.resolve();
    } catch (error) {
      console.error("error in deleteOccurrence", error);
      return Promise.reject(error);
    }
  };

  didOpenOccurrenceModal = () => {
    this.onChangeDescription(
      `${secondsToMinutesAndHours(this.videoStore.playedSeconds)}: `
    );
    this.setModal(true);
  };

  didCloseOccurrenceModal = () => {
    this.setModal(false);
  };

  submitOccurrence = async ({ description, category }) => {
    try {
      const response = await this.mobR2APIService.submitEvent({
        scheduleToken: this.jobScheduleStore.selectedJobSchedule.token,
        description,
        category,
      });
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.getOcurrencesList();
      return Promise.resolve();
    } catch (error) {
      console.error("error in submitOccurrence", error);
      return Promise.reject(error);
    }
  };
  updateOccurrence = async ({ token, description, category }) => {
    try {
      const response = await this.mobR2APIService.updateEvent({
        scheduleToken: this.jobScheduleStore.selectedJobSchedule.token,
        description,
        category,
        token,
      });
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.getOcurrencesList();
      return Promise.resolve();
    } catch (error) {
      console.error("error in updateEvent", error);
      return Promise.reject(error);
    }
  };

  didSaveOccurrence = async () => {
    if (!this.selectedOccurrence.category) {
      this.notificationsStore.addSnackbarNotification({
        color: "warning",
        message: "Escolha a categoria do evento",
      });
      return;
    }

    if (this.selectedOccurrence.token) {
      await this.updateOccurrence(this.selectedOccurrence);
    } else {
      await this.submitOccurrence(this.selectedOccurrence);
    }

    this.setModal(false);
  };
}

export default OccurrencesStore;
