// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import _debounce from "lodash/debounce";
import _mean from "lodash/mean";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

import PageTitle from "../../components/PageTitle/PageTitle";
import LoadingView from "../../components/LoadingView/LoadingView";
import { GridContainer, GridItem } from "../../components/Grid";
import { SimpleCard } from "../../components/Card";
import InfoLabel from "../../components/InfoLabel/InfoLabel";
import SearchBar from "../../components/SearchBar/SearchBar";
import PhoneWithSMSLink from "../../components/Phone/PhoneWithSMSLink";
import DatePicker from "../../components/Form/DatePicker";
import InputSelect from "../../components/Form/InputSelect";
import Table from "../../components/Table/Table";

// Translate
import Translate from "../../components/I18n/Translate";

// Utils
import { secondsToMinutesAndHours } from "../../utils/DateUtils";
import {
  startOfTheDayMoment,
  endOfTheDayMoment,
  stringDateFromUnixDate,
} from "../../utils/DateUtils";

// Style
import { successColor, dangerColor } from "../../assets/jss/app-js-styles";
const styles = (theme) => ({
  exportButton: {
    position: "absolute",
    left: 15,
    top: -10,
    [theme.breakpoints.down("sm")]: {
      position: "initial",
    },
  },
  footerContainer: {
    textAlign: "center",
    marginTop: 16,
    position: "relative",
  },
});

const allOption = { label: "Todos", token: "all" };

@withLocalized
@inject("stores")
@observer
class ConnectivityAuthenticationReportPage extends Component {
  state = {
    fromDate: startOfTheDayMoment().subtract(7, "days"),
    toDate: endOfTheDayMoment(),
    selectedVehicle: allOption,
  };

  componentDidMount() {
    const { vehicleStore } = this.props.stores;
    this.requestNewLogData();
    this.requestNewLogData.flush();

    vehicleStore.getVehiclesList();
  }

  componentWillUnmount() {
    this.requestNewLogData.cancel();
  }

  didWantToUpdateLogData = () => {
    this.props.stores.reportsStore.setIsFetching(true);

    this.requestNewLogData();
  };

  requestNewLogData = _debounce(() => {
    const { fromDate, toDate, selectedVehicle } = this.state;
    this.props.stores.reportsStore.getConnectivityAuthenticationsLogs(
      fromDate.format("X"),
      toDate.format("X"),
      selectedVehicle.token !== "all" ? selectedVehicle.token : null
    );
  }, 2000);

  setNewDataFilter = (fromDate, toDate) => {
    this.setState(
      {
        fromDate,
        toDate,
      },
      this.didWantToUpdateLogData
    );
  };

  onChangeFromDate = (_, newValue) => {
    const toDate = this.state.toDate.isBefore(newValue)
      ? newValue
      : this.state.toDate;
    this.setNewDataFilter(newValue, toDate);
  };

  onChangeToDate = (_, newValue) => {
    const fromDate = this.state.fromDate.isAfter(newValue)
      ? newValue
      : this.state.fromDate;
    this.setNewDataFilter(fromDate, newValue);
  };

  onChangeSelectedVehicle = (_, selectedVehicle) => {
    this.setState(
      {
        selectedVehicle,
      },
      this.didWantToUpdateLogData
    );
  };

  findVehicleName = (token) => {
    const vehicle = this.props.stores.vehicleStore.vehiclesList.find(
      ($0) => $0.token === token
    );
    return vehicle && vehicle.label ? vehicle.label : "";
  };

  render() {
    const { classes, stores, localizedPlural } = this.props;
    const { reportsStore, i18nStore, vehicleStore } = stores;
    const reportLogs = reportsStore.connectivityAuthenticationLogs;
    const filteredReportLogs =
      reportsStore.filteredConnectivityAuthenticationLogs;

    // TODO: Utilizar o componente DateFromAndDateToPickers aqui;

    return (
      <Fragment>
        <PageTitle id="Menu@authenticationsReport">
          Relatório de autenticação
        </PageTitle>
        <SimpleCard cardStyle={{ marginTop: 0 }}>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <DatePicker
                label="Data início"
                name="fromDate"
                value={this.state.fromDate}
                onChange={this.onChangeFromDate}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <DatePicker
                label="Data fim"
                name="toDate"
                value={this.state.toDate}
                onChange={this.onChangeToDate}
                fullWidth
                isValidDate={($0) => $0.isSameOrBefore(endOfTheDayMoment())}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <InputSelect
                label="Veículo"
                name="selectedVehicle"
                value={this.state.selectedVehicle}
                options={[allOption, ...vehicleStore.vehiclesList]}
                onChange={this.onChangeSelectedVehicle}
                withPortal
                valueKey="token"
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginBottom: 16 }}>
            <GridItem xs={12}>
              <SearchBar
                value={reportsStore.searchString}
                onChange={reportsStore.setSearchString}
              />
            </GridItem>
          </GridContainer>
        </SimpleCard>
        {reportsStore.isFetching || vehicleStore.isFetching ? (
          <LoadingView />
        ) : (
          <SimpleCard cardStyle={{ marginTop: 8, marginBottom: 8 }}>
            <GridContainer>
              <GridItem xs={12}>
                {reportLogs.length > 0 ? (
                  filteredReportLogs.length > 0 ? (
                    <Table
                      pivotBy={["vehicle"]}
                      data={filteredReportLogs.map((log) => {
                        const durationInSeconds =
                          log.duration && log.duration.length > 0
                            ? parseInt(log.duration, 10)
                            : null;

                        return {
                          id: log.mac_address,
                          vehicle: this.findVehicleName(log.bus_token),
                          date: stringDateFromUnixDate(
                            log.date,
                            `${i18nStore.dateFormatWithFourDigitsYear} ${i18nStore.timeFormat}`
                          ),
                          identifier: log.phone ? (
                            <Fragment>
                              <PhoneWithSMSLink>{log.phone}</PhoneWithSMSLink>
                              {log.whatsapp === "True" && (
                                <i
                                  className="fab fa-whatsapp"
                                  style={{ marginLeft: 8, color: "#4CD366" }}
                                />
                              )}
                            </Fragment>
                          ) : (
                            log.mac_address
                          ),
                          operator: log.operator,
                          duration: durationInSeconds,
                          status: (
                            <i
                              className="fas fa-circle"
                              style={{
                                fontSize: 10,
                                color:
                                  log.status === "online"
                                    ? successColor
                                    : dangerColor,
                              }}
                            />
                          ),
                        };
                      })}
                      columns={[
                        {
                          Header: "Data",
                          accessor: "date",
                          Aggregated: (row) => {
                            return null;
                          },
                        },
                        {
                          Header: "Veículo",
                          accessor: "vehicle",
                        },
                        {
                          Header: "Duração",
                          accessor: "duration",
                          aggregate: (vals) => {
                            return _mean(vals);
                          },
                          Cell: ({ value }) =>
                            value ? secondsToMinutesAndHours(value) : "—",
                          Aggregated: (row) => {
                            // console.log("row", row);
                            return (
                              <span>
                                {secondsToMinutesAndHours(
                                  parseInt(row.value, 10)
                                )}{" "}
                                (média)
                              </span>
                            );
                          },
                        },
                        {
                          Header: "Celular",
                          accessor: "identifier",
                          Aggregated: (row) => {
                            return null;
                          },
                        },
                        {
                          Header: "Operadora",
                          accessor: "operator",
                          Aggregated: (row) => {
                            return null;
                          },
                        },
                        {
                          Header: "Status",
                          accessor: "status",
                          Aggregated: (row) => {
                            return null;
                          },
                          sortable: false,
                          getHeaderProps: () => {
                            return {
                              style: {
                                textAlign: "center",
                              },
                            };
                          },
                          getProps: () => {
                            return {
                              style: {
                                textAlign: "center",
                              },
                            };
                          },
                        },
                      ]}
                    />
                  ) : (
                    <p>
                      <Translate id="Connectivity@noResultsForCurrentFiltersLabel">
                        Nada encontrado para a pesquisa atual.
                      </Translate>
                    </p>
                  )
                ) : (
                  <InfoLabel
                    large
                    style={{ textAlign: "center", margin: "30px 0" }}
                  >
                    <Translate id="Connectivity@noReportDataMessage">
                      Não encontramos informações relevantes para exibir neste
                      momento.
                    </Translate>
                  </InfoLabel>
                )}
              </GridItem>
            </GridContainer>
          </SimpleCard>
        )}
        {!reportsStore.isFetching && filteredReportLogs.length > 0 && (
          <GridContainer>
            <GridItem xs={12} className={classes.footerContainer}>
              <InfoLabel showIcon={false}>
                <Translate
                  id="Connectivity@reportQuantityLabel"
                  variables={{
                    logsCount: localizedPlural({
                      id: "Connectivity@logsQtyLabel",
                      value: filteredReportLogs.length,
                      zero: "—",
                      one: "# registro",
                      other: "# registros",
                    }),
                    phonesCount: localizedPlural({
                      id: "Connectivity@phonesQtyLabel",
                      value: [
                        ...new Set(filteredReportLogs.map(($0) => $0.phone)),
                      ].length,
                      zero: "sem nenhum número telefônico",
                      one: "sendo apenas um telefone",
                      other: "sendo # telefones únicos",
                    }),
                  }}
                >
                  Total de $logsCount, $phonesCount.
                </Translate>
              </InfoLabel>
            </GridItem>
          </GridContainer>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(ConnectivityAuthenticationReportPage);
