// @ts-nocheck
export const SERVERS_LIST = ["pioneira", "piracicabana"];

const isNodeEnvProduction = process.env.NODE_ENV === "production";
export const isLocalhost = window.location.host.includes("localhost");
export const isProductionServer =
  isNodeEnvProduction &&
  !isLocalhost &&
  !window.location.host.includes("lab.mobr2.com");

export const SERVER_URL = !isLocalhost
  ? window.location.host
  : // : "localhost:8080";
    "app.mobr2.com";
