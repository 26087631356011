// @ts-nocheck
import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

import { Button } from "../../Button";
import { Paper } from "@material-ui/core";
import { GridContainer, GridItem } from "../../Grid";

const getItemStyle = (draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${8}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

class DraggableStopItem extends Component {
  render() {
    const { token, name, index, didRemoveStop } = this.props;

    return (
      <Draggable key={index} draggableId={`${token}_${index}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(provided.draggableProps.style)}
          >
            <Paper
              style={{
                border: snapshot.isDragging ? "1px solid #1876d2" : "initial",
              }}
            >
              <GridContainer
                justify="space-between"
                alignItems="center"
                style={{ padding: "0 8px" }}
              >
                <GridItem xs sm>
                  <p style={{ margin: 0 }}>
                    #{index + 1}- {name}
                  </p>
                </GridItem>
                <GridItem>
                  <Button color="danger" small justIcon onClick={didRemoveStop}>
                    <i className="fas fa-trash-alt" />
                  </Button>
                </GridItem>
              </GridContainer>
            </Paper>
          </div>
        )}
      </Draggable>
    );
  }
}

export default DraggableStopItem;
