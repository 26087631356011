export type ContractRuleDvr = {
  admin: boolean;
  supervisor: boolean;
  analyst: boolean;
  operator: boolean;
  semob: boolean;
  garage_manager: boolean;
};

export type ContractRuleSys = {
  admin: boolean;
  operator: boolean;
  maintenance: boolean;
};

export interface ContractRules {
  sys: ContractRuleSys;
  dvr: ContractRuleDvr;
}

export const CONTRACT_DEFAULT_RULES: ContractRules = Object.freeze({
  sys: Object.freeze({
    admin: false,
    operator: false,
    maintenance: false,
  }),
  dvr: Object.freeze({
    admin: false,
    supervisor: false,
    analyst: false,
    operator: false,
    semob: false,
    garage_manager: false,
  }),
});

export const titleForRules = (rule: string) => {
  switch (rule) {
    case "admin":
      return "Administrador";
    case "maintenance":
      return "Manutenção";
    case "supervisor":
      return "Supervisor";
    case "analyst":
      return "Analista";
    case "garage_manager":
      return "Gerente de garagem";
    case "semob":
      return "Semob";
    case "operator":
    default:
      return "Operador";
  }
};

class ContractModel {
  cftvUrl?: string;
  hash: string;
  name: string;
  rules: ContractRules;

  constructor(contractData: any) {
    this.cftvUrl = contractData.cftv_url;
    this.hash = contractData.contract_hash;
    this.name = contractData.contract_name;
    this.rules = contractData.rules || CONTRACT_DEFAULT_RULES;
  }

  get label() {
    return this.name;
  }

  get hasCFTV() {
    return !!this.cftvUrl;
  }
}

export default ContractModel;
