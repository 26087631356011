// @ts-nocheck
import { observable, action, autorun } from "mobx";

import DateRange, {
  didChangeDateRange,
} from "../../components/Charts/DateRange";

import { DID_LOGOUT } from "../../store/InternalEventsStore/InternalEventsStore";

// Utils
import { objectListToCsv } from "../../utils/ArrayUtils";
import {
  startOfCurrentWeekMoment,
  endOfCurrentWeekMoment,
  getNowMoment,
} from "../../utils/DateUtils";

export const WifiChartType = Object.freeze({
  hourlyTraffic: "hourly-traffic",
  visitors: "visitors",
});

class WifiMetricsReportStore {
  @observable hourlyTrafficChartView = null;
  @observable visitorsChartView = null;

  constructor(
    authStore,
    i18nStore,
    internalEventsStore,
    notificationsStore,
    mobR2APIService
  ) {
    this.authStore = authStore;
    this.i18nStore = i18nStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    this.setInitialValues();

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  setInitialValues = () => {
    this.hourlyTrafficChartView = this.defaultChart(
      WifiChartType.hourlyTraffic
    );
    this.visitorsChartView = this.defaultChart(WifiChartType.visitors);
  };

  @action
  clearStore = () => {
    this.resetChartView("hourlyTrafficChartView");
    this.resetChartView("visitorsChartView");
  };

  getChartViewForType = (chartType) => {
    switch (chartType) {
      case WifiChartType.hourlyTraffic:
        return "hourlyTrafficChartView";

      case WifiChartType.visitors:
      default:
        return "visitorsChartView";
    }
  };

  @action
  updateChartView = (chartViewId, newData) => {
    this[chartViewId] = {
      ...this[chartViewId],
      chartData: null,
      ...newData,
    };
  };

  getChartViewForType = (chartType) => {
    switch (chartType) {
      case WifiChartType.hourlyTraffic:
        return "hourlyTrafficChartView";

      case WifiChartType.visitors:
      default:
        return "visitorsChartView";
    }
  };

  @action
  observeChart = (chartType) => {
    const chartViewId = this.getChartViewForType(chartType);
    autorun(
      () => {
        if (this[chartViewId] !== null) {
          const {
            fromDate,
            toDate,
            fromHour,
            toHour,
            weekDays,
            type: chartType,
          } = this[chartViewId];
          if (fromDate && toDate) {
            this.getChartDataForView(
              chartViewId,
              fromDate,
              toDate,
              fromHour,
              toHour,
              weekDays,
              chartType
            );
          } else {
            this[chartViewId].chartData = null;
          }
        }
      },
      { name: `${chartViewId} -> getChartDataForView`, delay: 300 }
    );
  };

  defaultChart = (chartType) => ({
    wizardCurrentStep: 0,
    chartData: null,
    isFetching: false,
    type: chartType,
    dateRange: DateRange.currentWeek,
    fromDate: startOfCurrentWeekMoment(),
    toDate: endOfCurrentWeekMoment(),
    fromHour: null,
    toHour: null,
    weekDays: [],
    error: null,
  });

  didChangeChartWizardCurrentStep = (chartViewId) =>
    action((newStep) => {
      this[chartViewId].wizardCurrentStep = newStep;
    });

  @action
  resetChartView = (chartViewId) => {
    const { type } = this[chartViewId];
    this[chartViewId] = {
      ...this.defaultChart(type),
    };
  };

  titleForChartType = (type) => {
    switch (type) {
      case WifiChartType.visitors:
        return "Perfil dos visitantes";
      case WifiChartType.hourlyTraffic:
        return "Horário de pico";
      default:
        return "";
    }
  };

  fileNameForChartType = (type) => {
    const { dateFormatToExportFileName } = this.i18nStore;
    let name = "";

    switch (type) {
      case WifiChartType.visitors:
        name = "perfil-dos-visitantes";
        break;
      case WifiChartType.hourlyTraffic:
      default:
        name = "horario-de-pico";
        break;
    }
    return `${name}_${getNowMoment().format(dateFormatToExportFileName)}`;
  };

  onChangeDateRangeForView = (chartViewId) => (_, value) => {
    didChangeDateRange(value, (dateRange, fromDate, toDate) => {
      this.updateChartView(chartViewId, {
        dateRange,
        fromDate,
        toDate,
      });
    });
  };
  onChangeFromDateForView = (chartViewId) => (_, value) => {
    const toDate = this[chartViewId].toDate.isBefore(value)
      ? value
      : this[chartViewId].toDate;
    this.updateChartView(chartViewId, {
      fromDate: value,
      toDate,
    });
  };
  onChangeToDateForView = (chartViewId) => (_, value) => {
    const fromDate = this[chartViewId].fromDate.isAfter(value)
      ? value
      : this[chartViewId].fromDate;
    this.updateChartView(chartViewId, {
      fromDate,
      toDate: value,
    });
  };
  onChangeFromHourForView = (chartViewId) => (fromHour) => {
    this.updateChartView(chartViewId, {
      fromHour,
    });
  };
  onChangeToHourForView = (chartViewId) => (toHour) => {
    this.updateChartView(chartViewId, {
      toHour,
    });
  };
  onChangeWeekDaysForView = (chartViewId) => (weekDays) => {
    this.updateChartView(chartViewId, {
      weekDays,
    });
  };

  allowToContinue = (chartViewId) => {
    if (this[chartViewId]) {
      switch (this[chartViewId].wizardCurrentStep) {
        case 0:
          const { fromDate, toDate } = this[chartViewId];
          return fromDate !== null && toDate !== null;
        default:
          return true;
      }
    }
    return false;
  };

  allowToExportData = (chartViewId) => {
    if (this[chartViewId]) {
      const { chartData, type } = this[chartViewId];
      switch (type) {
        case WifiChartType.visitors:
          return false;
        case WifiChartType.hourlyTraffic:
        default:
          return chartData !== null;
      }
    }
    return false;
  };

  disabledStepsForView = (chartViewId) => {
    if (this[chartViewId]) {
      switch (this[chartViewId].wizardCurrentStep) {
        case 0:
          const { fromDate, toDate } = this[chartViewId];
          return fromDate !== null && toDate !== null ? [] : [3];
        default:
          return [];
      }
    }
    return [];
  };

  @action
  getChartDataForView = (
    chartViewId,
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    chartType
  ) => {
    this[chartViewId].isFetching = true;

    const options = {
      hourlyTraffic: false,
      peopleStatistics: false,
      connectionsPerType: false,
    };

    if (chartType === WifiChartType.hourlyTraffic) {
      options.hourlyTraffic = true;
    } else if (chartType === WifiChartType.visitors) {
      options.peopleStatistics = true;
      options.connectionsPerType = true;
    }

    this.requestMetricsWifi(
      fromDate.format("X"),
      toDate.format("X"),
      fromHour,
      toHour,
      weekDays,
      options
    )
      .then(
        action((result) => {
          this[chartViewId].chartData = result;
          this[chartViewId].error = null;
          this[chartViewId].isFetching = false;
        })
      )
      .catch(
        action((error) => {
          if (error && error.statusCode && error.statusCode === 404) {
            this[chartViewId].error =
              "Nenhum resultado para os filtros atuais.";
          }
          this[chartViewId].chartData = null;
          this[chartViewId].isFetching = false;
        })
      );
  };

  requestMetricsWifi = (
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    dataType
  ) => {
    if (!dataType) return;
    const connectionsPerType = dataType.connectionsPerType
      ? dataType.connectionsPerType
      : false;
    const hourlyTraffic = dataType.hourlyTraffic
      ? dataType.hourlyTraffic
      : false;
    const peopleStatistics = dataType.peopleStatistics
      ? dataType.peopleStatistics
      : false;

    return new Promise(async (resolve, reject) => {
      this.mobR2APIService
        .requestMetricsWifi({
          fromDate,
          toDate,
          fromHour,
          toHour,
          weekDays,
          connectionsPerType,
          hourlyTraffic,
          peopleStatistics,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _prepareChartDataToExport = (chartData, chartType) => {
    if (chartType === WifiChartType.hourlyTraffic) {
      const data = chartData.hourly_traffic;
      return Object.keys(data).map((dayWeekString) => {
        const columns = {};
        Object.keys(data[dayWeekString]).forEach((hourString) => {
          columns[hourString] = data[dayWeekString][hourString];
        });
        return {
          ...columns,
          dayOfTheWeek: dayWeekString,
        };
      });
    }
    return null;
  };

  _exportToCsv = (list, fileName) => {
    const csvString = objectListToCsv(list);
    let element = document.createElement("a");
    const file = new Blob([csvString], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.csv`;
    element.click();
  };

  exportChartViewToCsv = (chartViewId) => {
    if (this[chartViewId]) {
      const { chartData, type } = this[chartViewId];
      if (!chartData) return false;

      const chartDataToExport = this._prepareChartDataToExport(chartData, type);
      if (chartDataToExport) {
        this._exportToCsv(chartDataToExport, this.fileNameForChartType(type));
      }
    }
  };
}

export default WifiMetricsReportStore;
