// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

import { theme1 } from "./ChartColors";

const BarChart = ({
  xAxisCategories,
  data,
  height,
  showToolbar,
  customTooltip,
  customOptions,
  customChartOptions,
  stacked,
  horizontal,
}) => {
  const chartData = {
    options: {
      ...theme1,
      plotOptions: {
        bar: {
          horizontal: horizontal,
        },
      },
      xaxis: {
        categories: xAxisCategories,
      },
      chart: {
        stacked: stacked,
        toolbar: {
          show: showToolbar,
        },
        ...customChartOptions,
      },
      tooltip: {
        ...customTooltip,
      },
      ...customOptions,
    },
  };
  return (
    <Chart
      options={chartData.options}
      series={data}
      type="bar"
      height={height}
    />
  );
};

BarChart.defaultProps = {
  showToolbar: false,
  stacked: false,
  horizontal: false,
  customChartOptions: {},
};

BarChart.propTypes = {
  customOptions: PropTypes.object,
  customChartOptions: PropTypes.object,
  customTooltip: PropTypes.object,
};

export default BarChart;
