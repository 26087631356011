// @ts-nocheck
import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import { Grid, Slider } from "@material-ui/core";
import { inject, observer } from "mobx-react";

import Button from "../Button/Button";
import Video from "./Video";
import VideoHelpModal from "./VideoHelpModal";
import CreateOccurrenceModal from "./CreateOccurrenceModal";

import { displayDurationFromSecondsFloat } from "../../utils/HourUtils";

import { KEYCODE } from "../../store/KeyboardEventsStore/KeyboardEventsStore";

const PLAYBACK_RATE_OPTIONS = [1.0, 2.0, 5.0, 10.0];

const styles = {
  timer: {
    display: "inline-block",
    margin: "0 20px",
  },
  cameraGrid: {
    transition: "all 400ms",
    flexBasis: "50%",
  },
  cameraGrid4: {
    height: "46vh",
  },
  selectedCameraGrid: {
    flexBasis: "100%",
    height: "90vh",
  },
  hiddenCameraGrid: {
    flexBasis: 0,
  },
};

const ChangeTimeButton = ({ value, setCurrentTime, disabled, children }) => (
  <Button
    color={"transparent"}
    justIcon
    disabled={disabled}
    onClick={() => setCurrentTime(parseInt(value, 10))}
  >
    {children}
  </Button>
);

const PlaybackRateButton = ({ currentValue, value, setPlaybackRate }) => (
  <Button
    color={currentValue !== value ? "transparent" : "primary"}
    justIcon
    onClick={() => setPlaybackRate(value)}
    style={{ textTransform: "initial" }}
  >
    {value}x
  </Button>
);

@inject("stores")
@observer
class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    let masterIndex = 0;
    props.urlList.every((url, index) => {
      if (
        index === masterIndex &&
        url.includes("joker") &&
        index < props.urlList.length - 1
      ) {
        masterIndex++;
        return true;
      }
      return false;
    });
    this.state = {
      videoSeconds: null,
      currentTime: 0,
      isPlaying: false,
      playbackRate: 1.0,
      selectedCamera: null,
      showHelpPanel: false,
      masterIndex,
    };
  }

  componentDidMount() {
    this.props.stores.keyboardEventsStore.setListener(
      "VideoPlayer",
      this.didNotifyKeyboardKeyPress
    );

    this.setState({
      isPlaying: true,
    });
  }
  componentWillUnmount() {
    this.props.stores.keyboardEventsStore.removeListener("VideoPlayer");
  }

  setVideoSeconds = (value) => {
    this.setState({ videoSeconds: value });
  };
  setPlayedSeconds = (value) => {
    this.props.stores.videoStore.setPlayedSeconds(value);
  };
  setCurrentTime = (value) => {
    this.setState({ currentTime: value });
  };
  setPlaying = (value) => {
    this.setState({ isPlaying: value });
  };
  togglePlaying = () => {
    this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
  };
  setPlaybackRate = (value) => {
    this.setState({ playbackRate: value });
  };
  setSelectedCamera = (value) => {
    this.setState({ selectedCamera: value });
  };

  didOpenOccurrenceModal = () => {
    this.props.stores.occurrencesStore.didOpenOccurrenceModal();
  };

  toggleOccurrenceDrawer = () => {
    this.props.stores.occurrencesStore.toggleOccurrenceDrawer();
  };

  didNotifyKeyboardKeyPress = (event) => {
    if (this.props.disabledKeyboardListener) {
      return false;
    }
    const { ctrlKey, shiftKey, keyCode } = event;

    const { occurrencesStore } = this.props.stores;

    if (occurrencesStore.isOpenOccurrenceModal) {
      if (ctrlKey && keyCode === KEYCODE.S) {
        occurrencesStore.didSaveOccurrence();
      }
      return;
    }

    const { playedSeconds } = this.props.stores.videoStore;

    if (keyCode === KEYCODE.ESC) {
      this.didSelectCamera(null)();
    } else if (keyCode === KEYCODE.ONE) {
      this.didSelectCamera(0)();
    } else if (keyCode === KEYCODE.TWO) {
      this.didSelectCamera(1)();
    } else if (keyCode === KEYCODE.THREE) {
      this.didSelectCamera(2)();
    } else if (keyCode === KEYCODE.FOUR) {
      this.didSelectCamera(3)();
    } else if (keyCode === KEYCODE.P || keyCode === KEYCODE.SPACE) {
      event.preventDefault();
      this.togglePlaying();
    } else if (keyCode === KEYCODE.ARROW_UP) {
      this.didChangePlaybackRateByIndex(true);
    } else if (keyCode === KEYCODE.ARROW_DOWN) {
      this.didChangePlaybackRateByIndex(false);
    } else if (keyCode === KEYCODE.ARROW_LEFT) {
      if (shiftKey) {
        if (playedSeconds > 9) this.setCurrentTime(playedSeconds - 10);
      } else {
        if (playedSeconds > 0) this.setCurrentTime(playedSeconds - 1);
      }
    } else if (keyCode === KEYCODE.ARROW_RIGHT) {
      const newValue = shiftKey ? 10 : 1;
      this.setCurrentTime(playedSeconds + newValue);
    } else if (keyCode === KEYCODE.H) {
      this.toggleHelpPanel();
    } else if (
      this.props.stores.authStore.isDVROperator &&
      keyCode === KEYCODE.E
    ) {
      event.preventDefault();
      this.setPlaying(false);
      this.didOpenOccurrenceModal();
    }
  };

  didChangePlaybackRateByIndex = (isAdding) => {
    const { playbackRate } = this.state;
    let actualSelectedIndex = 0;
    PLAYBACK_RATE_OPTIONS.forEach(($0, index) => {
      if ($0 === playbackRate) {
        actualSelectedIndex = index;
      }
    });
    if (isAdding) {
      if (actualSelectedIndex + 1 < PLAYBACK_RATE_OPTIONS.length) {
        this.setPlaybackRate(PLAYBACK_RATE_OPTIONS[actualSelectedIndex + 1]);
      }
    } else {
      if (actualSelectedIndex > 0) {
        this.setPlaybackRate(PLAYBACK_RATE_OPTIONS[actualSelectedIndex - 1]);
      }
    }
  };

  didSelectCamera = (index) => () => {
    if (this.props.urlList.length > index) {
      this.setSelectedCamera(
        this.state.selectedCamera === index ? null : index
      );
    }
  };

  onSliderChange = (_, value) => {
    const floatValue = parseFloat(value / 100);
    const result = floatValue * this.state.videoSeconds;

    this.setCurrentTime(result);
  };

  onChangePlayedTime = (playedSeconds) => {
    this.setPlayedSeconds(playedSeconds);
  };

  onDurationChange = (seconds) => {
    this.setVideoSeconds(seconds);
  };

  onEnded = () => {
    this.setPlaying(false);
  };

  toggleHelpPanel = () => {
    this.setState((prevState) => {
      return { showHelpPanel: !prevState.showHelpPanel };
    });
  };

  renderChangeTimeButton = (value, isAddition) => {
    const { playedSeconds } = this.props.stores.videoStore;
    return (
      <ChangeTimeButton
        value={isAddition ? playedSeconds + value : playedSeconds - value}
        setCurrentTime={this.setCurrentTime}
      >
        {isAddition ? "+" : "-"}
        {value}
      </ChangeTimeButton>
    );
  };

  renderPlaybackRateButton = (value) => (
    <PlaybackRateButton
      key={value}
      currentValue={this.state.playbackRate}
      value={value}
      setPlaybackRate={this.setPlaybackRate}
    />
  );

  render() {
    const { classes, urlList } = this.props;
    const {
      videoSeconds,
      currentTime,
      isPlaying,
      playbackRate,
      selectedCamera,
    } = this.state;
    const { authStore } = this.props.stores;
    const { playedSeconds } = this.props.stores.videoStore;

    if (!urlList || urlList.length < 1) {
      return null;
    }

    return (
      <Fragment>
        <VideoHelpModal
          isOpen={this.state.showHelpPanel}
          didClose={this.toggleHelpPanel}
        />
        <CreateOccurrenceModal />
        <Grid container>
          {urlList.map((url, index) => {
            const isSelected = urlList.length === 1 || selectedCamera === index;
            return (
              <Grid
                key={index}
                item
                xs={isSelected ? 12 : 6}
                className={classnames({
                  [classes.cameraGrid]: true,
                  [classes.cameraGrid4]: urlList.length >= 3,
                  [classes.selectedCameraGrid]: isSelected,
                  [classes.hiddenCameraGrid]:
                    selectedCamera !== null && !isSelected,
                })}
              >
                <Video
                  url={url}
                  isPlaying={isPlaying}
                  playbackRate={playbackRate}
                  currentTime={currentTime}
                  name={`Câmera ${index + 1}`}
                  shouldNotify={index === this.state.masterIndex}
                  onChangeTime={this.onChangePlayedTime}
                  onDurationChange={this.onDurationChange}
                  onEnded={this.onEnded}
                  didSelect={this.didSelectCamera(index)}
                  isHidden={selectedCamera !== null && !isSelected}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid
          container
          item
          xs={12}
          style={{ padding: "0 8px", marginBottom: 6 }}
        >
          <Slider
            value={(playedSeconds * 100) / videoSeconds}
            onChange={this.onSliderChange}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ padding: "0 16px", marginTop: -12 }}
        >
          <Grid item sm>
            <Button
              color={"transparent"}
              justIcon
              onClick={this.props.didWantToExit}
              style={{ marginRight: 30 }}
            >
              <i className="fas fa-times" />
            </Button>
            <Button
              color={"transparent"}
              justIcon
              onClick={this.togglePlaying}
              style={{ marginRight: 8 }}
            >
              <i className={`fas fa-${isPlaying ? "pause" : "play"}`} />
            </Button>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            sm
            style={{ textAlign: "center" }}
          >
            {this.renderChangeTimeButton(1, false)}
            {this.renderChangeTimeButton(10, false)}
            <p className={classes.timer}>
              {displayDurationFromSecondsFloat(playedSeconds)} /{" "}
              {displayDurationFromSecondsFloat(videoSeconds)}
            </p>
            {this.renderChangeTimeButton(1, true)}
            {this.renderChangeTimeButton(10, true)}
          </Grid>
          <Grid item sm style={{ textAlign: "right" }}>
            {PLAYBACK_RATE_OPTIONS.map(this.renderPlaybackRateButton)}
            <Button
              color="transparent"
              justIcon
              onClick={this.toggleHelpPanel}
              style={{ marginLeft: 30, marginRight: 8 }}
            >
              <i className="fas fa-question-circle" />
            </Button>
            {authStore.isDVROperator && (
              <Button
                color="warning"
                outline
                justIcon
                onClick={this.didOpenOccurrenceModal}
                style={{ marginRight: 8 }}
              >
                <i className="fas fa-map-marker-exclamation" />
              </Button>
            )}
            {(authStore.isDVROperator || authStore.isDVRAnalyst) && (
              <Button
                color="primary"
                outline
                justIcon
                onClick={this.toggleOccurrenceDrawer}
              >
                <i className="fas fa-bars" />
              </Button>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

VideoPlayer.defaultProps = {
  width: "100%",
};

export default withStyles(styles)(VideoPlayer);
