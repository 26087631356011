// @ts-nocheck
import React from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "../Button/Button";

const styles = {
  playerContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#000",
  },
  player: {
    display: "block",
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 100,
    margin: 0,
    transition: "300ms all",
  },
  buttonContainerIsHidden: {
    opacity: 0,
  },
};

class Video extends React.Component {
  state = {
    showButtons: false,
  };

  componentDidMount() {
    if (this.props.shouldNotify) {
      this.player.ontimeupdate = () => {
        if (this.player) this.props.onChangeTime(this.player.currentTime);
      };
      this.player.ondurationchange = () => {
        if (this.player) this.props.onDurationChange(this.player.duration);
      };
      this.player.onended = () => this.props.onEnded();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.playPause(this.props.isPlaying);
    }
    if (prevProps.playbackRate !== this.props.playbackRate) {
      this.setPlaybackRate(this.props.playbackRate);
    }
    if (prevProps.currentTime !== this.props.currentTime) {
      this.player.currentTime = this.props.currentTime;
    }
  }

  playPause = (shouldPlay) => {
    if (shouldPlay) {
      this.player.play();
    } else {
      this.player.pause();
    }
  };

  setPlaybackRate = (playbackRate) => {
    this.player.playbackRate = playbackRate;
  };

  didShowButtons = () => {
    this.setState({ showButtons: true });
  };
  didHideButtons = () => {
    this.setState({ showButtons: false });
  };
  render() {
    const { classes, url, isHidden } = this.props;
    const { showButtons } = this.state;

    return (
      <div
        className={classes.playerContainer}
        onMouseEnter={this.didShowButtons}
        onMouseLeave={this.didHideButtons}
        onClick={this.props.didSelect}
      >
        <div
          className={classnames({
            [classes.buttonContainer]: true,
            [classes.buttonContainerIsHidden]: isHidden || !showButtons,
          })}
        >
          <Button
            color="primary"
            justIcon
            small
            href={url}
            download
            target="_blank"
          >
            <i className="fas fa-cloud-download" />
          </Button>
        </div>
        <video
          ref={(player) => (this.player = player)}
          muted
          className={classes.player}
        >
          <source src={url} type="video/mp4" />
        </video>
      </div>
    );
  }
}

export default withStyles(styles)(Video);
