// @ts-nocheck
import React from "react";
import animationData from "./EmptyStatus.json";
import LottieAnimation from "../LottieAnimation";

const EmptyStatus = ({ height }) => (
  <LottieAnimation animationData={animationData} height={height} />
);

export default EmptyStatus;
