// @ts-nocheck
import React from "react";

import EmptyStatus from "./EmptyStatus/EmptyStatus";
import DrinkMachine from "./DrinkMachine/DrinkMachine";
import SquareDropLoader from "./SquareDropLoader/SquareDropLoader";

const RandomAnimation = ({ height }) => {
  const animations = [
    <EmptyStatus height={height} />,
    <DrinkMachine height={height} />,
    <SquareDropLoader height={height} />,
  ];

  const randomIndex = Math.floor(Math.random() * animations.length);

  return animations[randomIndex];
};

RandomAnimation.defaultProps = {
  height: 200,
};

export default RandomAnimation;
