// @ts-nocheck
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import { InputText } from "../Form";
import Map from "../Map/Map";

import { GridContainer, GridItem } from "../Grid";

// Style
const styles = {};

class InputMap extends React.Component {
  onChange = ({ event, latLng, pixel }) => {
    this.props.onChange(this.props.name, {
      latitude: `${parseFloat(latLng[0].toFixed(6))}`,
      longitude: `${parseFloat(latLng[1].toFixed(6))}`,
    });
  };

  onChangeInputText = ({ target }) => {
    const { name, value } = target;

    this.props.onChange(this.props.name, {
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      [name]: value,
    });
  };

  render() {
    const { latitude, longitude } = this.props;
    const floatLatitude = parseFloat(latitude);
    const floatLongitude = parseFloat(longitude);
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <InputText
                label="latitude"
                name="latitude"
                value={latitude}
                onChange={this.onChangeInputText}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputText
                label="longitude"
                name="longitude"
                value={longitude}
                onChange={this.onChangeInputText}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <Map
            latitude={floatLatitude}
            longitude={floatLongitude}
            metaWheelZoom
            zoom={14}
            height={300}
            onClick={this.onChange}
            markers={[
              {
                key: `${latitude}.${longitude}`,
                latitude: floatLatitude,
                longitude: floatLongitude,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(InputMap);
