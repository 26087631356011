// @ts-nocheck
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Select from "react-select";

// Components
import { InputText, InputSwitch } from "../Form";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import Map from "../Map/Map";
import DNDSelectStopsContainer from "./lineConfigurationForm/DNDSelectStopsContainer";

import withStyles from "@material-ui/core/styles/withStyles";

import { listObjectWithUniqueValues } from "../../utils/ArrayUtils";

import { APP_ROUTES } from "../../routes/app-routes";

// Translate
import { CancelString, FormErrorString } from "../I18n/CommonStrings";

// Style
const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
};

@inject("stores")
@observer
class LineConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.match.params.lineToken) {
      const line = props.stores.linesStore.getWithToken(
        props.match.params.lineToken
      );
      if (!line) {
        this.state = {
          redirect: true,
        };
      } else {
        this.state = {
          ...this.getStateFromExistingLine(line),
          token: line.token,
          isEditing: true,
        };
      }
    } else {
      this.state = this.getDefaultState();
    }
  }

  componentDidMount() {
    this.props.stores.stopsStore.getDataList();
  }

  getDefaultState = () => {
    return {
      name: "",
      stopsList: [],
      isRandom: false,
      isWaitingForm: false,
      formWasSubmit: false,
      modalIsOpen: false,
      wasEdited: false,
      isEditing: false,
      redirect: false,
      isValid: {
        name: false,
      },
    };
  };

  getStateFromExistingLine = (line) => {
    return {
      ...this.getDefaultState(),
      name: line.name,
      stopsList: line.stopsList,
      isRandom: line.isRandom,
      isValid: {
        name: line.name.length > 1,
      },
    };
  };

  onChangeInputText = (event) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  onChangeIsRandom = () => {
    this.setState((prevState) => ({
      isRandom: !prevState.isRandom,
      wasEdited: true,
    }));
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    const { name } = isValid;
    if (name) {
      return true;
    }

    return false;
  };

  getFormDataToSubmit = ({ name, stopsList, isRandom }) => ({
    name,
    stopsList: isRandom
      ? listObjectWithUniqueValues({
          list: stopsList,
          keyToCompare: "token",
        })
      : stopsList,
    isRandom,
  });

  didSelectStop = (stop) => {
    this.setState((prevState) => ({
      stopsList: [...prevState.stopsList, stop],
      wasEdited: true,
    }));
  };

  didSelectStops = (stopsList) => {
    this.setState({
      stopsList,
      wasEdited: true,
    });
  };

  submitForm = () => {
    const { stores } = this.props;
    const { isEditing } = this.state;
    const formData = {
      ...this.getFormDataToSubmit(this.state),
    };

    if (isEditing) {
      stores.linesStore
        .update({ ...formData, token: this.state.token })
        .then(() => {
          this.submitHasSuccess();
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      stores.linesStore
        .submit(formData)
        .then((_) => {
          this.submitHasSuccess();
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.history.goBack();
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.stores.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => {
    this.props.history.goBack();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  didRemoveStop = (index) => () => {
    this.setState((prevState) => ({
      stopsList: prevState.stopsList.filter(
        (_, stopIndex) => stopIndex !== index
      ),
      wasEdited: true,
    }));
  };

  didReorderStopsList = (reordenedList) => {
    this.setState({
      stopsList: reordenedList,
      wasEdited: true,
    });
  };

  renderRandomSelectStopsList = (dataList, stopsList) =>
    this.renderStopSelector({
      options: dataList,
      value: listObjectWithUniqueValues({
        list: stopsList,
        keyToCompare: "token",
      }),
      placeholder: "Selecione as paradas desta linha...",
      isMultiple: true,
    });

  renderDNDSelectStopList = (dataList, stopsList) => {
    return (
      <Fragment>
        <p>Ordene os pontos na sequencia de parada (IDA e VOLTA)</p>
        <DNDSelectStopsContainer
          stopsList={stopsList}
          didRemoveStop={this.didRemoveStop}
          didReorderStopsList={this.didReorderStopsList}
        />
        {this.renderStopSelector({
          options: dataList,
          value: null,
          placeholder: "Adicionar parada",
          isMultiple: false,
        })}
      </Fragment>
    );
  };

  renderStopSelector = ({ options, value, placeholder, isMultiple }) => {
    return (
      <Select
        getOptionValue={({ token }) => token}
        getOptionLabel={({ name }) => name}
        value={value}
        placeholder={placeholder}
        options={options}
        noOptionsMessage={() => "Nenhuma parada encontrada"}
        onChange={isMultiple ? this.didSelectStops : this.didSelectStop}
        isClearable={isMultiple}
        isMulti={isMultiple}
        closeMenuOnSelect={!isMultiple}
      />
    );
  };

  renderStopsList = () => {
    const { dataList } = this.props.stores.stopsStore;
    const { stopsList, isRandom } = this.state;

    return (
      <GridContainer style={{ marginBottom: 20 }}>
        <GridItem xs={12}>
          {isRandom
            ? this.renderRandomSelectStopsList(dataList, stopsList)
            : this.renderDNDSelectStopList(dataList, stopsList)}
        </GridItem>
      </GridContainer>
    );
  };

  didSelectStopOnMap = ({ value }) => {
    const stop = this.props.stores.stopsStore.dataList.find(
      ($0) => $0.token === value
    );
    if (stop) {
      this.didSelectStop(stop);
    }
  };

  renderMap = () => {
    const { dataList } = this.props.stores.stopsStore;
    const { stopsList } = this.state;

    if (dataList.length < 1) return null;

    return (
      <Map
        latitude={-15.799889}
        longitude={-47.887267}
        zoom={12}
        height={600}
        metaWheelZoom
        markers={dataList.map(({ latitude, longitude, token }) => ({
          key: token,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          isSelected: stopsList.find(($0) => $0.token === token),
          onClick: this.didSelectStopOnMap,
        }))}
      />
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={APP_ROUTES.LINES} />;
    }

    const { classes } = this.props;

    const { name, isRandom, formWasSubmit, isValid, isWaitingForm, isEditing } =
      this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={7} md={8}>
          <GridContainer>
            <GridItem xs={12}>
              <h3 className={classes.title}>
                {isEditing ? "Editar" : "Adicionar"} linha
              </h3>
            </GridItem>
            <GridItem xs={12}>
              <InputText
                label="Nome"
                name="name"
                isRequired
                hasError={formWasSubmit && !isValid.name}
                hasSuccess={formWasSubmit && isValid.name}
                value={name}
                onChange={this.onChangeInputText}
                minLength={2}
              />
            </GridItem>
            <GridItem xs={12}>
              <InputSwitch
                label="Gerenciar paradas automaticamente"
                name="isRandom"
                isActive={isRandom}
                onChange={this.onChangeIsRandom}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>{this.renderMap()}</GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={5} md={4}>
          {this.renderStopsList()}
        </GridItem>
        <GridItem xs={12}>
          <GridContainer justify="space-between">
            <GridItem xs={12} sm={4}>
              <Button block onClick={this.didCancel}>
                <i className="fas fa-chevron-left" /> <CancelString />
              </Button>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Button
                color="primary"
                block
                disabled={!this.state.wasEdited}
                isLoading={isWaitingForm}
                onClick={this.onSubmit}
              >
                {isEditing ? "Atualizar" : "Salvar"}{" "}
                <i className="fas fa-save" />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(LineConfigurationForm);
