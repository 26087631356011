// @ts-nocheck
import { secondsToMinutesAndHours } from "../utils/DateUtils";

export const JOB_SCHEDULE_STATUS = Object.freeze({
  NOT_FOUND: "notfound",
  SCHEDULED: "scheduled",
  READY: "ready",
  WATCHING: "watching",
  VIEWED: "viewed",
  FINISHED: "finished",
});

export const JOB_SCHEDULE_RULES = Object.freeze({
  OPERATOR: "operator",
  GARAGE_MANAGER: "garage_manager",
  SEMOB: "semob",
});

export const titleForStatus = (status) => {
  switch (status) {
    case JOB_SCHEDULE_STATUS.NOT_FOUND:
      return "Indisponível";
    case JOB_SCHEDULE_STATUS.SCHEDULED:
      return "Processando";
    case JOB_SCHEDULE_STATUS.READY:
      return "Disponível para assistir";

    case JOB_SCHEDULE_STATUS.VIEWED:
      return "Disponível p/ Analista";

    case JOB_SCHEDULE_STATUS.WATCHING:
      return "Bloqueado por Operador";

    case JOB_SCHEDULE_STATUS.FINISHED:
      return "Finalizado";
  }
};
export const titleForRules = (status) => {
  switch (status) {
    case JOB_SCHEDULE_RULES.OPERATOR:
      return "Operador";
    case JOB_SCHEDULE_RULES.GARAGE_MANAGER:
      return "Gerente de garagem";
    case JOB_SCHEDULE_RULES.SEMOB:
      return "Semob";
  }
};

class JobScheduleModel {
  token;
  status;
  analyst;
  operator;
  supervisor;
  vehicle;
  dateCreate;
  dateViewed;
  fromDate;
  toDate;
  cams;
  totalEvents;
  viewedTime;

  constructor(data, cftvUrl: string) {
    this.token = data.token;
    this.status = data.status;
    this.analyst = data.analyst || null;
    this.operator = data.operator || null;
    this.supervisor = data.supervisor || null;
    this.vehicle = data.bus;
    this.dateCreate = data.date_create;
    this.dateViewed = data.date_viewed;
    this.fromDate = data.date_ini;
    this.toDate = data.date_end;
    this.cams =
      cftvUrl && Array.isArray(data.cams)
        ? data.cams.map((file) => `${cftvUrl}/files/${file}`)
        : [];
    this.totalEvents = data.total_events;
    this.viewedTime =
      data.viewed_time && data.viewed_time > 0
        ? secondsToMinutesAndHours(data.viewed_time)
        : "-";
  }
}

export default JobScheduleModel;
