// @ts-nocheck
import { SERVER_URL } from "../utils/EnvUtils";

export const VIDEO_STATUS = Object.freeze({
  PROCESSING: 0, // Processando pedido
  WAITING: 1, // Aguardando coleta (quando não encontra arquivos dentro do periodo na tabela stats_collect)
  GENERATING: 2, // Gerando video
  READY: 3, // Video pronto
  CANCELED: 4, // Pedido cancelado (acao do usuario ou automatico - anda nao implementado)
  FAILED: 5, // Falha na geracao (nome do arquivo existe na tabela status_collect, mas não existe no disco)
});

class VideoModel {
  camera;
  dateFinished;
  duration;
  fileMd5;
  status;
  token;

  constructor(video) {
    this.camera = video.camera;
    this.dateFinished = video.date_finished;
    this.duration = video.duration;
    this.fileMd5 = video.file_md5
      ? `https://${SERVER_URL}/files/${video.file_md5}.mp4`
      : null;
    this.status = video.status;
    this.token = video.token_video;
  }
}

export default VideoModel;
