import { observable, action } from "mobx";

// Model
import ContractModel from "../../Model/ContractModel";

import MobR2APIService from "../../services/MobR2APIService";

const SESSION_STORAGE_KEY = "mobr2_selectedContract";

class ContractStore {
  mobr2APIService: MobR2APIService;

  @observable contractsList: ContractModel[] = [];
  @observable isFetching = false;
  @observable selectedContract?: ContractModel = null;
  @observable isOutOfPlace = false;

  constructor(mobr2APIService: MobR2APIService) {
    this.mobr2APIService = mobr2APIService;
  }

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };

  @action
  setContractsList = (newValue: ContractModel[]) => {
    this.contractsList = newValue;
  };

  @action
  setSelectedContract = (newValue: ContractModel | null) => {
    this.selectedContract = newValue;

    if (newValue) {
      window.sessionStorage.setItem(SESSION_STORAGE_KEY, newValue.hash);
    } else {
      window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
    }
  };

  @action
  setIsOutOfPlace = (newValue: boolean) => {
    this.isOutOfPlace = newValue;
  };

  didSelectContractWithHash = (contractHash: string) => {
    const contract = this.contractsList.find(
      ({ hash }) => hash === contractHash
    );
    if (contract) {
      this.setSelectedContract(contract);
    }
  };

  didWantToRemoveSelectedContract = () => {
    if (this.selectedContract && this.contractsList.length > 1) {
      this.setSelectedContract(null);
    }
  };

  getContractsList = async () => {
    this.setIsFetching(true);
    let newContractsList: ContractModel[] = [];

    const selectedContractHashFromSession =
      window.sessionStorage.getItem(SESSION_STORAGE_KEY);

    try {
      const response: any = await this.mobr2APIService.requestContracts();

      if (response.status === 200) {
        response.data[0].contracts.forEach((contractData) => {
          const newContract = new ContractModel(contractData);
          newContractsList.push(newContract);
        });

        this.setContractsList(newContractsList);

        // Set selected if only one contract
        if (this.contractsList.length === 1) {
          this.didSelectContractWithHash(this.contractsList[0].hash);
        }
        // Try to select from sessionStorage
        else if (selectedContractHashFromSession) {
          this.didSelectContractWithHash(selectedContractHashFromSession);
        }

        this.setIsFetching(false);
      }
    } catch (error) {
      window.debugError("Error in getContractsList", error);
      // @ts-ignore
      if (error?.statusCode === 403) {
        this.clearStore();
        this.setIsOutOfPlace(true);
      }
    }
  };

  clearStore = () => {
    this.setIsFetching(false);
    this.setContractsList([]);
    this.setSelectedContract(null);
  };
}

export default ContractStore;
