// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Fade } from "react-reveal";
import withStyles from "@material-ui/core/styles/withStyles";

import PageTitle from "../components/PageTitle/PageTitle";
import { withLocalized } from "../components/HOC";
import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardHeader, CardText, CardBody } from "../components/Card";
import { Button } from "../components/Button";

// Style
import { cardTitle } from "../assets/jss/app-js-styles";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
};

@withLocalized
@inject("stores")
@observer
class SelectContractLayout extends Component {
  didSelectContract = (selectedContract) => {
    this.props.stores.contractStore.didSelectContractWithHash(
      selectedContract.hash
    );
  };

  isOutOfPlace = this.props.stores.contractStore.isOutOfPlace;

  render() {
    const { contractStore } = this.props.stores;
    const { contractsList, isOutOfPlace } = contractStore;

    return (
      <Fragment>
        <PageTitle>Selecione uma empresa</PageTitle>
        <GridContainer
          justify="center"
          direction="row"
          style={{ height: "100vh" }}
        >
          <GridItem xs={11} sm={10} md={6}>
            <Fade>
              {contractsList.length > 0 ? (
                <Card>
                  <CardHeader color="primary" text>
                    <CardText color="primary">
                      <h4 className={this.props.classes.cardTitleWhite}>
                        Selecione uma empresa
                      </h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <Select
                      value={null}
                      placeholder={"selecione uma empresa..."}
                      options={contractsList}
                      autoFocus={true}
                      noOptionsMessage={() => "Nenhum contrato encontrado"}
                      onChange={this.didSelectContract}
                      isClearable={false}
                    />
                  </CardBody>
                </Card>
              ) : (
                <GridContainer
                  justify="center"
                  alignItems="center"
                  style={{ minHeight: "100vh" }}
                >
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    <h4>
                      {isOutOfPlace
                        ? "Não é possível acessar fora do seu local de serviço"
                        : "Você não tem permissão para acessar nenhuma empresa."}
                    </h4>

                    <Button
                      color="primary"
                      small
                      onClick={this.props.stores.authStore.logout}
                    >
                      Voltar ao Login
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </Fade>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

SelectContractLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectContractLayout);
