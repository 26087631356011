// @ts-nocheck
import { observable, action, computed } from "mobx";

import VideoRequestOrderModel from "../../Model/VideoRequestOrderModel";
import VideoModel from "../../Model/VideoModel";

// Utils Stores
import { DID_LOGOUT } from "../InternalEventsStore/InternalEventsStore";

class VideoStore {
  @observable videosList = [];
  @observable isFetching = false;
  @observable urlListToWatch = null;

  @observable playedSeconds = 0;

  constructor(internalEventsStore, notificationsStore, mobR2APIService) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.videosList = [];
    this.urlListToWatch = null;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setPlayedSeconds = (newValue) => {
    this.playedSeconds = newValue;
  };

  @action
  setUrlListToWatch = (urlList) => {
    this.urlListToWatch = urlList;
  };

  @computed
  get shouldOpenPlayer() {
    return this.urlListToWatch && this.urlListToWatch.length > 0;
  }

  submitVideoOrder = async ({ selectedTerminal, fromDate, toDate }) => {
    try {
      const response = await this.mobR2APIService.submitVideoOrder({
        terminalToken: selectedTerminal.token,
        fromDate,
        toDate,
      });
      this.requestVideos();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.success,
        color: "success",
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  @action
  setVideosList = (list) => {
    this.videosList = list;
  };

  requestVideos = async () => {
    this.setIsFetching(true);
    const orders = {};
    try {
      const response = await this.mobR2APIService.requestVideos();
      if (response.status === 200) {
        response.data.videos.forEach(
          ({
            token_order,
            status,
            date_ini,
            date_end,
            date_order,
            date_finished,
            camera,
            duration,
            file_md5,
            token_video,
            terminal_name,
          }) => {
            if (!orders[token_order]) {
              orders[token_order] = new VideoRequestOrderModel({
                token_order,
                terminal_name,
                date_order,
                date_ini,
                date_end,
              });
            }
            orders[token_order][`cam${camera}`] = new VideoModel({
              status,
              file_md5,
              token_video,
              duration,
              date_finished,
              camera,
            });
          }
        );
      }
    } catch (error) {
      console.error("error in requestVideos", error);
    } finally {
      this.setVideosList(Object.values(orders));
      this.setIsFetching(false);
    }
  };

  delete = async (token) => {
    try {
      const response = await this.mobR2APIService.deleteVideoOrder(token);
      this.requestVideos();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "danger",
      });
    } catch (error) {
      console.error("Error in deleteVideoOrder", error);
    }
  };
}

export default VideoStore;
