// @ts-nocheck
import React, { Component } from "react";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

import { appRoutesForCurrentUser } from "../routes/app-routes";
import { getPageComponentForRoutePath } from "../routes/PageComponentForRoute";

import baseLayoutStyle from "../assets/jss/js-styles/layouts/baseLayoutStyle";

import { APP_ROUTES } from "../routes/app-routes";

@inject("stores")
@observer
class BaseLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileDrawerOpen: false,
    };
  }

  componentDidMount() {
    autorun(() => {
      if (
        !this.props.location.pathname.includes(APP_ROUTES.WATCHING) &&
        this.props.stores.videoStore.shouldOpenPlayer
      ) {
        this.props.history.push(APP_ROUTES.WATCHING);
      }
    });
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.state.isMobileDrawerOpen) {
        this.setState({ isMobileDrawerOpen: false });
      }
    }
  }

  handleMobileDrawerToggle = () => {
    this.setState({ isMobileDrawerOpen: !this.state.isMobileDrawerOpen });
  };

  renderRoute = (route) => {
    const { path } = route;
    const Component = getPageComponentForRoutePath(path);

    if (!Component) {
      return <Redirect key={path} from={path} to="/" />;
    }

    return (
      <Route
        key={path}
        path={path}
        render={(props) => (
          <Fade>
            <Component {...props} />
          </Fade>
        )}
      />
    );
  };

  fromRedirect = () => {
    const urlSearch = this.props.location.search;

    if (urlSearch.includes("?from=")) {
      return <Redirect from="/" to={urlSearch.replace("?from=", "")} />;
    }
    return null;
  };

  render() {
    const { classes, stores, ...rest } = this.props;
    const { authStore, contractStore } = stores;
    const { currentUser } = authStore;
    const { selectedContract } = contractStore;

    const appRoutes = appRoutesForCurrentUser(selectedContract.rules);
    const menuRoutes = appRoutes.filter((route) => route.showOnMenu);

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={menuRoutes}
          currentUser={currentUser}
          handleMobileDrawerToggle={this.handleMobileDrawerToggle}
          isMobileDrawerOpen={this.state.isMobileDrawerOpen}
          {...rest}
        />
        <div className={classes.mainPanel}>
          <Header
            routes={menuRoutes}
            handleMobileDrawerToggle={this.handleMobileDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <Switch>
              {this.fromRedirect()}
              {appRoutes.map((route) => {
                if (route.redirect) {
                  return (
                    <Redirect
                      key={route.from}
                      from={route.from}
                      to={route.to}
                    />
                  );
                }
                if (route.collapse) {
                  return route.submenus.map((submenu) =>
                    this.renderRoute(submenu)
                  );
                }

                return this.renderRoute(route);
              })}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

BaseLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(baseLayoutStyle)(BaseLayout);
