// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

import Table from "../../components/Table/Table";
import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import PhoneWithSMSLink from "../../components/Phone/PhoneWithSMSLink";

// Translate
import Plural from "../../components/I18n/Plural";

// Icons
import WifiIcon from "@material-ui/icons/Wifi";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";

const WifiPhonesTodayChartWidget = observer(({ classes, phonesList }) => (
  <Card className={classes.chartCard}>
    <CardHeader color="black" icon>
      <CardIcon color="black">
        <WifiIcon />
      </CardIcon>
      <h4 className={classes.cardIconTitle}>
        <Plural
          id="ConnectivityBI@wifiPhonesTodayListTitle"
          value={phonesList.length}
          zero="Hoje um número acessou sua Wi-Fi"
          one="Hoje um número acessou sua Wi-Fi"
          other="Hoje # números acessaram sua Wi-Fi"
        />
      </h4>
    </CardHeader>
    <CardBody>
      <Table
        data={phonesList.map((phone) => ({
          identifier: (
            <span style={{ fontSize: "1.1em" }}>
              <PhoneWithSMSLink>{phone}</PhoneWithSMSLink>
            </span>
          ),
        }))}
        columns={[
          {
            Header: "",
            sortable: false,
            accessor: "identifier",
            getHeaderProps: () => {
              return {
                style: {
                  textAlign: "left",
                },
              };
            },
          },
        ]}
      />
    </CardBody>
  </Card>
));
export default withStyles(chartsStyle)(WifiPhonesTodayChartWidget);
