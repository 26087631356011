// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import BarChart from "../../components/Charts/BarChart";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

// Translate
import Translate from "../../components/I18n/Translate";

// Icons
import WifiIcon from "@material-ui/icons/Wifi";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";

const WifiConnectionsByPeriodChartWidget = observer(
  ({ classes, localizedString, chartData }) => (
    <Card className={classes.chartCard}>
      <CardHeader color="black" icon>
        <CardIcon color="black">
          <WifiIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <Translate id="ConnectivityBI@wifiConnectionsByPeriodChartTitle">
            Dispositivos únicos por período
          </Translate>
        </h4>
      </CardHeader>
      <CardBody>
        <BarChart
          stacked
          height={400}
          xAxisCategories={[
            localizedString({
              id: "Common@yesterday",
              defaultString: "Ontem",
            }),
            localizedString(
              {
                id: "ConnectivityBI@lastDaysWithVariable",
                defaultString: "Últimos $days dias",
              },
              { days: "7" }
            ),
            localizedString(
              {
                id: "ConnectivityBI@lastDaysWithVariable",
                defaultString: "Últimos $days dias",
              },
              { days: "30" }
            ),
          ]}
          data={chartData}
        />
      </CardBody>
    </Card>
  )
);
export default withLocalized(
  withStyles(chartsStyle)(WifiConnectionsByPeriodChartWidget)
);
