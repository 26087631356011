import { action, computed, observable } from "mobx";
import {
  ContractRuleDvr,
  ContractRules,
  ContractRuleSys,
  CONTRACT_DEFAULT_RULES,
} from "../../Model/ContractModel";
import UserModel from "../../Model/UserModel";
import MobR2APIService from "../../services/MobR2APIService";
import ContractStore from "../ContractStore/ContractStore";
import I18nStore from "../I18nStore/I18nStore";
// Utils Stores
import InternalEventsStore, {
  DID_LOGOUT,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";

const kCurrentUserKey = "k_mobr2_current_user_token";

type RuleKey = keyof ContractRuleSys | keyof ContractRuleDvr;
type ContractRulesKey = keyof ContractRules;

class AuthStore {
  contractStore: ContractStore;
  i18nStore: I18nStore;
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;

  @observable
  isFetching = false;
  @observable
  currentUser = null;

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService
  ) {
    this.internalEventsStore = internalEventsStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    const savedUserToken = window.localStorage.getItem(kCurrentUserKey);

    if (savedUserToken) {
      this.tryToLoginWithSavedUserToken(savedUserToken);
    }
  }

  @action
  setIsFetching = (isFetching) => {
    this.isFetching = isFetching;
  };
  @action
  setCurrentUser = (currentUser) => {
    this.currentUser = currentUser;
    this.setIsFetching(false);
  };

  @computed
  get rules(): ContractRules {
    return (
      this.contractStore?.selectedContract?.rules || CONTRACT_DEFAULT_RULES
    );
  }

  @computed
  get isAuthenticated(): boolean {
    return this.currentUser !== null;
  }

  @computed
  get hasAccessToSystem(): boolean {
    if (this.isAuthenticated) {
      return Object.values(this.rules.sys).filter((value) => value).length > 0;
    }

    return false;
  }
  @computed
  get hasAccessToDVR(): boolean {
    if (this.isAuthenticated) {
      return Object.values(this.rules.dvr).filter((value) => value).length > 0;
    }

    return false;
  }
  @computed
  get isDVRAdmin(): boolean {
    return this.hasAccessOnlyTo("dvr", "admin");
  }
  @computed
  get isDVRSupervisor(): boolean {
    return this.hasAccessOnlyTo("dvr", "supervisor");
  }
  @computed
  get isDVRGarageManager(): boolean {
    if (this.isDVRAdmin || this.isDVRSupervisor) {
      return false;
    }
    return this.hasAccessOnlyTo("dvr", "garage_manager");
  }
  @computed
  get isDVRSemob(): boolean {
    if (this.isDVRAdmin || this.isDVRSupervisor) {
      return false;
    }
    return this.hasAccessOnlyTo("dvr", "semob");
  }
  @computed
  get isDVROperator(): boolean {
    if (
      this.isDVRAdmin ||
      this.isDVRSupervisor ||
      this.isDVRGarageManager ||
      this.isDVRSemob ||
      this.isDVRAnalyst
    ) {
      return false;
    }
    return this.hasAccessOnlyTo("dvr", "operator");
  }
  @computed
  get isDVRAnalyst(): boolean {
    return this.hasAccessOnlyTo("dvr", "analyst");
  }

  @computed
  get currentUserToken(): string | null {
    return this.isAuthenticated ? this.currentUser.token : null;
  }

  hasAccessOnlyTo = (category: ContractRulesKey, rule: RuleKey) => {
    if (this.isAuthenticated) {
      return this.rules[category][rule];
    }

    return false;
  };

  hasAccessTo = (category: ContractRulesKey, rule: RuleKey) => {
    if (this.isAuthenticated) {
      return this.rules[category].admin || this.rules[category][rule];
    }

    return false;
  };

  tryToLoginWithSavedUserToken = async (userToken: string) => {
    this.setIsFetching(true);
    const user = await this.getCurrentUserProfile(userToken);
    this.setCurrentUser(user);
  };

  didLogin = (user) => {
    window.localStorage.setItem(kCurrentUserKey, user.token);
    this.setCurrentUser(user);
  };

  getCurrentUserProfile = async (userToken: string) => {
    try {
      const { data } = (await this.mobR2APIService.requestUser(
        userToken
      )) as any;

      const user = new UserModel(data);
      return user;
    } catch (error) {
      console.error("error in requestUser", error);
      // Clear saved user token
      window.localStorage.removeItem(kCurrentUserKey);
      return null;
    }
  };

  submitLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const { status, data } = (await this.mobR2APIService.submitLogin({
        email,
        password,
      })) as any;
      if (status === 200) {
        const user = await this.getCurrentUserProfile(data.user_token);
        this.didLogin(user);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error("error in submitLogin", error);
      return Promise.reject();
    }
  };

  logout = (force = false) => {
    // Clear saved user token
    window.localStorage.removeItem(kCurrentUserKey);

    if (force) {
      window.location.href = "/login";
    }

    if (this.isAuthenticated) {
      this.internalEventsStore.notify(DID_LOGOUT);
      this.setCurrentUser(null);
    }
  };

  recoverPassword = (_: string) => {
    return Promise.reject();
  };
}

export default AuthStore;
