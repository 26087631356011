import { action, computed, observable, reaction } from "mobx";

// Utils Stores
import InternalEventsStore, {
  DID_LOGOUT,
} from "../InternalEventsStore/InternalEventsStore";

// Utils
import moment from "moment";
import MobR2APIService from "../../services/MobR2APIService";
import {
  endOfTheDayMoment,
  secondsToMinutesAndHours,
  getLastWeekMoment,
  stringDateFromUnixDate,
} from "../../utils/DateUtils";
import UsersStore from "../UsersStore/UsersStore";

type ViewMode = "global" | "individual" | null;

class CFTVReportsStore {
  private readonly userStore: UsersStore;
  private readonly mobR2APIService: MobR2APIService;

  @observable fromDate = getLastWeekMoment();
  @observable toDate = endOfTheDayMoment();
  @observable rule: "operator" | "analyst" = "operator";
  @observable garage: string | null = "";
  @observable selectedUserToken: string | null = null;
  @observable private viewMode: ViewMode = null;

  @observable isFetching = false;
  @observable searchString = "";
  @observable reportData: Record<string, any> | null = null;

  constructor(
    internalEventsStore: InternalEventsStore,
    userStore: UsersStore,
    mobR2APIService: MobR2APIService
  ) {
    this.userStore = userStore;
    this.mobR2APIService = mobR2APIService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });

    this.setReactions();
  }

  @action
  setReportData = (value: Record<string, any> | null) => {
    this.reportData = value;
  };
  @action
  setSearchString = (newValue: string) => {
    this.searchString = newValue;
  };
  @action
  private setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setFromDate = (newValue: moment.Moment) => {
    this.setIsFetching(true);
    this.fromDate = newValue;
  };
  @action
  setToDate = (newValue: moment.Moment) => {
    this.setIsFetching(true);
    this.toDate = newValue;
  };
  @action
  setRule = (newValue: "operator" | "analyst") => {
    this.setIsFetching(true);
    this.rule = newValue;
  };
  @action
  setGarage = (newValue: string | null) => {
    if (newValue) {
      this.setIsFetching(true);
    }
    this.garage = newValue;
  };
  @action
  setSelectedUserToken = (newValue: string | null) => {
    if (newValue) {
      this.setIsFetching(true);
    }
    this.selectedUserToken = newValue;
  };
  @action setViewMode = (newValue: ViewMode) => {
    this.viewMode = newValue;
  };

  @computed
  get getGlobalReportData() {
    if (!this.reportData) {
      return null;
    }

    const { total } = this.reportData;
    const watched = total?.watched_videos || 0;
    const totalReady = total?.total_ready_videos || 0;

    return {
      occurrences: total?.occurrences || 0,
      watched,
      total: totalReady,
      available: totalReady - watched,
      rule: this.rule,
    };
  }

  @computed
  get getOccurrencesCategories() {
    if (!this.reportData) {
      return null;
    }

    const category = this.reportData.categories?.map(
      ({ category }) => category
    );

    return {
      category,
      occurrencesCategories: this.reportData.categories,
    };
  }

  @computed
  get getUserReportData() {
    if (!this.reportData?.users?.length) {
      return null;
    }

    const data = this.reportData?.users[0];

    return {
      occurrences: data.occurrences,
      watchedVideos: data.watched_videos,
      watchedHours: data.watched_hours,
      watchedHoursMean: data.watched_hours_mean,
    };
  }

  @computed
  get getUserSchedulesData() {
    if (!this.reportData?.schedules?.length) {
      return null;
    }

    const userData = this.reportData.schedules;

    const commonData = userData.map((item) => {
      return {
        vehicle: item.bus_name,
        occurrences: item.total_events,
        endWatching: stringDateFromUnixDate(item.date_viewed, "DD/MM/YY HH:mm"),
        startWatching: stringDateFromUnixDate(
          item.date_watching,
          "DD/MM/YY HH:mm"
        ),
        requisition: stringDateFromUnixDate(item.date_create),
        watchedTime: secondsToMinutesAndHours(item.total_watched_seconds),
        videoTime: secondsToMinutesAndHours(item.total_ready_seconds),
        analystVideo: stringDateFromUnixDate(
          item.date_finished,
          "DD/MM/YY HH:mm"
        ),
      };
    });

    // const filteredData = userData.filter(
    //   (filteredItem) => filteredItem.total_events > 0
    // );
    const filteredDataByOccurrences = userData.map((item) => {
      return {
        vehicle: item.bus_name,
        occurrences: item.total_events,
        analystVideo: stringDateFromUnixDate(
          item.date_finished,
          "DD/MM/YY HH:mm"
        ),
        endWatching: stringDateFromUnixDate(item.date_viewed, "DD/MM/YY HH:mm"),
      };
    });

    const secondsVideosAvailable = userData.reduce(
      (accumulator, current) =>
        accumulator + (current.total_ready_seconds || 0),
      0
    );
    const hoursVideosAvailable = secondsVideosAvailable / 60 / 60;

    return { commonData, hoursVideosAvailable, filteredDataByOccurrences };
  }

  @computed
  get getRuleToRequestAPI() {
    if (!this.selectedUserToken) {
      return this.rule;
    }
    if (this.userStore.userIsDvrAnalyst(this.selectedUserToken)) {
      return "analyst";
    }
    if (this.userStore.userIsDvrOperator(this.selectedUserToken)) {
      return "operator";
    }
    return null;
  }

  @computed
  get isGlobalViewMode() {
    return this.viewMode === "global";
  }

  @computed
  get isIndividualViewMode() {
    return this.viewMode === "individual";
  }

  getMetricsCFTV = async () => {
    this.setIsFetching(true);

    const rule = this.getRuleToRequestAPI;

    if (!rule) {
      return;
    }

    try {
      const { data, status }: Record<string, any> =
        await this.mobR2APIService.requestMetricsCFTV({
          fromDate: this.fromDate.format("X"),
          toDate: this.toDate.format("X"),
          rule,
          token: this.selectedUserToken,
          garage: this.garage,
        });

      if (status === 200 && data) {
        this.setReportData(data);
      } else {
        this.setReportData(null);
      }
    } catch (error) {
      window.debugError("error in requestMetricsCFTV", error);
      this.setReportData(null);
    } finally {
      this.setIsFetching(false);
    }
  };

  setReactions = () => {
    reaction(
      () => [this.viewMode, this.fromDate, this.toDate, this.rule, this.garage],
      () => {
        if (this.isGlobalViewMode) {
          this.getMetricsCFTV();
        }
      },
      { delay: 2000 }
    );

    reaction(
      () => [this.viewMode, this.fromDate, this.toDate, this.selectedUserToken],
      () => {
        if (this.isIndividualViewMode && this.selectedUserToken) {
          this.getMetricsCFTV();
        }
      },
      { delay: 1000 }
    );
  };

  clearStore = () => {
    this.setViewMode(null);
    this.setSearchString("");
    this.setReportData(null);
    this.setSelectedUserToken(null);
    this.setGarage("");
  };
}

export default CFTVReportsStore;
