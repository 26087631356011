// @ts-nocheck
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import List from "./List";
import ListItem from "./ListItem";

// Style
const styles = {
  itemIndex: {
    fontSize: 22,
    minWidth: 48,
    textAlign: "right",
  },
};

const TopItemsList = ({
  classes,
  itemsList,
  renderTitle,
  renderDescription,
}) => {
  return (
    <List>
      {itemsList.map((item, index) => {
        const indexToPrint = index + 1;
        return (
          <ListItem
            key={index}
            divider={indexToPrint < itemsList.length}
            dense
            leading={<span className={classes.itemIndex}>#{indexToPrint}</span>}
            title={renderTitle(item)}
            description={renderDescription(item)}
          />
        );
      })}
    </List>
  );
};

export default withStyles(styles)(TopItemsList);
