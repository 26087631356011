// @ts-nocheck
export const displayDurationFromSecondsFloat = (timeInSeconds) => {
  let hours = Math.floor((timeInSeconds % 86400) / 3600);
  let minutes = Math.floor((timeInSeconds % 3600) / 60);
  let seconds = Math.floor(timeInSeconds) % 60;

  const addZeroIfLessThanTen = (value) => (value < 10 ? "0" : "");
  const formatTime = (value) => `${addZeroIfLessThanTen(value)}${value}`;

  const formattedHours = hours > 0 ? `${formatTime(hours)}:` : "";
  const formattedMinutes = formatTime(minutes);
  const formattedSeconds = formatTime(seconds);

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};
