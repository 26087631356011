// @ts-nocheck
import React from "react";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactDOM from "react-dom";

// Styles
import buttonStyle from "../../assets/jss/js-styles/components/buttonStyle";
const styles = {
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: "#3C4858",
    fontSize: "18px",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  ...buttonStyle,
};

const AlertModal = ({
  classes,
  title,
  onConfirm,
  onCancel,
  children,
  showCancel = false,
  showConfirm = true,
  warning,
  success,
  danger,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  return ReactDOM.createPortal(
    <SweetAlert
      warning={warning}
      success={success}
      danger={danger}
      style={{ display: "block", top: 20, marginTop: 0 }}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnCssClass={classnames({
        [classes.button]: true,
        [classes.success]: !warning,
        [classes.danger]: warning || danger,
      })}
      cancelBtnCssClass={classes.button}
      confirmBtnText={confirmButtonLabel}
      cancelBtnText={cancelButtonLabel}
      closeOnClickOutside={false}
      allowEscape={false}
      showConfirm={showConfirm}
      showCancel={showCancel}
    >
      {children}
    </SweetAlert>,
    document.getElementById("modal-root")
  );
};

AlertModal.defaultProps = {
  onConfirm: () => {}, // because this prop is required on SweetAlert
};

export default withStyles(styles)(AlertModal);
