// @ts-nocheck
import { observable, computed, action, autorun } from "mobx";

import {
  auth,
  authUsersRef,
  usersRef,
  serverTimestamp,
} from "../FirebaseStore/FirebaseStore";

import { DID_LOGOUT } from "../InternalEventsStore/InternalEventsStore";

// Utils
import { deviceInfo } from "../../utils/DeviceUtils";

class FirebaseAuthStore {
  @observable anonymousUser = null;

  @observable isAuthUserReady = false;

  @observable firebaseUserId = null;
  @observable firebaseUser = null;

  constructor(authStore, internalEventsStore) {
    this.authStore = authStore;
    this.signInAnonymously();
    this.setAuthUserIfAuthenticated();
    this.setFirebaseUserListener();

    // Logout listener
    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.logout,
    });
  }

  @action
  setAuthUserIfAuthenticated = () => {
    autorun(() => {
      if (this.isAuthenticated) {
        const authUserRef = authUsersRef.child(this.uid);
        if (this.authStore.currentUser) {
          const { token: userToken } = this.authStore.currentUser;

          authUserRef.onDisconnect().remove();
          authUserRef
            .set({
              userToken,
              connectedSince: serverTimestamp,
            })
            .then(
              action(() => {
                this.isAuthUserReady = true;
              })
            );
        } else {
          authUserRef.once("value", (snapshot) => {
            if (snapshot.exists()) {
              authUserRef.remove();
            }
          });
          action(() => (this.isAuthUserReady = false));
        }
      } else {
        action(() => (this.anonymousUser = null));
      }
    });
  };

  @action
  setFirebaseUserId = (userId) => {
    this.firebaseUserId = userId;
  };

  @action
  setFirebaseUser = (user) => {
    this.firebaseUser = user;
  };

  setFirebaseUserListener = () => {
    autorun(async () => {
      if (this.isAuthenticated) {
        let userId = "";
        const user = {
          createdAt: serverTimestamp,
          deviceInfo: deviceInfo(),
          currentUrl: window.location.href,
        };

        if (this.authStore.currentUser) {
          const { token, name, email } = this.authStore.currentUser;
          userId = token;
          user["name"] = name;
          user["email"] = email;
          user["isAnonymous"] = false;
        } else {
          userId = this.uid;
          user["isAnonymous"] = true;
        }

        const snapshot = await usersRef.child(userId).once("value");
        if (!snapshot.exists()) {
          await usersRef.child(userId).set(user);
        }

        this.setFirebaseUserId(userId);
      } else {
        this.setFirebaseUserId(null);
      }
    });

    autorun(() => {
      if (this.firebaseUserId) {
        usersRef.child(this.firebaseUserId).on("value", (snapshot) => {
          if (snapshot.exists()) {
            this.setFirebaseUser({
              id: snapshot.key,
              ...snapshot.val(),
            });
          }
        });
      } else {
        this.setFirebaseUser(null);
      }
    });
  };

  @computed
  get isAuthReady() {
    return this.isAuthUserReady && this.firebaseUser !== null;
  }

  @computed
  get uid() {
    if (this.isAuthenticated) {
      return this.anonymousUser.uid;
    }
    return null;
  }

  @computed
  get isAuthenticated() {
    return this.anonymousUser !== null;
  }

  @action
  signInAnonymously = () => {
    auth
      .signInAnonymously()
      .then(
        action((value) => {
          this.anonymousUser = value.user;
        })
      )
      .catch((error) => {
        setTimeout(() => {
          this.signInAnonymously();
        }, 2000);
      });
  };

  @action
  logout = async () => {
    this.anonymousUser = null;
    await auth.signOut();
    this.signInAnonymously();
  };
}

export default FirebaseAuthStore;
