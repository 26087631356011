// @ts-nocheck
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";

// Styles
import {
  defaultFont,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  infoColor,
  dangerColor,
  successColor,
  warningColor,
} from "../../assets/jss/app-js-styles";

const styles = {
  Containers: {
    DefaultStyle: {
      width: window.screen.width < 960 ? 320 : 600,
    },
  },
  NotificationItem: {
    DefaultStyle: {
      ...defaultFont,
      fontWeight: 400,
      padding: "16px 22px",
      lineHeight: "20px",
      marginBottom: "20px",
      fontSize: "1em",
      color: "#ffffff",
      borderRadius: "3px",
      boxShadow:
        "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)",
    },
    success: {
      backgroundColor: successColor,
      ...successBoxShadow,
    },
    info: {
      backgroundColor: infoColor,
      ...infoBoxShadow,
    },
    warning: {
      backgroundColor: warningColor,
      ...warningBoxShadow,
    },
    error: {
      backgroundColor: dangerColor,
      ...dangerBoxShadow,
    },
  },
  Title: {
    DefaultStyle: {
      fontSize: "1.2em",
      textTransform: "uppercase",
      fontWeight: 700,
      color: "#ffffff",
    },
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: "transparent",
      fontFamily: "inherit",
      fontSize: 30,
      top: -2,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 30,
      height: 30,
    },
  },
};

@inject("stores")
@observer
class SnackbarNotificationContainer extends Component {
  constructor(props) {
    super(props);

    this._notificationSystem = null;
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    const { notificationsStore } = this.props.stores;

    this.disposerAutorun = autorun(
      () => {
        if (notificationsStore.snackbarNotification) {
          this.addNotification(notificationsStore.snackbarNotification);
          notificationsStore.setNotificationsList(null);
        }
      },
      { name: "Set snackbar notifications" }
    );
  }

  componentWillUnmount() {
    this.disposerAutorun();
  }

  addNotification = ({
    token,
    title,
    message,
    children,
    color = "success",
    timeout = 5000,
  }) => {
    this._notificationSystem.addNotification({
      uid: token,
      title,
      message,
      children,
      level: color === "danger" ? "error" : color,
      autoDismiss: timeout / 1000,
    });
  };

  removeNotification = (token) => {
    if (token) {
      this._notificationSystem.removeNotification(token);
    }
  };

  clearNotifications = () => {
    this._notificationSystem.clearNotifications();
  };

  render() {
    return <NotificationSystem ref="notificationSystem" style={styles} />;
  }
}

export default SnackbarNotificationContainer;
