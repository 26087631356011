import moment from "moment";

import TerminalModel from "./TerminalModel";

import { getNowMoment, momentDateFromUnixDate } from "../utils/DateUtils";

interface NameToken {
  name: string;
  token: string;
}

class VehicleModel {
  name: string;
  token: string;
  plate: string;
  comments: string;
  garage?: NameToken;
  playlists: NameToken[];
  internet?: NameToken;
  line?: NameToken;
  terminal?: TerminalModel;
  operator?: string;
  phoneNumber?: string;
  isSelected?: boolean;
  hasCamera?: boolean;
  statsCameras?: boolean[];
  lastCameraCollectDate?: string;
  hasCameraCollectAlert: boolean;

  constructor(vehicle) {
    const terminal = vehicle.terminal || null;

    this.name = vehicle.name;
    this.token = vehicle.token;
    this.plate = vehicle.plate;
    this.comments = vehicle.comments;
    this.garage = vehicle.garage || null;
    this.playlists = vehicle.playlists || [];
    this.internet = vehicle.wifi || null;
    this.line = vehicle.line || null;
    this.terminal = new TerminalModel(terminal);
    this.operator = vehicle?.operator || "";
    this.phoneNumber = vehicle?.phone_number || "";

    this.hasCamera = vehicle?.camera === "True";
    this.statsCameras =
      this.hasCamera && vehicle.stats_cameras ? vehicle.stats_cameras : [];
    const collectDate: moment.Moment | null =
      this.hasCamera && vehicle.last_camera_collect
        ? momentDateFromUnixDate(vehicle.last_camera_collect)
        : null;
    this.lastCameraCollectDate = collectDate
      ? collectDate.format("DD/MM/YY HH:mm")
      : "—";

    this.hasCameraCollectAlert = this.hasCamera
      ? collectDate
        ? collectDate.isBefore(getNowMoment().subtract(4, "days"))
        : true
      : false;

    this.isSelected = vehicle.isSelected || false;
  }

  get allCamerasAreWorking(): boolean {
    if (!this.hasCamera) return true;
    if (this.statsCameras.length < 1) return false;
    return this.statsCameras.every((cameraIsWorking) => cameraIsWorking);
  }

  get hasCamerasAlert(): boolean {
    return this.hasCamera && !this.allCamerasAreWorking;
  }

  get label(): string {
    return this.name;
  }

  get isTerminalOnline() {
    return this.terminal?.isOnline || false;
  }

  get hasSendCamCommand() {
    return this.terminal?.hasSendCamCommand || false;
  }
}

export default VehicleModel;
