// @ts-nocheck
import React, { Component } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import DraggableStopItem from "./DraggableStopItem";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightgrey" : "transparent",
  padding: 8,
  border: "1px solid lightgrey",
  borderRadius: 6,
  marginBottom: 6,
});

class DNDSelectStopsContainer extends Component {
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsList = reorder(
      this.props.stopsList,
      result.source.index,
      result.destination.index
    );

    this.props.didReorderStopsList(itemsList);
  };

  render() {
    if (this.props.stopsList.length < 1) return null;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.stopsList.map((stop, index) => (
                <DraggableStopItem
                  key={index}
                  index={index}
                  token={stop.token}
                  name={stop.name}
                  didRemoveStop={this.props.didRemoveStop(index)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DNDSelectStopsContainer;
