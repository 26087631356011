// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";

import { Card, CardHeader, CardIcon, CardBody } from "../../components/Card";
import BigCounter from "../../components/BigCounter/BigCounter";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

// Icons
import WifiIcon from "@material-ui/icons/Wifi";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
const styles = {
  ...chartsStyle,
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

const WifiDevicesOnlineChartWidget = observer(({ classes, chartData }) => {
  const bigCounterContainerStyle = {};
  const bigCounterStyle = {
    fontSize: "4em",
    marginTop: 20,
  };
  return (
    <Card className={classes.chartCard}>
      <CardHeader color="black" icon>
        <CardIcon color="black">
          <WifiIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Visitantes no momento</h4>
      </CardHeader>
      <CardBody className={classes.cardBody}>
        <BigCounter
          containerStyle={bigCounterContainerStyle}
          style={bigCounterStyle}
          value={chartData.total_devices}
          label="total online"
        />

        {chartData.authenticated > 0 && (
          <BigCounter
            containerStyle={bigCounterContainerStyle}
            style={bigCounterStyle}
            value={chartData.authenticated}
          />
        )}
      </CardBody>
    </Card>
  );
});
export default withLocalized(withStyles(styles)(WifiDevicesOnlineChartWidget));
