// @ts-nocheck
import {
  primaryColor,
  infoColor,
  successColor,
  dangerColor,
  warningColor,
  roseColor,
  grayColor,
} from "../../assets/jss/app-js-styles";

export const theme1Colors = [
  primaryColor,
  infoColor,
  "#2e3131",
  successColor,
  warningColor,
  "#8c14fc",
  dangerColor,
  "#95a5a6",
  "#aea8d3",
];
export const theme2Colors = [
  warningColor,
  "#2e3131",
  successColor,
  "#674172",
  roseColor,
  infoColor,
  "#95a5a6",
  primaryColor,
];
export const theme3Colors = [
  grayColor,
  successColor,
  warningColor,
  dangerColor,
  "#2e3131",
  primaryColor,
  infoColor,
  "#95a5a6",
];

export const theme1 = {
  fill: {
    colors: theme1Colors,
  },
  markers: {
    colors: theme1Colors,
  },
  colors: theme1Colors,
};

export const theme2 = {
  fill: {
    colors: theme2Colors,
  },
  markers: {
    colors: theme2Colors,
  },
  colors: theme2Colors,
};

export const theme3 = {
  fill: {
    colors: theme3Colors,
  },
  markers: {
    colors: theme3Colors,
  },
  colors: theme3Colors,
};
