// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import React from "react";
// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
import { Card, CardBody, CardHeader, CardIcon } from "../../components/Card";
import BarChart from "../../components/Charts/BarChart";

const OccurrencesCategoriesChart = inject("stores")(
  observer(({ stores: { cftvReportsStore }, classes }) => {
    const { getOccurrencesCategories } = cftvReportsStore;

    if (!getOccurrencesCategories) {
      return null;
    }

    const { occurrencesCategories, category } = getOccurrencesCategories;

    return (
      <Card className={classes.chartCard}>
        <CardHeader color="black" icon>
          <CardIcon color="black">
            <i className="fas fa-bullhorn" />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Relatório de Ocorrências</h4>
        </CardHeader>
        <CardBody>
          <BarChart
            horizontal
            height={2000}
            xAxisCategories={category.map((items) => items)}
            data={[
              {
                name: "",
                data: occurrencesCategories.map(({ total }) => total),
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  })
);
export default withStyles(chartsStyle)(OccurrencesCategoriesChart);
