import { inject, observer } from "mobx-react";
import React, { useState } from "react";

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import moment from "moment";
import DateFromAndDateToPickers from "../../components/Date/DateFromAndDateToPickers";
import Modal from "../../components/Modal/Modal";

import RandomAnimation from "../../components/Animations/RandomAnimation";
import BarChart from "../../components/Charts/BarChart";
import InfoLabel from "../../components/InfoLabel/InfoLabel";
import LoadingView from "../../components/LoadingView/LoadingView";
import Table from "../../components/Table/Table";

interface IUserReportsFormModalProps {
  didCancel: () => void;
  userFullName?: string;
  stores: any;
  selectedUserToken?: string | null;
}

const UserReportsFormModal = inject("stores")(
  observer(
    ({
      didCancel,
      stores: { cftvReportsStore, usersStore },
      userFullName,
      selectedUserToken,
    }: IUserReportsFormModalProps) => {
      const [selectedOption, setSelectedOption] = useState("occurrences");

      if (!userFullName) {
        return null;
      }

      const isAnalystRule = usersStore.userIsDvrAnalyst(selectedUserToken);
      const isOperatorRule = usersStore.userIsDvrOperator(selectedUserToken);

      const isOccurrences = selectedOption === "occurrences";
      const isWatchedVideos = selectedOption === "watchedVideos";
      const isWatchedHours = selectedOption === "watchedHours";
      const isWatchedHoursMean = selectedOption === "watchedHoursMean";

      return (
        // @ts-ignore
        <Modal
          modalTitle={userFullName}
          isOpen
          didCloseModal={didCancel}
          fullScreen
          maxWidth="sm"
        >
          <Grid container spacing={6} style={{ marginBottom: 30 }}>
            <DateFromAndDateToPickers
              updatedDates={updatedDates}
              isCollectDateByWeek
            />
          </Grid>
          {renderChart()}
        </Modal>
      );

      function renderChart() {
        const { isFetching, getUserReportData, getUserSchedulesData } =
          cftvReportsStore;

        if (isFetching) {
          return (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingView />
            </Grid>
          );
        }

        if (!getUserReportData || !getUserSchedulesData) {
          return renderNotFoundMessage();
        }

        const { occurrences, watchedVideos, watchedHours, watchedHoursMean } =
          getUserReportData;
        const { commonData, hoursVideosAvailable, filteredDataByOccurrences } =
          getUserSchedulesData;

        const listItems = [
          {
            button: true,
            divider: true,
            icon: "scroll",
            primary: "Ocorrências registradas",
            secondary: occurrences,
            key: "occurrences",
          },
          {
            button: true,
            divider: true,
            icon: "tv",
            primary: "Quantidade de vídeos assistidos",
            secondary: watchedVideos,
            key: "watchedVideos",
          },
          {
            button: true,
            divider: true,
            icon: "film-alt",
            primary: "Total de horas assistidas",
            secondary: watchedHours,
            key: "watchedHours",
          },
          {
            button: true,
            divider: false,
            icon: "swatchbook",
            primary: "Média de horas assistidas por vídeo",
            secondary: watchedHoursMean,
            key: "watchedHoursMean",
          },
        ];

        return (
          <Grid container spacing={3}>
            <Grid item>
              <h4>Selecione para ver informações detalhadas</h4>
              <List style={{ border: "2px solid lightgrey", borderRadius: 8 }}>
                {listItems.map((options) => {
                  return renderListItem(options, options.key);
                })}
              </List>
            </Grid>
            <Grid
              item
              xs={7}
              style={{ border: "2px solid lightgrey", borderRadius: 8 }}
            >
              {isWatchedHoursMean ? (
                <BarChart
                  height={365}
                  xAxisCategories={["Horas dos vídeos", "Horas assistidas"]}
                  data={[
                    {
                      name: "horas",
                      data: [hoursVideosAvailable, watchedHours],
                    },
                  ]}
                />
              ) : (
                <Table
                  //@ts-ignore
                  data={renderTableData(commonData, filteredDataByOccurrences)}
                  columns={getColumns()}
                />
              )}
            </Grid>
          </Grid>
        );
      }

      function updatedDates(fromDate: moment.Moment, toDate: moment.Moment) {
        cftvReportsStore.setFromDate(fromDate);
        cftvReportsStore.setToDate(toDate);
      }

      function renderNotFoundMessage() {
        return (
          <Grid
            container
            justify="center"
            style={{ marginTop: 40 }}
            spacing={4}
          >
            <Grid item xs={12}>
              <InfoLabel
                large
                style={{ textAlign: "center", marginBottom: 30 }}
              >
                Não encontramos informações relevantes para exibir neste
                momento.
              </InfoLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <RandomAnimation />
            </Grid>
          </Grid>
        );
      }

      function renderListItem(
        { divider, icon, primary, secondary, button },
        key
      ) {
        return (
          <ListItem
            key={key}
            divider={divider}
            button={button}
            onClick={() => setSelectedOption(key)}
          >
            <ListItemIcon>
              <i className={`fas fa-${icon}`} />
            </ListItemIcon>
            <ListItemText
              primary={primary}
              secondary={secondary ? secondary : "—"}
            />
          </ListItem>
        );
      }

      function getColumns() {
        const getHeaderStyle = (position: "center" | "left" = "left") => ({
          getHeaderProps: () => ({ style: { textAlign: position } }),
          getProps: () => ({ style: { textAlign: position } }),
        });

        let list = [
          {
            Header: "Veículo",
            accessor: "vehicle",
          },
          {
            Header: "Data Assistida",
            accessor: isAnalystRule ? "analystVideo" : "endWatching",
          },
        ];

        if (isAnalystRule) {
          if (isOccurrences) {
            list.push({
              Header: "Eventos",
              accessor: "occurrences",
              ...getHeaderStyle("center"),
            });
          }
          if (isWatchedVideos) {
            list.push(
              {
                Header: "Data Requisição",
                accessor: "requisition",
              },
              {
                Header: "Tempo Assistido",
                accessor: "watchedTime",
                ...getHeaderStyle(),
              }
            );
          }
          if (isWatchedHours) {
            list.push(
              {
                Header: "Tempo Assistido",
                accessor: "watchedTime",
              },
              {
                Header: "Duração do Vídeo",
                accessor: "videoTime",
                ...getHeaderStyle(),
              }
            );
          }
        }

        if (isOperatorRule) {
          if (isOccurrences) {
            list.push({
              Header: "Eventos",
              accessor: "occurrences",
              ...getHeaderStyle("center"),
            });
          }

          if (isWatchedVideos) {
            list.push(
              {
                Header: "Data Requisição",
                accessor: "requisition",
              },
              {
                Header: "Tempo Assistido",
                accessor: "watchedTime",
                ...getHeaderStyle(),
              }
            );
          }
          if (isWatchedHours) {
            list.push(
              {
                Header: "Tempo Assistido",
                accessor: "watchedTime",
              },
              {
                Header: "Duração do Vídeo",
                accessor: "videoTime",
                ...getHeaderStyle(),
              }
            );
          }
        }
        return list;
      }

      function renderTableData(data, filteredData) {
        if (isOccurrences) {
          return filteredData;
        }
        return data;
      }
    }
  )
);

export default UserReportsFormModal;
