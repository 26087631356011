// @ts-nocheck
const getColorForIndex = (index) => {
  switch (index) {
    case 1:
      return "#26C281";
    case 2:
      return "#9B59B6";
    case 3:
      return "#F4D03F";
    case 4:
      return "#F4B350";
    case 5:
      return "#34495E";
    case 6:
      return "#E08283";
    case 7:
      return "#00E640";
    case 8:
      return "#674172";
    case 9:
      return "#446CB3";
    case 10:
      return "#019875";
    case 11:
      return "$ABB7B7";
    case 12:
      return "#ECECEC";
    case 13:
      return "#BE90D4";
    case 14:
      return "#DB0A5B";
    case 15:
      return "#96281B";
    case 16:
      return "#AEA8D3";
    case 17:
      return "#FDE3A7";
    case 18:
      return "#E9D460";
    case 19:
      return "#03C9A9";
    case 20:
      return "#013243";
    default:
      return "#89C4F4";
  }
};

const backgroundColorOptions = [
  "#000",
  "#fff",
  "#7f8c8d",
  "#1D8BF1",
  "#314A6C",
  "#01A057",
  "#16a085",
  "#D44A47",
  "#FFA138",
  "#825CFF",
];

const fontColorOptions = [
  "#fff",
  "#333",
  "#ecf0f1",
  "#f1c40f",
  "#CAE7EE",
  "#FADCCC",
  "#A0C5FF",
  "#1D8BF1",
  "#01A057",
  "#FFA138",
];

export { backgroundColorOptions, fontColorOptions, getColorForIndex };
