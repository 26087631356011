// @ts-nocheck
import React, { Component } from "react";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import { FormControl, Button } from "@material-ui/core";

// Style
import customInputStyle from "../../assets/jss/js-styles/components/customInputStyle";

class InputFile extends Component {
  onChange = ({ target }) => {
    Array.from(target.files).forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        this.props.onChangeFile(this.props.name, {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          base64: e.target.result,
        });
      };
    });
  };

  render() {
    const {
      classes,
      accept,
      multiple,
      name,
      label,
      formControlProps,
      disabled,
      children,
    } = this.props;

    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classnames(
        formControlProps.className,
        classes.formControl
      );
    } else {
      formControlClasses = classes.formControl;
    }

    return (
      <FormControl
        {...formControlProps}
        disabled={disabled}
        fullWidth
        className={formControlClasses}
        style={{ paddingTop: 8 }}
      >
        <p
          style={{
            color: "#AAAAAA",
            fontSize: 12,
            fontWeight: 500,
            textTransform: "uppercase",
            margin: 0,
          }}
        >
          {label}
        </p>
        {children}
        <input
          accept={accept}
          style={{ display: "none" }}
          id={name}
          multiple={multiple}
          type="file"
          onChange={this.onChange}
        />
        <label htmlFor={name}>
          <Button variant="contained" component="span">
            Escolher arquivo
          </Button>
        </label>
      </FormControl>
    );
  }
}

InputFile.defaultProps = {
  accept: "image/*",
  multiple: false,
};

export default withStyles(customInputStyle)(InputFile);
