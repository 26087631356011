import { observable, computed, action } from "mobx";

// Model
import NetworkModel from "../../Model/NetworkModel";

// Utils Stores
import InternalEventsStore, {
  DID_LOGOUT,
} from "../InternalEventsStore/InternalEventsStore";
import FileService from "../../services/FileService";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import MobR2APIService from "../../services/MobR2APIService";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";
import I18nStore from "../I18nStore/I18nStore";

class NetworksStore {
  i18nStore: I18nStore;
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  @observable dataList: NetworkModel[] = [];
  @observable isFetching = false;
  @observable searchString = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService,
    fileService: FileService
  ) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;
    this.fileService = fileService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString: string) => {
    this.searchString = searchString;
  };
  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };

  @action
  setDataList = (newList: NetworkModel[]) => {
    this.dataList = newList;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (network, textIncludesSearchString) =>
          textIncludesSearchString(network.wifiName) ||
          textIncludesSearchString(network.label)
      );
    }

    return this.dataList;
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    let newList = [];

    this.mobR2APIService
      .requestNetworks()
      .then((response: any) => {
        if (response.status === 200) {
          response.data.wifis.forEach((networkData) => {
            const network = new NetworkModel(networkData, this.fileService);
            newList.push(network);
          });
        }
        this.setDataList(newList.sort(dynamicSortByProperty("label")));

        this.setIsFetching(false);
      })
      .catch((error) => {
        console.error("error in requestNetworks", error);
        if (error && error.statusCode === 429) {
          setTimeout(() => {
            this.getDataList();
          }, 2000);
        }
      });
  };

  submit = (network: NetworkModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .submitNetwork(network)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (network: NetworkModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .updateNetwork(network)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (network: NetworkModel) => {
    return new Promise((resolve, reject) => {
      this.mobR2APIService
        .deleteNetwork(network)
        .then((response: any) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "warning",
          });
          this.getDataList(false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getWithToken = (token: string) => {
    if (this.dataList.length > 0) {
      const list = this.dataList.filter((network) => network.token === token);
      if (list.length > 0) {
        return list[0];
      }
    }
    return null;
  };
}

export default NetworksStore;
