import { observable, computed, action } from "mobx";

// Model
import PlaylistModel from "../../Model/PlaylistModel";

// Utils Stores
import InternalEventsStore, {
  DID_LOGOUT,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import MobR2APIService from "../../services/MobR2APIService";
import FileService from "../../services/FileService";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";
import I18nStore from "../I18nStore/I18nStore";

class PlaylistsStore {
  i18nStore: I18nStore;
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  @observable dataList: PlaylistModel[] = [];
  @observable isFetching: boolean = false;
  @observable searchString: string = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService,
    fileService: FileService
  ) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;
    this.fileService = fileService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString: string) => {
    this.searchString = searchString;
  };
  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setDataList = (newList: PlaylistModel[]) => {
    this.dataList = newList;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (playlist: PlaylistModel, textIncludesSearchString) =>
          textIncludesSearchString(playlist.label)
      );
    }

    return this.dataList;
  }

  getDataList = async () => {
    this.setIsFetching(true);

    try {
      let newPlaylistsList = [];
      const response: any = await this.mobR2APIService.requestPlaylists();
      if (response.status === 200) {
        response.data.forEach((playlistData: any) => {
          const newPlaylist = new PlaylistModel(playlistData);
          newPlaylistsList.push(newPlaylist);
        });
      }
      this.setDataList(newPlaylistsList.sort(dynamicSortByProperty("name")));
    } catch (error) {
      window.debugError("error in requestPlaylists", error);
    } finally {
      this.setIsFetching(false);
    }
  };

  getWithToken = (token: string) => {
    if (this.dataList.length > 0) {
      return this.dataList.find((playlist) => playlist.token === token);
    }
    return null;
  };
}

export default PlaylistsStore;
