// @ts-nocheck
import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import Grid from "@material-ui/core/Grid";

import { InputText, InputSelect } from "../Form";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import LoadingView from "../LoadingView/LoadingView";

const CreateOccurrenceModal = inject("stores")(
  observer(({ stores }) => {
    const { occurrencesStore } = stores;

    const {
      isFetchingCategories,
      occurrencesCategoriesList,
      isOpenOccurrenceModal,
      selectedOccurrence,
      onChangeCategory,
      onChangeDescription,
      didCloseOccurrenceModal,
      didSaveOccurrence,
    } = occurrencesStore;
    const { category, description } = selectedOccurrence;

    useEffect(() => {
      occurrencesStore.getOccurrencesCategoriesList();
    }, [occurrencesStore]);

    return (
      <Modal
        isOpen={isOpenOccurrenceModal}
        modalTitle="Registro de ocorrência"
        didCloseModal={didCloseOccurrenceModal}
        maxWidth="xs"
        actions={
          !isFetchingCategories && (
            <Button color="primary" block onClick={didSaveOccurrence}>
              Registrar <i className="fas fa-save" />
            </Button>
          )
        }
      >
        <Grid container justify="center">
          {isFetchingCategories ? (
            <Grid
              item
              xs={12}
              style={{ margin: "20px 0", textAlign: "center" }}
            >
              <LoadingView />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InputText
                label="Mensagem"
                name="description"
                value={description}
                multiline
                onChange={({ target }) => onChangeDescription(target.value)}
              />
              <InputSelect
                label="Tipo"
                name="category"
                value={category}
                options={occurrencesCategoriesList}
                valueKey="token"
                withPortal
                onChange={(_, selectedValue) => onChangeCategory(selectedValue)}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    );
  })
);

export default CreateOccurrenceModal;
