// @ts-nocheck
import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import PageTitle from "../components/PageTitle/PageTitle";
import LiveMapContainer from "./gpsMonitoringPage/LiveMapContainer";
import ReplayMapContainer from "./gpsMonitoringPage/ReplayMapContainer";

class GPSMonitoringPage extends Component {
  render() {
    const { location, fullScreen, match } = this.props;

    const pathUrl = match.path;

    return (
      <Fragment>
        <PageTitle>Monitoramento</PageTitle>
        <Switch>
          <Route
            path={`${pathUrl}/replay`}
            render={(props) => (
              <ReplayMapContainer
                {...props}
                pathUrl={pathUrl}
                location={location}
                fullScreen={fullScreen}
              ></ReplayMapContainer>
            )}
          />
          <Route
            path={"/"}
            render={(props) => (
              <LiveMapContainer
                {...props}
                pathUrl={pathUrl}
                location={location}
                fullScreen={fullScreen}
              ></LiveMapContainer>
            )}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default GPSMonitoringPage;
