// @ts-nocheck
import React from "react";

import Grid from "@material-ui/core/Grid";

import Modal from "../Modal/Modal";

const ShortcutHint = ({ label, shortcut }) => (
  <Grid
    container
    item
    xs={12}
    style={{
      padding: "8px 0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
    }}
    alignItems="center"
  >
    <Grid item xs>
      {label}
    </Grid>
    <Grid item style={{ fontSize: "1.2em", fontWeight: "bold" }}>
      {shortcut}
    </Grid>
  </Grid>
);

const VideoHelpModal = ({ isOpen, didClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      modalTitle="Atalhos de teclado"
      maxWidth="xs"
      didCloseModal={didClose}
    >
      <Grid container>
        <ShortcutHint label="Play / Pause" shortcut="barra de espaço" />
        <ShortcutHint label="Avançar 1s" shortcut="⇥" />
        <ShortcutHint label="Avançar 10s" shortcut="⇧⇥" />
        <ShortcutHint label="Retroceder 1s" shortcut="⇤" />
        <ShortcutHint label="Retroceder 10s" shortcut="⇧⇤" />
        <ShortcutHint label="Aumentar velocidade de reprodução" shortcut="↑" />
        <ShortcutHint label="Diminuir velocidade de reprodução" shortcut="↓" />
        <ShortcutHint label="Zoom câmera 1" shortcut="1" />
        <ShortcutHint label="Zoom câmera 2" shortcut="2" />
        <ShortcutHint label="Zoom câmera 3" shortcut="3" />
        <ShortcutHint label="Zoom câmera 4" shortcut="4" />
        <ShortcutHint label="Cancelar Zoom" shortcut="ESC" />
        <ShortcutHint label="Registrar ocorrência" shortcut="E" />
        <ShortcutHint label="Abrir / fechar esta tela" shortcut="H" />
      </Grid>
    </Modal>
  );
};

export default VideoHelpModal;
