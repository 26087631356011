// @ts-nocheck
import { List, ListItem, ListItemText } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { reaction } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import SimpleCard from "../components/Card/SimpleCard";
import InfoLabel from "../components/InfoLabel/InfoLabel";
import LoadingView from "../components/LoadingView/LoadingView";
import PageTitle from "../components/PageTitle/PageTitle";
import Period from "../components/Period/Period";
import UpdateListProgressCounter from "../components/UpdateListProgressCounter/UpdateListProgressCounter";
import { JOB_SCHEDULE_STATUS } from "../Model/JobScheduleModel";

// Style
const styles = {
  title: {
    fontSize: "1.4em",
    fontWeight: 500,
  },
};

@inject("stores")
@observer
class OperatorPage extends Component {
  componentDidMount() {
    this.refreshJobs(true)();

    reaction(
      () => this.props.stores.jobScheduleStore.selectedJobSchedule,
      (selectedJobSchedule) => {
        if (selectedJobSchedule) {
          this.didPlay(selectedJobSchedule.cams);
        }
      }
    );
  }

  refreshJobs = (withFetching) => () => {
    const { jobScheduleStore } = this.props.stores;
    jobScheduleStore.setIsFetching(withFetching);
    jobScheduleStore.getJobScheduleList();
  };

  didPlay = (files) => {
    this.props.stores.videoStore.setUrlListToWatch(files);
  };

  didWantToPlay = (token) => () => {
    if (this.props.stores.authStore.isDVROperator) {
      this.props.stores.jobScheduleStore.operatorDidWantToPlay(token);
    }
  };

  render() {
    const { classes } = this.props;
    const { isFetching, jobScheduleList } = this.props.stores.jobScheduleStore;

    const list = jobScheduleList.filter(
      ({ status }) => status === JOB_SCHEDULE_STATUS.READY
    );

    return (
      <Fragment>
        <PageTitle>Operador</PageTitle>
        <Grid
          container
          style={{
            marginTop: -2,
            marginBottom: 20,
            position: "sticky",
            top: 104,
            zIndex: 10,
          }}
        >
          <Grid item xs={12}>
            <UpdateListProgressCounter
              callback={this.refreshJobs(false)}
              isHidden={isFetching || list.length > 0}
            />
          </Grid>
        </Grid>
        <Grid container style={{ padding: "0 16px" }} spacing={3}>
          {isFetching ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingView />
            </Grid>
          ) : list.length < 1 ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <InfoLabel>
                Nenhum vídeo disponível para análise no momento.
              </InfoLabel>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12}>
                <p style={{ textAlign: "center" }}>
                  Selecione um trabalho para analisar:
                </p>
              </Grid>
              {list.map(({ token, vehicle, supervisor, fromDate, toDate }) => (
                <Grid key={token} item xs={12} sm={6} md={4} lg={3}>
                  <SimpleCard
                    onClick={this.didWantToPlay(token)}
                    cardStyle={{ margin: 0, cursor: "pointer" }}
                    cardBodyStyle={{ padding: 0 }}
                  >
                    <List>
                      <ListItem divider dense>
                        <ListItemText
                          primary={<p className={classes.title}>{vehicle}</p>}
                          secondary="Veículo"
                        />
                      </ListItem>
                      <ListItem divider dense>
                        <ListItemText
                          primary={supervisor.name}
                          secondary="Supervisor"
                        />
                      </ListItem>
                      <ListItem dense>
                        <ListItemText
                          primary={
                            <Period fromDate={fromDate} toDate={toDate} />
                          }
                          secondary="Período"
                        />
                      </ListItem>
                    </List>
                  </SimpleCard>
                </Grid>
              ))}
            </Fragment>
          )}
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(OperatorPage);
