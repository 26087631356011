// @ts-nocheck
import React from "react";
import animationData from "./SquareDropLoader.json";
import LottieAnimation from "../LottieAnimation";

const SquareDropLoader = ({ height }) => (
  <LottieAnimation animationData={animationData} height={height} />
);

export default SquareDropLoader;
