import MobR2APIService from "./MobR2APIService";
import FileService from "./FileService";

class MainServices {
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  constructor() {
    this.mobR2APIService = new MobR2APIService();
    this.fileService = new FileService();
  }
}

export default MainServices;
