// @ts-nocheck
import { observable, action, computed } from "mobx";

import VehicleModel from "../../Model/VehicleModel";

// Utils Stores
import { DID_LOGOUT } from "../InternalEventsStore/InternalEventsStore";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";
import { SERVERS_LIST } from "../../utils/EnvUtils";

class VehicleStore {
  @observable isFetching = false;
  @observable searchString = "";
  @observable filterByStatus = "all";
  @observable filterByAlerts = [];
  @observable vehiclesList = [];
  @observable selectedGarage = null;
  @observable vehiclesStatusCount = {
    offline: 0,
    online: 0,
  };

  @observable selectedVehicleToken = null;

  constructor(
    internalEventsStore,
    terminalsStore,
    notificationsStore,
    mobR2APIService
  ) {
    this.terminalsStore = terminalsStore;
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;

    internalEventsStore.subscribeTo({
      eventKey: DID_LOGOUT,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.searchString = "";
    this.filterByStatus = "all";
    this.filterByAlerts = [];
    this.vehiclesStatusCount = {
      offline: 0,
      online: 0,
    };
    this.vehiclesList = [];
    this.selectedGarage = null;
  };

  @action
  setVehiclesStatusCount = (value) => {
    this.vehiclesStatusCount = value;
  };

  @action
  setSelectedVehicle = (token) => {
    this.selectedVehicleToken = token;
  };

  removeSelectedVehicle = () => {
    this.setSelectedVehicle(null);
  };

  @computed
  get vehiclesListFilteredByGarageAndStatus() {
    return this.vehiclesList.filter(({ garage, terminal }) => {
      const matchGarage = this.selectedGarage
        ? this.selectedGarage.token === garage?.token
        : true;
      const matchStatus =
        this.filterByStatus !== "all"
          ? terminal?.status === this.filterByStatus
          : true;

      return matchGarage && matchStatus;
    });
  }

  @computed
  get filteredVehiclesList() {
    const list =
      this.filterByAlerts.length < 1
        ? this.vehiclesListFilteredByGarageAndStatus
        : this.vehiclesListFilteredByGarageAndStatus.filter(
            ({ hasCamerasAlert, terminal }) => {
              const {
                hasTemperatureAlert,
                hasEnergyAlert,
                hasDiskSpaceAlert,
                hasDiskSpeedAlert,
                hasCriticalAlert,
              } = terminal;

              const alerts = this.filterByAlerts.map(({ value }) => value);
              const matchTemperatureAlert =
                hasTemperatureAlert && alerts.includes("temperature");
              const matchEnergyAlert =
                hasEnergyAlert && alerts.includes("energy");
              const matchDiskSpaceAlert =
                hasDiskSpaceAlert && alerts.includes("diskSpace");
              const matchDiskSpeedAlert =
                hasDiskSpeedAlert && alerts.includes("diskSpeed");
              const matchCriticalAlert =
                hasCriticalAlert && alerts.includes("critical");
              const matchCameraAlert =
                alerts.includes("camera") && hasCamerasAlert;

              return (
                matchTemperatureAlert ||
                matchEnergyAlert ||
                matchDiskSpaceAlert ||
                matchDiskSpeedAlert ||
                matchCriticalAlert ||
                matchCameraAlert
              );
            }
          );

    if (this.searchString.length > 0 && list.length > 0) {
      return didSearchList(
        this.searchString,
        list,
        (vehicle, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(vehicle.name);
          const matchPhoneNumber = textIncludesSearchString(
            vehicle.phoneNumber
          );
          const matchOperator = textIncludesSearchString(vehicle.operator);
          const matchLine = textIncludesSearchString(vehicle.line?.name);
          const matchComments = textIncludesSearchString(vehicle.comments);
          const matchPlaylist = vehicle.playlists.some(({ name }) =>
            textIncludesSearchString(name)
          );
          const matchInternet = textIncludesSearchString(
            vehicle.internet?.name
          );
          const matchCamera =
            this.searchString.toLowerCase() === "camera" &&
            vehicle.camera?.token.length > 0;
          const matchSerialNumber = textIncludesSearchString(
            vehicle.terminal.serialNumber
          );
          const matchVersion = textIncludesSearchString(
            vehicle.terminal.version
          );

          return (
            matchName ||
            matchPhoneNumber ||
            matchOperator ||
            matchLine ||
            matchComments ||
            matchPlaylist ||
            matchInternet ||
            matchCamera ||
            matchSerialNumber ||
            matchVersion
          );
        }
      );
    }

    return list;
  }

  @computed
  get selectedVehiclesList() {
    return this.vehiclesList.filter(({ isSelected }) => isSelected);
  }

  @computed
  get selectedVehicle() {
    if (this.selectedVehicleToken === null) return null;

    return this.vehiclesList.find(
      ({ token }) => this.selectedVehicleToken === token
    );
  }

  requestBus = (filters, customServer) => {
    return this.mobR2APIService.requestBus(filters, customServer);
  };

  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setFilterByStatus = (status) => {
    this.filterByStatus = status;
  };

  @action
  setFilterByAlerts = (alerts) => {
    this.filterByAlerts = alerts || [];
  };

  @action
  setVehiclesList = (newList) => {
    this.vehiclesList = newList;
  };

  @action
  setSelectedGarage = (selectedValue) => {
    this.selectedGarage = selectedValue.token === "all" ? null : selectedValue;
  };

  getVehiclesListWithoutFetchingAnimation = (
    { filterByCamera, filterByGPS } = {
      filterByCamera: false,
      filterByGPS: false,
    }
  ) => {
    this.getVehiclesList({
      withFetchingAnimation: false,
      filterByCamera,
      filterByGPS,
    });
  };

  getVehiclesList = async (
    { withFetchingAnimation, filterByCamera, filterByGPS } = {
      withFetchingAnimation: true,
      filterByCamera: false,
      filterByGPS: false,
    }
  ) => {
    this.setIsFetching(withFetchingAnimation);
    let newVehiclesList = [];
    let vehiclesOfflineCount = 0;
    let vehiclesOnlineCount = 0;

    const backupSelectedTokenList = this.selectedVehiclesList.map(
      ({ token }) => token
    );

    try {
      const response = await this.requestBus({ filterByCamera, filterByGPS });
      if (response.status === 200) {
        response.data.forEach(async (vehicle) => {
          const isSelected = backupSelectedTokenList.includes(vehicle.token);
          const vehicleModel = new VehicleModel({ ...vehicle, isSelected });
          if (vehicleModel?.terminal?.status === "online") {
            vehiclesOnlineCount++;
          } else {
            vehiclesOfflineCount++;
          }
          newVehiclesList.push(vehicleModel);
        });
      }
      this.setVehiclesList(newVehiclesList.sort(dynamicSortByProperty("name")));
      this.setVehiclesStatusCount({
        online: vehiclesOnlineCount,
        offline: vehiclesOfflineCount,
      });
      this.setIsFetching(false);
    } catch (error) {
      console.error("error in getVehiclesList", error);
      if (error && error.statusCode === 429) {
        setTimeout(() => {
          this.getVehiclesList();
        }, 1000);
      }
    }
  };

  requestAllServersVehicles = async () => {
    const vehiclesList = [];

    for (const server of SERVERS_LIST) {
      try {
        const response = await this.requestBus(
          {
            withFetchingAnimation: false,
            filterByCamera: false,
            filterByGPS: true,
          },
          server
        );

        if (
          response.status === 200 &&
          response.data &&
          Array.isArray(response.data)
        ) {
          response.data.forEach((vehicle) => {
            if (vehicle.terminal.status === "online") {
              vehiclesList.push(new VehicleModel(vehicle));
            }
          });
        }
      } catch (error) {
        console.error("error in requestAllServersVehicles", server, error);
      }
    }
    return vehiclesList;
  };

  getWithToken = (token) => {
    return this.vehiclesList.find((vehicle) => vehicle.token === token) || null;
  };

  submit = async (data) => {
    try {
      const response = await this.mobR2APIService.submitBus(data);

      this.getVehiclesListWithoutFetchingAnimation();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });

      return Promise.resolve();
    } catch (error) {
      console.error("error in submitBus", error);
      if (error && error.statusCode === 409) {
        this.notificationsStore.addSnackbarNotification({
          message: error.message,
          color: "danger",
        });
      }
      return Promise.reject(error);
    }
  };
  update = async (data) => {
    try {
      const response = await this.mobR2APIService.updateBus(data);

      this.getVehiclesListWithoutFetchingAnimation();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });

      return Promise.resolve();
    } catch (error) {
      console.error("error in updateBus", error);
      if (error && error.statusCode === 409) {
        this.notificationsStore.addSnackbarNotification({
          message: error.message,
          color: "danger",
        });
      }
      return Promise.reject(error);
    }
  };

  didCheckAllVehicles = (isChecked) => {
    const tokensList = this.filteredVehiclesList.map(({ token }) => token);
    this.setVehiclesList([
      ...this.vehiclesList.map(($0) =>
        tokensList.includes($0.token) ? { ...$0, isSelected: isChecked } : $0
      ),
    ]);
  };

  didSelectVehicle = (selectedToken) => {
    this.setVehiclesList([
      ...this.vehiclesList.map(($0) =>
        selectedToken === $0.token ? { ...$0, isSelected: !$0.isSelected } : $0
      ),
    ]);
  };

  @computed
  get vehiclesWithGpsLocation() {
    if (this.vehiclesList.length > 0) {
      return this.vehiclesList.filter(
        ({ terminal }) => terminal.gps.latitude && terminal.gps.longitude
      );
    }
    return [];
  }

  requestGPS = async ({ token, fromDate, toDate }) => {
    try {
      const response = await this.mobR2APIService.requestGPS({
        vehicleToken: token,
        fromDate,
        toDate,
      });
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("error in requestGPS", error);
      return [];
    }
  };

  sendCommandToVehicle = async (data) => {
    const { token, command } = data;
    try {
      const { data } = await this.mobR2APIService.submitConfig({
        vehicles: [
          {
            token,
            wifi: "undefined",
            command,
            line: "undefined",
            playlist: "undefined",
          },
        ],
      });

      const vehicle = this.getWithToken(token);
      if (vehicle) {
        vehicle.terminal.setCommand("sendcam");
      }

      this.notificationsStore.addSnackbarNotification({
        message: data.message,
        color: "success",
      });

      this.getVehiclesListWithoutFetchingAnimation();
      return Promise.resolve();
    } catch (error) {
      console.error("error in sendCommandToVehicle", error);
      if (error && error.statusCode === 429) {
        setTimeout(() => this.sendCommandToVehicle(data), 1000);
        return Promise.reject();
      }
    }
  };

  submitConfigToSelectedVehicles = async (config) => {
    try {
      const key = Object.keys(config)[0];

      if (Object.values(config).includes("undefined")) {
        await this.mobR2APIService.deleteConfig({
          playlist: [],
          internet: [],
          line: [],
          [key]: this.selectedVehiclesList.map(($0) => $0.token),
        });
      } else {
        const setConfig =
          key === "internet" ? { wifi: config.internet } : config;
        await this.mobR2APIService.submitConfig({
          vehicles: this.selectedVehiclesList.map(($0) => ({
            token: $0.token,
            wifi: "undefined",
            command: "undefined",
            line: "undefined",
            playlist: "undefined",
            ...setConfig,
          })),
        });
      }
      this.didCheckAllVehicles(false);
      this.getVehiclesListWithoutFetchingAnimation();
      return Promise.resolve();
    } catch (error) {
      console.error("error in sendCommandToTerminal", error);
      return Promise.reject();
    }
  };
}

export default VehicleStore;
