// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import SimpleCard from "../components/Card/SimpleCard";
import PageTitle from "../components/PageTitle/PageTitle";
import Table from "../components/Table/Table";
import SearchBar from "../components/SearchBar/SearchBar";
import Button from "../components/Button/Button";
import LoadingView from "../components/LoadingView/LoadingView";
import ConfirmDeleteModal from "../components/Modal/ConfirmDeleteModal";

import UserFormModal from "./usersPage/UserFormModal";

import { titleForRules } from "../Model/ContractModel";

// Style
const styles = {};

@inject("stores")
@observer
class UsersPage extends Component {
  state = {
    didWantToDelete: false,
    tokenToDelete: null,
    isFormOpen: false,
    isEditingToken: null,
  };

  componentDidMount() {
    this.props.stores.usersStore.getUsersList();
  }

  didWantToDelete = (token) => () => {
    this.setState({
      didWantToDelete: true,
      tokenToDelete: token,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
      tokenToDelete: null,
    });
  };

  didDelete = async () => {
    try {
      await this.props.stores.usersStore.delete(this.state.tokenToDelete);
    } finally {
      this.didCancelDeleteAction();
    }
  };

  didWantToAdd = () => {
    this.setState({
      isFormOpen: true,
    });
  };

  didWantToUpdate = (isEditingToken) => () => {
    this.setState({
      isEditingToken,
      isFormOpen: true,
    });
  };

  didCancelForm = () => {
    this.setState({
      isEditingToken: null,
      isFormOpen: false,
    });
  };

  renderCameraHeader = (camera) => {
    const { classes } = this.props;
    return {
      Header: (
        <span className={classes.cameraHeader}>
          <i className="fas fa-video" />{" "}
          <span className={classes.cameraNumber}>{camera}</span>
        </span>
      ),
      accessor: `cam${camera}`,
      sortable: false,
      getHeaderProps: () => {
        return {
          style: {
            textAlign: "center",
          },
        };
      },
      getProps: () => {
        return {
          style: {
            textAlign: "center",
          },
        };
      },
    };
  };

  getColumns = () => {
    const hasSysOperatorRule = this.props.stores.authStore.hasAccessTo(
      "sys",
      "operator"
    );
    const hasDvrAdminRule = this.props.stores.authStore.hasAccessTo(
      "dvr",
      "admin"
    );
    let list = [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
    ];

    if (hasSysOperatorRule) {
      list.push({
        Header: "Sistema",
        accessor: "sys",
      });
    }
    if (hasDvrAdminRule) {
      list.push({
        Header: "CFTV",
        accessor: "dvr",
      });
    }

    return list;
  };

  getRuleLabel = (rules) => {
    const selectedRule = Object.entries(rules).find(([key, value]) => value);
    if (selectedRule) {
      return titleForRules(selectedRule[0]);
    }
    return "-";
  };

  render() {
    const { isFetching, filteredUsersList, searchString, setSearchString } =
      this.props.stores.usersStore;

    const selectedUser = this.state.isEditingToken
      ? filteredUsersList.find(
          ({ token }) => token === this.state.isEditingToken
        )
      : null;

    return (
      <Fragment>
        <PageTitle>Cadastro de usuários</PageTitle>
        {this.state.didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDelete}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <UserFormModal
          isOpen={this.state.isFormOpen}
          user={selectedUser}
          didCancel={this.didCancelForm}
        />
        <Grid container style={{ padding: "0 16px" }} spacing={2}>
          <Grid item xs={12}>
            <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
              <SearchBar value={searchString} onChange={setSearchString} />
            </SimpleCard>
          </Grid>
          <Grid item xs={12}>
            <SimpleCard>
              <Grid container>
                <Grid item xs={12}>
                  <Button color="primary" small onClick={this.didWantToAdd}>
                    Novo usuário <i className="fas fa-plus" />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {isFetching ? (
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <LoadingView />
                    </Grid>
                  ) : (
                    <Table
                      data={filteredUsersList.map(
                        ({ token, name, email, rules }) => {
                          return {
                            name,
                            email,
                            sys: this.getRuleLabel(rules.sys),
                            dvr: this.getRuleLabel(rules.dvr),
                            actions: token,
                          };
                        }
                      )}
                      columns={this.getColumns()}
                      actions={[
                        {
                          type: "delete",
                          onClick: this.didWantToDelete,
                        },
                        {
                          type: "edit",
                          onClick: this.didWantToUpdate,
                        },
                      ]}
                    />
                  )}
                </Grid>
              </Grid>
            </SimpleCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(UsersPage);
