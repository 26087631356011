// @ts-nocheck
import { action, observable } from "mobx";
import { grayColor } from "../assets/jss/app-js-styles";
import { stringDateFromUnixDate } from "../utils/DateUtils";

class TerminalModel {
  @observable command;
  name;
  serialNumber;
  comments;
  token;
  status;
  lastStatus;
  color;
  screenSize;
  screenInterface;
  uptime;
  diskFreeSpace;
  version;
  dhcp;
  internetInterface;
  localIPAddress;
  internetIPAddress;
  internetMacAddress;
  internetDownloadSpeed;
  internetUploadSpeed;
  timezone;
  bluetooth;
  wifi;
  rpi;
  @observable gps;
  wifiAntenna;
  operationalSystem;
  kernel;
  isSelected;
  usedIn;
  ramMemoryFree;
  diskSpeedRaw;

  constructor(terminal) {
    const terminalName = terminal?.name || "";

    this.command = terminal?.command || "";
    this.name = terminalName;
    this.serialNumber = terminal?.serial_number || "";
    this.comments = terminal?.comments || "";
    this.token = terminal?.token || "";
    this.status = terminal?.status || "offline";
    this.lastStatus = terminal?.last_status
      ? stringDateFromUnixDate(terminal.last_status, "DD/MM/YY HH:mm")
      : "—";
    this.color = terminal?.color || grayColor;
    this.screenSize = terminal?.screen_size || "";
    this.screenInterface = terminal?.screen_interface || "";
    this.uptime = terminal?.uptime || "";
    this.diskFreeSpace = terminal?.free_space || "0";
    this.version = terminal?.version || "";
    this.dhcp = terminal?.dhcp === "True";
    this.internetInterface = terminal?.internet_interface || "";
    this.localIPAddress = terminal?.local_address || "";
    this.internetIPAddress = terminal?.internet_address || "";
    this.internetMacAddress = terminal?.internet_macaddress || "";
    this.internetDownloadSpeed = terminal?.internet_download || "";
    this.internetUploadSpeed = terminal?.internet_upload || "";
    this.timezone = terminal?.timezone || "America/Sao_Paulo";
    this.bluetooth = terminal?.bluetooth || "";
    this.wifi = {
      ssid: terminal?.wifi_ssid || "",
      signal: terminal?.wifi_signal || "",
      quality: terminal?.wifi_quality || "",
      clients: terminal?.wifi_clients || "",
      leases: terminal?.wifi_leases || "",
    };
    this.rpi = {
      model: terminal?.rpi_model || "",
      temperature: terminal?.rpi_temperature || 50,
      throttled: terminal?.rpi_throttled || "0x0",
    };
    this.gps = {
      latitude: terminal?.latitude || null,
      longitude: terminal?.longitude || null,
      velocity: terminal?.velocity || null,
      altitude: terminal?.altitude || null,
      externalDevice: terminal?.gps === "True",
    };
    this.wifiAntenna = terminal?.wifi_antenna === "True";
    this.operationalSystem = terminal?.operational_system || "";
    this.kernel = terminal?.kernel || "";
    this.usedIn = {
      name: terminal?.used_in?.name || "",
      token: terminal?.used_in?.token || "",
    };
    this.ramMemoryFree = terminal?.free_mem || null;
    this.diskSpeedRaw = terminal?.disk_speed || 15;
  }

  @action
  setCommand(newCommand) {
    this.command = newCommand;
  }

  get isOnline() {
    return this.status === "online";
  }

  get label(): string {
    return this.name;
  }

  get hasSendCamCommand() {
    return this.command === "sendcam";
  }

  get hasCriticalAlert() {
    return (
      this.hasCriticalEnergyAlert ||
      this.hasCriticalTemperatureAlert ||
      this.hasCriticalDiskSpaceAlert ||
      (this.isOnline && this.hasCriticalDiskSpeedAlert)
    );
  }

  get hasTemperatureAlert() {
    return this.rpiTemperature > 75;
  }

  get hasCriticalTemperatureAlert() {
    return this.rpiTemperature > 90;
  }

  get hasEnergyAlert() {
    return this.rpi.throttled.includes("0x5000");
  }

  get hasCriticalEnergyAlert() {
    return this.rpi.throttled.includes("0x50005");
  }

  get hasDiskSpaceAlert() {
    return this.diskSpace < 5120;
  }

  get hasCriticalDiskSpaceAlert() {
    return this.diskSpace < 2048;
  }

  get hasDiskSpeedAlert() {
    return this.diskSpeed < 15;
  }

  get hasCriticalDiskSpeedAlert() {
    return this.diskSpeed < 10;
  }

  private get diskSpace() {
    const diskSpace = parseInt(this.diskFreeSpace, 10);
    return diskSpace > 0 ? diskSpace : 10000;
  }

  private get diskSpeed() {
    return parseInt(this.diskSpeedRaw, 10);
  }

  private get rpiTemperature() {
    return parseInt(this.rpi.temperature, 10);
  }
}

export default TerminalModel;
