// @ts-nocheck
import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import React from "react";
// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
import { Card, CardBody, CardHeader, CardIcon } from "../../components/Card";
import BarChart from "../../components/Charts/BarChart";
import { titleForRules } from "../../Model/ContractModel";

const TotalVideosWatchedChart = inject("stores")(
  observer(({ stores: { cftvReportsStore }, classes }) => {
    const { getGlobalReportData } = cftvReportsStore;

    if (!getGlobalReportData) {
      return null;
    }

    const { occurrences, watched, total, available, rule } =
      getGlobalReportData;

    return (
      <Card className={classes.chartCard}>
        <CardHeader color="black" icon>
          <CardIcon color="black">
            <i className="fas fa-video" />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            Relatório de Vídeos - {titleForRules(rule)}
          </h4>
        </CardHeader>
        <CardBody>
          <BarChart
            height={365}
            xAxisCategories={[
              "Total",
              "Assistidos",
              "Disponíveis",
              "Ocorrências",
            ]}
            data={[
              {
                name: "",
                data: [total, watched, available, occurrences],
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  })
);
export default withStyles(chartsStyle)(TotalVideosWatchedChart);
