// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

import { ListItemIcon, ListItemText } from "@material-ui/core";
import MaterialListItem from "@material-ui/core/ListItem";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Style
const styles = {
  icon: {
    width: 26,
    marginRight: 0,
    textAlign: "center",
  },
};

const renderIcon = (iconName) => <i className={`fas fa-${iconName}`} />;

const ListItem = ({
  classes,
  button,
  divider,
  disabled,
  dense,
  onClick,
  leading,
  icon,
  title,
  description,
}) => (
  <MaterialListItem
    button={button}
    divider={divider}
    disabled={disabled}
    dense={dense}
    onClick={onClick}
  >
    {leading && leading}
    {!leading && icon && (
      <ListItemIcon classes={{ root: classes.icon }}>
        {renderIcon(icon)}
      </ListItemIcon>
    )}
    <ListItemText primary={title} secondary={description} />
  </MaterialListItem>
);

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  leading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  icon: PropTypes.string,
  button: PropTypes.bool,
  divider: PropTypes.bool,
  disabled: PropTypes.bool,
  dense: PropTypes.bool,
};

export default withStyles(styles)(ListItem);
