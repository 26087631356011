// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import SimpleCard from "../components/Card/SimpleCard";
import SearchBar from "../components/SearchBar/SearchBar";
import PageTitle from "../components/PageTitle/PageTitle";
import InputSelect from "../components/Form/InputSelect";
import DatePicker from "../components/Form/DatePicker";
import Table from "../components/Table/Table";
import Button from "../components/Button/Button";
import ConfirmDeleteModal from "../components/Modal/ConfirmDeleteModal";
import Modal from "../components/Modal/Modal";
import Period from "../components/Period/Period";
import UpdateListProgressCounter from "../components/UpdateListProgressCounter/UpdateListProgressCounter";

import RequestScheduleForm from "./supervisorPage/RequestScheduleForm";

import { endOfTheDayMoment } from "../utils/DateUtils";

import {
  JOB_SCHEDULE_STATUS,
  JOB_SCHEDULE_RULES,
  titleForStatus,
  titleForRules,
} from "../Model/JobScheduleModel";

// Utils
import { stringDateFromUnixDate } from "../utils/DateUtils";

const statusListOptions = [
  ...Object.values(JOB_SCHEDULE_STATUS).map((status) => ({
    label: titleForStatus(status),
    value: status,
  })),
];
const rulesListOptions = [
  ...Object.values(JOB_SCHEDULE_RULES).map((rules) => ({
    label: titleForRules(rules),
    value: rules,
  })),
];

// Styel
const styles = {};

@inject("stores")
@observer
class SupervisorPage extends Component {
  state = {
    didWantToDelete: false,
    tokenToDelete: null,
    isOpenModal: false,
  };

  componentDidMount() {
    this.refreshJobs(true)();

    this.props.stores.vehicleStore.getVehiclesList({
      withFetchingAnimation: true,
      filterByCamera: true,
      filterByGPS: false,
    });
  }

  refreshJobs = (withFetching) => () => {
    const { jobScheduleStore } = this.props.stores;
    jobScheduleStore.setIsFetching(withFetching);
    jobScheduleStore.getJobScheduleList();
  };

  displayDate = (date) => stringDateFromUnixDate(date, "DD/MM/YYYY HH:mm");

  didWantToDelete =
    ({ token }) =>
    () => {
      this.setState({
        didWantToDelete: true,
        tokenToDelete: token,
      });
    };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
      tokenToDelete: null,
    });
  };

  didDelete = async () => {
    await this.props.stores.jobScheduleStore.delete(this.state.tokenToDelete);

    this.didCancelDeleteAction();
  };

  didOpenModal = () => this.setState({ isOpenModal: true });
  didCloseModal = () => this.setState({ isOpenModal: false });

  renderStatus = (status) => {
    return titleForStatus(status);
  };

  onChangeFromDate = (_, newValue) => {
    const { setNewDataFilter, toDate } = this.props.stores.jobScheduleStore;
    const newToDate = toDate.isBefore(newValue) ? newValue : toDate;
    setNewDataFilter(newValue, newToDate);
  };

  onChangeToDate = (_, newValue) => {
    const { setNewDataFilter, fromDate } = this.props.stores.jobScheduleStore;
    const newFromDate = fromDate.isAfter(newValue) ? newValue : fromDate;
    setNewDataFilter(newFromDate, newValue);
  };

  canDeleteWithStatus = (status) => {
    switch (status) {
      case JOB_SCHEDULE_STATUS.NOT_FOUND:
      case JOB_SCHEDULE_STATUS.SCHEDULED:
      case JOB_SCHEDULE_STATUS.READY:
        return true;

      default:
        return false;
    }
  };

  render() {
    const { isOpenModal } = this.state;
    const {
      isFetching,
      jobScheduleList,
      searchString,
      setSearchString,
      filterByStatus,
      filterByRule,
      fromDate,
      toDate,
      setFilterByStatus,
      setFilterByRule,
      page,
      limit,
      onChangePaginate,
      totalItems,
      isFetchingPaginate,
    } = this.props.stores.jobScheduleStore;

    // TODO: Utilizar o componente DateFromAndDateToPickers aqui;

    return (
      <Fragment>
        <RequestScheduleForm didCancel={this.didCloseModal}>
          {({ body, action }) => (
            <Modal
              isOpen={isOpenModal}
              didCloseModal={this.didCloseModal}
              fullScreen
              modalTitle="Registrar novos trabalhos para revisão"
              actions={action}
            >
              {body}
            </Modal>
          )}
        </RequestScheduleForm>
        <PageTitle>Supervisor</PageTitle>
        {this.state.didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDelete}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        <UpdateListProgressCounter callback={this.refreshJobs(false)} />
        <Grid
          container
          justify="space-between"
          style={{ padding: "0 15px", marginBottom: 20 }}
        >
          <Grid item xs sm md lg>
            <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12} sm={6} md={3}>
                  <SearchBar value={searchString} onChange={setSearchString} />
                </Grid>
                <Grid container item xs={12} sm={6} md={3}>
                  <Grid item xs={12} sm>
                    <DatePicker
                      label="Data início"
                      name="fromDate"
                      value={fromDate}
                      onChange={this.onChangeFromDate}
                      fullWidth
                      removeMargin
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <DatePicker
                      label="Data fim"
                      name="toDate"
                      value={toDate}
                      onChange={this.onChangeToDate}
                      fullWidth
                      removeMargin
                      isValidDate={($0) =>
                        $0.isSameOrBefore(endOfTheDayMoment())
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InputSelect
                    label="Tipo de usuário"
                    name="rule"
                    value={rulesListOptions.find(
                      ({ value }) => value === filterByRule
                    )}
                    options={rulesListOptions}
                    onChange={(_, selectedRule) => {
                      setFilterByRule(selectedRule.value);
                    }}
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InputSelect
                    label="Filtre por status"
                    name="status"
                    value={statusListOptions.find(
                      ({ value }) => value === filterByStatus[0]
                    )}
                    options={statusListOptions}
                    onChange={(_, selectedStatus) => {
                      setFilterByStatus([selectedStatus.value]);
                    }}
                    withPortal
                    formControlStyles={{ margin: 0, padding: 0 }}
                  />
                </Grid>
              </Grid>
            </SimpleCard>
          </Grid>
        </Grid>

        <Grid container style={{ padding: "0 16px" }} spacing={2}>
          <Grid item xs={12}>
            <SimpleCard>
              <Button small color="primary" onClick={this.didOpenModal}>
                Adicionar <i className="fas fa-plus" />
              </Button>
              <Table
                manual
                isLoading={isFetching || isFetchingPaginate}
                hidePagination={isFetching}
                page={page}
                totalItems={totalItems}
                pageSize={limit}
                didChangePagination={({ page, pageSize }) =>
                  onChangePaginate(page, pageSize)
                }
                data={jobScheduleList.map(
                  ({
                    token,
                    operator,
                    supervisor,
                    analyst,
                    vehicle,
                    dateCreate,
                    fromDate,
                    toDate,
                    totalEvents,
                    viewedTime,
                    status,
                  }) => {
                    return {
                      status: this.renderStatus(status),
                      dateCreate: this.displayDate(dateCreate),
                      vehicle,
                      supervisor: supervisor.name,
                      operator: operator ? operator.name : "—",
                      analyst: analyst ? analyst.name : "—",
                      totalEvents,
                      viewedTime,
                      period: <Period fromDate={fromDate} toDate={toDate} />,
                      actions: {
                        token,
                        canDelete: this.canDeleteWithStatus(status),
                      },
                    };
                  }
                )}
                actions={[
                  {
                    type: "delete",
                    onClick: this.didWantToDelete,
                  },
                ]}
                columns={[
                  {
                    Header: "Data requisição",
                    accessor: "dateCreate",
                    sortable: false,
                  },
                  {
                    Header: "Veículo",
                    accessor: "vehicle",
                    sortable: false,
                  },
                  {
                    Header: "Período",
                    accessor: "period",
                    sortable: false,
                  },
                  {
                    Header: "Supervisor",
                    accessor: "supervisor",
                    sortable: false,
                  },
                  {
                    Header: "Analista",
                    accessor: "analyst",
                    sortable: false,
                  },
                  {
                    Header: "Operador",
                    accessor: "operator",
                    sortable: false,
                  },
                  {
                    Header: "Visualizado por",
                    accessor: "viewedTime",
                    sortable: false,
                  },
                  {
                    Header: "Eventos",
                    accessor: "totalEvents",
                    sortable: false,
                    getHeaderProps: () => ({
                      style: {
                        textAlign: "center",
                      },
                    }),
                    getProps: () => ({
                      style: {
                        textAlign: "center",
                      },
                    }),
                  },
                ]}
              />
            </SimpleCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(SupervisorPage);
