// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject } from "mobx-react";

// Components
import { InputText, InputSwitch, InputColorPicker } from "../Form";
import InputFile from "../Form/InputFile";
import InputMap from "../Form/InputMap";

import AudioPlayer from "../Audio/AudioPlayer";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

import withStyles from "@material-ui/core/styles/withStyles";

import {
  backgroundColorOptions,
  fontColorOptions,
} from "../../utils/ColorUtils";

// Translate
import {
  DeleteString,
  CancelString,
  FormErrorString,
} from "../I18n/CommonStrings";

// Style
const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
};

@inject("stores")
class StopConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.stop) {
      this.state = this.getStateFromExistingStop(props.stop);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    return {
      name: "",
      audioFile: null,
      audioUrl: "",
      latitude: "-15.799889",
      longitude: "-47.887267",
      bearing: null,
      meters: "",
      backgroundColor: "#a61414",
      fontColor: "#ffffff",
      isTransparent: false,
      duration: "",
      isWaitingForm: false,
      formWasSubmit: false,
      modalIsOpen: false,
      wasEdited: false,
      isValid: {
        name: false,
      },
    };
  };

  getStateFromExistingStop = (stop) => {
    return {
      ...this.getDefaultState(),
      name: stop.name,
      audioUrl: stop.audioUrl,
      latitude: stop.latitude,
      longitude: stop.longitude,
      bearing: stop.bearing,
      meters: stop.meters,
      backgroundColor: stop.backgroundColor,
      fontColor: stop.fontColor,
      isTransparent: stop.isTransparent,
      duration: stop.duration,
      isValid: {
        name: stop.name.length >= 2,
      },
    };
  };

  onChangeInputText = (event) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  onChangeMapInput = (_, { latitude, longitude }) => {
    this.setState({ latitude: `${latitude}`, longitude: `${longitude}` });
  };

  onChangeIsTransparent = () => {
    this.setState((prevState) => ({
      isTransparent: !prevState.isTransparent,
      wasEdited: true,
    }));
  };

  onChangeInputColorPicker = ({ name: key, color }) => {
    this.setState({ [key]: color });
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    const { name } = isValid;
    if (name) {
      return true;
    }

    return false;
  };

  onChangeFileUpload = (_, { name, size, type, base64 }) => {
    this.setState({
      audioFile: {
        name,
        size,
        type,
        base64,
      },
      wasEdited: true,
    });
  };

  getFormDataToSubmit = ({
    name,
    audioFile,
    latitude,
    longitude,
    bearing,
    meters,
    backgroundColor,
    fontColor,
    isTransparent,
    duration,
  }) => ({
    name,
    latitude,
    longitude,
    bearing,
    meters,
    backgroundColor,
    fontColor,
    isTransparent,
    duration,
    audio: audioFile,
  });

  submitForm = () => {
    const { stop, stores } = this.props;
    const formData = {
      ...this.getFormDataToSubmit(this.state),
    };
    if (stop) {
      stores.stopsStore
        .update({
          ...stop,
          ...formData,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      stores.stopsStore
        .submit(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.stores.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = (_) => {
    this.props.didCancel();
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  renderUploadAudio = () => {
    const { audioFile, audioUrl } = this.state;
    return (
      <InputFile
        name="audioUrl"
        label="Áudio"
        onChangeFile={this.onChangeFileUpload}
        accept="audio/mp3"
      >
        {(audioUrl || audioFile) && (
          <div style={{ marginBottom: 10 }}>
            <AudioPlayer src={audioFile ? audioFile.base64 : audioUrl} />
          </div>
        )}
      </InputFile>
    );
  };

  render() {
    const { classes, stop } = this.props;
    const isEditing = stop !== null;

    const {
      name,
      latitude,
      longitude,
      meters,
      backgroundColor,
      fontColor,
      isTransparent,
      duration,
      formWasSubmit,
      isValid,
      isWaitingForm,
    } = this.state;

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <h3 className={classes.title}>
              {isEditing ? "Configurar" : "Adicionar"} parada
            </h3>
          </GridItem>
          <GridItem xs={12}>
            <InputText
              label="Nome"
              name="name"
              isRequired
              hasError={formWasSubmit && !isValid.name}
              hasSuccess={formWasSubmit && isValid.name}
              value={name}
              onChange={this.onChangeInputText}
              minLength={2}
            />
          </GridItem>
          <GridItem xs={12}>{this.renderUploadAudio()}</GridItem>
          <GridItem xs={12}>
            <InputMap
              name="location"
              latitude={latitude}
              longitude={longitude}
              onChange={this.onChangeMapInput}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <InputText
              label="Distância"
              name="meters"
              inputProps={{
                autoComplete: "off",
              }}
              value={meters}
              onChange={this.onChangeInputText}
              helpText="Em metros"
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <InputText
              label="Duração"
              name="duration"
              inputProps={{
                autoComplete: "off",
              }}
              value={duration}
              onChange={this.onChangeInputText}
              helpText="Em segundos"
            />
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={6} md={4}>
            <InputColorPicker
              label="Cor de fundo"
              name="backgroundColor"
              options={backgroundColorOptions}
              color={backgroundColor}
              onChange={this.onChangeInputColorPicker}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InputColorPicker
              label="Cor do texto"
              name="fontColor"
              options={fontColorOptions}
              color={fontColor}
              onChange={this.onChangeInputColorPicker}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InputSwitch
              label="Transparência"
              name="isTransparent"
              isActive={isTransparent}
              onChange={this.onChangeIsTransparent}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={4}>
            <Button block onClick={this.didCancel}>
              <i className="fas fa-chevron-left" /> <CancelString />
            </Button>
          </GridItem>
          {isEditing && (
            <GridItem xs={12} sm={4}>
              <Button color="danger" block onClick={this.props.didWantToDelete}>
                <DeleteString /> <i className="fas fa-trash-alt" />
              </Button>
            </GridItem>
          )}
          <GridItem xs={12} sm={4}>
            <Button
              color="primary"
              block
              disabled={!this.state.wasEdited}
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? "Atualizar" : "Salvar"} <i className="fas fa-save" />
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(StopConfigurationForm);
