// @ts-nocheck
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { inject, observer } from "mobx-react";

import Gravatar from "../Gravatar/Gravatar";

import { APP_ROUTES } from "../../routes/app-routes";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  AppBar,
  Toolbar,
  Hidden,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { GridContainer, GridItem } from "../Grid";

// core components
import { Button, LinkButton } from "../Button";

import headerStyle from "../../assets/jss/js-styles/components/headerStyle";
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  ...headerStyle,
  logoToolbar: {
    backgroundColor: "#fff",
    minHeight: "initial",
    padding: "6px 24px",
  },
  logo: {
    width: 40,
  },
  navToolbar: {
    minHeight: "initial",
    padding: "0 24px",
  },
  navigationButton: {
    margin: 0,
    padding: "16px 10px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
  menuItem: {
    border: "2px dashed transparent",
  },
  selectedMenuItem: {
    borderColor: primaryColor,
  },
  serverTitle: {
    margin: 0,
    color: "#333",
    fontSize: "1.2em",
    cursor: "pointer",
    textTransform: "uppercase",
    "& small": {
      textTransform: "lowercase",
    },
  },
};

@inject("stores", "appVersion")
@observer
class Header extends Component {
  state = {
    menuAnchorEl: null,
    showVersion: false,
    selectedRoute: null,
  };

  didOpenMenu = (event) => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  didCloseMenu = () => {
    this.setState({ menuAnchorEl: null }, () => {
      setTimeout(() => this.setState({ selectedRoute: null }), 200);
    });
  };

  openMenu = (path) => () => {
    this.didCloseMenu();
    this.props.history.push(path);
  };

  logout = () => {
    this.props.stores.authStore.logout();
  };

  didShowVersion = () => {
    this.setState({ showVersion: true });
  };
  didHideVersion = () => {
    this.setState({ showVersion: false });
  };

  didSelectRoute = (selectedRoute) => (event) => {
    this.setState({
      selectedRoute,
      menuAnchorEl: event.currentTarget,
    });
  };

  renderMenuItems = () => {
    const { classes } = this.props;
    if (this.state.selectedRoute) {
      const { submenus } = this.state.selectedRoute;

      return submenus.map(({ path, name, icon }) => {
        const isSelected = this.props.location.pathname.includes(path);
        return (
          <MenuItem
            key={path}
            classes={{
              root: classnames({
                [classes.menuItem]: true,
                [classes.selectedMenuItem]: isSelected,
              }),
            }}
            onClick={this.openMenu(path)}
          >
            <ListItemIcon>
              <i className={`fas fa-fw fa-${icon}`} />
            </ListItemIcon>
            <ListItemText primary={name} />
          </MenuItem>
        );
      });
    }

    return [
      <MenuItem key="profile" onClick={this.openMenu(APP_ROUTES.PROFILE)}>
        <ListItemIcon>
          <i className="fas fa-fw fa-user" />
        </ListItemIcon>
        <ListItemText primary="Meu perfil" />
      </MenuItem>,
      <Divider key="divider" component="li" />,
      <MenuItem key="logout" onClick={this.logout}>
        <ListItemIcon>
          <i className="fas fa-fw fa-sign-out-alt" />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </MenuItem>,
    ];
  };

  renderMenu = () => {
    const { menuAnchorEl } = this.state;
    return (
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={this.didCloseMenu}
        style={{ marginTop: this.state.selectedRoute ? 50 : 20 }}
      >
        {this.renderMenuItems()}
      </Menu>
    );
  };

  renderCollapseMenuRoute = (route) => {
    const { classes, location } = this.props;
    const { selectedRoute } = this.state;
    const { path, name, icon } = route;

    const matchPath = location.pathname.includes(path);

    const isSelected =
      (selectedRoute && selectedRoute.path === path) || matchPath;

    return (
      <Button
        key={path}
        color={isSelected ? "primary" : "transparent"}
        className={classes.navigationButton}
        onClick={this.didSelectRoute(route)}
      >
        <i
          className={`fas fa-${icon}`}
          style={{ marginRight: 6, width: "1.25em" }}
        />{" "}
        {name}{" "}
        <i
          className="fas fa-chevron-down"
          style={{ fontSize: 12, marginTop: 6 }}
        />
      </Button>
    );
  };

  render() {
    const { classes, routes, handleMobileDrawerToggle } = this.props;

    const { authStore, contractStore, settingsStore } = this.props.stores;
    const { name, email } = authStore.currentUser;

    return (
      <AppBar className={classes.primary} position="sticky">
        {this.renderMenu()}
        <Toolbar className={classes.logoToolbar}>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem>
              <LinkButton
                color="transparent"
                justIcon
                style={{ margin: 0 }}
                to="/"
              >
                <img
                  src={settingsStore.logoImage}
                  alt="Logotipo"
                  className={classes.logo}
                />
              </LinkButton>
            </GridItem>
            <GridItem
              onClick={contractStore.didWantToRemoveSelectedContract}
              xs
            >
              <p
                className={classes.serverTitle}
                onMouseEnter={this.didShowVersion}
                onMouseLeave={this.didHideVersion}
              >
                {contractStore.selectedContract.name}{" "}
                {this.state.showVersion && (
                  <small>v{this.props.appVersion}</small>
                )}
              </p>
            </GridItem>
            <GridItem>
              <Button
                color="transparent"
                justIcon
                style={{ margin: 0 }}
                onClick={this.didOpenMenu}
              >
                <Gravatar name={name} email={email} size={30} />
              </Button>
            </GridItem>
          </GridContainer>
        </Toolbar>
        {routes.length > 1 && (
          <Toolbar className={classes.navToolbar}>
            <GridContainer justify="space-between" alignItems="center">
              <Hidden smDown>
                <GridItem xs={12}>
                  {routes.map((route) => {
                    if (route.collapse) {
                      return this.renderCollapseMenuRoute(route);
                    }

                    const isSelected = this.props.location.pathname.includes(
                      route.path
                    );

                    return (
                      <LinkButton
                        key={route.path}
                        color={isSelected ? "primary" : "transparent"}
                        className={classes.navigationButton}
                        to={route.path}
                      >
                        <i
                          className={`fas fa-${route.icon}`}
                          style={{ marginRight: 6, width: "1.25em" }}
                        />{" "}
                        {route.name}
                      </LinkButton>
                    );
                  })}
                </GridItem>
              </Hidden>

              <Hidden mdUp>
                <Button
                  className={classes.mobileMenuButton}
                  color="transparent"
                  justIcon
                  aria-label="open drawer"
                  onClick={handleMobileDrawerToggle}
                >
                  <i className="fas fa-bars" />
                </Button>
              </Hidden>
            </GridContainer>
          </Toolbar>
        )}
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(Header);
