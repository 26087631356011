// @ts-nocheck
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TwitterPicker } from "react-color";

class InputColorPicker extends Component {
  state = {
    displayPicker: false,
  };

  toggleDisplayPicker = () => {
    if (!this.props.readOnly) {
      this.setState({
        displayPicker: !this.state.displayPicker,
      });
    }
  };

  handleChange = (color) => {
    const { name, onChange } = this.props;
    this.setState({ displayPicker: false }, () => {
      onChange({ name, color: color.hex });
    });
  };

  render() {
    const { label, color, hasError, hasSuccess, hidden, options } = this.props;
    const styles = {
      label: {
        display: "block",
      },
      color: {
        width: "100%",
        height: "26px",
        borderRadius: "4px",
        background: `${color}`,
        border: "1px solid rgba(0,0,0,.1)",
      },
      swatch: {
        padding: "4px",
        background: "#fff",
        borderRadius: "4px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        width: "100%",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        top: 44,
        left: 8,
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    };

    return (
      <div
        className={classNames({
          "form-group": true,
          "has-error": hasError,
          "has-success": hasSuccess,
          hidden: hidden,
        })}
        style={{ position: "relative" }}
      >
        <label className="control-label" style={styles.label}>
          {label}
        </label>
        <div style={styles.swatch} onClick={this.toggleDisplayPicker}>
          <div style={styles.color} />
        </div>
        {this.state.displayPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.toggleDisplayPicker} />
            <TwitterPicker
              color={color}
              onChange={this.handleChange}
              colors={options}
              style={{ backgroundColor: "#000" }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

InputColorPicker.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  disabled: PropTypes.bool,
  isWaiting: PropTypes.bool,
  hidden: PropTypes.bool,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputColorPicker;
