// @ts-nocheck
// ##############################
// // // IconCard styles
// #############################

import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  cardTitle,
} from "../../app-js-styles";

const chartsStyle = {
  chartGrid: {
    marginBottom: 50,
  },
  chartCard: {
    height: "100%",
    margin: 0,
  },
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  legendTitle: {
    color: grayColor,
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  cardFooter: {
    display: "block",
  },
};

export default chartsStyle;
