// @ts-nocheck
import React, { Component } from "react";

import { saveComponentErrorLog } from "../store/FirebaseStore/FirebaseStore";
import Translate from "./I18n/Translate";

import { GridFullScreen } from "./Grid";

class ErrorCatcher extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState(
      {
        hasError: true,
      },
      () => {
        saveComponentErrorLog({
          error: error && error.toString(),
          ...errorInfo,
        });
      }
    );
  }

  render() {
    if (this.props.showErrorOnTheScreen && this.state.hasError) {
      return (
        <GridFullScreen>
          <p style={{ fontSize: "1.2em" }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 6 }}
            />
            <Translate id="Common@interfaceCatchErrorMessage">
              Ocorreu um erro inesperado. Em caso de dúvidas entre em contato
              com o suporte.
            </Translate>
          </p>
        </GridFullScreen>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorCatcher.defaultProps = {
  showErrorOnTheScreen: true,
};

export default ErrorCatcher;
