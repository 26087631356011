// @ts-nocheck
import { Component } from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";

class PhoneWithSMSLink extends Component {
  render() {
    const { children } = this.props;
    const phone = parsePhoneNumberFromString(children);

    if (phone && phone.isValid()) {
      const formattedPhone = phone.formatInternational();
      return formattedPhone;
    }
    return "—";
  }
}
export default PhoneWithSMSLink;
