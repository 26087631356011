// @ts-nocheck
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { FormControlLabel, Switch } from "@material-ui/core";

// Style
import customCheckboxRadioSwitch from "../../assets/jss/js-styles/customCheckboxRadioSwitch";

const styles = {
  ...customCheckboxRadioSwitch,
  formControlLabel: {
    marginRight: 30,
  },
  formControlLabelVertical: {
    flexDirection: "column",
    marginLeft: 0,
    padding: 8,
    marginRight: "1%",
    marginBottom: "1%",
    border: "1px solid #AAAAAA",
    borderRadius: 8,
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    marginRight: 0,
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#AAAAAA",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    marginLeft: -10,
  },
  labelVertical: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#AAAAAA",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
  },
};

const InputSwitch = ({
  classes,
  isActive,
  onChange,
  name,
  disabled,
  alignCenter,
  labelInVertical,
  label,
}) => (
  <FormControlLabel
    className={classnames({
      [classes.formControlLabel]: !labelInVertical,
      [classes.formControlLabelVertical]: labelInVertical,
      [classes.alignCenter]: alignCenter,
    })}
    disabled={disabled}
    control={
      <Switch
        checked={isActive}
        onChange={() => onChange(name)}
        value="muted"
        disabled={disabled}
        classes={{
          switchBase: classes.switchBase,
          checked: classes.switchChecked,
          thumb: classes.switchIcon,
          track: classes.switchBar,
        }}
      />
    }
    classes={{
      label: labelInVertical ? classes.labelVertical : classes.label,
    }}
    label={label}
  />
);

InputSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  labelInVertical: PropTypes.bool,
  alignCenter: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default withStyles(styles)(InputSwitch);
