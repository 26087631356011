// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import Modal from "../../components/Modal/Modal";
import GarageForm from "./GarageForm";

@inject("stores")
@observer
class GarageFormModal extends Component {
  render() {
    const { isOpen, didCancel, ...rest } = this.props;
    return (
      <GarageForm didCancel={didCancel} {...rest}>
        {({ isEditing, body, action }) => (
          <Modal
            modalTitle={isEditing ? "Atualizar garagem" : "Cadastrar garagem"}
            isOpen={isOpen}
            didCloseModal={didCancel}
            actions={action}
            maxWidth="xs"
          >
            {body}
          </Modal>
        )}
      </GarageForm>
    );
  }
}
export default GarageFormModal;
